@media screen and (max-width: 767px) {.Stock__Head_1-O9h {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }
  @media (--screen--xs-max) {.Stock__Head_1-O9h {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }
  @media screen and (min-width: 768px) {.Stock__Head_1-O9h {
    grid-column: span 6
}
  }
  @media (--screen--sm-min) {.Stock__Head_1-O9h {
    grid-column: span 6
}
  }
  @media print {
    .Stock__Head_1-O9h > a {
      display: none;
    }
  }

.Stock__Title_2s7eo {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media print {

.Stock__Title_2s7eo {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Stock__Description_Jh1Hr {
    grid-column: span 6
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Stock__Description_Jh1Hr {
    grid-column: span 6
}
  }

@media screen and (min-width: 1366px) {

.Stock__Description_Jh1Hr {
    grid-column: 7 / span 5
}
  }

@media (--screen--lg-min) {

.Stock__Description_Jh1Hr {
    grid-column: 7 / span 5
}
  }

@media screen and (max-width: 767px) {

.Stock__Chart_1GXhf {
    position: relative;
    left: 50%;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 48px;
    margin-left: -50vw
}
  }

@media (--screen--xs-max) {

.Stock__Chart_1GXhf {
    position: relative;
    left: 50%;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 48px;
    margin-left: -50vw
}
  }

@media print {

.Stock__Chart_1GXhf {
    display: none
}
  }
