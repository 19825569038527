.Accordion__Block_301o1 {
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.3);
}

  .Accordion__Block_301o1:not(:first-child) {
    padding-top: 16px;
  }

.Accordion__Question_tuh5P,
.Accordion__Answer_3f4-v {
  padding: 0 26px;
}

.Accordion__Question_tuh5P {
  --rotate: -45deg;

  position: relative;
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  cursor: pointer;
  font-weight: 500;
}

.Accordion__Question_tuh5P::before,
  .Accordion__Question_tuh5P::after {
    position: absolute;
    top: 11px;
    left: 0;
    width: 7px;
    height: 1px;
    background: rgb(18, 82, 245);
    background: var(--color--second-blue);
    content: "";
    transition: transform ease-in-out 0.3s;
    will-change: transform;
  }

.Accordion__Question_tuh5P::after {
    transform: translateX(-2px) rotate(calc(var(--rotate) * -1));
  }

.Accordion__Question_tuh5P::before {
    transform: translateX(2px) rotate(var(--rotate));
  }

.Accordion__Question_tuh5P[data-state="open"] {
    --rotate: 45deg;
  }

.Accordion__AnswerWrapper_1ytZM {
  overflow: hidden;
  height: 0;
  transition: height ease-in-out 0.2s;
}

.Accordion__Answer_3f4-v {
  padding-top: 16px;
  transform-origin: top;
}

.Accordion__Answer_3f4-v a {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
    font-weight: 500;
  }

.Accordion__FadeEnter_gWPIy {
  opacity: 0;
  transform: translateY(-20px) scaleY(0.8);
}

.Accordion__FadeEnterActive_LvO-g {
  opacity: 1;
  transform: translateY(0) scaleY(1);
  transition: opacity ease-in-out 0.3s, transform ease-in-out 0.2s;
}

.Accordion__FadeExitActive_3AZjD {
  opacity: 0;
  transform: translateY(-20px) scaleY(0.8);
  transition: opacity ease-in-out 0.15s, transform ease-in-out 0.2s;
}
