@media screen and (max-width: 1023px) {.Content__Title_61SjI {
    margin-bottom: 8px;
    margin-bottom: var(--padding--sm)
}
  }
  @media (--screen--sm-max) {.Content__Title_61SjI {
    margin-bottom: 8px;
    margin-bottom: var(--padding--sm)
}
  }
  @media screen and (min-width: 1024px) {.Content__Title_61SjI {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg)
}
  }
  @media (--screen--md-min) {.Content__Title_61SjI {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg)
}
  }

@media screen and (max-width: 1023px) {

.Content__Description_1YU2_ {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

@media (--screen--sm-max) {

.Content__Description_1YU2_ {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

@media screen and (min-width: 1024px) {

.Content__Description_1YU2_ {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }

@media (--screen--md-min) {

.Content__Description_1YU2_ {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }

@media screen and (max-width: 1023px) {

.Content__Description_1YU2_ > p + p {
      margin-top: 16px;
      margin-top: var(--padding--lg)
  }
    }

@media (--screen--sm-max) {

.Content__Description_1YU2_ > p + p {
      margin-top: 16px;
      margin-top: var(--padding--lg)
  }
    }

@media screen and (min-width: 1024px) {

.Content__Description_1YU2_ > p + p {
      margin-top: 24px;
      margin-top: var(--padding--xl)
  }
    }

@media (--screen--md-min) {

.Content__Description_1YU2_ > p + p {
      margin-top: 24px;
      margin-top: var(--padding--xl)
  }
    }

.Content__Form_g-FOx {
  display: grid;
  grid-gap: 24px;
  grid-gap: var(--padding--xl);
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 767px) {

.Content__Form_g-FOx {
    padding-bottom: 40px;
    padding-bottom: var(--padding--3xl)
}
  }

@media (--screen--xs-max) {

.Content__Form_g-FOx {
    padding-bottom: 40px;
    padding-bottom: var(--padding--3xl)
}
  }

@media screen and (min-width: 768px) {

.Content__Form_g-FOx {
    padding-bottom: 48px
}
  }

@media (--screen--sm-min) {

.Content__Form_g-FOx {
    padding-bottom: 48px
}
  }

.Content__Form_g-FOx > div {
    grid-column: 1 / -1;
  }

@media screen and (min-width: 1024px) {

.Content__Form_g-FOx > div:nth-child(2) {
        grid-column: 1
    }
      }

@media (--screen--md-min) {

.Content__Form_g-FOx > div:nth-child(2) {
        grid-column: 1
    }
      }

@media screen and (min-width: 1024px) {

.Content__Form_g-FOx > div:nth-child(3) {
        grid-column: 2
    }
      }

@media (--screen--md-min) {

.Content__Form_g-FOx > div:nth-child(3) {
        grid-column: 2
    }
      }

@media screen and (min-width: 1024px) {

.Content__Form_g-FOx > div:nth-child(5) {
        grid-column: 1
    }
      }

@media (--screen--md-min) {

.Content__Form_g-FOx > div:nth-child(5) {
        grid-column: 1
    }
      }

@media screen and (min-width: 1024px) {

.Content__Form_g-FOx > div:nth-child(6) {
        grid-column: 2
    }
      }

@media (--screen--md-min) {

.Content__Form_g-FOx > div:nth-child(6) {
        grid-column: 2
    }
      }

@media screen and (min-width: 768px) {

.Content__SendWrapper_2UjM- {
    display: flex;
    align-items: center
}
  }

@media (--screen--sm-min) {

.Content__SendWrapper_2UjM- {
    display: flex;
    align-items: center
}
  }

@media screen and (max-width: 1023px) {

.Content__SendWrapper_2UjM- {
    margin-top: 8px
}
  }

@media (--screen--sm-max) {

.Content__SendWrapper_2UjM- {
    margin-top: 8px
}
  }

@media screen and (min-width: 1024px) {

.Content__SendWrapper_2UjM- {
    margin-top: 10px
}
  }

@media (--screen--md-min) {

.Content__SendWrapper_2UjM- {
    margin-top: 10px
}
  }

.Content__SendPolicy_35LiQ {
  font-size: 12px;
  line-height: 16px;
}

@media screen and (max-width: 767px) {

.Content__SendPolicy_35LiQ {
    margin-top: 16px
}
  }

@media (--screen--xs-max) {

.Content__SendPolicy_35LiQ {
    margin-top: 16px
}
  }

@media screen and (min-width: 768px) {

.Content__SendPolicy_35LiQ {
    width: 281px;
    margin-left: 24px
}
  }

@media (--screen--sm-min) {

.Content__SendPolicy_35LiQ {
    width: 281px;
    margin-left: 24px
}
  }

.Content__SendPolicy_35LiQ > a {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
  }
