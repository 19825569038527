.Hero__Wrapper_13FQw {
  position: relative;
  overflow: hidden;
  padding-bottom: var(--padding--4xl);
}

.Hero__WrapperTags_2xVTb {
  position: relative;
}

.Hero__Title_uiOO6 {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Hero__Tags_3nDot {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -16px;
}

@media screen and (max-width: 767px) {

.Hero__Tags_3nDot {
    display: none
}
  }

@media (--screen--xs-max) {

.Hero__Tags_3nDot {
    display: none
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Hero__Tags_3nDot {
    margin-top: 283px
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Hero__Tags_3nDot {
    margin-top: 283px
}
  }

@media screen and (min-width: 1366px) {

.Hero__Tags_3nDot {
    margin-top: 450px
}
  }

@media (--screen--lg-min) {

.Hero__Tags_3nDot {
    margin-top: 450px
}
  }

.Hero__Tag_ubJRa {
  margin-bottom: 16px;
}

.Hero__Tag_ubJRa:not(:last-child) {
    margin-right: 16px;
  }

.Hero__Head_21VjI {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {

.Hero__Head_21VjI {
    display: flex;
    justify-content: space-between
}
  }

@media (--screen--sm-min) {

.Hero__Head_21VjI {
    display: flex;
    justify-content: space-between
}
  }

.Hero__Factoids_7-9yC {
  display: flex;
}

.Hero__Factoid_lHedt:not(:last-child) {
    margin-right: 32px;
  }

.Hero__FactoidNumber_2OkF6 {
  margin-bottom: 8px;
  margin-bottom: var(--padding--sm);
}

.Hero__FactoidText_zO6oz {
  color: rgb(0, 196, 255);
  color: var(--color--accent-light-blue);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-transform: uppercase;
}
