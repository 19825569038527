@media screen and (max-width: 767px) {.Card__Wrapper_1xFgs {
    padding-bottom: 20px
}

    .Card__Wrapper_1xFgs:not(:last-child) {
      border-bottom: 1px solid rgba(18, 82, 245, 0.1);
      border-bottom: 1px solid rgba(var(--rgb--second-blue), 0.1);
    }
  }
  @media (--screen--xs-max) {.Card__Wrapper_1xFgs {
    padding-bottom: 20px
}

    .Card__Wrapper_1xFgs:not(:last-child) {
      border-bottom: 1px solid rgba(18, 82, 245, 0.1);
      border-bottom: 1px solid rgba(var(--rgb--second-blue), 0.1);
    }
  }
  @media screen and (min-width: 768px) {.Card__Wrapper_1xFgs {
    padding: 24px 32px 28px;
    border: 1px solid rgba(18, 82, 245, 0.1);
    border: 1px solid rgba(var(--rgb--second-blue), 0.1);
    border-radius: 2px;
    box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1)
}
  }
  @media (--screen--sm-min) {.Card__Wrapper_1xFgs {
    padding: 24px 32px 28px;
    border: 1px solid rgba(18, 82, 245, 0.1);
    border: 1px solid rgba(var(--rgb--second-blue), 0.1);
    border-radius: 2px;
    box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1)
}
  }
  .Card__Wrapper_1xFgs table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  .Card__Wrapper_1xFgs table td,
    .Card__Wrapper_1xFgs table th {
      padding-top: 12px;
      padding-right: 0;
      padding-bottom: 12px;
      padding-left: 0;
    }
  .Card__Wrapper_1xFgs td {
    vertical-align: top;
  }
  @media screen and (max-width: 1023px) {
  .Card__Wrapper_1xFgs td:first-child {
        width: 30%
    }
      }
  @media (--screen--sm-max) {
  .Card__Wrapper_1xFgs td:first-child {
        width: 30%
    }
      }
  @media screen and (min-width: 1024px) and (max-width: 1365px) {
  .Card__Wrapper_1xFgs td:first-child {
        width: 30%
    }
      }
  @media (--screen--md-only) {
  .Card__Wrapper_1xFgs td:first-child {
        width: 30%
    }
      }
  @media screen and (min-width: 1366px) {
  .Card__Wrapper_1xFgs td:first-child {
        width: 40%
    }
      }
  @media (--screen--lg-min) {
  .Card__Wrapper_1xFgs td:first-child {
        width: 40%
    }
      }
  @media screen and (max-width: 1023px) {
  .Card__Wrapper_1xFgs td:nth-child(2) {
        width: 15%
    }
      }
  @media (--screen--sm-max) {
  .Card__Wrapper_1xFgs td:nth-child(2) {
        width: 15%
    }
      }
  @media screen and (min-width: 1024px) and (max-width: 1365px) {
  .Card__Wrapper_1xFgs td:nth-child(2) {
        width: 20%
    }
      }
  @media (--screen--md-only) {
  .Card__Wrapper_1xFgs td:nth-child(2) {
        width: 20%
    }
      }
  @media screen and (min-width: 1366px) {
  .Card__Wrapper_1xFgs td:nth-child(2) {
        width: 20%
    }
      }
  @media (--screen--lg-min) {
  .Card__Wrapper_1xFgs td:nth-child(2) {
        width: 20%
    }
      }
  .Card__Wrapper_1xFgs td:last-child {
      text-align: right;
    }
  .Card__Wrapper_1xFgs td > span {
      font-size: 12px;
      line-height: 16px;
    }

.Card__Head_3_TgX {
  margin-bottom: 20px;
}
