.TimelineWrapper__Timeline_3qFWR {
  margin-top: 32px;
  grid-row-gap: 32px;
}

.TimelineWrapper__Date_3LNcn {
  margin-bottom: 8px;
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
}
