.ErrorPageNotFound__Wrapper_2lukg {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
}

.ErrorPageNotFound__Inner_25n-2 {
  padding-bottom: var(--padding--5xl);
}

.ErrorPageNotFound__Container_FQPg4 {
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.ErrorPageNotFound__Container_FQPg4 {
    grid-column: 2 / span 10
}
  }

@media (--screen--sm-only) {

.ErrorPageNotFound__Container_FQPg4 {
    grid-column: 2 / span 10
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.ErrorPageNotFound__Container_FQPg4 {
    grid-column: 3 / span 8
}
  }

@media (--screen--md-only) {

.ErrorPageNotFound__Container_FQPg4 {
    grid-column: 3 / span 8
}
  }

@media screen and (min-width: 1366px) {

.ErrorPageNotFound__Container_FQPg4 {
    grid-column: 4 / span 6
}
  }

@media (--screen--lg-min) {

.ErrorPageNotFound__Container_FQPg4 {
    grid-column: 4 / span 6
}
  }

.ErrorPageNotFound__Title_3UxLI {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.ErrorPageNotFound__Description_26Yec {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

.ErrorPageNotFound__Menu_3QyXj {
  display: flex;
}

@media screen and (max-width: 767px) {

.ErrorPageNotFound__Menu_3QyXj {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: clac(24px * -1);
    margin-bottom: clac(var(--padding--xl) * -1)
}
  }

@media (--screen--xs-max) {

.ErrorPageNotFound__Menu_3QyXj {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: clac(24px * -1);
    margin-bottom: clac(var(--padding--xl) * -1)
}
  }

@media screen and (min-width: 768px) {

.ErrorPageNotFound__Menu_3QyXj {
    justify-content: space-between
}
  }

@media (--screen--sm-min) {

.ErrorPageNotFound__Menu_3QyXj {
    justify-content: space-between
}
  }

.ErrorPageNotFound__MenuItem_3YHg6 {
  font-weight: 500;
}

@media screen and (max-width: 767px) {

.ErrorPageNotFound__MenuItem_3YHg6 {
    margin-right: 20px;
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl);
    margin-left: 20px
}
  }

@media (--screen--xs-max) {

.ErrorPageNotFound__MenuItem_3YHg6 {
    margin-right: 20px;
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl);
    margin-left: 20px
}
  }
