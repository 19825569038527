.Scheme__Wrapper_28afx {
  margin-top: 40px;
  margin-top: var(--padding--3xl);
  margin-bottom: var(--padding--5xl);
}

.Scheme__LineY_8isTi::before {
    width: 1px;
    background: rgb(18, 82, 245);
    background: var(--color--second-blue);
  }

.Scheme__Title_3BNZ2,
.Scheme__Module_o9rCI,
.Scheme__Member_2eaAm {
  position: relative;
}

@media screen and (min-width: 768px) {

.Scheme__Title_3BNZ2::after,
  .Scheme__Title_3BNZ2::before,
  .Scheme__Module_o9rCI::after,
  .Scheme__Module_o9rCI::before,
  .Scheme__Member_2eaAm::after,
  .Scheme__Member_2eaAm::before {
      position: absolute;
      z-index: 1;
      content: ""
  }
    }

@media (--screen--sm-min) {

.Scheme__Title_3BNZ2::after,
  .Scheme__Title_3BNZ2::before,
  .Scheme__Module_o9rCI::after,
  .Scheme__Module_o9rCI::before,
  .Scheme__Member_2eaAm::after,
  .Scheme__Member_2eaAm::before {
      position: absolute;
      z-index: 1;
      content: ""
  }
    }

.Scheme__Link_2te6m {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(var(--rgb--black), 0.3);
  transition: border-color ease-in-out 0.3s;
}

.Scheme__Link_2te6m:hover {
    border-color: transparent;
  }

.Scheme__Description_dC5pk {
  margin-top: 16px;
  margin-top: var(--padding--lg);
}

.Scheme__Job_2nIUu {
  margin-top: 8px;
  margin-top: var(--padding--sm);
}

.Scheme__Dropdown_1K2_V {
  margin-top: 24px;
}

.Scheme__Members_14LTf {
  display: grid;
  margin-top: 24px;
}

@media screen and (max-width: 1023px) {

.Scheme__Members_14LTf {
    grid-row-gap: 16px
}
  }

@media (--screen--sm-max) {

.Scheme__Members_14LTf {
    grid-row-gap: 16px
}
  }

@media screen and (min-width: 1024px) {

.Scheme__Members_14LTf {
    grid-row-gap: 24px
}
  }

@media (--screen--md-min) {

.Scheme__Members_14LTf {
    grid-row-gap: 24px
}
  }

.Scheme__Members_14LTf > .Scheme__Member_2eaAm {
    margin-top: 0;
  }

.Scheme__Grid_22eCl {
  margin-top: 32px;
  margin-top: var(--padding--2xl);
}

@media screen and (max-width: 767px) {

.Scheme__Grid_22eCl {
    position: relative;
    left: 50%;
    width: 100vw;
    padding-bottom: 500px;
    padding-left: 24px;
    margin-bottom: -500px;
    margin-left: -50vw;
    overflow-x: auto
}
  }

@media (--screen--xs-max) {

.Scheme__Grid_22eCl {
    position: relative;
    left: 50%;
    width: 100vw;
    padding-bottom: 500px;
    padding-left: 24px;
    margin-bottom: -500px;
    margin-left: -50vw;
    overflow-x: auto
}
  }

.Scheme__Container_2qW8b {
  margin-top: 32px;
  grid-row-gap: 32px;
}

@media screen and (max-width: 767px) {

.Scheme__Container_2qW8b {
    width: 660px;
    margin-right: auto;
    margin-left: auto;
    grid-template-columns: repeat(2, 302px)
}
  }

@media (--screen--xs-max) {

.Scheme__Container_2qW8b {
    width: 660px;
    margin-right: auto;
    margin-left: auto;
    grid-template-columns: repeat(2, 302px)
}
  }

@media screen and (min-width: 768px) {

.Scheme__Container_2qW8b {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.Scheme__Container_2qW8b {
    --noOfColumns: 12
}
  }

.Scheme__Module_o9rCI {
  --indent-left-line-y: -16px;
  --indent-left-pointer: -48px;

  padding: 24px 32px 32px;
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
  border-radius: 2px;
}

.Scheme__Item_2Q_aw {
}

@media screen and (min-width: 768px) {

.Scheme__Item_2Q_aw {
    grid-column: span 6
}
  }

@media (--screen--sm-min) {

.Scheme__Item_2Q_aw {
    grid-column: span 6
}
  }

.Scheme__Member_2eaAm {
  padding-top: 16px;
  padding-bottom: 22px;
  border: 1px solid rgba(18, 82, 245, 0.1);
  border: 1px solid rgba(var(--rgb--second-blue), 0.1);
  margin-top: 24px;
  background: rgb(255, 255, 255);
  background: var(--color--white);
  border-radius: 2px;
  box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1);
}

@media screen and (max-width: 767px) {

.Scheme__Member_2eaAm {
    padding-right: 24px;
    padding-left: 24px
}
  }

@media (--screen--xs-max) {

.Scheme__Member_2eaAm {
    padding-right: 24px;
    padding-left: 24px
}
  }

@media screen and (min-width: 768px) {

.Scheme__Member_2eaAm {
    padding-right: 32px;
    padding-left: 32px
}
  }

@media (--screen--sm-min) {

.Scheme__Member_2eaAm {
    padding-right: 32px;
    padding-left: 32px
}
  }

.Scheme__UsingPointerOne_21uKK,
.Scheme__UsingPointerTwo_LRU33,
.Scheme__UsingPointerThree_1Dsop,
.Scheme__UsingPointerFour_3ldIp,
.Scheme__UsingPointerFive_1aHwL,
.Scheme__UsingPointerSix_1REqY,
.Scheme__UsingPointerSeven_1STgq,
.Scheme__UsingPointerEight_2q4w5 {
}

.Scheme__UsingPointerOne_21uKK,
.Scheme__UsingPointerTwo_LRU33,
.Scheme__UsingPointerThree_1Dsop,
.Scheme__UsingPointerFour_3ldIp,
.Scheme__UsingPointerSix_1REqY,
.Scheme__UsingPointerEight_2q4w5,
.Scheme__Member_2eaAm {
}

.Scheme__UsingPointerTwo_LRU33 .Scheme__Title_3BNZ2::after, .Scheme__UsingPointerFour_3ldIp .Scheme__Title_3BNZ2::after, .Scheme__UsingPointerFive_1aHwL .Scheme__Title_3BNZ2::after, .Scheme__UsingPointerSix_1REqY .Scheme__Title_3BNZ2::after {
      top: -1px;
      left: var(--indent-left-pointer);
      width: 33px;
      height: 28px;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMjgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI1Ljk3IDI2Ljk1bDYuMzMtNi40NS02LjMzLTYuNDUiIHN0cm9rZT0iIzEyNTJGNSIvPjxwYXRoIGQ9Ik0zMS45MDgtMTQ0LjVIMjAuNWMtMTEuMDQ2IDAtMjAgOC45NTQtMjAgMjBWLjVjMCAxMS4wNDYgOC45NTQgMjAgMjAgMjBoMTEuNDA4IiBzdHJva2U9IiMxMjUyRjUiLz48L3N2Zz4K);
    }

.Scheme__UsingPointerSeven_1STgq > .Scheme__Title_3BNZ2::after, .Scheme__UsingPointerEight_2q4w5 > .Scheme__Title_3BNZ2::after {
      top: 12px;
      left: var(--indent-left-pointer);
      width: 34px;
      height: 29px;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMjkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS02Mi41NCA5OC41aDQzLjAzOGMxMS4wNDUgMCAyMC04Ljk1NCAyMC0yMHYtNTFjMC0xMS4wNDYgOC45NTQtMjAgMjAtMjBoMTEuNTE4IiBzdHJva2U9IiMxMjUyRjUiLz48cGF0aCBkPSJNMjYuMTA2IDEzLjlsNi4zNTMtNi40NUwyNi4xMDYgMSIgc3Ryb2tlPSIjMTI1MkY1Ii8+PC9zdmc+Cg==);
    }

.Scheme__UsingPointerOne_21uKK {
}

.Scheme__UsingPointerOne_21uKK::before {
    top: 62px;
    bottom: -55px;
    left: var(--indent-left-line-y);
  }

.Scheme__UsingPointerOne_21uKK > .Scheme__Title_3BNZ2::after {
      top: 18px;
      left: var(--indent-left-pointer);
      width: 32px;
      height: 21px;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMjEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMyLjAwNy41SDIwLjVDOS40NTQuNS41IDkuNDU0LjUgMjAuNXYxMjVjMCAxMS4wNDYgOC45NTQgMjAgMjAgMjBoMTEuNTA3IiBzdHJva2U9IiMxMjUyRjUiLz48L3N2Zz4K);
    }

.Scheme__UsingPointerTwo_LRU33::before {
    top: 108px;
    bottom: -55px;
    left: var(--indent-left-line-y);
  }

.Scheme__UsingPointerTwo_LRU33 > .Scheme__Title_3BNZ2::before {
      top: 64px;
      left: var(--indent-left-pointer);
      width: 32px;
      height: 21px;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMjEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMyLjAwNy41SDIwLjVDOS40NTQuNS41IDkuNDU0LjUgMjAuNXYxMjVjMCAxMS4wNDYgOC45NTQgMjAgMjAgMjBoMTEuNTA3IiBzdHJva2U9IiMxMjUyRjUiLz48L3N2Zz4K);
    }

.Scheme__UsingPointerThree_1Dsop::before {
    top: 62px;
    bottom: -55px;
    left: var(--indent-left-line-y);
  }

.Scheme__UsingPointerThree_1Dsop > .Scheme__Title_3BNZ2::after {
      top: 12px;
      left: -80px;
      width: 66px;
      height: 29px;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjYiIGhlaWdodD0iMjkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTY1LjI3NyA3LjVMNTguNSAxbTYuNzc3IDYuNUw1OC41IDE0bTYuNzc3LTYuNUgwaDEyLjVjMTEuMDQ2IDAgMjAgOC45NTQgMjAgMjBWNjIiIHN0cm9rZT0iIzEyNTJGNSIvPjwvc3ZnPgo=);
    }

.Scheme__UsingPointerFour_3ldIp::after {
    top: 36px;
    right: -50px;
    width: 67px;
    height: 73px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjciIGhlaWdodD0iNzMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMzLjQ5OCAyNTguNTVWNjkuMDVjMC0xMS4wNDYgOC45NTQtMjAgMjAtMjBoMTIiIHN0cm9rZT0iIzEyNTJGNSIgc3Ryb2tlLWRhc2hhcnJheT0iOCA4Ii8+PHBhdGggZD0iTTU4Ljk0OCA1NS41bDYuNDUtNi40NS02LjQ1LTYuNDUiIHN0cm9rZT0iIzEyNTJGNSIvPjxwYXRoIGQ9Ik0zMy40OTkgNjAuMDV2LTMyLjVjMC0xMS4wNDYtOC45NTQtMjAtMjAtMjBIMS45OTgiIHN0cm9rZT0iIzEyNTJGNSIgc3Ryb2tlLWRhc2hhcnJheT0iOCA4Ii8+PHBhdGggZD0iTTcuOTUgMTQuMDVMMS41IDcuNmw2LjQ1LTYuNDUiIHN0cm9rZT0iIzEyNTJGNSIvPjwvc3ZnPgo=);
  }

.Scheme__UsingPointerFour_3ldIp::before {
    top: 27px;
    bottom: -55px;
    left: var(--indent-left-line-y);
  }

.Scheme__UsingPointerFive_1aHwL::before {
    top: 102px;
    bottom: -84px;
    left: var(--indent-left-line-y);
    width: 1px;
    background-image: linear-gradient(
      0deg,
      #1252f5 25%,
      #fff 25%,
      #fff 50%,
      #1252f5 50%,
      #1252f5 75%,
      #fff 75%,
      #fff 100%
    );
    background-size: 32px 32px;
  }

.Scheme__UsingPointerSix_1REqY {
  grid-row: 1 / span 2;
}

.Scheme__UsingPointerSix_1REqY::before {
    top: 64px;
    right: -17px;
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Scheme__UsingPointerSix_1REqY::before {
      bottom: 358px
  }
    }

@media (--screen--sm-only) {

.Scheme__UsingPointerSix_1REqY::before {
      bottom: 358px
  }
    }

.Scheme__UsingPointerSix_1REqY::after {
    right: -17px;
    width: 64px;
    height: 21px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iMjEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMjAuNWg0My41MDJjMTEuMDQ2IDAgMjAtOC45NTUgMjAtMjB2LTg0YzAtMTEuMDQ2IDguOTU1LTIwIDIwLTIwaDExLjc1MiIgc3Ryb2tlPSIjMTI1MkY1Ii8+PC9zdmc+Cg==);
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Scheme__UsingPointerSix_1REqY::after {
      bottom: 338px
  }
    }

@media (--screen--sm-only) {

.Scheme__UsingPointerSix_1REqY::after {
      bottom: 338px
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY::before {
        bottom: 340px
    }
      }

@media (--screen--md-only) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY::before {
        bottom: 340px
    }
      }

@media screen and (min-width: 1366px) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY::before {
        bottom: 285px
    }
      }

@media (--screen--lg-min) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY::before {
        bottom: 285px
    }
      }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY::after {
        bottom: 320px
    }
      }

@media (--screen--md-only) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY::after {
        bottom: 320px
    }
      }

@media screen and (min-width: 1366px) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY::after {
        bottom: 265px
    }
      }

@media (--screen--lg-min) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY::after {
        bottom: 265px
    }
      }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY::before {
        bottom: 320px
    }
      }

@media (--screen--md-only) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY::before {
        bottom: 320px
    }
      }

@media screen and (min-width: 1366px) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY::before {
        bottom: 297px
    }
      }

@media (--screen--lg-min) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY::before {
        bottom: 297px
    }
      }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY::after {
        bottom: 300px
    }
      }

@media (--screen--md-only) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY::after {
        bottom: 300px
    }
      }

@media screen and (min-width: 1366px) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY::after {
        bottom: 276px
    }
      }

@media (--screen--lg-min) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY::after {
        bottom: 276px
    }
      }

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
          left: var(--indent-left-pointer);
        }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
            top: 88px
        }
          }

@media (--screen--sm-only) {

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
            top: 88px
        }
          }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
            top: 66px
        }
          }

@media (--screen--md-only) {

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
            top: 66px
        }
          }

@media screen and (min-width: 1366px) {

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
            bottom: -110px
        }
          }

@media (--screen--lg-min) {

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
            bottom: -110px
        }
          }

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::after {
          left: var(--indent-left-pointer);
          width: 64px;
          height: 22px;
          background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTY0IC41SDIwLjVDOS40NTQuNS41IDkuNDU0LjUgMjAuNVY3OWMwIDExLjA0NiA4Ljk1NCAyMCAyMCAyMEg2NCIgc3Ryb2tlPSIjMTI1MkY1Ii8+PC9zdmc+Cg==);
        }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::after {
            top: 67px
        }
          }

@media (--screen--sm-only) {

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::after {
            top: 67px
        }
          }

@media screen and (min-width: 768px) and (max-width: 1023px) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
              bottom: -118px
          }
            }

@media (--screen--sm-only) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
              bottom: -118px
          }
            }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
              bottom: -132px
          }
            }

@media (--screen--md-only) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
              bottom: -132px
          }
            }

@media screen and (min-width: 1366px) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
              top: 67px
          }
            }

@media (--screen--lg-min) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
              top: 67px
          }
            }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::after {
              top: 45px
          }
            }

@media (--screen--md-only) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::after {
              top: 45px
          }
            }

@media screen and (min-width: 1366px) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::after {
              top: 46px
          }
            }

@media (--screen--lg-min) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::after {
              top: 46px
          }
            }

@media screen and (min-width: 768px) and (max-width: 1023px) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
              bottom: -104px
          }
            }

@media (--screen--sm-only) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
              bottom: -104px
          }
            }

@media screen and (min-width: 1024px) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
              top: 77px
          }
            }

@media (--screen--md-min) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
              top: 77px
          }
            }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
              bottom: -110px
          }
            }

@media (--screen--md-only) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::before {
              bottom: -110px
          }
            }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::after {
              top: 56px
          }
            }

@media (--screen--md-only) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::after {
              top: 56px
          }
            }

@media screen and (min-width: 1366px) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::after {
              top: 56px
          }
            }

@media (--screen--lg-min) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(1)::after {
              top: 56px
          }
            }

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(2)::after {
          left: var(--indent-left-pointer);
          width: 65px;
          height: 29px;
          background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjUiIGhlaWdodD0iMjkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTU3Ljg1IDI3LjlsNi40NC02LjQ1TDU3Ljg1IDE1IiBzdHJva2U9IiMxMjUyRjUiLz48cGF0aCBkPSJNNjQuMzktNzdIMjAuNUM5LjQ1NC03NyAuNS02OC4wNDYuNS01N1YxLjVjMCAxMS4wNDYgOC45NTQgMjAgMjAgMjBoNDMuODkiIHN0cm9rZT0iIzEyNTJGNSIvPjwvc3ZnPgo=);
        }

@media screen and (min-width: 1366px) {

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(2)::after {
            bottom: 24px
        }
          }

@media (--screen--lg-min) {

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(2)::after {
            bottom: 24px
        }
          }

@media screen and (min-width: 768px) and (max-width: 1365px) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(2)::after {
              bottom: 34px
          }
            }

@media (--screen--sm-min) and (--screen--md-max) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(2)::after {
              bottom: 34px
          }
            }

@media screen and (min-width: 768px) and (max-width: 1023px) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(2)::after {
              bottom: 45px
          }
            }

@media (--screen--sm-only) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(2)::after {
              bottom: 45px
          }
            }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(2)::after {
              bottom: 34px
          }
            }

@media (--screen--md-only) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(2)::after {
              bottom: 34px
          }
            }

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(3)::after {
          right: -50px;
          bottom: 16px;
          width: 64px;
          height: 21px;
          background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iMjEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMjAuNWg0My41MDJjMTEuMDQ2IDAgMjAtOC45NTUgMjAtMjB2LTg0YzAtMTEuMDQ2IDguOTU1LTIwIDIwLTIwaDExLjc1MiIgc3Ryb2tlPSIjMTI1MkY1Ii8+PC9zdmc+Cg==);
        }

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(3)::before {
          left: var(--indent-left-pointer);
          width: 65px;
          height: 29px;
          background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjUiIGhlaWdodD0iMjkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTU3Ljg1IDI3LjlsNi40NC02LjQ1TDU3Ljg1IDE1IiBzdHJva2U9IiMxMjUyRjUiLz48cGF0aCBkPSJNNjQuMzktNzdIMjAuNUM5LjQ1NC03NyAuNS02OC4wNDYuNS01N1YxLjVjMCAxMS4wNDYgOC45NTQgMjAgMjAgMjBoNDMuODkiIHN0cm9rZT0iIzEyNTJGNSIvPjwvc3ZnPgo=);
        }

@media screen and (min-width: 1366px) {

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(3)::before {
            bottom: 34px
        }
          }

@media (--screen--lg-min) {

.Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(3)::before {
            bottom: 34px
        }
          }

@media screen and (min-width: 768px) and (max-width: 1023px) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(3)::before {
              bottom: 54px
          }
            }

@media (--screen--sm-only) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(3)::before {
              bottom: 54px
          }
            }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(3)::before {
              bottom: 34px
          }
            }

@media (--screen--md-only) {

[data-lang="ru"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(3)::before {
              bottom: 34px
          }
            }

@media screen and (min-width: 768px) and (max-width: 1023px) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(3)::before {
              bottom: 45px
          }
            }

@media (--screen--sm-only) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(3)::before {
              bottom: 45px
          }
            }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(3)::before {
              bottom: 34px
          }
            }

@media (--screen--md-only) {

[data-lang="en"] .Scheme__UsingPointerSix_1REqY > .Scheme__Members_14LTf > .Scheme__Member_2eaAm:nth-child(3)::before {
              bottom: 34px
          }
            }

.Scheme__UsingPointerSeven_1STgq {
  grid-row: 1;
}

.Scheme__UsingPointerEight_2q4w5 {
  grid-row: 2;
}

.Scheme__UsingPointerEight_2q4w5::before {
    top: 64px;
    left: var(--indent-left-line-y);
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

[data-lang="ru"] .Scheme__UsingPointerEight_2q4w5::before {
        bottom: 134px
    }
      }

@media (--screen--sm-only) {

[data-lang="ru"] .Scheme__UsingPointerEight_2q4w5::before {
        bottom: 134px
    }
      }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

[data-lang="ru"] .Scheme__UsingPointerEight_2q4w5::before {
        bottom: 140px
    }
      }

@media (--screen--md-only) {

[data-lang="ru"] .Scheme__UsingPointerEight_2q4w5::before {
        bottom: 140px
    }
      }

@media screen and (min-width: 1366px) {

[data-lang="ru"] .Scheme__UsingPointerEight_2q4w5::before {
        bottom: auto
    }
      }

@media (--screen--lg-min) {

[data-lang="ru"] .Scheme__UsingPointerEight_2q4w5::before {
        bottom: auto
    }
      }

@media screen and (min-width: 768px) and (max-width: 1023px) {

[data-lang="en"] .Scheme__UsingPointerEight_2q4w5::before {
        bottom: 70px
    }
      }

@media (--screen--sm-only) {

[data-lang="en"] .Scheme__UsingPointerEight_2q4w5::before {
        bottom: 70px
    }
      }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

[data-lang="en"] .Scheme__UsingPointerEight_2q4w5::before {
        bottom: auto
    }
      }

@media (--screen--md-only) {

[data-lang="en"] .Scheme__UsingPointerEight_2q4w5::before {
        bottom: auto
    }
      }

@media screen and (min-width: 1366px) {

[data-lang="en"] .Scheme__UsingPointerEight_2q4w5::before {
        bottom: 98px
    }
      }

@media (--screen--lg-min) {

[data-lang="en"] .Scheme__UsingPointerEight_2q4w5::before {
        bottom: 98px
    }
      }
