.Hero__Wrapper_3gxmE {
  position: relative;
  overflow: hidden;
  padding-bottom: var(--padding--4xl);
}

  @media screen and (min-width: 1366px) {.Hero__Wrapper_3gxmE {
    max-height: 721px
}
  }

  @media (--screen--lg-min) {.Hero__Wrapper_3gxmE {
    max-height: 721px
}
  }

.Hero__Title_2hr0S {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Hero__Grid_1sB0T {
    grid-column: 6 span
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Hero__Grid_1sB0T {
    grid-column: 6 span
}
  }

@media screen and (min-width: 1366px) {

.Hero__Grid_1sB0T {
    grid-column: 5 span
}
  }

@media (--screen--lg-min) {

.Hero__Grid_1sB0T {
    grid-column: 5 span
}
  }

@media screen and (max-width: 767px) {

.Hero__Factoids_1rEpt {
    margin-top: 83px
}
  }

@media (--screen--xs-max) {

.Hero__Factoids_1rEpt {
    margin-top: 83px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Hero__Factoids_1rEpt {
    margin-top: 135px
}
  }

@media (--screen--sm-only) {

.Hero__Factoids_1rEpt {
    margin-top: 135px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Hero__Factoids_1rEpt {
    margin-top: 96px
}
  }

@media (--screen--md-only) {

.Hero__Factoids_1rEpt {
    margin-top: 96px
}
  }

@media screen and (min-width: 1366px) {

.Hero__Factoids_1rEpt {
    margin-top: 140px
}
  }

@media (--screen--lg-min) {

.Hero__Factoids_1rEpt {
    margin-top: 140px
}
  }
