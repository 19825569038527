.Cases__Wrapper_oDpet {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--5xl);
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
}

.Cases__Projects_WFM1u {
}

@media screen and (max-width: 767px) {

.Cases__Projects_WFM1u {
    grid-row-gap: 24px
}
  }

@media (--screen--xs-max) {

.Cases__Projects_WFM1u {
    grid-row-gap: 24px
}
  }

@media screen and (min-width: 768px) {

.Cases__Projects_WFM1u {
    --noOfColumns: 2
}
  }

@media (--screen--sm-min) {

.Cases__Projects_WFM1u {
    --noOfColumns: 2
}
  }
