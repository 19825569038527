.ContentPopupInvest__Heading_2PIGw {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.ContentPopupInvest__Title_nZMZJ {
  margin-top: 24px;
  margin-top: var(--padding--xl);
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.ContentPopupInvest__Description_2D6rn {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

.ContentPopupInvest__Description_2D6rn p + p {
    margin-top: 24px;
    margin-top: var(--padding--xl);
  }

.ContentPopupInvest__Description_2D6rn b {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
    font-weight: 500;
  }

.ContentPopupInvest__Form_R01xv {
  display: grid;
  padding-bottom: 48px;
  grid-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.ContentPopupInvest__Form_R01xv > div {
    grid-column: 1 / -1;
  }

.ContentPopupInvest__Form_R01xv > div:nth-child(2) {
      grid-column: 1;
    }

.ContentPopupInvest__Form_R01xv > div:nth-child(3) {
      grid-column: 2;
    }

.ContentPopupInvest__SendWrapper_3hbAI {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.ContentPopupInvest__SendPolicy_2qw90 {
  margin-left: 24px;
  font-size: 12px;
  line-height: 16px;
}

@media screen and (min-width: 768px) {

.ContentPopupInvest__SendPolicy_2qw90 {
    width: 281px
}
  }

@media (--screen--sm-min) {

.ContentPopupInvest__SendPolicy_2qw90 {
    width: 281px
}
  }

.ContentPopupInvest__SendPolicy_2qw90 > a {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
  }
