.Content__Title_t8w0z {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

.Content__Description_2aDQA {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

@media screen and (min-width: 1024px) {

.Content__DescriptionVacancy_19ojS {
    width: 300px
}
  }

@media (--screen--md-min) {

.Content__DescriptionVacancy_19ojS {
    width: 300px
}
  }

@media screen and (min-width: 1024px) {

.Content__Content_FvUGI {
    display: flex
}
  }

@media (--screen--md-min) {

.Content__Content_FvUGI {
    display: flex
}
  }

@media screen and (min-width: 1024px) {

.Content__ColLeft_1CLVP,
.Content__ColRight_H30qu {
    width: 50%
}
  }

@media (--screen--md-min) {

.Content__ColLeft_1CLVP,
.Content__ColRight_H30qu {
    width: 50%
}
  }

.Content__ColLeft_1CLVP {
  padding-right: 16px;
  padding-right: var(--padding--lg);
}

.Content__ColRight_H30qu {
  padding-left: 16px;
  padding-left: var(--padding--lg);
}

.Content__Form_3eR1w {
  display: grid;
  grid-gap: 24px;
}

@media screen and (min-width: 768px) {

.Content__Form_3eR1w {
    grid-template-columns: repeat(2, 1fr)
}
  }

@media (--screen--sm-min) {

.Content__Form_3eR1w {
    grid-template-columns: repeat(2, 1fr)
}
  }

@media screen and (min-width: 768px) {

.Content__Field_3XtE0,
.Content__SendWrapper_3k_1r {
    grid-column: 1 / -1
}
  }

@media (--screen--sm-min) {

.Content__Field_3XtE0,
.Content__SendWrapper_3k_1r {
    grid-column: 1 / -1
}
  }

@media screen and (min-width: 768px) {

.Content__Phone_1vsxK {
    grid-column: 1
}
  }

@media (--screen--sm-min) {

.Content__Phone_1vsxK {
    grid-column: 1
}
  }

@media screen and (min-width: 768px) {

.Content__Email_911UA {
    grid-column: 2
}
  }

@media (--screen--sm-min) {

.Content__Email_911UA {
    grid-column: 2
}
  }

@media screen and (min-width: 768px) {

.Content__SendWrapper_3k_1r {
    display: flex;
    align-items: center
}
  }

@media (--screen--sm-min) {

.Content__SendWrapper_3k_1r {
    display: flex;
    align-items: center
}
  }

@media screen and (max-width: 1023px) {

.Content__SendWrapper_3k_1r {
    margin-top: 8px
}
  }

@media (--screen--sm-max) {

.Content__SendWrapper_3k_1r {
    margin-top: 8px
}
  }

@media screen and (min-width: 1024px) {

.Content__SendWrapper_3k_1r {
    margin-top: 10px
}
  }

@media (--screen--md-min) {

.Content__SendWrapper_3k_1r {
    margin-top: 10px
}
  }

.Content__FormButton_3FNje {
  flex-shrink: 0;
}

.Content__SendPolicy_3PjVZ {
  font-size: 12px;
  line-height: 16px;
}

@media screen and (max-width: 767px) {

.Content__SendPolicy_3PjVZ {
    margin-top: 16px
}
  }

@media (--screen--xs-max) {

.Content__SendPolicy_3PjVZ {
    margin-top: 16px
}
  }

@media screen and (min-width: 768px) {

.Content__SendPolicy_3PjVZ {
    width: 281px;
    margin-left: 24px
}
  }

@media (--screen--sm-min) {

.Content__SendPolicy_3PjVZ {
    width: 281px;
    margin-left: 24px
}
  }

.Content__SendPolicy_3PjVZ > a {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
  }
