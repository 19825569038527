@media screen and (max-width: 767px) {.Anchors__Wrapper_3nxlf {
    padding-bottom: 16px;
    padding-bottom: var(--padding--lg)
}
  }

@media (--screen--xs-max) {.Anchors__Wrapper_3nxlf {
    padding-bottom: 16px;
    padding-bottom: var(--padding--lg)
}
  }

@media screen and (min-width: 768px) {

.Anchors__Title_1_hCX {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.3);
    color: rgba(var(--rgb--black), 0.3);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-transform: uppercase
}
  }

@media (--screen--sm-min) {

.Anchors__Title_1_hCX {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.3);
    color: rgba(var(--rgb--black), 0.3);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-transform: uppercase
}
  }

.Anchors__List_35vMD {
  position: relative;
  padding: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  counter-reset: counter;
  list-style: none;
}

.Anchors__List_35vMD > li {
    position: relative;
    padding-left: 24px;
  }

.Anchors__List_35vMD > li::before {
      position: absolute;
      top: 0;
      left: 0;
      color: rgb(18, 82, 245);
      color: var(--color--second-blue);
      content: counter(counter);
      counter-increment: counter;
    }

.Anchors__List_35vMD > li:not(:last-child) {
      margin-bottom: 16px;
    }

.Anchors__Anchor_1LDET {
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  font-weight: 500;
}

.Anchors__Anchor_1LDET:hover {
    cursor: pointer;
  }
