.Hero__Wrapper_1JhP8 {
  position: relative;
  overflow: hidden;
  padding-bottom: var(--padding--4xl);
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Hero__Head_1vaoo {
    grid-column: span 7
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Hero__Head_1vaoo {
    grid-column: span 7
}
  }

@media screen and (min-width: 1366px) {

.Hero__Head_1vaoo {
    grid-column: span 6
}
  }

@media (--screen--lg-min) {

.Hero__Head_1vaoo {
    grid-column: span 6
}
  }

.Hero__Title_ON-6Y {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Hero__Link_2qI3t {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

.Hero__Factoids_cAmh6 {
  margin-top: var(--padding--4xl);
}
