.Item__Wrapper_2u9Jn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

  @media screen and (max-width: 767px) {.Item__Wrapper_2u9Jn {
    padding: 16px 32px
}
  }

  @media (--screen--xs-max) {.Item__Wrapper_2u9Jn {
    padding: 16px 32px
}
  }

  @media screen and (min-width: 768px) {.Item__Wrapper_2u9Jn {
    padding: 24px 32px
}
  }

  @media (--screen--sm-min) {.Item__Wrapper_2u9Jn {
    padding: 24px 32px
}
  }

  @media screen and (min-width: 1366px) {.Item__Wrapper_2u9Jn {
    min-height: 270px
}
  }

  @media (--screen--lg-min) {.Item__Wrapper_2u9Jn {
    min-height: 270px
}
  }

  @media print {.Item__Wrapper_2u9Jn {
    padding: 20px
}
  }

.Item__Text_1eM4X {
  margin-bottom: 24px;
}

@media print {

.Item__Text_1eM4X {
    margin-bottom: 0
}
  }

.Item__Text_1eM4X p:not(:last-child) {
      margin-bottom: 24px;
    }

.Item__Logo_3antG {
  height: 24px;
  margin-top: auto;
}

.Item__Logo_3antG img {
    max-height: 100%;
  }

.Item__Title_AQNOm {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
  font-weight: 500;
  transition: color 0.3s;
}
