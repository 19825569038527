.Internships__Wrapper_3uwwt {
  margin-top: var(--padding--4xl);
  margin-bottom: var(--padding--5xl);
}

.Internships__Inner_1_XE1 {
}

@media screen and (min-width: 1024px) {

.Internships__Inner_1_XE1 {
    --noOfColumns: 2
}
  }

@media (--screen--md-min) {

.Internships__Inner_1_XE1 {
    --noOfColumns: 2
}
  }

.Internships__SubTitleWrapper_19ypl,
.Internships__DescriptionWrapper_3ZLGR {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Internships__SubTitleWrapper_19ypl,
.Internships__DescriptionWrapper_3ZLGR {
    --noOfColumns: 12
}
  }

@media (--screen--sm-only) {

.Internships__SubTitleWrapper_19ypl,
.Internships__DescriptionWrapper_3ZLGR {
    --noOfColumns: 12
}
  }

@media screen and (min-width: 1024px) {

.Internships__SubTitleWrapper_19ypl,
.Internships__DescriptionWrapper_3ZLGR {
    --noOfColumns: 6
}
  }

@media (--screen--md-min) {

.Internships__SubTitleWrapper_19ypl,
.Internships__DescriptionWrapper_3ZLGR {
    --noOfColumns: 6
}
  }

.Internships__Title_30noA {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Internships__SubTitle_1Pz71 {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Internships__SubTitle_1Pz71 {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Internships__SubTitle_1Pz71 {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Internships__SubTitle_1Pz71 {
    grid-column: span 5
}
  }

@media (--screen--md-only) {

.Internships__SubTitle_1Pz71 {
    grid-column: span 5
}
  }

@media screen and (min-width: 1366px) {

.Internships__SubTitle_1Pz71 {
    grid-column: span 6
}
  }

@media (--screen--lg-min) {

.Internships__SubTitle_1Pz71 {
    grid-column: span 6
}
  }

.Internships__Description_3Gz8s {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Internships__Description_3Gz8s {
    grid-column: span 7
}
  }

@media (--screen--sm-only) {

.Internships__Description_3Gz8s {
    grid-column: span 7
}
  }

@media screen and (min-width: 1024px) {

.Internships__Description_3Gz8s {
    grid-column: span 6
}
  }

@media (--screen--md-min) {

.Internships__Description_3Gz8s {
    grid-column: span 6
}
  }
