.sustainable-development__Item_8pWQS {
  font-weight: 300;
}

  @media screen and (max-width: 1023px) {

  .sustainable-development__Item_8pWQS:not(:last-child) {
      margin-bottom: 16px;
      margin-bottom: var(--padding--lg)
  }
    }

  @media (--screen--sm-max) {

  .sustainable-development__Item_8pWQS:not(:last-child) {
      margin-bottom: 16px;
      margin-bottom: var(--padding--lg)
  }
    }

  @media screen and (min-width: 1024px) {

  .sustainable-development__Item_8pWQS:not(:last-child) {
      margin-right: 56px
  }
    }

  @media (--screen--md-min) {

  .sustainable-development__Item_8pWQS:not(:last-child) {
      margin-right: 56px
  }
    }

  @media screen and (min-width: 1024px) {.sustainable-development__Item_8pWQS {
    display: inline-block
}
  }

  @media (--screen--md-min) {.sustainable-development__Item_8pWQS {
    display: inline-block
}
  }

  @media screen and (max-width: 1365px) {.sustainable-development__Item_8pWQS {
    font-size: 26px;
    line-height: 32px
}
  }

  @media (--screen--md-max) {.sustainable-development__Item_8pWQS {
    font-size: 26px;
    line-height: 32px
}
  }

  @media screen and (min-width: 1366px) {.sustainable-development__Item_8pWQS {
    font-size: 32px;
    line-height: 38px
}
  }

  @media (--screen--lg-min) {.sustainable-development__Item_8pWQS {
    font-size: 32px;
    line-height: 38px
}
  }

  .sustainable-development__Item_8pWQS > a::after {
      content: none;
    }
