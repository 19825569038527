.Logo__wrapperLogo_1jWzG {
    display: flex;
    justify-content: end;
    align-items: center;
}

    .Logo__wrapperLogo_1jWzG a {
        margin-left: 5px;
    }

    .Logo__wrapperLogo_1jWzG img {
        width: 120px;
    }

    .Logo__wrapperLogo_1jWzG .Logo__link_25FmS {

    }