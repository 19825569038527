.Cells__Item_2ipqk {
}

  .Cells__Item_2ipqk:not(:last-child) {
    padding-bottom: 32px;
    padding-bottom: var(--padding--2xl);
    border-bottom: 1px solid rgba(0, 18, 117, 0.3);
    border-bottom: 1px solid rgba(var(--rgb--main-blue), 0.3);
  }

  @media screen and (max-width: 767px) {.Cells__Item_2ipqk {
    grid-row-gap: 24px
}

    .Cells__Item_2ipqk:not(:first-child) {
      padding-top: 24px;
      padding-top: var(--padding--xl);
    }
  }

  @media (--screen--xs-max) {.Cells__Item_2ipqk {
    grid-row-gap: 24px
}

    .Cells__Item_2ipqk:not(:first-child) {
      padding-top: 24px;
      padding-top: var(--padding--xl);
    }
  }

  @media screen and (min-width: 768px) {.Cells__Item_2ipqk {
    --noOfColumns: 12;

    padding-top: 24px;

    padding-top: var(--padding--xl)
}
  }

  @media (--screen--sm-min) {.Cells__Item_2ipqk {
    --noOfColumns: 12;

    padding-top: 24px;

    padding-top: var(--padding--xl)
}
  }

.Cells__Top_1ckxs {
  padding-bottom: 16px;
  padding-bottom: var(--padding--lg);
  border-bottom: 1px solid rgb(18, 82, 245);
  border-bottom: 1px solid var(--color--second-blue);
  font-weight: 500;
}

@media screen and (max-width: 767px) {

.Cells__Top_1ckxs {
    display: none
}
  }

@media (--screen--xs-max) {

.Cells__Top_1ckxs {
    display: none
}
  }

@media screen and (min-width: 768px) {

.Cells__Top_1ckxs {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.Cells__Top_1ckxs {
    --noOfColumns: 12
}
  }

@media screen and (min-width: 768px) {

.Cells__Date_3ChrK {
    grid-column: 3 span
}
  }

@media (--screen--sm-min) {

.Cells__Date_3ChrK {
    grid-column: 3 span
}
  }

@media screen and (min-width: 768px) {

.Cells__Categories_K62cM {
    /* grid-column-end: 3 span; */
}
  }

@media (--screen--sm-min) {

.Cells__Categories_K62cM {
    /* grid-column-end: 3 span; */
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Cells__Categories_K62cM {
    grid-column: 4 span
}
  }

@media (--screen--sm-only) {

.Cells__Categories_K62cM {
    grid-column: 4 span
}
  }

@media screen and (min-width: 1024px) {

.Cells__Categories_K62cM {
    grid-column: 5/3 span
}
  }

@media (--screen--md-min) {

.Cells__Categories_K62cM {
    grid-column: 5/3 span
}
  }

.Cells__Category_DJ5o2:not(:last-child) {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl);
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Cells__NameWrapper_3YX8Y {
    grid-column: 5 span
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Cells__NameWrapper_3YX8Y {
    grid-column: 5 span
}
  }

@media screen and (min-width: 1366px) {

.Cells__NameWrapper_3YX8Y {
    grid-column: 9/4 span
}
  }

@media (--screen--lg-min) {

.Cells__NameWrapper_3YX8Y {
    grid-column: 9/4 span
}
  }

.Cells__Links_3VUKq {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

@media screen and (max-width: 767px) {
    .Cells__Link_muxDL:not(:last-child) {
      margin-right: 40px;
    }
  }

@media (--screen--xs-max) {
    .Cells__Link_muxDL:not(:last-child) {
      margin-right: 40px;
    }
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .Cells__Link_muxDL:not(:last-child) {
      margin-right: 24px;
    }
  }

@media (--screen--sm-only) {
    .Cells__Link_muxDL:not(:last-child) {
      margin-right: 24px;
    }
  }

@media screen and (min-width: 1024px) {
    .Cells__Link_muxDL:not(:last-child) {
      margin-right: 40px;
    }
  }

@media (--screen--md-min) {
    .Cells__Link_muxDL:not(:last-child) {
      margin-right: 40px;
    }
  }

@media screen and (max-width: 767px) {

.Cells__Title_1RJfF {
    margin-bottom: 8px;
    font-weight: 500
}
  }

@media (--screen--xs-max) {

.Cells__Title_1RJfF {
    margin-bottom: 8px;
    font-weight: 500
}
  }

@media screen and (min-width: 768px) {

.Cells__Title_1RJfF {
    display: none
}
  }

@media (--screen--sm-min) {

.Cells__Title_1RJfF {
    display: none
}
  }
