.PopupContent__Wrapper_XPzbN {
  padding-top: 32px;
  padding-bottom: 48px;
}

.PopupContent__Title_2QIGQ {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

.PopupContent__Link_2eXUA {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}
