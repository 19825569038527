.Media__Wrapper_1mU3G {
  padding: var(--padding--4xl) 0 var(--padding--5xl);
}

.Media__Items_2w5st {
}

@media screen and (min-width: 768px) {

.Media__Items_2w5st {
    grid-row-gap: 32px
}
  }

@media (--screen--sm-min) {

.Media__Items_2w5st {
    grid-row-gap: 32px
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Media__Items_2w5st {
    --noOfColumns: 3
}
      .Media__Items_2w5st > div:first-child {
        grid-column: 3 span;
      }

      .Media__Items_2w5st > div:last-child {
        display: none;
      }
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Media__Items_2w5st {
    --noOfColumns: 3
}
      .Media__Items_2w5st > div:first-child {
        grid-column: 3 span;
      }

      .Media__Items_2w5st > div:last-child {
        display: none;
      }
  }

@media screen and (min-width: 1366px) {

.Media__Items_2w5st {
    --noOfColumns: 4
}
      .Media__Items_2w5st > div:first-child {
        grid-column: 2 span;
        grid-row: 2 span;
      }
  }

@media (--screen--lg-min) {

.Media__Items_2w5st {
    --noOfColumns: 4
}
      .Media__Items_2w5st > div:first-child {
        grid-column: 2 span;
        grid-row: 2 span;
      }
  }

@media print {

.Media__Items_2w5st {
    display: block
}

    .Media__Items_2w5st > div {
      display: inline-block;
      width: 30%;
      margin-right: 2%;
      vertical-align: top;
    }
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Media__ItemTitle_1TB3I {
    font-size: 26px;
    line-height: 32px
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Media__ItemTitle_1TB3I {
    font-size: 26px;
    line-height: 32px
}
  }

@media screen and (min-width: 1366px) {

.Media__ItemTitle_1TB3I {
    font-size: 32px;
    line-height: 38px
}
  }

@media (--screen--lg-min) {

.Media__ItemTitle_1TB3I {
    font-size: 32px;
    line-height: 38px
}
  }

@media screen and (max-width: 767px) {

.Media__ItemPicture_2TWda {
    height: 105px
}
  }

@media (--screen--xs-max) {

.Media__ItemPicture_2TWda {
    height: 105px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Media__ItemPicture_2TWda {
    height: 360px
}
  }

@media (--screen--sm-only) {

.Media__ItemPicture_2TWda {
    height: 360px
}
  }

@media screen and (min-width: 1024px) {

.Media__ItemPicture_2TWda {
    /* TODO: убрать !important */
    height: 375px !important
}
  }

@media (--screen--md-min) {

.Media__ItemPicture_2TWda {
    /* TODO: убрать !important */
    height: 375px !important
}
  }
