.VacancyList__Wrapper_1j1og {
  overflow: hidden;
}

.VacancyList__Background_3PhNH {
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
}

.VacancyList__Inner_6A-mJ {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--5xl);
}

.VacancyList__HeadingWrapper_18W2d {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media screen and (min-width: 768px) {

.VacancyList__HeadingWrapper_18W2d {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.VacancyList__HeadingWrapper_18W2d {
    --noOfColumns: 12
}
  }

.VacancyList__Title_1mlF7 {
  padding-top: var(--padding--4xl);
  margin-top: calc(var(--padding--4xl) * -1);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.VacancyList__Title_1mlF7 {
    grid-column: span 7
}
  }

@media (--screen--sm-only) {

.VacancyList__Title_1mlF7 {
    grid-column: span 7
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.VacancyList__Title_1mlF7 {
    grid-column: span 8
}
  }

@media (--screen--md-only) {

.VacancyList__Title_1mlF7 {
    grid-column: span 8
}
  }

@media screen and (min-width: 1366px) {

.VacancyList__Title_1mlF7 {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.VacancyList__Title_1mlF7 {
    grid-column: span 5
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.VacancyList__Description_zpMN8 {
    grid-column: span 9
}
  }

@media (--screen--sm-only) {

.VacancyList__Description_zpMN8 {
    grid-column: span 9
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.VacancyList__Description_zpMN8 {
    grid-column: span 8
}
  }

@media (--screen--md-only) {

.VacancyList__Description_zpMN8 {
    grid-column: span 8
}
  }

@media screen and (min-width: 1366px) {

.VacancyList__Description_zpMN8 {
    grid-column: span 6
}
  }

@media (--screen--lg-min) {

.VacancyList__Description_zpMN8 {
    grid-column: span 6
}
  }

.VacancyList__Description_zpMN8 a:not([class]) {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
    font-weight: 500;
    word-break: break-word;
  }

.VacancyList__Description_zpMN8 a:not([class])::after {
      content: none;
    }

.VacancyList__Link_3uNhc {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

.VacancyList__List_3Q-Hu {
  margin-top: 32px;
  margin-top: var(--padding--2xl);
}

@media screen and (min-width: 1024px) {

.VacancyList__SlidesContainer_155u9::after,
  .VacancyList__SlidesContainer_155u9::before {
      position: absolute;
      z-index: 1;
      top: 0;
      width: 24px;
      height: 100%;
      content: ""
  }
    }

@media (--screen--md-min) {

.VacancyList__SlidesContainer_155u9::after,
  .VacancyList__SlidesContainer_155u9::before {
      position: absolute;
      z-index: 1;
      top: 0;
      width: 24px;
      height: 100%;
      content: ""
  }
    }

@media screen and (min-width: 1024px) {

.VacancyList__SlidesContainer_155u9::after {
      right: 0;
      background: linear-gradient(to left, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
      background: linear-gradient(to left, var(--color--white) 0%, rgba(var(--rgb--white), 0) 100%)
  }
    }

@media (--screen--md-min) {

.VacancyList__SlidesContainer_155u9::after {
      right: 0;
      background: linear-gradient(to left, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
      background: linear-gradient(to left, var(--color--white) 0%, rgba(var(--rgb--white), 0) 100%)
  }
    }

@media screen and (min-width: 1024px) {

.VacancyList__Background_3PhNH .VacancyList__SlidesContainer_155u9::after {
        background: linear-gradient(
          to left,
          rgb(240, 248, 255) 0%,
          rgba(240, 248, 255, 0) 100%
        );
        background: linear-gradient(
          to left,
          var(--color--bg-light) 0%,
          rgba(var(--rgb--bg-light), 0) 100%
        )
    }
      }

@media (--screen--md-min) {

.VacancyList__Background_3PhNH .VacancyList__SlidesContainer_155u9::after {
        background: linear-gradient(
          to left,
          rgb(240, 248, 255) 0%,
          rgba(240, 248, 255, 0) 100%
        );
        background: linear-gradient(
          to left,
          var(--color--bg-light) 0%,
          rgba(var(--rgb--bg-light), 0) 100%
        )
    }
      }

@media screen and (min-width: 1024px) {

.VacancyList__SlidesContainer_155u9::before {
      left: 0;
      background: linear-gradient(
        to right,
        rgb(255, 255, 255) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: linear-gradient(
        to right,
        var(--color--white) 0%,
        rgba(var(--rgb--white), 0) 100%
      )
  }
    }

@media (--screen--md-min) {

.VacancyList__SlidesContainer_155u9::before {
      left: 0;
      background: linear-gradient(
        to right,
        rgb(255, 255, 255) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: linear-gradient(
        to right,
        var(--color--white) 0%,
        rgba(var(--rgb--white), 0) 100%
      )
  }
    }

@media screen and (min-width: 1024px) {

.VacancyList__Background_3PhNH .VacancyList__SlidesContainer_155u9::before {
        background: linear-gradient(
          to right,
          rgb(240, 248, 255) 0%,
          rgba(240, 248, 255, 0) 100%
        );
        background: linear-gradient(
          to right,
          var(--color--bg-light) 0%,
          rgba(var(--rgb--bg-light), 0) 100%
        )
    }
      }

@media (--screen--md-min) {

.VacancyList__Background_3PhNH .VacancyList__SlidesContainer_155u9::before {
        background: linear-gradient(
          to right,
          rgb(240, 248, 255) 0%,
          rgba(240, 248, 255, 0) 100%
        );
        background: linear-gradient(
          to right,
          var(--color--bg-light) 0%,
          rgba(var(--rgb--bg-light), 0) 100%
        )
    }
      }

@media screen and (max-width: 1023px) {

.VacancyList__SlidesContainer_155u9 {
    overflow: visible !important
}
  }

@media (--screen--sm-max) {

.VacancyList__SlidesContainer_155u9 {
    overflow: visible !important
}
  }

@media screen and (min-width: 1024px) {

.VacancyList__SlidesContainer_155u9 {
    position: relative;
    padding: 16px 24px 64px;
    margin: -16px -24px -64px
}
  }

@media (--screen--md-min) {

.VacancyList__SlidesContainer_155u9 {
    position: relative;
    padding: 16px 24px 64px;
    margin: -16px -24px -64px
}
  }

@media screen and (max-width: 767px) {

.VacancyList__Slide_17BEP {
    width: 300px
}
  }

@media (--screen--xs-max) {

.VacancyList__Slide_17BEP {
    width: 300px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.VacancyList__Slide_17BEP {
    width: 280px
}
  }

@media (--screen--sm-only) {

.VacancyList__Slide_17BEP {
    width: 280px
}
  }

.VacancyList__SliderArrowLeft_3-9Qj {
  transform: translateY(calc(-50% - 24px)) rotate(180deg) !important;
}

.VacancyList__SliderArrowRight_7JjMX {
  transform: translateY(calc(-50% - 24px)) !important;
}
