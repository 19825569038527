.Content__Date_30FDQ {
  margin-top: 40px;
  margin-top: var(--padding--3xl);
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Content__Title_3bb4k {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

.Content__Sources_3Rvxs {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

@media screen and (max-width: 767px) {

.Content__Sources_3Rvxs {
    grid-row-gap: 24px;
    grid-row-gap: var(--padding--xl)
}
  }

@media (--screen--xs-max) {

.Content__Sources_3Rvxs {
    grid-row-gap: 24px;
    grid-row-gap: var(--padding--xl)
}
  }

@media screen and (min-width: 768px) {

.Content__Sources_3Rvxs {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.Content__Sources_3Rvxs {
    --noOfColumns: 12
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Source_2HE9s {
    grid-column: span 5
}
  }

@media (--screen--sm-only) {

.Content__Source_2HE9s {
    grid-column: span 5
}
  }

@media screen and (min-width: 1024px) {

.Content__Source_2HE9s {
    grid-column: span 4
}
  }

@media (--screen--md-min) {

.Content__Source_2HE9s {
    grid-column: span 4
}
  }

.Content__SourceTitle_317ZC {
  margin-bottom: 8px;
  margin-bottom: var(--padding--sm);
}

.Content__Video_35nTY {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
}

.Content__Video_35nTY video {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.Content__Description_qd0O6 {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__PopupGallery_2rG8z {
    width: 720px
}
  }

@media (--screen--sm-only) {

.Content__PopupGallery_2rG8z {
    width: 720px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Content__PopupGallery_2rG8z {
    width: 896px
}
  }

@media (--screen--md-only) {

.Content__PopupGallery_2rG8z {
    width: 896px
}
  }

@media screen and (min-width: 1366px) {

.Content__PopupGallery_2rG8z {
    width: 1174px
}
  }

@media (--screen--lg-min) {

.Content__PopupGallery_2rG8z {
    width: 1174px
}
  }

.Content__Gallery_HQFET {
  grid-row-gap: 32px;
  grid-row-gap: var(--padding--2xl);
}

@media screen and (min-width: 768px) {

.Content__Gallery_HQFET {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.Content__Gallery_HQFET {
    --noOfColumns: 12
}
  }

.Content__GalleryItem_1Jbpc {
  overflow: hidden;
  border: 1px solid rgba(18, 82, 245, 0.1);
  border: 1px solid rgba(var(--rgb--second-blue), 0.1);
  border-radius: 2px;
  box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1);
  box-shadow: 0 16px 24px rgba(var(--rgb--second-blue), 0.1);
}

@media screen and (max-width: 767px) {

.Content__GalleryItem_1Jbpc {
    height: 246px
}
  }

@media (--screen--xs-max) {

.Content__GalleryItem_1Jbpc {
    height: 246px
}
  }

@media screen and (min-width: 768px) {

.Content__GalleryItem_1Jbpc {
    grid-column: span 4
}
  }

@media (--screen--sm-min) {

.Content__GalleryItem_1Jbpc {
    grid-column: span 4
}
  }

.Content__GalleryItem_1Jbpc img {
    -o-object-fit: cover;
       object-fit: cover;
  }

@media screen and (max-width: 767px) {

.Content__GalleryItem_1Jbpc img {
      min-height: 100%
  }
    }

@media (--screen--xs-max) {

.Content__GalleryItem_1Jbpc img {
      min-height: 100%
  }
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__GalleryItem_1Jbpc img {
      width: 219px;
      height: 147px
  }
    }

@media (--screen--sm-only) {

.Content__GalleryItem_1Jbpc img {
      width: 219px;
      height: 147px
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Content__GalleryItem_1Jbpc img {
      width: 277px;
      height: 186px
  }
    }

@media (--screen--md-only) {

.Content__GalleryItem_1Jbpc img {
      width: 277px;
      height: 186px
  }
    }

@media screen and (min-width: 1366px) {

.Content__GalleryItem_1Jbpc img {
      width: 370px;
      height: 248px
  }
    }

@media (--screen--lg-min) {

.Content__GalleryItem_1Jbpc img {
      width: 370px;
      height: 248px
  }
    }

.Content__Separator_3hFoO {
  height: 1px;
  border: 0;
  margin: var(--padding--4xl) 0 40px 0;
  margin: var(--padding--4xl) 0 var(--padding--3xl) 0;
  background: rgba(0, 0, 0, 0.1);
  background: rgba(var(--rgb--black), 0.1);
}

.Content__Tags_2JDv7 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(16px * -1);
  margin-bottom: calc(var(--padding--lg) * -1);
}

.Content__Tag_1LfB5 {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
}

.Content__Tag_1LfB5:not(:last-child) {
    margin-right: 16px;
    margin-right: var(--padding--lg);
  }

.Content__Share_1Jc_1 {
  margin-top: 40px;
  margin-top: var(--padding--3xl);
}

@media print {

.Content__Share_1Jc_1 {
    display: none
}
  }

.Content__NewsSubscriptionMagazine_33SNY {
  margin-top: var(--padding--5xl);
  margin-bottom: var(--padding--5xl);
}
