* + .Picture__Wrapper_3FLxK,
div.Picture__Wrapper_3FLxK + * {
  margin-top: 40px !important;
}

.Picture__Wrapper_3FLxK {
  position: relative;
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Picture__Wrapper_3FLxK {
    margin-right: -79px
}
  }

@media (--screen--md-only) {

.Picture__Wrapper_3FLxK {
    margin-right: -79px
}
  }

@media screen and (min-width: 1366px) {

.Picture__Wrapper_3FLxK {
    margin-right: -101px
}
  }

@media (--screen--lg-min) {

.Picture__Wrapper_3FLxK {
    margin-right: -101px
}
  }

.Picture__Image_2SoFd {
  overflow: hidden;
  border-radius: 2px;
}

@media screen and (max-width: 767px) {

.Picture__Image_2SoFd {
    margin-right: -24px;
    margin-left: -24px
}
  }

@media (--screen--xs-max) {

.Picture__Image_2SoFd {
    margin-right: -24px;
    margin-left: -24px
}
  }

.Picture__Caption_P_Eo8 {
  margin-top: 16px;
  font-size: 12px;
  line-height: 16px;
}

.Picture__Link_2Ktn2 {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
