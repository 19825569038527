.Content__Title_1nc20 {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

.Content__List_1zwfE {
  margin-top: 40px;
  margin-top: var(--padding--3xl);
  margin-bottom: var(--padding--5xl);
  grid-row-gap: 32px;
  grid-row-gap: var(--padding--2xl);
}

@media screen and (min-width: 768px) {

.Content__List_1zwfE {
    --noOfColumns: 2
}
  }

@media (--screen--sm-min) {

.Content__List_1zwfE {
    --noOfColumns: 2
}
  }

.Content__List_1zwfE + button {
  margin-top: calc((var(--padding--5xl) * -1) + 32px);
  margin-top: calc((var(--padding--5xl) * -1) + var(--padding--2xl));
}
