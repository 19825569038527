.Content__Wrapper_1Ygk1 {
  padding-bottom: var(--padding--5xl);
}

.Content__Head_2oD14 {
  margin-bottom: var(--padding--4xl);
}

@media screen and (max-width: 767px) {

.Content__Head_2oD14 {
    margin-bottom: 48px
}
  }

@media (--screen--xs-max) {

.Content__Head_2oD14 {
    margin-bottom: 48px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Head_2oD14 {
    grid-column: span 6
}
  }

@media (--screen--sm-only) {

.Content__Head_2oD14 {
    grid-column: span 6
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Content__Head_2oD14 {
    grid-column: span 7
}
  }

@media (--screen--md-only) {

.Content__Head_2oD14 {
    grid-column: span 7
}
  }

@media screen and (min-width: 1366px) {

.Content__Head_2oD14 {
    grid-column: span 6
}
  }

@media (--screen--lg-min) {

.Content__Head_2oD14 {
    grid-column: span 6
}
  }

.Content__Title_33p-C {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Content__Cards_9853M {
}

@media screen and (max-width: 767px) {

.Content__Cards_9853M {
    grid-row-gap: 24px
}
  }

@media (--screen--xs-max) {

.Content__Cards_9853M {
    grid-row-gap: 24px
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Content__Cards_9853M {
    grid-row-gap: 48px
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Content__Cards_9853M {
    grid-row-gap: 48px
}
  }

@media screen and (min-width: 1366px) {

.Content__Cards_9853M {
    grid-row-gap: 64px
}
  }

@media (--screen--lg-min) {

.Content__Cards_9853M {
    grid-row-gap: 64px
}
  }

@media screen and (max-width: 767px) {

.Content__Card_1XydT:not(:last-child) {
      padding-bottom: 24px;
      border-bottom: 1px solid rgb(196, 196, 196)
  }
    }

@media (--screen--xs-max) {

.Content__Card_1XydT:not(:last-child) {
      padding-bottom: 24px;
      border-bottom: 1px solid rgb(196, 196, 196)
  }
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Card_1XydT {
    grid-column: span 5
}
  }

@media (--screen--sm-only) {

.Content__Card_1XydT {
    grid-column: span 5
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Content__Card_1XydT {
    grid-column: span 4
}
  }

@media (--screen--md-only) {

.Content__Card_1XydT {
    grid-column: span 4
}
  }

@media screen and (min-width: 1366px) {

.Content__Card_1XydT {
    grid-column: span 3
}
  }

@media (--screen--lg-min) {

.Content__Card_1XydT {
    grid-column: span 3
}
  }

.Content__Company_2KcB9 {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Content__Name_2Cm_y {
  margin-bottom: 4px;
}

.Content__Email_1Ht90 {
  font-size: 12px;
  line-height: 16px;
}

.Content__Phone_2yHvM {
  margin-top: 16px;
  margin-top: var(--padding--lg);
}
