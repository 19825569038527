.Content__Wrapper_13fpI {
  padding-bottom: var(--padding--5xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__ColLeft_2yq-R {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Content__ColLeft_2yq-R {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) {

.Content__ColLeft_2yq-R {
    grid-column: span 7
}
  }

@media (--screen--md-min) {

.Content__ColLeft_2yq-R {
    grid-column: span 7
}
  }

.Content__Items_20c-c:not(:last-child) {
    margin-bottom: var(--padding--4xl);
  }

.Content__AccordionHeading_1_l1E {
  margin-bottom: 32px;
}

@media screen and (max-width: 767px) {

.Content__AccordionHeading_1_l1E {
    padding-top: 80px;
    margin-top: -80px
}
  }

@media (--screen--xs-max) {

.Content__AccordionHeading_1_l1E {
    padding-top: 80px;
    margin-top: -80px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__AccordionHeading_1_l1E {
    padding-top: 80px;
    margin-top: -80px
}
  }

@media (--screen--sm-only) {

.Content__AccordionHeading_1_l1E {
    padding-top: 80px;
    margin-top: -80px
}
  }

@media screen and (min-width: 1024px) {

.Content__AccordionHeading_1_l1E {
    padding-top: 150px;
    margin-top: -150px
}
  }

@media (--screen--md-min) {

.Content__AccordionHeading_1_l1E {
    padding-top: 150px;
    margin-top: -150px
}
  }

.Content__ColRight_221Yh {
}

@media screen and (min-width: 768px) {

.Content__ColRight_221Yh {
    grid-column-end: span 4
}
  }

@media (--screen--sm-min) {

.Content__ColRight_221Yh {
    grid-column-end: span 4
}
  }

@media screen and (min-width: 1024px) {

.Content__ColRight_221Yh {
    grid-column-start: 9
}
  }

@media (--screen--md-min) {

.Content__ColRight_221Yh {
    grid-column-start: 9
}
  }

.Content__Head_14xzj {
  margin-bottom: 40px;
}

.Content__Title_1rxqC {
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {

.Content__Anchors_10X8f {
    position: -webkit-sticky;
    position: sticky
}
  }

@media (--screen--sm-min) {

.Content__Anchors_10X8f {
    position: -webkit-sticky;
    position: sticky
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Content__Anchors_10X8f {
    padding: 16px 24px 24px
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Content__Anchors_10X8f {
    padding: 16px 24px 24px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Anchors_10X8f {
    top: 90px
}
  }

@media (--screen--sm-only) {

.Content__Anchors_10X8f {
    top: 90px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Content__Anchors_10X8f {
    top: 140px
}
  }

@media (--screen--md-only) {

.Content__Anchors_10X8f {
    top: 140px
}
  }

@media screen and (min-width: 1366px) {

.Content__Anchors_10X8f {
    top: 150px;
    padding: 16px 32px 32px
}
  }

@media (--screen--lg-min) {

.Content__Anchors_10X8f {
    top: 150px;
    padding: 16px 32px 32px
}
  }

.Content__FixedAnchors_1jWON {
}

@media screen and (max-width: 767px) {

.Content__FixedAnchors_1jWON {
    position: fixed;
    z-index: 2;
    right: 16px;
    bottom: 16px;
    overflow: hidden;
    width: 56px;
    height: 56px;
    background: rgb(18, 82, 245) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTUgMGgxNXYxSDV6TTAgMGgydjFIMHpNNSA3aDE1djFINXpNMCA3aDJ2MUgwek01IDE0aDE1djFINXpNMCAxNGgydjFIMHoiLz48L3N2Zz4K) center no-repeat;
    background: var(--color--second-blue) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTUgMGgxNXYxSDV6TTAgMGgydjFIMHpNNSA3aDE1djFINXpNMCA3aDJ2MUgwek01IDE0aDE1djFINXpNMCAxNGgydjFIMHoiLz48L3N2Zz4K) center no-repeat;
    border-radius: 50%;
    box-shadow: 0 8px 24px rgba(18, 82, 245, 0.35)
}
  }

@media (--screen--xs-max) {

.Content__FixedAnchors_1jWON {
    position: fixed;
    z-index: 2;
    right: 16px;
    bottom: 16px;
    overflow: hidden;
    width: 56px;
    height: 56px;
    background: rgb(18, 82, 245) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTUgMGgxNXYxSDV6TTAgMGgydjFIMHpNNSA3aDE1djFINXpNMCA3aDJ2MUgwek01IDE0aDE1djFINXpNMCAxNGgydjFIMHoiLz48L3N2Zz4K) center no-repeat;
    background: var(--color--second-blue) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTUgMGgxNXYxSDV6TTAgMGgydjFIMHpNNSA3aDE1djFINXpNMCA3aDJ2MUgwek01IDE0aDE1djFINXpNMCAxNGgydjFIMHoiLz48L3N2Zz4K) center no-repeat;
    border-radius: 50%;
    box-shadow: 0 8px 24px rgba(18, 82, 245, 0.35)
}
  }

.Content__SuggestButton_23n1O {
  display: block;
}
