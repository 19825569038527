.CardWrapper__Wrapper_3Y7OX {
  padding: 24px 32px 32px;
}

  .CardWrapper__Wrapper_3Y7OX:not(:last-child) {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl);
  }

.CardWrapper__Name__14D0 {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}
