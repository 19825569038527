.PressReleases__Wrapper_3BFrO {
  padding: var(--padding--4xl) 0 var(--padding--5xl);
}

.PressReleases__Cards_ZGCjg {
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

.PressReleases__Cards_ZGCjg {
    --noOfColumns: 3
}
      .PressReleases__Cards_ZGCjg > div:last-child {
        display: none;
      }
  }

@media (--screen--sm-min) and (--screen--md-max) {

.PressReleases__Cards_ZGCjg {
    --noOfColumns: 3
}
      .PressReleases__Cards_ZGCjg > div:last-child {
        display: none;
      }
  }

@media screen and (min-width: 1366px) {

.PressReleases__Cards_ZGCjg {
    --noOfColumns: 4
}
  }

@media (--screen--lg-min) {

.PressReleases__Cards_ZGCjg {
    --noOfColumns: 4
}
  }

@media print {

.PressReleases__Cards_ZGCjg {
    display: block
}

    .PressReleases__Cards_ZGCjg > div {
      display: inline-block;
      width: 30%;
      margin-right: 2%;
      vertical-align: top;
    }
  }
