@media screen and (max-width: 767px) {.Item__Item_-5kf4 {
    max-width: 366px;
    margin: 0 auto
}
  }
  @media (--screen--xs-max) {.Item__Item_-5kf4 {
    max-width: 366px;
    margin: 0 auto
}
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {.Item__Item_-5kf4 {
    grid-column: span 6
}
  }
  @media (--screen--sm-only) {.Item__Item_-5kf4 {
    grid-column: span 6
}
  }
  @media screen and (max-width: 1023px) {.Item__Item_-5kf4 {
    padding: 24px 32px 32px;
    padding: var(--padding--xl) var(--padding--2xl) var(--padding--2xl)
}
  }
  @media (--screen--sm-max) {.Item__Item_-5kf4 {
    padding: 24px 32px 32px;
    padding: var(--padding--xl) var(--padding--2xl) var(--padding--2xl)
}
  }
  @media screen and (min-width: 1024px) {.Item__Item_-5kf4 {
    grid-column: span 4
}
  }
  @media (--screen--md-min) {.Item__Item_-5kf4 {
    grid-column: span 4
}
  }
  @media screen and (min-width: 1024px) and (max-width: 1365px) {.Item__Item_-5kf4 {
    padding: 24px;
    padding: var(--padding--xl)
}
  }
  @media (--screen--md-only) {.Item__Item_-5kf4 {
    padding: 24px;
    padding: var(--padding--xl)
}
  }
  @media screen and (min-width: 1366px) {.Item__Item_-5kf4 {
    padding: 24px 32px 32px;
    padding: var(--padding--xl) var(--padding--2xl) var(--padding--2xl)
}
  }
  @media (--screen--lg-min) {.Item__Item_-5kf4 {
    padding: 24px 32px 32px;
    padding: var(--padding--xl) var(--padding--2xl) var(--padding--2xl)
}
  }

.Item__Heading_JQsNi {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

.Item__Poster_2e5x5 {
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Item__Poster_2e5x5 img {
    -o-object-fit: cover;
       object-fit: cover;
  }

@media screen and (max-width: 767px) {

.Item__Poster_2e5x5 img {
      width: 302px;
      height: 378px
  }
    }

@media (--screen--xs-max) {

.Item__Poster_2e5x5 img {
      width: 302px;
      height: 378px
  }
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Item__Poster_2e5x5 img {
      width: 277px;
      height: 346px
  }
    }

@media (--screen--sm-only) {

.Item__Poster_2e5x5 img {
      width: 277px;
      height: 346px
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Item__Poster_2e5x5 img {
      width: 229px;
      height: 286px
  }
    }

@media (--screen--md-only) {

.Item__Poster_2e5x5 img {
      width: 229px;
      height: 286px
  }
    }

@media screen and (min-width: 1366px) {

.Item__Poster_2e5x5 img {
      width: 306px;
      height: 382px
  }
    }

@media (--screen--lg-min) {

.Item__Poster_2e5x5 img {
      width: 306px;
      height: 382px
  }
    }

.Item__DescriptionOver_16mDl {
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 246px;
  padding: 16px 24px 24px;
  padding: var(--padding--lg) var(--padding--xl) var(--padding--xl);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.8);
  background: rgba(var(--rgb--white), 0.8);
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateY(246px);
  transition: transform ease-in-out 0.3s;
}

.Item__DescriptionOver_16mDl[data-state="opened"] {
    transform: translateY(0);
  }

.Item__Bottom_2Wnkk {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Item__Link_2AIUz b {
    color: #c4c4c4;
    font-weight: 300;
    text-transform: lowercase;
  }

.Item__Button_2btMq {
  position: relative;
  width: 32px;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(18, 82, 245, 0.3);
  border-color: rgba(var(--rgb--second-blue), 0.3);
  border-radius: 50%;
  transition: border-color ease-in-out 0.3s, background ease-in-out 0.3s;
}

.Item__Button_2btMq:hover {
    border-color: rgb(18, 82, 245);
    border-color: var(--color--second-blue);
    background: rgb(18, 82, 245);
    background: var(--color--second-blue);
    cursor: pointer;
  }

.Item__Button_2btMq:hover > svg > path {
        fill: rgb(255, 255, 255);
        fill: var(--color--white);
      }

.Item__Button_2btMq > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.Item__Button_2btMq > svg > path {
      transition: fill ease-in-out 0.3s;
    }
