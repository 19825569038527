.Cards__Wrapper_tzym0 {
}

  @media screen and (max-width: 1023px) {.Cards__Wrapper_tzym0 {
    grid-row-gap: 24px
}
  }

  @media (--screen--sm-max) {.Cards__Wrapper_tzym0 {
    grid-row-gap: 24px
}
  }

  @media screen and (min-width: 1024px) {.Cards__Wrapper_tzym0 {
    --noOfColumns: 2
}
  }

  @media (--screen--md-min) {.Cards__Wrapper_tzym0 {
    --noOfColumns: 2
}
  }

@media screen and (max-width: 767px) {

.Cards__Title_gLdXF {
    margin-bottom: 16px
}
  }

@media (--screen--xs-max) {

.Cards__Title_gLdXF {
    margin-bottom: 16px
}
  }

@media screen and (min-width: 768px) {

.Cards__Title_gLdXF {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

@media (--screen--sm-min) {

.Cards__Title_gLdXF {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

.Cards__Card_3Gm0- {
  padding: 24px 40px 40px;
  padding: var(--padding--xl) 40px var(--padding--3xl);
}

.Cards__Text_gcba2:not(:last-child) {
    padding-bottom: 24px;
    padding-bottom: var(--padding--xl);
  }

.Cards__Link_3Zko1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}
