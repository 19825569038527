.Hero__Wrapper__YXrt {
  position: relative;
  margin-bottom: var(--padding--5xl);
}

  .Hero__Wrapper__YXrt::before {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 384px;
    margin-left: -50vw;
    background-image: url(/_next/static/images/wave-3ea343f09eafe361a13a5114faa42420.svg);
    background-repeat: no-repeat;
    content: "";
  }

  @media screen and (max-width: 767px) {

  .Hero__Wrapper__YXrt::before {
      background-position: -500px, center;
      transform: translateY(-60px)
  }
    }

  @media (--screen--xs-max) {

  .Hero__Wrapper__YXrt::before {
      background-position: -500px, center;
      transform: translateY(-60px)
  }
    }

  @media screen and (min-width: 768px) {

  .Hero__Wrapper__YXrt::before {
      background-position: center;
      transform: translateY(-45px)
  }
    }

  @media (--screen--sm-min) {

  .Hero__Wrapper__YXrt::before {
      background-position: center;
      transform: translateY(-45px)
  }
    }

.Hero__Links_2IlUS {
  position: relative;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {

.Hero__Links_2IlUS {
    justify-content: space-between
}
  }

@media (--screen--xs-max) {

.Hero__Links_2IlUS {
    justify-content: space-between
}
  }

.Hero__Year_3PZs1 {
  padding: 0 16px;
}

.Hero__Title_23jRF {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}
