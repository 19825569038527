.TimelineEvents__WrapperWrapper_3EXkr {
  will-change: transform;
}

.TimelineEvents__Wrapper_31pFz {
  position: relative;
}

.TimelineEvents__Wrapper_31pFz[data-theme="dark"] {
    --accent-rgb: var(--rgb--accent-light-blue);
  }

.TimelineEvents__Wrapper_31pFz[data-theme="light"] {
    --accent-rgb: var(--rgb--second-blue);
  }

@media screen and (min-width: 768px) {

.TimelineEvents__Wrapper_31pFz {
    width: calc(var(--grids) * 100%)
}
  }

@media (--screen--sm-min) {

.TimelineEvents__Wrapper_31pFz {
    width: calc(var(--grids) * 100%)
}
  }

.TimelineEvents__SliderYears_202K7 {
  display: flex;
  margin-bottom: 56px;
}

@media screen and (max-width: 767px) {

.TimelineEvents__SliderYears_202K7 {
    display: none
}
  }

@media (--screen--xs-max) {

.TimelineEvents__SliderYears_202K7 {
    display: none
}
  }

.TimelineEvents__SliderYear_2vbDT {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
  opacity: 0.3;
  transition: opacity 0.3s;
}

.TimelineEvents__SliderYear_2vbDT:not(:last-child) {
    margin-right: 16px;
  }

.TimelineEvents__ActiveYear_3cUrd {
  opacity: 1;
  pointer-events: none;
}

.TimelineEvents__FutureYear_1azGb {
  opacity: 0.15;
  pointer-events: none;
}

.TimelineEvents__Events_Ed26f {
}

@media screen and (min-width: 768px) {

.TimelineEvents__Events_Ed26f {
    grid-template-columns: repeat(calc(var(--grids) * 6), 1fr)
}
  }

@media (--screen--sm-min) {

.TimelineEvents__Events_Ed26f {
    grid-template-columns: repeat(calc(var(--grids) * 6), 1fr)
}
  }

.TimelineEvents__Event_12I4W {
  position: relative;
  z-index: 1;
  cursor: pointer;
  pointer-events: none;
}

@media screen and (max-width: 767px) {

.TimelineEvents__Event_12I4W:hover .TimelineEvents__Title_22H0w > span::after {
            opacity: 1;
            transform: translateX(6px)
        }
          }

@media (--screen--xs-max) {

.TimelineEvents__Event_12I4W:hover .TimelineEvents__Title_22H0w > span::after {
            opacity: 1;
            transform: translateX(6px)
        }
          }

@media screen and (min-width: 768px) {

.TimelineEvents__Event_12I4W {
    padding-left: 24px;
    grid-column: 2 span
}

    .TimelineEvents__Event_12I4W::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3px;
      background: linear-gradient(180deg, #d6ebff 0%, #1252f5 22.59%, #d6ebff 60.42%, #d6ebff 100%);
      border-radius: 100px;
      content: "";
    }

    .TimelineEvents__Event_12I4W::after {
      position: absolute;
      z-index: 1;
      left: -10px;
      width: 23px;
      height: 23px;
      background-color: rgb(18, 82, 245);
      background-color: var(--color--second-blue);
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0zLjUgMHY3TTcgMy41SDAiIHN0cm9rZT0iI2ZmZiIvPjwvc3ZnPg==);
      background-position: 50%;
      background-repeat: no-repeat;
      border-radius: 50%;
      content: "";
      pointer-events: all;
      transition: background-color 0.3s, box-shadow 0.3s;
    }
      .TimelineEvents__Event_12I4W:hover::after {
        background-color: rgba(var(--accent-rgb), 1);
        box-shadow: 0 0 0 16px rgba(var(--accent-rgb), 0.5), 0 0 0 48px rgba(var(--accent-rgb), 0.2),
          0 0 0 96px rgba(var(--accent-rgb), 0.1);
      }

      .TimelineEvents__Event_12I4W:hover .TimelineEvents__Year_6D04C {
        color: rgba(var(--accent-rgb), 1);
      }

    .TimelineEvents__Event_12I4W:nth-child(odd) {
      align-self: flex-end;
      grid-row: 1;
    }

      .TimelineEvents__Event_12I4W:nth-child(odd)::before {
        bottom: -140px;
      }

      .TimelineEvents__Event_12I4W:nth-child(odd)::after {
        bottom: -11px;
      }

    .TimelineEvents__Event_12I4W:nth-child(even) {
      align-self: flex-start;
      grid-row: 3;
    }

      .TimelineEvents__Event_12I4W:nth-child(even)::before {
        top: -130px;
      }

      .TimelineEvents__Event_12I4W:nth-child(even)::after {
        top: -11px;
      }

    .TimelineEvents__Event_12I4W:nth-child(6n + 1) {
      padding-bottom: 164px;
    }

      .TimelineEvents__Event_12I4W:nth-child(6n + 1)::before {
        bottom: 0;
        background-image: linear-gradient(
          180deg,
          rgba(18, 70, 245, 0.12) 0%,
          rgba(18, 73, 245, 0.8) 91.89%,
          #00c4ff 96.8%,
          rgba(18, 82, 245, 0.18) 100%
        );
      }

    .TimelineEvents__Event_12I4W:nth-child(6n + 2) {
      padding-top: 121px;
    }

      .TimelineEvents__Event_12I4W:nth-child(6n + 2)::before {
        top: 0;
        background-image: linear-gradient(
          180deg,
          rgba(18, 70, 245, 0.12) 0%,
          #00c4ff 20.91%,
          rgba(18, 79, 245, 0.62) 28.64%,
          rgba(18, 82, 245, 0.18) 100%
        );
      }

    .TimelineEvents__Event_12I4W:nth-child(6n + 3) {
      padding-bottom: 224px;
    }

    .TimelineEvents__Event_12I4W:nth-child(6n + 4) {
      padding-top: 176px;
    }

    .TimelineEvents__Event_12I4W:nth-child(6n + 5) {
      padding-bottom: 133px;
    }

    .TimelineEvents__Event_12I4W:nth-child(6n + 6) {
      padding-top: 133px;
    }

    .TimelineEvents__Event_12I4W:nth-child(2) {
      grid-column: 2/2 span;
    }
  }

@media (--screen--sm-min) {

.TimelineEvents__Event_12I4W {
    padding-left: 24px;
    grid-column: 2 span
}

    .TimelineEvents__Event_12I4W::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3px;
      background: linear-gradient(180deg, #d6ebff 0%, #1252f5 22.59%, #d6ebff 60.42%, #d6ebff 100%);
      border-radius: 100px;
      content: "";
    }

    .TimelineEvents__Event_12I4W::after {
      position: absolute;
      z-index: 1;
      left: -10px;
      width: 23px;
      height: 23px;
      background-color: rgb(18, 82, 245);
      background-color: var(--color--second-blue);
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0zLjUgMHY3TTcgMy41SDAiIHN0cm9rZT0iI2ZmZiIvPjwvc3ZnPg==);
      background-position: 50%;
      background-repeat: no-repeat;
      border-radius: 50%;
      content: "";
      pointer-events: all;
      transition: background-color 0.3s, box-shadow 0.3s;
    }
      .TimelineEvents__Event_12I4W:hover::after {
        background-color: rgba(var(--accent-rgb), 1);
        box-shadow: 0 0 0 16px rgba(var(--accent-rgb), 0.5), 0 0 0 48px rgba(var(--accent-rgb), 0.2),
          0 0 0 96px rgba(var(--accent-rgb), 0.1);
      }

      .TimelineEvents__Event_12I4W:hover .TimelineEvents__Year_6D04C {
        color: rgba(var(--accent-rgb), 1);
      }

    .TimelineEvents__Event_12I4W:nth-child(odd) {
      align-self: flex-end;
      grid-row: 1;
    }

      .TimelineEvents__Event_12I4W:nth-child(odd)::before {
        bottom: -140px;
      }

      .TimelineEvents__Event_12I4W:nth-child(odd)::after {
        bottom: -11px;
      }

    .TimelineEvents__Event_12I4W:nth-child(even) {
      align-self: flex-start;
      grid-row: 3;
    }

      .TimelineEvents__Event_12I4W:nth-child(even)::before {
        top: -130px;
      }

      .TimelineEvents__Event_12I4W:nth-child(even)::after {
        top: -11px;
      }

    .TimelineEvents__Event_12I4W:nth-child(6n + 1) {
      padding-bottom: 164px;
    }

      .TimelineEvents__Event_12I4W:nth-child(6n + 1)::before {
        bottom: 0;
        background-image: linear-gradient(
          180deg,
          rgba(18, 70, 245, 0.12) 0%,
          rgba(18, 73, 245, 0.8) 91.89%,
          #00c4ff 96.8%,
          rgba(18, 82, 245, 0.18) 100%
        );
      }

    .TimelineEvents__Event_12I4W:nth-child(6n + 2) {
      padding-top: 121px;
    }

      .TimelineEvents__Event_12I4W:nth-child(6n + 2)::before {
        top: 0;
        background-image: linear-gradient(
          180deg,
          rgba(18, 70, 245, 0.12) 0%,
          #00c4ff 20.91%,
          rgba(18, 79, 245, 0.62) 28.64%,
          rgba(18, 82, 245, 0.18) 100%
        );
      }

    .TimelineEvents__Event_12I4W:nth-child(6n + 3) {
      padding-bottom: 224px;
    }

    .TimelineEvents__Event_12I4W:nth-child(6n + 4) {
      padding-top: 176px;
    }

    .TimelineEvents__Event_12I4W:nth-child(6n + 5) {
      padding-bottom: 133px;
    }

    .TimelineEvents__Event_12I4W:nth-child(6n + 6) {
      padding-top: 133px;
    }

    .TimelineEvents__Event_12I4W:nth-child(2) {
      grid-column: 2/2 span;
    }
  }

@media screen and (max-width: 1023px) {
    .TimelineEvents__Event_12I4W:not(:last-child) {
      margin-bottom: 32px;
    }
  }

@media (--screen--sm-max) {
    .TimelineEvents__Event_12I4W:not(:last-child) {
      margin-bottom: 32px;
    }
  }

.TimelineEvents__Year_6D04C {
  margin-bottom: 8px;
  margin-bottom: var(--padding--sm);
  pointer-events: all;
  transition: color 0.3s;
}

.TimelineEvents__Title_22H0w {
  margin-bottom: 8px;
  margin-bottom: var(--padding--sm);
  pointer-events: all;
}

@media screen and (min-width: 768px) {

.TimelineEvents__Title_22H0w {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px
}
  }

@media (--screen--sm-min) {

.TimelineEvents__Title_22H0w {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px
}
  }

@media screen and (min-width: 768px) {

.TimelineEvents__Title_22H0w > span::before,
    .TimelineEvents__Title_22H0w > span::after {
        content: none
    }
      }

@media (--screen--sm-min) {

.TimelineEvents__Title_22H0w > span::before,
    .TimelineEvents__Title_22H0w > span::after {
        content: none
    }
      }

@media screen and (min-width: 768px) {

.TimelineEvents__Title_22H0w > span {
      padding: 0;
      color: currentColor
  }
    }

@media (--screen--sm-min) {

.TimelineEvents__Title_22H0w > span {
      padding: 0;
      color: currentColor
  }
    }

@media screen and (max-width: 767px) {

.TimelineEvents__Timeline_1JXNC {
    display: none
}
  }

@media (--screen--xs-max) {

.TimelineEvents__Timeline_1JXNC {
    display: none
}
  }

@media screen and (min-width: 768px) {

.TimelineEvents__Timeline_1JXNC {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%)
}
  }

@media (--screen--sm-min) {

.TimelineEvents__Timeline_1JXNC {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%)
}
  }

@media screen and (min-width: 768px) {

.TimelineEvents__TimelineWrapper_26e5M {
    position: relative;
    grid-column: calc(var(--grids) * 6) span;
    grid-row: 2
}
  }

@media (--screen--sm-min) {

.TimelineEvents__TimelineWrapper_26e5M {
    position: relative;
    grid-column: calc(var(--grids) * 6) span;
    grid-row: 2
}
  }
