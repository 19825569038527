.List__Wrapper_-spEu {
  margin-bottom: var(--padding--5xl);
}

.List__Tab_1-TOf {
  justify-content: center;
}

.List__Items_2r1xr {
  margin-top: 40px;
  margin-top: var(--padding--3xl);
  grid-row-gap: 32px;
  grid-row-gap: var(--padding--2xl);
}

@media screen and (min-width: 768px) {

.List__Items_2r1xr {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.List__Items_2r1xr {
    --noOfColumns: 12
}
  }

@media print {

.List__Items_2r1xr {
    display: block
}

    .List__Items_2r1xr::after {
      display: table;
      clear: both;
      content: "";
    }

    .List__Items_2r1xr > div {
      display: inline-block;
      width: 30%;
      margin-right: 2%;
    }

    .List__Items_2r1xr img {
      width: 100%;
      height: 250px;
    }
  }
