@media screen and (min-width: 768px) and (max-width: 1023px) {.Item__Wrapper_Uy9P- {
    flex-basis: 407px !important
}
  }
  @media (--screen--sm-only) {.Item__Wrapper_Uy9P- {
    flex-basis: 407px !important
}
  }
  @media screen and (hover: hover) {
      .Item__Wrapper_Uy9P-:hover .Item__Title_3QkpC {
        color: var(--card--accent-color);
      }
  }

.Item__Image_irhJg {
  overflow: hidden;
}

.Item__Image_irhJg img {
    -o-object-fit: cover;
       object-fit: cover;
  }

@media screen and (max-width: 767px) {

.Item__Image_irhJg img {
      width: 268px;
      height: 150px
  }
    }

@media (--screen--xs-max) {

.Item__Image_irhJg img {
      width: 268px;
      height: 150px
  }
    }

@media screen and (min-width: 768px) {

.Item__Image_irhJg img {
      height: 200px
  }
    }

@media (--screen--sm-min) {

.Item__Image_irhJg img {
      height: 200px
  }
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Item__Image_irhJg img {
      width: 407px
  }
    }

@media (--screen--sm-only) {

.Item__Image_irhJg img {
      width: 407px
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Item__Image_irhJg img {
      width: 432px
  }
    }

@media (--screen--md-only) {

.Item__Image_irhJg img {
      width: 432px
  }
    }

@media screen and (min-width: 1366px) {

.Item__Image_irhJg img {
      width: 571px
  }
    }

@media (--screen--lg-min) {

.Item__Image_irhJg img {
      width: 571px
  }
    }

@media screen and (max-width: 767px) {

.Item__Footer_yp0C3 {
    padding: 16px 24px 32px;
    padding: var(--padding--lg) var(--padding--xl) var(--padding--2xl)
}
  }

@media (--screen--xs-max) {

.Item__Footer_yp0C3 {
    padding: 16px 24px 32px;
    padding: var(--padding--lg) var(--padding--xl) var(--padding--2xl)
}
  }

@media screen and (min-width: 768px) {

.Item__Footer_yp0C3 {
    padding: 24px 32px 32px;
    padding: var(--padding--xl) var(--padding--2xl) var(--padding--2xl)
}
  }

@media (--screen--sm-min) {

.Item__Footer_yp0C3 {
    padding: 24px 32px 32px;
    padding: var(--padding--xl) var(--padding--2xl) var(--padding--2xl)
}
  }

.Item__Title_3QkpC {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
  transition: color ease-in-out 0.3s;
}
