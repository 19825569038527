.PopupItem__Wrapper_3UCDJ {
  grid-row-gap: 40px;
}

  @media screen and (max-width: 767px) {.PopupItem__Wrapper_3UCDJ {
    padding-bottom: 24px
}
  }

  @media (--screen--xs-max) {.PopupItem__Wrapper_3UCDJ {
    padding-bottom: 24px
}
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {.PopupItem__Wrapper_3UCDJ {
    padding-bottom: 40px
}
  }

  @media (--screen--sm-only) {.PopupItem__Wrapper_3UCDJ {
    padding-bottom: 40px
}
  }

  @media screen and (min-width: 1024px) {.PopupItem__Wrapper_3UCDJ {
    padding-bottom: 48px
}
  }

  @media (--screen--md-min) {.PopupItem__Wrapper_3UCDJ {
    padding-bottom: 48px
}
  }

.PopupItem__Title_1QrhA {
  margin-bottom: 8px;
  margin-bottom: var(--padding--sm);
}

.PopupItem__List_c0HUr {
  --noOfColumns: 2;
  grid-row-gap: 16px;
}

.PopupItem__Name_1gXKj {
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
}
