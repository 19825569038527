@media screen and (max-width: 767px) {.FootNote__Wrapper__5pSD {
    margin-top: 32px
}
  }

@media (--screen--xs-max) {.FootNote__Wrapper__5pSD {
    margin-top: 32px
}
  }

.FootNote__Inner_21Otx {
  position: relative;
  padding-left: 19px;
}

.FootNote__Inner_21Otx::before {
    position: absolute;
    top: 4px;
    bottom: 3px;
    left: 0;
    width: 3px;
    background-color: rgb(18, 82, 245);
    background-color: var(--color--second-blue);
    content: "";
  }

.FootNote__Digit_r-rK3 {
  margin-bottom: 11px;
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  font-size: 32px;
  font-weight: 300;
  line-height: 33px;
}
