.Hero__Bg_Rx201 {
  /* opacity: 0.6; */
}

.Hero__Wrapper_1QfRl {
  padding-bottom: var(--padding--4xl);
}

.Hero__Title_3LPPH {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Hero__Lead_TA_sL {
}

@media screen and (min-width: 768px) {

.Hero__Lead_TA_sL {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.Hero__Lead_TA_sL {
    --noOfColumns: 12
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Hero__Lead_TA_sL div {
      grid-column: 8 span
  }
    }

@media (--screen--sm-only) {

.Hero__Lead_TA_sL div {
      grid-column: 8 span
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Hero__Lead_TA_sL div {
      grid-column: 6 span
  }
    }

@media (--screen--md-only) {

.Hero__Lead_TA_sL div {
      grid-column: 6 span
  }
    }

@media screen and (min-width: 1366px) {

.Hero__Lead_TA_sL div {
      grid-column: 5 span
  }
    }

@media (--screen--lg-min) {

.Hero__Lead_TA_sL div {
      grid-column: 5 span
  }
    }

.Hero__Lead_TA_sL p:not(:last-child) {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl);
  }

.Hero__Factoids_3An-U {
  margin-top: var(--padding--4xl);
}
