.Hero__Wrapper_1W6jj {
  position: relative;
  overflow: hidden;
  padding-bottom: var(--padding--4xl);
}

  @media print {.Hero__Wrapper_1W6jj {
    display: none
}
  }

.Hero__WrapperTags_QY1J7 {
  position: relative;
}

.Hero__Title_NfosB {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Hero__Tags_Mjagg {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -16px;
}

@media screen and (max-width: 767px) {

.Hero__Tags_Mjagg {
    display: none
}
  }

@media (--screen--xs-max) {

.Hero__Tags_Mjagg {
    display: none
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Hero__Tags_Mjagg {
    margin-top: 283px
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Hero__Tags_Mjagg {
    margin-top: 283px
}
  }

@media screen and (min-width: 1366px) {

.Hero__Tags_Mjagg {
    margin-top: 480px
}
  }

@media (--screen--lg-min) {

.Hero__Tags_Mjagg {
    margin-top: 480px
}
  }

.Hero__Tag_3JdII {
  margin-bottom: 16px;
}

.Hero__Tag_3JdII:not(:last-child) {
    margin-right: 16px;
  }

.Hero__Head_1GF0Y {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {

.Hero__Head_1GF0Y {
    display: flex;
    justify-content: space-between
}
  }

@media (--screen--sm-min) {

.Hero__Head_1GF0Y {
    display: flex;
    justify-content: space-between
}
  }

.Hero__Factoids_ZQwYc {
  display: flex;
}

.Hero__Factoid_30cll:not(:last-child) {
    margin-right: 32px;
  }

.Hero__FactoidNumber_39wiU {
  margin-bottom: 8px;
  margin-bottom: var(--padding--sm);
}

.Hero__FactoidText_K0hes {
  color: rgb(0, 196, 255);
  color: var(--color--accent-light-blue);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-transform: uppercase;
}
