@media screen and (max-width: 767px) {.Factoids__Wrapper_2Z3je {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(2, minmax(0, 1fr))
}
  }
  @media (--screen--xs-max) {.Factoids__Wrapper_2Z3je {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(2, minmax(0, 1fr))
}
  }
  @media screen and (min-width: 768px) {.Factoids__Wrapper_2Z3je {
    display: flex
}
  }
  @media (--screen--sm-min) {.Factoids__Wrapper_2Z3je {
    display: flex
}
  }

.Factoids__Factoid_1rbL5 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .Factoids__Factoid_1rbL5:not(:last-child) {
      margin-right: 40px;
    }
  }

@media (--screen--sm-only) {
    .Factoids__Factoid_1rbL5:not(:last-child) {
      margin-right: 40px;
    }
  }

@media screen and (min-width: 1024px) {
    .Factoids__Factoid_1rbL5:not(:last-child) {
      margin-right: 64px;
    }
  }

@media (--screen--md-min) {
    .Factoids__Factoid_1rbL5:not(:last-child) {
      margin-right: 64px;
    }
  }

.Factoids__Name_2Hf_a {
  color: rgb(0, 196, 255) !important;
  color: var(--color--accent-light-blue) !important;
}

@media screen and (max-width: 1023px) {

.Factoids__Name_2Hf_a {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg)
}
  }

@media (--screen--sm-max) {

.Factoids__Name_2Hf_a {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg)
}
  }

@media screen and (min-width: 1024px) {

.Factoids__Name_2Hf_a {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

@media (--screen--md-min) {

.Factoids__Name_2Hf_a {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

.Factoids__Name_2Hf_a div {
    color: rgba(0, 196, 255, 0.5);
    color: rgba(var(--rgb--accent-light-blue), 0.5);
    text-transform: lowercase;
  }

@media screen and (max-width: 767px) {

.Factoids__Name_2Hf_a div {
      overflow: hidden;
      width: 66px;
      text-overflow: ellipsis;
      white-space: nowrap
  }
    }

@media (--screen--xs-max) {

.Factoids__Name_2Hf_a div {
      overflow: hidden;
      width: 66px;
      text-overflow: ellipsis;
      white-space: nowrap
  }
    }

.Factoids__Value_3Q_2z {
  margin-bottom: 4px;
  margin-bottom: var(--padding--xs);
}
