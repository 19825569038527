.Hero__Wrapper_1JHuu {
  position: relative;
  overflow: hidden;
  padding-bottom: var(--padding--4xl);
}

.Hero__Head_3CriL {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (min-width: 768px) {

.Hero__Head_3CriL {
    display: block
}
  }

@media (--screen--sm-min) {

.Hero__Head_3CriL {
    display: block
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Hero__Head_3CriL {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Hero__Head_3CriL {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) {

.Hero__Head_3CriL {
    grid-column: span 6
}
  }

@media (--screen--md-min) {

.Hero__Head_3CriL {
    grid-column: span 6
}
  }

.Hero__Title_6oeeM {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Hero__ForGdrHoldersLink_2gb9W {
  margin-top: 6px;
}

@media screen and (min-width: 768px) {

.Hero__ForGdrHoldersLink_2gb9W {
    margin-top: 0;
    margin-left: 39px
}
  }

@media (--screen--sm-min) {

.Hero__ForGdrHoldersLink_2gb9W {
    margin-top: 0;
    margin-left: 39px
}
  }
