.Stocks__Revenue_3tfP6 {
  padding-top: 24px;
  border: 1px solid #e9efff;
  background: rgb(255, 255, 255);
  background: var(--color--white);
  border-radius: 2px;
  box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1);
}

  @media screen and (max-width: 767px) {.Stocks__Revenue_3tfP6 {
    padding-right: 32px;
    padding-bottom: 40px;
    padding-left: 32px
}
  }

  @media (--screen--xs-max) {.Stocks__Revenue_3tfP6 {
    padding-right: 32px;
    padding-bottom: 40px;
    padding-left: 32px
}
  }

  @media screen and (min-width: 768px) {.Stocks__Revenue_3tfP6 {
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 40px
}
  }

  @media (--screen--sm-min) {.Stocks__Revenue_3tfP6 {
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 40px
}
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {.Stocks__Revenue_3tfP6 {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

  @media (--screen--sm-only) {.Stocks__Revenue_3tfP6 {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

  @media screen and (min-width: 1024px) and (max-width: 1365px) {.Stocks__Revenue_3tfP6 {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }

  @media (--screen--md-only) {.Stocks__Revenue_3tfP6 {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }

  @media screen and (min-width: 1366px) {.Stocks__Revenue_3tfP6 {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl)
}
  }

  @media (--screen--lg-min) {.Stocks__Revenue_3tfP6 {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl)
}
  }

.Stocks__Title_38Z83 {
  margin-bottom: 16px;
}

.Stocks__Footer_3Ge6_ {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {

.Stocks__Footer_3Ge6_ {
    align-items: flex-start
}
  }

@media (--screen--xs-max) {

.Stocks__Footer_3Ge6_ {
    align-items: flex-start
}
  }

@media screen and (min-width: 768px) {

.Stocks__Footer_3Ge6_ {
    align-items: flex-end
}
  }

@media (--screen--sm-min) {

.Stocks__Footer_3Ge6_ {
    align-items: flex-end
}
  }

.Stocks__Footer_3Ge6_ > div:first-child {
      flex-shrink: 0;
    }

.Stocks__Total_110Oa {
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  font-weight: 300;
}

@media screen and (max-width: 1365px) {

.Stocks__Total_110Oa {
    font-size: 26px;
    line-height: 38px
}
  }

@media (--screen--md-max) {

.Stocks__Total_110Oa {
    font-size: 26px;
    line-height: 38px
}
  }

@media screen and (min-width: 1366px) {

.Stocks__Total_110Oa {
    font-size: 32px;
    line-height: 36px
}
  }

@media (--screen--lg-min) {

.Stocks__Total_110Oa {
    font-size: 32px;
    line-height: 36px
}
  }

.Stocks__Chart_2-yTY {
  margin-bottom: 24px;
}
