.Filters__Wrapper_158fZ {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

.Filters__Inner_s5kWt {
}

@media screen and (max-width: 767px) {

.Filters__Inner_s5kWt {
    grid-row-gap: 24px
}
  }

@media (--screen--xs-max) {

.Filters__Inner_s5kWt {
    grid-row-gap: 24px
}
  }

@media screen and (min-width: 768px) {

.Filters__Inner_s5kWt {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.Filters__Inner_s5kWt {
    --noOfColumns: 12
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Filters__Inner_s5kWt {
    grid-row-gap: 32px
}
  }

@media (--screen--sm-only) {

.Filters__Inner_s5kWt {
    grid-row-gap: 32px
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Filters__Inner_s5kWt > div:nth-child(1) {
        grid-column: 8 span
    }
      }

@media (--screen--sm-min) and (--screen--md-max) {

.Filters__Inner_s5kWt > div:nth-child(1) {
        grid-column: 8 span
    }
      }

@media screen and (min-width: 1366px) {

.Filters__Inner_s5kWt > div:nth-child(1) {
        grid-column: 9 span
    }
      }

@media (--screen--lg-min) {

.Filters__Inner_s5kWt > div:nth-child(1) {
        grid-column: 9 span
    }
      }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Filters__Inner_s5kWt > div:nth-child(2) {
        grid-column: 4 span
    }
      }

@media (--screen--sm-min) and (--screen--md-max) {

.Filters__Inner_s5kWt > div:nth-child(2) {
        grid-column: 4 span
    }
      }

@media screen and (min-width: 1366px) {

.Filters__Inner_s5kWt > div:nth-child(2) {
        grid-column: 3 span
    }
      }

@media (--screen--lg-min) {

.Filters__Inner_s5kWt > div:nth-child(2) {
        grid-column: 3 span
    }
      }

.Filters__StatusFilter_2pukX {
  margin-top: 40px;
  margin-top: var(--padding--3xl);
}
