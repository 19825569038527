@media screen and (max-width: 1023px) {.Stages__Wrapper_Yot-3 {
    margin-top: var(--padding--4xl)
}
  }

@media (--screen--sm-max) {.Stages__Wrapper_Yot-3 {
    margin-top: var(--padding--4xl)
}
  }

.Stages__Stages_dUuhp {
  display: flex;
}

@media screen and (max-width: 767px) {

.Stages__Stages_dUuhp {
    position: relative
}
  }

@media (--screen--xs-max) {

.Stages__Stages_dUuhp {
    position: relative
}
  }

.Stages__Title_35uNN {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

.Stages__Stage_1FiyF {
  flex-grow: 1;
  border: 0.5px solid #e9efff;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 16px 25px rgba(18, 82, 245, 0.1);
}

.Stages__Stage_1FiyF:hover .Stages__Name_160ml {
      color: rgb(18, 82, 245);
      color: var(--color--second-blue);
    }

.Stages__Stage_1FiyF:hover .Stages__Tooltip_37sW_ {
      --translateY: 0;

      opacity: 1;
      visibility: visible;
    }

.Stages__Stage_1FiyF:not(:last-child) {
    margin-right: 32px;
  }

@media screen and (min-width: 768px) {

.Stages__Stage_1FiyF {
    position: relative
}
  }

@media (--screen--sm-min) {

.Stages__Stage_1FiyF {
    position: relative
}
  }

@media screen and (max-width: 1023px) {
      .Stages__Stage_1FiyF:first-child .Stages__Tooltip_37sW_ {
        left: 0;
      }
      .Stages__Stage_1FiyF:last-child .Stages__Tooltip_37sW_ {
        right: 0;
      }
      .Stages__Stage_1FiyF:not(:last-child):not(:first-child) .Stages__Tooltip_37sW_ {
        --translateX: -50%;

        left: 50%;
      }
  }

@media (--screen--sm-max) {
      .Stages__Stage_1FiyF:first-child .Stages__Tooltip_37sW_ {
        left: 0;
      }
      .Stages__Stage_1FiyF:last-child .Stages__Tooltip_37sW_ {
        right: 0;
      }
      .Stages__Stage_1FiyF:not(:last-child):not(:first-child) .Stages__Tooltip_37sW_ {
        --translateX: -50%;

        left: 50%;
      }
  }

.Stages__Name_160ml {
  cursor: pointer;
  font-weight: 500;
  line-height: 54px;
  text-align: center;
  transition: color 0.3s;
}

.Stages__Tooltip_37sW_ {
  --translateY: 5px;
  --translateX: 0;

  position: absolute;
  z-index: 1;
  top: calc(100% + 20px);
  width: 300px;
  padding: 16px 24px 24px;
  border: 0.5px solid rgba(18, 82, 245, 0.1);
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1);
  opacity: 0;
  transform: translateX(var(--translateX)) translateY(var(--translateY));
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
  visibility: hidden;
}

@media screen and (min-width: 1024px) {

.Stages__Tooltip_37sW_ {
    --translateX: -50%;

    left: 50%
}
  }

@media (--screen--md-min) {

.Stages__Tooltip_37sW_ {
    --translateX: -50%;

    left: 50%
}
  }
