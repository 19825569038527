.Hero__Wrapper_LkLCC {
  position: relative;
  overflow: hidden;
  padding-bottom: var(--padding--5xl);
}

.Hero__Title_2UPkB {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Hero__Grid_10kUV {
    grid-column: 8 span
}
  }

@media (--screen--sm-only) {

.Hero__Grid_10kUV {
    grid-column: 8 span
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Hero__Grid_10kUV {
    grid-column: 6 span
}
  }

@media (--screen--md-only) {

.Hero__Grid_10kUV {
    grid-column: 6 span
}
  }

@media screen and (min-width: 1366px) {

.Hero__Grid_10kUV {
    grid-column: 5 span
}
  }

@media (--screen--lg-min) {

.Hero__Grid_10kUV {
    grid-column: 5 span
}
  }

.Hero__Links_1H6Sh {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

@media screen and (min-width: 768px) {

.Hero__Links_1H6Sh {
    display: flex
}
  }

@media (--screen--sm-min) {

.Hero__Links_1H6Sh {
    display: flex
}
  }

.Hero__Link_1qhPt {
  font-weight: 500;
  white-space: nowrap;
}

.Hero__Link_1qhPt:not(:last-child) {
    margin-right: 32px;
  }

@media screen and (max-width: 1023px) {
    .Hero__Link_1qhPt:not(:last-child) {
      margin-bottom: 16px;
    }
  }

@media (--screen--sm-max) {
    .Hero__Link_1qhPt:not(:last-child) {
      margin-bottom: 16px;
    }
  }

.Hero__Button_2luE8 {
  margin-top: 32px;
  margin-top: var(--padding--2xl);
}
