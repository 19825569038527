.Sort__Wrapper_1beHk {
  display: flex;
}

  .Sort__Wrapper_1beHk > div:not(:last-child) {
      margin-right: 24px;
      margin-right: var(--padding--xl);
    }

.Sort__Button_1Emsc {
  transition: color ease-in-out 0.3s;
}

.Sort__Button_1Emsc:hover {
    cursor: pointer;
  }

.Sort__Active_2Q7fO {
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  pointer-events: none;
}
