@media screen and (max-width: 767px) {.Obligation__Head_3jRY1 {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }
  @media (--screen--xs-max) {.Obligation__Head_3jRY1 {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }
  @media screen and (min-width: 768px) {.Obligation__Head_3jRY1 {
    grid-column: span 6
}
  }
  @media (--screen--sm-min) {.Obligation__Head_3jRY1 {
    grid-column: span 6
}
  }
  @media print {
    .Obligation__Head_3jRY1 > a {
      display: none;
    }
  }

.Obligation__Title_q3k7j {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media print {

.Obligation__Title_q3k7j {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Obligation__Description_23OmM {
    padding-top: 16px;
    padding-top: var(--padding--lg);
    grid-column: span 6
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Obligation__Description_23OmM {
    padding-top: 16px;
    padding-top: var(--padding--lg);
    grid-column: span 6
}
  }

@media screen and (min-width: 1366px) {

.Obligation__Description_23OmM {
    padding-top: 21px;
    grid-column: 7 / span 5
}
  }

@media (--screen--lg-min) {

.Obligation__Description_23OmM {
    padding-top: 21px;
    grid-column: 7 / span 5
}
  }
