.Subscription__Wrapper_26e9w {
}

  @media screen and (max-width: 767px) {.Subscription__Wrapper_26e9w {
    grid-row-gap: 40px
}
  }

  @media (--screen--xs-max) {.Subscription__Wrapper_26e9w {
    grid-row-gap: 40px
}
  }

  @media screen and (min-width: 768px) {.Subscription__Wrapper_26e9w {
    --noOfColumns: 12
}
  }

  @media (--screen--sm-min) {.Subscription__Wrapper_26e9w {
    --noOfColumns: 12
}
  }

.Subscription__Title_1a-Wi {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media screen and (min-width: 768px) {

.Subscription__TitleWrapper_17Xgv {
    grid-column: span 5
}
  }

@media (--screen--sm-min) {

.Subscription__TitleWrapper_17Xgv {
    grid-column: span 5
}
  }

@media screen and (min-width: 768px) {

.Subscription__DescriptionWrapper_2TvrW {
    margin-top: 20px;
    grid-column: 7 / span 6
}
  }

@media (--screen--sm-min) {

.Subscription__DescriptionWrapper_2TvrW {
    margin-top: 20px;
    grid-column: 7 / span 6
}
  }
