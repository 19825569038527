@media screen and (max-width: 1023px) {.TabsWrapper__Wrapper_8sbzt {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }

@media (--screen--sm-max) {.TabsWrapper__Wrapper_8sbzt {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }

.TabsWrapper__Content_2vXDV {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}
