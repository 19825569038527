.Image__Wrapper_3SbM2 {
  position: relative;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
}

  @media screen and (min-width: 1px) and (max-width: 1023px) {.Image__Wrapper_3SbM2 {
    height: 254px
}
  }

  @media (--screen--xs-min) and (--screen--sm-max) {.Image__Wrapper_3SbM2 {
    height: 254px
}
  }

  @media screen and (min-width: 1024px) and (max-width: 1365px) {.Image__Wrapper_3SbM2 {
    height: 318px
}
  }

  @media (--screen--md-only) {.Image__Wrapper_3SbM2 {
    height: 318px
}
  }

  @media screen and (min-width: 1366px) {.Image__Wrapper_3SbM2 {
    height: 420px
}
  }

  @media (--screen--lg-min) {.Image__Wrapper_3SbM2 {
    height: 420px
}
  }

.Image__WithText_3p4zK {
  position: relative;
}

.Image__WithText_3p4zK::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      204.28deg,
      rgba(0, 0, 0, 0) 22.46%,
      rgb(0, 0, 0) 88.26%
    );
    background: linear-gradient(
      204.28deg,
      rgba(var(--rgb--black), 0) 22.46%,
      var(--color--black) 88.26%
    );
    content: "";
    opacity: 0.3;
  }

.Image__Text_1hrdT {
  position: absolute;
  z-index: 1;
  bottom: 0;
  color: rgb(255, 255, 255);
  color: var(--color--white);
}

@media screen and (max-width: 767px) {

.Image__Text_1hrdT {
    padding: 0 32px 16px 24px
}
  }

@media (--screen--xs-max) {

.Image__Text_1hrdT {
    padding: 0 32px 16px 24px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Image__Text_1hrdT {
    padding: 0 56px 24px 32px
}
  }

@media (--screen--sm-only) {

.Image__Text_1hrdT {
    padding: 0 56px 24px 32px
}
  }

@media screen and (min-width: 1024px) {

.Image__Text_1hrdT {
    padding-bottom: 32px;
    padding-left: 40px
}
  }

@media (--screen--md-min) {

.Image__Text_1hrdT {
    padding-bottom: 32px;
    padding-left: 40px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Image__Text_1hrdT {
    padding-right: 72px
}
  }

@media (--screen--md-only) {

.Image__Text_1hrdT {
    padding-right: 72px
}
  }

@media screen and (min-width: 1366px) {

.Image__Text_1hrdT {
    padding-right: 260px
}
  }

@media (--screen--lg-min) {

.Image__Text_1hrdT {
    padding-right: 260px
}
  }
