@media screen and (max-width: 767px) {.ExpandLink__Wrapper_2y1LU {
    margin-top: 32px;
    margin-top: var(--padding--2xl)
}
  }

@media (--screen--xs-max) {.ExpandLink__Wrapper_2y1LU {
    margin-top: 32px;
    margin-top: var(--padding--2xl)
}
  }

.ExpandLink__Description_2S_iX {
  margin-top: 12px;
  font-size: 12px;
  line-height: 16px;
}

.ExpandLink__Link_l6C3- {
  margin-top: 16px;
}
