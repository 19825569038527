@media screen and (max-width: 767px) {.List__Title_3J_NO {
    margin-bottom: 16px
}
  }
  @media (--screen--xs-max) {.List__Title_3J_NO {
    margin-bottom: 16px
}
  }
  @media screen and (min-width: 768px) {.List__Title_3J_NO {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }
  @media (--screen--sm-min) {.List__Title_3J_NO {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

.List__Items_tgTTm {
  margin-bottom: var(--padding--4xl);
}

@media screen and (max-width: 767px) {

.List__Items_tgTTm {
    grid-row-gap: 24px
}
  }

@media (--screen--xs-max) {

.List__Items_tgTTm {
    grid-row-gap: 24px
}
  }

@media screen and (min-width: 768px) {

.List__Items_tgTTm {
    --noOfColumns: 2;

    grid-row-gap: 32px
}
  }

@media (--screen--sm-min) {

.List__Items_tgTTm {
    --noOfColumns: 2;

    grid-row-gap: 32px
}
  }

.List__Year_2BY44 {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.List__ItemsByYear_26CnQ:not(:last-child) {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl);
  }

.List__Filters_HilxT {
  margin-bottom: var(--padding--4xl);
}
