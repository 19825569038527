.History__Title_3Fx56 {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

@media screen and (max-width: 767px) {

.History__Table_vcMbI {
    position: relative;
    left: 50%;
    width: 100vw;
    padding: 0 24px;
    margin-left: -50vw;
    overflow-x: auto
}
  }

@media (--screen--xs-max) {

.History__Table_vcMbI {
    position: relative;
    left: 50%;
    width: 100vw;
    padding: 0 24px;
    margin-left: -50vw;
    overflow-x: auto
}
  }

.History__TableWrapper_2dJds {
  position: relative;
}

@media screen and (max-width: 767px) {

.History__TableWrapper_2dJds::after {
      position: absolute;
      top: 0;
      right: -24px;
      display: block;
      width: 24px;
      height: 100%;
      content: ""
  }
    }

@media (--screen--xs-max) {

.History__TableWrapper_2dJds::after {
      position: absolute;
      top: 0;
      right: -24px;
      display: block;
      width: 24px;
      height: 100%;
      content: ""
  }
    }

@media screen and (max-width: 767px) {

.History__TableWrapper_2dJds {
    width: 892px;
    margin-right: 24px
}
  }

@media (--screen--xs-max) {

.History__TableWrapper_2dJds {
    width: 892px;
    margin-right: 24px
}
  }

.History__TableInner_10OvQ {
  grid-row-gap: 24px;
  grid-row-gap: var(--padding--xl);
}

@media screen and (max-width: 1365px) {

.History__TableInner_10OvQ {
    font-size: 12px;
    line-height: 16px
}
  }

@media (--screen--md-max) {

.History__TableInner_10OvQ {
    font-size: 12px;
    line-height: 16px
}
  }

.History__Row_MIW-M {
  padding-bottom: 32px;
  padding-bottom: var(--padding--2xl);
  border-bottom: 1px solid rgba(18, 82, 245, 0.3);
  border-bottom: 1px solid rgba(var(--rgb--second-blue), 0.3);
}

@media screen and (max-width: 767px) {

.History__Row_MIW-M {
    --noOfColumns: 6;

    grid-template-columns: repeat(var(--noOfColumns), 122px)
}
  }

@media (--screen--xs-max) {

.History__Row_MIW-M {
    --noOfColumns: 6;

    grid-template-columns: repeat(var(--noOfColumns), 122px)
}
  }

@media screen and (min-width: 768px) {

.History__Row_MIW-M {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.History__Row_MIW-M {
    --noOfColumns: 12
}
  }

.History__RowHeading_3KcaG {
  padding-bottom: 24px;
  padding-bottom: var(--padding--xl);
  border-bottom: 1px solid rgb(18, 82, 245);
  border-bottom: 1px solid var(--color--second-blue);
}

@media screen and (min-width: 768px) {

.History__Cell_e9Uvo {
    grid-column: span 2
}
  }

@media (--screen--sm-min) {

.History__Cell_e9Uvo {
    grid-column: span 2
}
  }

.History__Cell_e9Uvo > span {
    display: block;
    margin-top: 4px;
    margin-top: var(--padding--xs);
    font-size: 12px;
    line-height: 16px;
  }

.History__CellHeading_10BQn {
  font-weight: 500;
}

@media screen and (min-width: 768px) {

.History__CellHeading_10BQn {
    grid-column: span 2
}
  }

@media (--screen--sm-min) {

.History__CellHeading_10BQn {
    grid-column: span 2
}
  }

.History__Note_qgoZM {
  margin-top: 16px;
  margin-top: var(--padding--lg);
  font-size: 12px;
  line-height: 16px;
}
