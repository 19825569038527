.Anchors__Wrapper_2PuW9 {
  margin-top: var(--padding--4xl);
  margin-bottom: var(--padding--4xl);
}

.Anchors__Inner_3kLvP {
}

@media screen and (max-width: 767px) {

.Anchors__Inner_3kLvP {
    grid-row-gap: 24px;
    grid-row-gap: var(--padding--xl)
}
  }

@media (--screen--xs-max) {

.Anchors__Inner_3kLvP {
    grid-row-gap: 24px;
    grid-row-gap: var(--padding--xl)
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Anchors__Inner_3kLvP {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Anchors__Inner_3kLvP {
    --noOfColumns: 12
}
  }

@media screen and (min-width: 1366px) {

.Anchors__Inner_3kLvP {
    display: flex;
    grid-column-gap: 0
}
  }

@media (--screen--lg-min) {

.Anchors__Inner_3kLvP {
    display: flex;
    grid-column-gap: 0
}
  }

@media screen and (min-width: 1366px) {

.Anchors__Item_1caTE:not(:last-child) {
      margin-right: 40px;
      margin-right: var(--padding--3xl)
  }
    }

@media (--screen--lg-min) {

.Anchors__Item_1caTE:not(:last-child) {
      margin-right: 40px;
      margin-right: var(--padding--3xl)
  }
    }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Anchors__Item_1caTE {
    grid-column: span 4
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Anchors__Item_1caTE {
    grid-column: span 4
}
  }

.Anchors__Link_2MgTP {
  position: relative;
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  font-size: 26px;
  font-weight: 300;
  line-height: 32px;
}

.Anchors__Link_2MgTP::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba(18, 82, 245, 0.3);
    background: rgba(var(--rgb--second-blue), 0.3);
    content: "";
    opacity: 1;
    transition: opacity ease-in-out 0.3s;
    will-change: opacity;
  }

.Anchors__Link_2MgTP:hover::after {
      opacity: 0;
    }
