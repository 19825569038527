@media screen and (min-width: 1024px) {.Hero__Wrapper_GzuV4 {
    background-color: rgb(240, 248, 255);
    background-color: var(--color--bg-light)
}
  }

@media (--screen--md-min) {.Hero__Wrapper_GzuV4 {
    background-color: rgb(240, 248, 255);
    background-color: var(--color--bg-light)
}
  }

.Hero__Title_2FO3G {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Hero__Link_1Td2f {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

.Hero__Facts_1OQHf {
}

@media screen and (max-width: 767px) {

.Hero__Facts_1OQHf {
    grid-row-gap: 32px;
    grid-row-gap: var(--padding--2xl)
}
  }

@media (--screen--xs-max) {

.Hero__Facts_1OQHf {
    grid-row-gap: 32px;
    grid-row-gap: var(--padding--2xl)
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Hero__Facts_1OQHf {
    --noOfColumns: 2
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Hero__Facts_1OQHf {
    --noOfColumns: 2
}
  }

@media screen and (min-width: 1366px) {

.Hero__Facts_1OQHf {
    --noOfColumns: 12
}
  }

@media (--screen--lg-min) {

.Hero__Facts_1OQHf {
    --noOfColumns: 12
}
  }

@media screen and (min-width: 1366px) {

.Hero__Fact_Zsabt:first-child {
      grid-column: span 5
  }
    }

@media (--screen--lg-min) {

.Hero__Fact_Zsabt:first-child {
      grid-column: span 5
  }
    }

.Hero__FactName_4OfIB {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}
