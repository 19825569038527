.CurrentInvestments__Wrapper_36BSs {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--5xl);
}

  @media print {.CurrentInvestments__Wrapper_36BSs {
    color: #000
}
  }

.CurrentInvestments__Head_2GlzG {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

.CurrentInvestments__Heading_2y07A {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.CurrentInvestments__ColLeft_3zAcy,
.CurrentInvestments__ColRight_1M9dl {
    grid-column: span 12
}
  }

@media (--screen--sm-only) {

.CurrentInvestments__ColLeft_3zAcy,
.CurrentInvestments__ColRight_1M9dl {
    grid-column: span 12
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.CurrentInvestments__ColLeft_3zAcy {
    grid-column: span 8
}
  }

@media (--screen--md-only) {

.CurrentInvestments__ColLeft_3zAcy {
    grid-column: span 8
}
  }

@media screen and (max-width: 1365px) {

.CurrentInvestments__ColLeft_3zAcy {
    margin-bottom: 48px
}
  }

@media (--screen--md-max) {

.CurrentInvestments__ColLeft_3zAcy {
    margin-bottom: 48px
}
  }

@media screen and (min-width: 1366px) {

.CurrentInvestments__ColLeft_3zAcy {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.CurrentInvestments__ColLeft_3zAcy {
    grid-column: span 5
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.CurrentInvestments__ColRight_1M9dl {
    grid-column: span 12
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.CurrentInvestments__ColRight_1M9dl {
    grid-column: span 12
}
  }

@media screen and (min-width: 1366px) {

.CurrentInvestments__ColRight_1M9dl {
    grid-column: 7 / span 6
}
  }

@media (--screen--lg-min) {

.CurrentInvestments__ColRight_1M9dl {
    grid-column: 7 / span 6
}
  }

.CurrentInvestments__Title_35c1_ {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.CurrentInvestments__Description_1wFjf {
  margin-top: 40px;
  margin-bottom: 48px;
}

.CurrentInvestments__Description_1wFjf > h4 {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl);
  }

.CurrentInvestments__Description_1wFjf * + p {
    margin-top: 24px;
  }

.CurrentInvestments__Note_2SkO_ {
  padding: 24px 32px 32px;
  background: rgba(18, 82, 245, 0.2);
  background: rgba(var(--rgb--second-blue), 0.2);
  border-radius: 2px;
}

.CurrentInvestments__Note_2SkO_ > h4 {
    margin-bottom: 16px;
  }

.CurrentInvestments__Cards_7793R {
}

@media screen and (min-width: 1366px) {

.CurrentInvestments__Cards_7793R {
    --noOfColumns: 2;

    grid-gap: 32px;

    grid-gap: var(--padding--2xl)
}
  }

@media (--screen--lg-min) {

.CurrentInvestments__Cards_7793R {
    --noOfColumns: 2;

    grid-gap: 32px;

    grid-gap: var(--padding--2xl)
}
  }

@media print {

.CurrentInvestments__Cards_7793R {
    display: block
}

    .CurrentInvestments__Cards_7793R > div {
      display: inline-block;
      width: 45%;
      margin-right: 1%;
    }
  }
