.ColoredBackground__Wrapper_2LycO {
  padding-top: 24px;
  padding-bottom: 32px;
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
}

  @media screen and (max-width: 767px) {.ColoredBackground__Wrapper_2LycO {
    margin: 0 -24px
}
  }

  @media (--screen--xs-max) {.ColoredBackground__Wrapper_2LycO {
    margin: 0 -24px
}
  }

  @media screen and (max-width: 1023px) {.ColoredBackground__Wrapper_2LycO {
    padding-right: 24px;
    padding-left: 24px
}
  }

  @media (--screen--sm-max) {.ColoredBackground__Wrapper_2LycO {
    padding-right: 24px;
    padding-left: 24px
}
  }

  @media screen and (min-width: 1024px) {.ColoredBackground__Wrapper_2LycO {
    padding-right: 32px;
    padding-left: 32px
}
  }

  @media (--screen--md-min) {.ColoredBackground__Wrapper_2LycO {
    padding-right: 32px;
    padding-left: 32px
}
  }
