.HumanCapital__Wrapper_-KJnb {
  margin-top: var(--padding--4xl);
  margin-bottom: var(--padding--5xl);
}

.HumanCapital__Inner_tE9Y6 {
}

.HumanCapital__HeadingWrapper_29mon {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media screen and (min-width: 768px) {

.HumanCapital__HeadingWrapper_29mon {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.HumanCapital__HeadingWrapper_29mon {
    --noOfColumns: 12
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.HumanCapital__Heading_24eqt {
    grid-column: span 9
}
  }

@media (--screen--sm-only) {

.HumanCapital__Heading_24eqt {
    grid-column: span 9
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.HumanCapital__Heading_24eqt {
    grid-column: span 8
}
  }

@media (--screen--md-only) {

.HumanCapital__Heading_24eqt {
    grid-column: span 8
}
  }

@media screen and (min-width: 1366px) {

.HumanCapital__Heading_24eqt {
    grid-column: span 7
}
  }

@media (--screen--lg-min) {

.HumanCapital__Heading_24eqt {
    grid-column: span 7
}
  }

.HumanCapital__Content_3uE1L {
  margin-top: 32px;
  margin-top: var(--padding--2xl);
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

.HumanCapital__Content_3uE1L {
    --noOfColumns: 2
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.HumanCapital__Content_3uE1L {
    --noOfColumns: 2
}
  }

@media screen and (min-width: 1366px) {

.HumanCapital__Content_3uE1L {
    --noOfColumns: 12
}
  }

@media (--screen--lg-min) {

.HumanCapital__Content_3uE1L {
    --noOfColumns: 12
}
  }

@media screen and (min-width: 1366px) {

.HumanCapital__Text_6gpNT {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.HumanCapital__Text_6gpNT {
    grid-column: span 5
}
  }

/* .Person {
  @media (--screen--xs-max) {
    margin-top: var(--padding--3xl);
  }

  @media (--screen--lg-min) {
    grid-column: 7 / span 5;
  }
} */
