* + .QuoteContainer__Wrapper_2kvDR,
div.QuoteContainer__Wrapper_2kvDR + * {
  margin-top: 40px;
}

.QuoteContainer__Author_3gGDN {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-left: 24px;
}

.QuoteContainer__Photo_2a4E9 {
  overflow: hidden;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(var(--rgb--black), 0.1);
  margin-right: 16px;
  border-radius: 2px;
}

.QuoteContainer__Job_3pplA {
  margin-top: 8px;
}

@media screen and (max-width: 767px) {

.QuoteContainer__Job_3pplA {
    font-size: 12px;
    line-height: 16px
}
  }

@media (--screen--xs-max) {

.QuoteContainer__Job_3pplA {
    font-size: 12px;
    line-height: 16px
}
  }
