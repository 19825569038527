@media screen and (max-width: 767px) {.Cards__Wrapper_3EYZZ {
    padding-bottom: 64px
}
  }
  @media (--screen--xs-max) {.Cards__Wrapper_3EYZZ {
    padding-bottom: 64px
}
  }
  @media screen and (min-width: 768px) and (max-width: 1365px) {.Cards__Wrapper_3EYZZ {
    padding-bottom: 72px
}
  }
  @media (--screen--sm-min) and (--screen--md-max) {.Cards__Wrapper_3EYZZ {
    padding-bottom: 72px
}
  }
  @media screen and (max-width: 1365px) {.Cards__Wrapper_3EYZZ {
    padding-top: 48px
}
  }
  @media (--screen--md-max) {.Cards__Wrapper_3EYZZ {
    padding-top: 48px
}
  }
  @media screen and (min-width: 1366px) {.Cards__Wrapper_3EYZZ {
    padding-top: 64px;
    padding-bottom: 88px
}
  }
  @media (--screen--lg-min) {.Cards__Wrapper_3EYZZ {
    padding-top: 64px;
    padding-bottom: 88px
}
  }

.Cards__Inner_1FvMh {
}

@media screen and (max-width: 767px) {

.Cards__Inner_1FvMh {
    grid-column-gap: 32px;
    grid-row-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(167px, 1fr))
}
  }

@media (--screen--xs-max) {

.Cards__Inner_1FvMh {
    grid-column-gap: 32px;
    grid-row-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(167px, 1fr))
}
  }

@media screen and (min-width: 768px) {

.Cards__Inner_1FvMh {
    grid-row-gap: 40px
}
  }

@media (--screen--sm-min) {

.Cards__Inner_1FvMh {
    grid-row-gap: 40px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Cards__Inner_1FvMh {
    --noOfColumns: 3
}
  }

@media (--screen--sm-only) {

.Cards__Inner_1FvMh {
    --noOfColumns: 3
}
  }

@media screen and (min-width: 1024px) {

.Cards__Inner_1FvMh {
    --noOfColumns: 4
}
  }

@media (--screen--md-min) {

.Cards__Inner_1FvMh {
    --noOfColumns: 4
}
  }

@media screen and (max-width: 767px) {

.Cards__Title_3vLCg {
    margin-bottom: 10px
}
  }

@media (--screen--xs-max) {

.Cards__Title_3vLCg {
    margin-bottom: 10px
}
  }

@media screen and (min-width: 768px) {

.Cards__Title_3vLCg {
    margin-bottom: 16px
}
  }

@media (--screen--sm-min) {

.Cards__Title_3vLCg {
    margin-bottom: 16px
}
  }

.Cards__Description_1LmdA {
  font-size: 12px;
  line-height: 16px;
}
