@media screen and (min-width: 768px) and (max-width: 1023px) {.Popup__Career_2cUZ1 {
    width: 94%
}
  }
  @media (--screen--sm-only) {.Popup__Career_2cUZ1 {
    width: 94%
}
  }
  @media screen and (min-width: 1024px) and (max-width: 1365px) {.Popup__Career_2cUZ1 {
    width: 88%
}
  }
  @media (--screen--md-only) {.Popup__Career_2cUZ1 {
    width: 88%
}
  }
  @media screen and (min-width: 1366px) {.Popup__Career_2cUZ1 {
    width: 86%
}
  }
  @media (--screen--lg-min) {.Popup__Career_2cUZ1 {
    width: 86%
}
  }

@media screen and (max-width: 767px) {

.Popup__Wrapper_2txLF {
    padding-bottom: 24px;
    padding-bottom: var(--padding--xl)
}
  }

@media (--screen--xs-max) {

.Popup__Wrapper_2txLF {
    padding-bottom: 24px;
    padding-bottom: var(--padding--xl)
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Popup__Wrapper_2txLF {
    padding-bottom: 40px;
    padding-bottom: var(--padding--3xl)
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Popup__Wrapper_2txLF {
    padding-bottom: 40px;
    padding-bottom: var(--padding--3xl)
}
  }

@media screen and (min-width: 1366px) {

.Popup__Wrapper_2txLF {
    padding-bottom: 56px
}
  }

@media (--screen--lg-min) {

.Popup__Wrapper_2txLF {
    padding-bottom: 56px
}
  }
