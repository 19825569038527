.Model__Wrapper_1po7L {
  margin-top: var(--padding--4xl);
  margin-bottom: var(--padding--5xl);
}

.Model__Head_3eAIy {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

@media screen and (min-width: 1366px) {

.Model__Head_3eAIy {
    display: flex;
    align-items: flex-end;
    justify-content: space-between
}
  }

@media (--screen--lg-min) {

.Model__Head_3eAIy {
    display: flex;
    align-items: flex-end;
    justify-content: space-between
}
  }

@media screen and (max-width: 1365px) {

.Model__Heading_waUCo {
    margin-bottom: 24px
}
  }

@media (--screen--md-max) {

.Model__Heading_waUCo {
    margin-bottom: 24px
}
  }

@media screen and (max-width: 767px) {

.Model__Download_3ZlTB {
    width: auto
}
  }

@media (--screen--xs-max) {

.Model__Download_3ZlTB {
    width: auto
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Model__Download_3ZlTB {
    align-items: center;
    margin-top: 3px
}
  }

@media (--screen--md-only) {

.Model__Download_3ZlTB {
    align-items: center;
    margin-top: 3px
}
  }

@media screen and (min-width: 1366px) {

.Model__Download_3ZlTB {
    align-items: flex-end;
    margin-bottom: 5px
}
  }

@media (--screen--lg-min) {

.Model__Download_3ZlTB {
    align-items: flex-end;
    margin-bottom: 5px
}
  }

.Model__Inner_WaGcp {
}

@media screen and (min-width: 768px) {

.Model__Inner_WaGcp {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.Model__Inner_WaGcp {
    --noOfColumns: 12
}
  }

@media screen and (min-width: 1024px) {

.Model__Inner_WaGcp {
    grid-row-gap: 32px;
    grid-row-gap: var(--padding--2xl)
}
  }

@media (--screen--md-min) {

.Model__Inner_WaGcp {
    grid-row-gap: 32px;
    grid-row-gap: var(--padding--2xl)
}
  }

.Model__Image_3bmMH {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Model__Image_3bmMH {
    grid-column: span 3
}
  }

@media (--screen--sm-only) {

.Model__Image_3bmMH {
    grid-column: span 3
}
  }

@media screen and (min-width: 1024px) {

.Model__Image_3bmMH {
    grid-column: span 12
}
  }

@media (--screen--md-min) {

.Model__Image_3bmMH {
    grid-column: span 12
}
  }

@media print {

.Model__Image_3bmMH {
    display: none
}
  }

@media screen and (min-width: 1024px) {

.Model__Image_3bmMH img {
      height: 343px;
      margin: auto
  }
    }

@media (--screen--md-min) {

.Model__Image_3bmMH img {
      height: 343px;
      margin: auto
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Model__Image_3bmMH img {
      height: 262px
  }
    }

@media (--screen--md-only) {

.Model__Image_3bmMH img {
      height: 262px
  }
    }

.Model__List_2QS5g {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Model__List_2QS5g {
    padding-top: 32px;
    padding-top: var(--padding--2xl);
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Model__List_2QS5g {
    padding-top: 32px;
    padding-top: var(--padding--2xl);
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) {

.Model__List_2QS5g {
    --noOfColumns: 3
}
  }

@media (--screen--md-min) {

.Model__List_2QS5g {
    --noOfColumns: 3
}
  }

@media screen and (min-width: 1024px) {

.Model__List_2QS5g {
    grid-column: span 12
}
  }

@media (--screen--md-min) {

.Model__List_2QS5g {
    grid-column: span 12
}
  }

@media screen and (max-width: 767px) {

.Model__Item_2ROJ4:not(:last-child)::after {
        width: 15px;
        height: 38px;
        margin: 24px 0;
        margin: var(--padding--xl) 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMzgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuMzU0IDB2MzYiIHN0cm9rZT0idXJsKCNwcmVmaXhfX3BhaW50MF9saW5lYXIpIiBzdHJva2Utd2lkdGg9IjIiLz48cGF0aCBkPSJNMTMuOTEgMzBsLTYuMzY0IDYuMzY0TDEuMTgyIDMwIiBzdHJva2U9IiMxRTVCRjYiIHN0cm9rZS13aWR0aD0iMS41Ii8+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJwcmVmaXhfX3BhaW50MF9saW5lYXIiIHgxPSItMTI0Nzg1MCIgeTE9Ii0zLjA1NSIgeDI9Ii0xMjQ3ODUwIiB5Mj0iMzUuOTQ2IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHN0b3Agc3RvcC1jb2xvcj0iI2ZmZiIvPjxzdG9wIG9mZnNldD0iLjE2MSIgc3RvcC1jb2xvcj0iI0IzREFGRiIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzFFNUJGNiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjwvc3ZnPgo=)
    }
      }

@media (--screen--xs-max) {

.Model__Item_2ROJ4:not(:last-child)::after {
        width: 15px;
        height: 38px;
        margin: 24px 0;
        margin: var(--padding--xl) 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMzgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuMzU0IDB2MzYiIHN0cm9rZT0idXJsKCNwcmVmaXhfX3BhaW50MF9saW5lYXIpIiBzdHJva2Utd2lkdGg9IjIiLz48cGF0aCBkPSJNMTMuOTEgMzBsLTYuMzY0IDYuMzY0TDEuMTgyIDMwIiBzdHJva2U9IiMxRTVCRjYiIHN0cm9rZS13aWR0aD0iMS41Ii8+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJwcmVmaXhfX3BhaW50MF9saW5lYXIiIHgxPSItMTI0Nzg1MCIgeTE9Ii0zLjA1NSIgeDI9Ii0xMjQ3ODUwIiB5Mj0iMzUuOTQ2IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHN0b3Agc3RvcC1jb2xvcj0iI2ZmZiIvPjxzdG9wIG9mZnNldD0iLjE2MSIgc3RvcC1jb2xvcj0iI0IzREFGRiIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzFFNUJGNiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjwvc3ZnPgo=)
    }
      }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Model__Item_2ROJ4:not(:last-child)::after {
        width: 15px;
        height: 52px;
        margin: 16px 0;
        margin: var(--padding--lg) 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iNTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuMzU0IDB2NTAiIHN0cm9rZT0idXJsKCNwcmVmaXhfX3BhaW50MF9saW5lYXIpIiBzdHJva2Utd2lkdGg9IjIiLz48cGF0aCBkPSJNMTMuOTEgNDRsLTYuMzY0IDYuMzY0TDEuMTgyIDQ0IiBzdHJva2U9IiMxRTVCRjYiIHN0cm9rZS13aWR0aD0iMS41Ii8+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJwcmVmaXhfX3BhaW50MF9saW5lYXIiIHgxPSI2LjI2MyIgeTE9IjAiIHgyPSIzMC42NDUiIHkyPSIxOS40NzUiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBzdG9wLWNvbG9yPSIjZmZmIi8+PHN0b3Agb2Zmc2V0PSIuMTYxIiBzdG9wLWNvbG9yPSIjQjNEQUZGIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMUU1QkY2Ii8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PC9zdmc+Cg==)
    }
      }

@media (--screen--sm-only) {

.Model__Item_2ROJ4:not(:last-child)::after {
        width: 15px;
        height: 52px;
        margin: 16px 0;
        margin: var(--padding--lg) 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iNTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuMzU0IDB2NTAiIHN0cm9rZT0idXJsKCNwcmVmaXhfX3BhaW50MF9saW5lYXIpIiBzdHJva2Utd2lkdGg9IjIiLz48cGF0aCBkPSJNMTMuOTEgNDRsLTYuMzY0IDYuMzY0TDEuMTgyIDQ0IiBzdHJva2U9IiMxRTVCRjYiIHN0cm9rZS13aWR0aD0iMS41Ii8+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJwcmVmaXhfX3BhaW50MF9saW5lYXIiIHgxPSI2LjI2MyIgeTE9IjAiIHgyPSIzMC42NDUiIHkyPSIxOS40NzUiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBzdG9wLWNvbG9yPSIjZmZmIi8+PHN0b3Agb2Zmc2V0PSIuMTYxIiBzdG9wLWNvbG9yPSIjQjNEQUZGIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMUU1QkY2Ii8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PC9zdmc+Cg==)
    }
      }

@media screen and (max-width: 1023px) {

.Model__Item_2ROJ4:not(:last-child)::after {
        position: relative;
        display: block;
        background-repeat: no-repeat;
        content: ""
    }
      }

@media (--screen--sm-max) {

.Model__Item_2ROJ4:not(:last-child)::after {
        position: relative;
        display: block;
        background-repeat: no-repeat;
        content: ""
    }
      }

@media screen and (max-width: 1023px) {

.Model__Item_2ROJ4 {
    position: relative
}
  }

@media (--screen--sm-max) {

.Model__Item_2ROJ4 {
    position: relative
}
  }

@media screen and (min-width: 1024px) {

.Model__Item_2ROJ4 {
    grid-column: span 1
}
  }

@media (--screen--md-min) {

.Model__Item_2ROJ4 {
    grid-column: span 1
}
  }

@media screen and (min-width: 1024px) {

.Model__Title_3bbrW:hover {
      border-bottom-color: transparent;
      cursor: pointer
  }
    }

@media (--screen--md-min) {

.Model__Title_3bbrW:hover {
      border-bottom-color: transparent;
      cursor: pointer
  }
    }

@media screen and (max-width: 1023px) {

.Model__Title_3bbrW {
    pointer-events: none
}
  }

@media (--screen--sm-max) {

.Model__Title_3bbrW {
    pointer-events: none
}
  }

@media screen and (min-width: 1024px) {

.Model__Title_3bbrW {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
    border-bottom: 1px dashed rgba(var(--rgb--black), 0.3);
    transition: border-bottom-color ease-in-out 0.3s
}
  }

@media (--screen--md-min) {

.Model__Title_3bbrW {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
    border-bottom: 1px dashed rgba(var(--rgb--black), 0.3);
    transition: border-bottom-color ease-in-out 0.3s
}
  }

.Model__Description_2htHA {
  margin-top: 16px;
  margin-top: var(--padding--lg);
}

.Model__Description_2htHA:first-child {
    margin-bottom: 0;
  }

.Model__Description_2htHA:last-child {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg);
  }

@media screen and (min-width: 1024px) {

.Model__Description_2htHA[data-state="open"] {
      opacity: 1;
      transform: translateY(0) scaleY(1)
  }
    }

@media (--screen--md-min) {

.Model__Description_2htHA[data-state="open"] {
      opacity: 1;
      transform: translateY(0) scaleY(1)
  }
    }

@media screen and (max-width: 1023px) {

.Model__Description_2htHA {
    height: auto !important
}
  }

@media (--screen--sm-max) {

.Model__Description_2htHA {
    height: auto !important
}
  }

@media screen and (min-width: 1024px) {

.Model__Description_2htHA {
    overflow: hidden;
    height: 0;
    opacity: 0;
    transform: translateY(-10px) scaleY(0.9);
    transform-origin: top;
    transition: height ease-in-out 0.2s, opacity ease-in-out 0.3s, transform ease-in-out 0.2s
}
  }

@media (--screen--md-min) {

.Model__Description_2htHA {
    overflow: hidden;
    height: 0;
    opacity: 0;
    transform: translateY(-10px) scaleY(0.9);
    transform-origin: top;
    transition: height ease-in-out 0.2s, opacity ease-in-out 0.3s, transform ease-in-out 0.2s
}
  }

.Model__Description_2htHA p + p {
    margin-top: 16px;
    margin-top: var(--padding--lg);
  }
