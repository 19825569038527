@media screen and (min-width: 768px) and (max-width: 1023px) {.FormWrapper__Wrapper_2jMFb {
    grid-column: span 10
}
  }
  @media (--screen--sm-only) {.FormWrapper__Wrapper_2jMFb {
    grid-column: span 10
}
  }
  @media screen and (min-width: 1024px) {.FormWrapper__Wrapper_2jMFb {
    grid-column: span 7
}
  }
  @media (--screen--md-min) {.FormWrapper__Wrapper_2jMFb {
    grid-column: span 7
}
  }

.FormWrapper__Form_RWcF2 {
  --noOfColumns: 2;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.FormWrapper__Form_RWcF2 > div,
  .FormWrapper__Form_RWcF2 .FormWrapper__Title_3JoEC {
    grid-column: 1 / -1;
  }

@media screen and (max-width: 1365px) {

.FormWrapper__Form_RWcF2 > div + .FormWrapper__Title_3JoEC {
      margin-top: 24px
  }
    }

@media (--screen--md-max) {

.FormWrapper__Form_RWcF2 > div + .FormWrapper__Title_3JoEC {
      margin-top: 24px
  }
    }

@media screen and (min-width: 1366px) {

.FormWrapper__Form_RWcF2 > div + .FormWrapper__Title_3JoEC {
      margin-top: 40px
  }
    }

@media (--screen--lg-min) {

.FormWrapper__Form_RWcF2 > div + .FormWrapper__Title_3JoEC {
      margin-top: 40px
  }
    }

.FormWrapper__Title_3JoEC + * {
  margin-top: 8px;
  margin-top: var(--padding--sm);
}

.FormWrapper__Checkbox_hXSmg {
  margin-top: -12px;
}

.FormWrapper__Checkbox_hXSmg > div {
    margin-bottom: 0;
  }

.FormWrapper__Double_3EAr1 {
  grid-column-gap: 24px;
}

@media screen and (max-width: 767px) {

.FormWrapper__Double_3EAr1 {
    grid-row-gap: 24px
}
  }

@media (--screen--xs-max) {

.FormWrapper__Double_3EAr1 {
    grid-row-gap: 24px
}
  }

@media screen and (min-width: 768px) {

.FormWrapper__Double_3EAr1 {
    --noOfColumns: 2
}
  }

@media (--screen--sm-min) {

.FormWrapper__Double_3EAr1 {
    --noOfColumns: 2
}
  }

@media screen and (min-width: 768px) {

.FormWrapper__SendWrapper_3eTBs {
    display: flex;
    align-items: center
}
  }

@media (--screen--sm-min) {

.FormWrapper__SendWrapper_3eTBs {
    display: flex;
    align-items: center
}
  }

@media screen and (max-width: 1023px) {

.FormWrapper__SendWrapper_3eTBs {
    margin-top: 8px
}
  }

@media (--screen--sm-max) {

.FormWrapper__SendWrapper_3eTBs {
    margin-top: 8px
}
  }

@media screen and (min-width: 1024px) {

.FormWrapper__SendWrapper_3eTBs {
    margin-top: 10px
}
  }

@media (--screen--md-min) {

.FormWrapper__SendWrapper_3eTBs {
    margin-top: 10px
}
  }

.FormWrapper__SendPolicy_3C482 {
  font-size: 12px;
  line-height: 16px;
}

@media screen and (max-width: 767px) {

.FormWrapper__SendPolicy_3C482 {
    margin-top: 16px;
    margin-top: var(--padding--lg)
}
  }

@media (--screen--xs-max) {

.FormWrapper__SendPolicy_3C482 {
    margin-top: 16px;
    margin-top: var(--padding--lg)
}
  }

@media screen and (min-width: 768px) {

.FormWrapper__SendPolicy_3C482 {
    width: 281px;
    margin-left: 24px
}
  }

@media (--screen--sm-min) {

.FormWrapper__SendPolicy_3C482 {
    width: 281px;
    margin-left: 24px
}
  }

.FormWrapper__SendPolicy_3C482 > a {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
  }
