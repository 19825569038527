.Content__Wrapper_1EHDA {
  padding-bottom: var(--padding--5xl);
}

@media screen and (min-width: 768px) {

.Content__ColLeft_1ZEDB {
    grid-column: span 7
}
  }

@media (--screen--sm-min) {

.Content__ColLeft_1ZEDB {
    grid-column: span 7
}
  }

.Content__ColRight_1PBOu {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__ColRight_1PBOu {
    grid-column: 8 / span 5
}
  }

@media (--screen--sm-only) {

.Content__ColRight_1PBOu {
    grid-column: 8 / span 5
}
  }

@media screen and (min-width: 1024px) {

.Content__ColRight_1PBOu {
    grid-column: 9 / span 4
}
  }

@media (--screen--md-min) {

.Content__ColRight_1PBOu {
    grid-column: 9 / span 4
}
  }
