* + .Table__Wrapper_2Petn,
div.Table__Wrapper_2Petn + * {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {

.Table__Wrapper_2Petn {
    position: relative;
    left: 50%;
    width: 100vw;
    padding-left: 24px;
    margin-left: -50vw;
    overflow-x: auto
}
  }

@media (--screen--xs-max) {

.Table__Wrapper_2Petn {
    position: relative;
    left: 50%;
    width: 100vw;
    padding-left: 24px;
    margin-left: -50vw;
    overflow-x: auto
}
  }

@media screen and (min-width: 768px) {

.Table__Wrapper_2Petn {
    grid-column: span 12
}
  }

@media (--screen--sm-min) {

.Table__Wrapper_2Petn {
    grid-column: span 12
}
  }

.Table__Wrapper_2Petn table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

@media screen and (max-width: 767px) {

.Table__Wrapper_2Petn table {
      margin-right: auto;
      margin-left: auto
  }
    }

@media (--screen--xs-max) {

.Table__Wrapper_2Petn table {
      margin-right: auto;
      margin-left: auto
  }
    }

.Table__Wrapper_2Petn table thead th {
        border-bottom: 1px solid rgb(18, 82, 245);
        border-bottom: 1px solid var(--color--second-blue);
        text-align: left;
      }

.Table__Wrapper_2Petn table b {
      font-weight: 500;
    }

.Table__Wrapper_2Petn table td,
    .Table__Wrapper_2Petn table th {
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 15px;
      padding-left: 0;
    }

.Table__Wrapper_2Petn table th {
      font-weight: 500;
    }

.Table__Wrapper_2Petn table tr:first-child td {
        font-weight: bold;
      }

.Table__Wrapper_2Petn table tr td:first-child {
        font-weight: bold;
        padding-left: 5px;
        text-align: left;
        max-width: 200px;
      }

.Table__Wrapper_2Petn table tr td {
        text-align: center;
      }

@media screen and (max-width: 767px) {

.Table__Inner_3ruBD {
    min-width: 524px;
    padding-right: 24px
}
  }

@media (--screen--xs-max) {

.Table__Inner_3ruBD {
    min-width: 524px;
    padding-right: 24px
}
  }
