@media screen and (max-width: 767px) {.TreatmentOrder__Wrapper_2kJQ2 {
    padding: 16px 24px 24px
}
  }
  @media (--screen--xs-max) {.TreatmentOrder__Wrapper_2kJQ2 {
    padding: 16px 24px 24px
}
  }
  @media screen and (min-width: 768px) {.TreatmentOrder__Wrapper_2kJQ2 {
    padding: 16px 32px 32px
}
  }
  @media (--screen--sm-min) {.TreatmentOrder__Wrapper_2kJQ2 {
    padding: 16px 32px 32px
}
  }
  @media screen and (min-width: 1024px) and (max-width: 1365px) {.TreatmentOrder__Wrapper_2kJQ2 {
    margin-top: 14px
}
  }
  @media (--screen--md-only) {.TreatmentOrder__Wrapper_2kJQ2 {
    margin-top: 14px
}
  }
  @media screen and (min-width: 1366px) {.TreatmentOrder__Wrapper_2kJQ2 {
    margin-top: 16px
}
  }
  @media (--screen--lg-min) {.TreatmentOrder__Wrapper_2kJQ2 {
    margin-top: 16px
}
  }
  .TreatmentOrder__Wrapper_2kJQ2 > ol {
    position: relative;
    padding: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    counter-reset: counter;
    list-style: none;
  }
  .TreatmentOrder__Wrapper_2kJQ2 > ol > li {
      position: relative;
      padding-left: 20px;
    }
  .TreatmentOrder__Wrapper_2kJQ2 > ol > li::before {
        position: absolute;
        top: 2px;
        left: 2px;
        color: rgb(18, 82, 245);
        color: var(--color--second-blue);
        content: counter(counter);
        counter-increment: counter;
        font-size: 12px;
        line-height: 16px;
      }
  .TreatmentOrder__Wrapper_2kJQ2 > ol > li:not(:last-child) {
        margin-bottom: 24px;
      }

.TreatmentOrder__Title_2QuKN {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

.TreatmentOrder__Download_2rlxT {
  margin-top: 8px;
}

.TreatmentOrder__Download_2rlxT > a {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
    font-weight: 500;
    word-break: break-all;
  }

.TreatmentOrder__Download_2rlxT > span {
    position: relative;
    display: inline-block;
    padding-left: 12px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.3);
    color: rgba(var(--rgb--black), 0.3);
  }

.TreatmentOrder__Download_2rlxT > span::before {
      position: absolute;
      top: 3px;
      bottom: 4px;
      left: 0;
      width: 1px;
      background: rgba(0, 0, 0, 0.3);
      background: rgba(var(--rgb--black), 0.3);
      content: "";
    }

@media screen and (max-width: 767px) {

.TreatmentOrder__Button_4HQbS {
    margin-top: 24px
}
  }

@media (--screen--xs-max) {

.TreatmentOrder__Button_4HQbS {
    margin-top: 24px
}
  }

@media screen and (min-width: 768px) {

.TreatmentOrder__Button_4HQbS {
    margin-top: 32px
}
  }

@media (--screen--sm-min) {

.TreatmentOrder__Button_4HQbS {
    margin-top: 32px
}
  }

@media screen and (min-width: 1024px) {

.TreatmentOrder__Button_4HQbS > button {
      width: 100%
  }
    }

@media (--screen--md-min) {

.TreatmentOrder__Button_4HQbS > button {
      width: 100%
  }
    }
