.Slider__Wrapper_1FmsW {
  position: relative;
  border: 1px solid rgba(18, 82, 245, 0.1);
  border: 1px solid rgba(var(--rgb--second-blue), 0.1);
  background: rgb(255, 255, 255);
  background: var(--color--white);
  border-radius: 2px;
  box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1);
}

  @media screen and (max-width: 767px) {.Slider__Wrapper_1FmsW {
    padding: 24px 32px 32px;
    padding: var(--padding--xl) 32px 32px
}
  }

  @media (--screen--xs-max) {.Slider__Wrapper_1FmsW {
    padding: 24px 32px 32px;
    padding: var(--padding--xl) 32px 32px
}
  }

  @media screen and (min-width: 768px) {.Slider__Wrapper_1FmsW {
    padding: 24px 32px;
    padding: var(--padding--xl) 32px
}
  }

  @media (--screen--sm-min) {.Slider__Wrapper_1FmsW {
    padding: 24px 32px;
    padding: var(--padding--xl) 32px
}
  }

.Slider__Title_22Ol4 {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

.Slider__Navigation_1pln8 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {

.Slider__Navigation_1pln8 {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%)
}
  }

@media (--screen--xs-max) {

.Slider__Navigation_1pln8 {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%)
}
  }

@media screen and (min-width: 768px) {

.Slider__Navigation_1pln8 {
    margin-top: 16px;
    margin-top: var(--padding--lg)
}
  }

@media (--screen--sm-min) {

.Slider__Navigation_1pln8 {
    margin-top: 16px;
    margin-top: var(--padding--lg)
}
  }
