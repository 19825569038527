.Contacts__Wrapper_1DvQ8 {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--5xl);
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Contacts__ColLeft_2Bjd3 {
    grid-column: span 7
}
  }

@media (--screen--md-only) {

.Contacts__ColLeft_2Bjd3 {
    grid-column: span 7
}
  }

@media screen and (min-width: 1366px) {

.Contacts__ColLeft_2Bjd3 {
    grid-column: span 6
}
  }

@media (--screen--lg-min) {

.Contacts__ColLeft_2Bjd3 {
    grid-column: span 6
}
  }

@media screen and (min-width: 1024px) {

.Contacts__ColRight_3moDs {
    margin-top: 16px;
    grid-column: 9 / span 4
}
  }

@media (--screen--md-min) {

.Contacts__ColRight_3moDs {
    margin-top: 16px;
    grid-column: 9 / span 4
}
  }

.Contacts__Title_1GHGX {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Contacts__Contact_1ajIC {
  font-weight: 300;
}

.Contacts__Contact_1ajIC:not(:last-child) {
    margin-bottom: 8px;
    margin-bottom: var(--padding--sm);
  }

@media screen and (max-width: 1365px) {

.Contacts__Contact_1ajIC {
    font-size: 26px;
    line-height: 32px
}
  }

@media (--screen--md-max) {

.Contacts__Contact_1ajIC {
    font-size: 26px;
    line-height: 32px
}
  }

@media screen and (min-width: 1366px) {

.Contacts__Contact_1ajIC {
    font-size: 32px;
    line-height: 38px
}
  }

@media (--screen--lg-min) {

.Contacts__Contact_1ajIC {
    font-size: 32px;
    line-height: 38px
}
  }
