.Person__Wrapper_3Vp0M {
}

  @media screen and (min-width: 768px) and (max-width: 1023px) {.Person__Wrapper_3Vp0M {
    --noOfColumns: 12
}
  }

  @media (--screen--sm-only) {.Person__Wrapper_3Vp0M {
    --noOfColumns: 12
}
  }

.Person__Logo_2gI3q {
}

@media screen and (max-width: 767px) {

.Person__Logo_2gI3q {
    width: 219px;
    margin-bottom: 40px
}
  }

@media (--screen--xs-max) {

.Person__Logo_2gI3q {
    width: 219px;
    margin-bottom: 40px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Person__Logo_2gI3q {
    grid-column: 9 / span 4;
    grid-row: 1
}
  }

@media (--screen--sm-only) {

.Person__Logo_2gI3q {
    grid-column: 9 / span 4;
    grid-row: 1
}
  }

.Person__Profile_11Zyb {
  display: flex;
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Person__Profile_11Zyb:hover .Person__Name_21AOv {
      color: rgb(18, 82, 245);
      color: var(--color--second-blue);
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Person__Profile_11Zyb {
    grid-column: 1 / span 6;
    grid-row: 1
}
  }

@media (--screen--sm-only) {

.Person__Profile_11Zyb {
    grid-column: 1 / span 6;
    grid-row: 1
}
  }

.Person__Photo_1L_Od {
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 32px;
  margin-right: var(--padding--2xl);
  border-radius: 2px;
}

@media screen and (max-width: 1365px) {

.Person__Photo_1L_Od {
    width: 123px
}
  }

@media (--screen--md-max) {

.Person__Photo_1L_Od {
    width: 123px
}
  }

@media screen and (min-width: 1366px) {

.Person__Photo_1L_Od {
    width: 168px
}
  }

@media (--screen--lg-min) {

.Person__Photo_1L_Od {
    width: 168px
}
  }

.Person__Photo_1L_Od img {
    outline: 1px solid rgba(0, 0, 0, 0.1);
    outline: 1px solid rgba(var(--rgb--black), 0.1);
    outline-offset: -1px;
  }

.Person__Bio_3aQSd {
  margin-top: -7px;
}

.Person__Name_21AOv {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
  transition: color ease-in-out 0.3s;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Person__Quote_3dicA {
    grid-column: span 12
}
  }

@media (--screen--sm-only) {

.Person__Quote_3dicA {
    grid-column: span 12
}
  }
