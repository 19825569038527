.NewInvestments__Wrapper_2N1Nd {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--5xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.NewInvestments__ColLeft_2frNh,
.NewInvestments__ColRight_1H8wn {
    grid-column: span 6
}
  }

@media (--screen--sm-only) {

.NewInvestments__ColLeft_2frNh,
.NewInvestments__ColRight_1H8wn {
    grid-column: span 6
}
  }

@media screen and (max-width: 767px) {

.NewInvestments__ColLeft_2frNh {
    margin-bottom: 40px
}
  }

@media (--screen--xs-max) {

.NewInvestments__ColLeft_2frNh {
    margin-bottom: 40px
}
  }

@media screen and (min-width: 1024px) {

.NewInvestments__ColLeft_2frNh {
    grid-column: span 5
}
  }

@media (--screen--md-min) {

.NewInvestments__ColLeft_2frNh {
    grid-column: span 5
}
  }

.NewInvestments__ColRight_1H8wn {

  /* .Head {
    @media (--screen--md-min) {
      margin-bottom: 70px;
    }
  } */
}

@media screen and (min-width: 1024px) {

.NewInvestments__ColRight_1H8wn {
    grid-column: 7 / span 5
}
  }

@media (--screen--md-min) {

.NewInvestments__ColRight_1H8wn {
    grid-column: 7 / span 5
}
  }

.NewInvestments__Head_1CR06,
.NewInvestments__SubHeading_y8irx,
.NewInvestments__Title_2sJYu {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.NewInvestments__Heading_1ytUv {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

@media screen and (min-width: 768px) {

.NewInvestments__Heading_1ytUv {
    grid-column: span 12
}
  }

@media (--screen--sm-min) {

.NewInvestments__Heading_1ytUv {
    grid-column: span 12
}
  }

.NewInvestments__Card_17Rq_:not(:last-child) {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl);
  }

.NewInvestments__Note_3_III {
  margin-top: 48px;
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
  border-radius: 2px;
}

@media screen and (max-width: 767px) {

.NewInvestments__Note_3_III {
    padding: 16px 24px 24px
}
  }

@media (--screen--xs-max) {

.NewInvestments__Note_3_III {
    padding: 16px 24px 24px
}
  }

@media screen and (min-width: 768px) {

.NewInvestments__Note_3_III {
    padding: 24px 32px 32px
}
  }

@media (--screen--sm-min) {

.NewInvestments__Note_3_III {
    padding: 24px 32px 32px
}
  }

.NewInvestments__Note_3_III > h4 {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg);
  }
