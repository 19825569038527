.Telegram__telegramWrapper_hjKTa {
  height: 600px;
  width: 340px;
  border: 1px solid rgba(18, 82, 245, 0.1);
  border: 1px solid rgba(18, 82, 245, 0.1);
  border: 1px solid rgba(var(--rgb--second-blue), 0.1);
  border-radius: 2px;
  box-shadow: 0 0 0 8px rgb(240, 248, 255);
  box-shadow: 0 0 0 8px rgb(240 248 255);
  box-shadow: 0 0 0 8px rgb(240, 248, 255);
  box-shadow: 0 0 0 8px var(--color--bg-light);
  overflow: overlay;
}

.Telegram__telegramSub_1FdFr {
  margin-bottom: 20px;
}

.Telegram__link_3YvZ3 {
  margin-bottom: 30px;
}