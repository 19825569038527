.History__Wrapper_3r3jp {
  overflow: hidden;
  padding-top: var(--padding--4xl);
  padding-right: 0;
  padding-bottom: var(--padding--4xl);
  padding-left: 0;
}

.History__Inner_2kIVq {
}

@media screen and (max-width: 767px) {

.History__Inner_2kIVq {
    margin-bottom: 32px
}
  }

@media (--screen--xs-max) {

.History__Inner_2kIVq {
    margin-bottom: 32px
}
  }

@media screen and (min-width: 768px) {

.History__Inner_2kIVq {
    --noOfColumns: 2
}
  }

@media (--screen--sm-min) {

.History__Inner_2kIVq {
    --noOfColumns: 2
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.History__Description_1Z8BN {
    padding-top: 14px
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.History__Description_1Z8BN {
    padding-top: 14px
}
  }

@media screen and (min-width: 1366px) {

.History__Description_1Z8BN {
    padding-top: 21px
}
  }

@media (--screen--lg-min) {

.History__Description_1Z8BN {
    padding-top: 21px
}
  }
