.Content__Grid_317mh {
  --noOfColumns: 2;
}

.Content__Layout_2hnNv {
  padding: 32px;
  padding: var(--padding--2xl);
}

.Content__Item_2GuwK {
  display: block;
}

.Content__Item_2GuwK:not(:last-child) {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl);
  }

.Content__List_1lQs5 {
}

@media screen and (max-width: 767px) {

.Content__List_1lQs5 {
    grid-row-gap: 24px
}
  }

@media (--screen--xs-max) {

.Content__List_1lQs5 {
    grid-row-gap: 24px
}
  }

@media screen and (min-width: 768px) {

.Content__List_1lQs5 {
    --noOfColumns: 3;

    grid-row-gap: 32px
}
  }

@media (--screen--sm-min) {

.Content__List_1lQs5 {
    --noOfColumns: 3;

    grid-row-gap: 32px
}
  }

.Content__Arrows_QhO9t {
  display: flex;
}
