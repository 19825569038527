.Hero__Wrapper_258Gw {
  position: relative;
  overflow: hidden;
  padding-bottom: var(--padding--5xl);
}

.Hero__Head_19CsB {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Hero__Head_19CsB {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Hero__Head_19CsB {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Hero__Head_19CsB {
    grid-column: span 6
}
  }

@media (--screen--md-only) {

.Hero__Head_19CsB {
    grid-column: span 6
}
  }

@media screen and (min-width: 1366px) {

.Hero__Head_19CsB {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.Hero__Head_19CsB {
    grid-column: span 5
}
  }

.Hero__Title_W8_l8 {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}
