@media screen and (max-width: 1023px) {.Goto__Wrapper_31h1H {
    padding: 16px 24px 24px
}
  }
  @media (--screen--sm-max) {.Goto__Wrapper_31h1H {
    padding: 16px 24px 24px
}
  }
  @media screen and (min-width: 1024px) {.Goto__Wrapper_31h1H {
    padding: 24px 32px 32px
}
  }
  @media (--screen--md-min) {.Goto__Wrapper_31h1H {
    padding: 24px 32px 32px
}
  }

* + .Goto__Wrapper_31h1H,
div.Goto__Wrapper_31h1H + * {
  margin-top: 40px !important;
}

.Goto__Title_2wmHf,
.Goto__Link_1nS5Q {
  padding-left: 37px;
}

.Goto__Title_2wmHf {
  position: relative;
  margin-bottom: 8px;
  margin-bottom: var(--padding--sm);
}

.Goto__Title_2wmHf::before {
    position: absolute;
    top: 3px;
    left: 0;
    width: 21px;
    height: 20px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIwLjIgMTBIMU04LjU3OSAySDFNMTUuMTQ3IDE4SDEiIHN0cm9rZT0iIzEyNTJGNSIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48L3N2Zz4K);
    content: "";
  }
