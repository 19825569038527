.Calendar__Wrapper_2NoyF {
  padding-bottom: var(--padding--5xl);
}

  @media screen and (max-width: 767px) {.Calendar__Wrapper_2NoyF {
    padding-top: var(--padding--5xl)
}
  }

  @media (--screen--xs-max) {.Calendar__Wrapper_2NoyF {
    padding-top: var(--padding--5xl)
}
  }

.Calendar__Title_3rEX6 {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Calendar__Events_2OWgL {
  margin-top: 32px;
  margin-top: var(--padding--2xl);
}

@media screen and (max-width: 767px) {

.Calendar__Events_2OWgL {
    grid-row-gap: 24px
}
  }

@media (--screen--xs-max) {

.Calendar__Events_2OWgL {
    grid-row-gap: 24px
}
  }

@media screen and (min-width: 768px) {

.Calendar__Events_2OWgL > div {
      grid-column: span 6
  }
    }

@media (--screen--sm-min) {

.Calendar__Events_2OWgL > div {
      grid-column: span 6
  }
    }
