.Means__Wrapper_3SOP5 {
}

  @media screen and (min-width: 768px) and (max-width: 1023px) {.Means__Wrapper_3SOP5 {
    grid-column: span 8
}
  }

  @media (--screen--sm-only) {.Means__Wrapper_3SOP5 {
    grid-column: span 8
}
  }

  @media screen and (max-width: 1023px) {.Means__Wrapper_3SOP5 {
    margin-top: 40px
}
  }

  @media (--screen--sm-max) {.Means__Wrapper_3SOP5 {
    margin-top: 40px
}
  }

  @media screen and (min-width: 1024px) and (max-width: 1365px) {.Means__Wrapper_3SOP5 {
    margin-top: 32px
}
  }

  @media (--screen--md-only) {.Means__Wrapper_3SOP5 {
    margin-top: 32px
}
  }

  @media screen and (max-width: 1365px) {.Means__Wrapper_3SOP5 {
    padding: 24px 32px 32px;
    border: 1px solid rgba(18, 82, 245, 0.1);
    border: 1px solid rgba(var(--rgb--second-blue), 0.1);
    border-radius: 2px;
    box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1);
    grid-row-gap: 24px
}
  }

  @media (--screen--md-max) {.Means__Wrapper_3SOP5 {
    padding: 24px 32px 32px;
    border: 1px solid rgba(18, 82, 245, 0.1);
    border: 1px solid rgba(var(--rgb--second-blue), 0.1);
    border-radius: 2px;
    box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1);
    grid-row-gap: 24px
}
  }

  @media screen and (min-width: 1366px) {.Means__Wrapper_3SOP5 {
    --noOfColumns: 2;

    margin-top: 40px;
    grid-row-gap: 32px
}
  }

  @media (--screen--lg-min) {.Means__Wrapper_3SOP5 {
    --noOfColumns: 2;

    margin-top: 40px;
    grid-row-gap: 32px
}
  }

@media screen and (min-width: 1366px) {

.Means__Mean_qRvpB {
    padding: 16px 24px 24px;
    border: 1px solid rgba(18, 82, 245, 0.1);
    border: 1px solid rgba(var(--rgb--second-blue), 0.1);
    border-radius: 2px;
    box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1)
}
  }

@media (--screen--lg-min) {

.Means__Mean_qRvpB {
    padding: 16px 24px 24px;
    border: 1px solid rgba(18, 82, 245, 0.1);
    border: 1px solid rgba(var(--rgb--second-blue), 0.1);
    border-radius: 2px;
    box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1)
}
  }

.Means__MeanLink_3-MIu {
  margin-bottom: 8px;
}
