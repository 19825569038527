.Hero__Wrapper_3WGdU {
  position: relative;
  overflow: hidden;
  padding-bottom: var(--padding--5xl);
}

.Hero__Title_19sX5 {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Hero__Link_1wYn_ {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Hero__Description_2S8Au {
    grid-column: span 7
}
  }

@media (--screen--sm-only) {

.Hero__Description_2S8Au {
    grid-column: span 7
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Hero__Description_2S8Au {
    grid-column: span 6
}
  }

@media (--screen--md-only) {

.Hero__Description_2S8Au {
    grid-column: span 6
}
  }

@media screen and (min-width: 1366px) {

.Hero__Description_2S8Au {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.Hero__Description_2S8Au {
    grid-column: span 5
}
  }
