.Strategy__Wrapper_1Pqmd {
  padding-right: 0;
  padding-left: 0;
  margin-top: var(--padding--4xl);
  margin-bottom: var(--padding--5xl);
}

  @media screen and (max-width: 767px) {.Strategy__Wrapper_1Pqmd {
    margin-bottom: 112px
}
  }

  @media (--screen--xs-max) {.Strategy__Wrapper_1Pqmd {
    margin-bottom: 112px
}
  }

  @media print {.Strategy__Wrapper_1Pqmd {
    margin-top: 20px
}
  }

.Strategy__Grid_3Pem1 {
  align-items: start;
}

@media screen and (min-width: 1024px) {

.Strategy__Grid_3Pem1 {
    --noOfColumns: 0
}
  }

@media (--screen--md-min) {

.Strategy__Grid_3Pem1 {
    --noOfColumns: 0
}
  }

@media screen and (min-width: 1024px) {

.Strategy__Slider_2_VWn {
    padding-top: 18px
}
  }

@media (--screen--md-min) {

.Strategy__Slider_2_VWn {
    padding-top: 18px
}
  }

.Strategy__Inner_3eMbD {
}

@media screen and (min-width: 1024px) {

.Strategy__Inner_3eMbD {
    --noOfColumns: 6
}
  }

@media (--screen--md-min) {

.Strategy__Inner_3eMbD {
    --noOfColumns: 6
}
  }

@media screen and (min-width: 1024px) {

.Strategy__Header_3t6Oi {
    grid-column: span 6
}
  }

@media (--screen--md-min) {

.Strategy__Header_3t6Oi {
    grid-column: span 6
}
  }

.Strategy__Title_2G_XW {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media print {

.Strategy__Title_2G_XW {
    font-weight: 700
}
  }

.Strategy__Link_1ty6S {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

@media print {

.Strategy__Link_1ty6S {
    display: none
}
  }

@media screen and (max-width: 1023px) {

.Strategy__Description_2nzL5 {
    margin-bottom: 40px
}
  }

@media (--screen--sm-max) {

.Strategy__Description_2nzL5 {
    margin-bottom: 40px
}
  }

@media screen and (min-width: 1024px) {

.Strategy__Description_2nzL5 {
    grid-column: span 5
}
  }

@media (--screen--md-min) {

.Strategy__Description_2nzL5 {
    grid-column: span 5
}
  }
