.Content__Wrapper_1SMAS {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--4xl);
}

.Content__Block_AVe9a + .Content__Block_AVe9a,
.Content__Block_AVe9a + .Content__BlockTitleLogo_1u7PT {
  margin-top: var(--padding--4xl);
}

.Content__BlockTitleLogo_1u7PT + .Content__Block_AVe9a {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

@media screen and (min-width: 768px) {

.Content__ColRight_2Hb9H {
    grid-column: 9 / span 4
}
  }

@media (--screen--sm-min) {

.Content__ColRight_2Hb9H {
    grid-column: 9 / span 4
}
  }

.Content__ColRight_2Hb9H a:not([class]) {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
    font-weight: 500;
    word-break: break-all;
  }

.Content__ColRight_2Hb9H a:not([class])::after {
      content: none;
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__ColLeft_3WHNc {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Content__ColLeft_3WHNc {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) {

.Content__ColLeft_3WHNc {
    grid-column: span 7
}
  }

@media (--screen--md-min) {

.Content__ColLeft_3WHNc {
    grid-column: span 7
}
  }
