.Cards__Wrapper_hG4Ae {
  padding: var(--padding--4xl) 0 var(--padding--5xl);
}

  @media print {.Cards__Wrapper_hG4Ae {
    margin-top: 20px
}
  }

.Cards__Title_3mj3w {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media print {

.Cards__Title_3mj3w {
    margin-bottom: 20px;
    font-weight: 700
}
  }

.Cards__Lead_2IkjP {
  margin-bottom: var(--padding--4xl);
}

.Cards__Projects_GWbfz {
  margin-top: 32px;
  margin-top: var(--padding--2xl);
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Cards__Projects_GWbfz {
    --noOfColumns: 2
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Cards__Projects_GWbfz {
    --noOfColumns: 2
}
  }

@media screen and (min-width: 768px) {

.Cards__Projects_GWbfz {
    grid-row-gap: 32px
}
  }

@media (--screen--sm-min) {

.Cards__Projects_GWbfz {
    grid-row-gap: 32px
}
  }

@media screen and (min-width: 1366px) {

.Cards__Projects_GWbfz {
    --noOfColumns: 4
}
  }

@media (--screen--lg-min) {

.Cards__Projects_GWbfz {
    --noOfColumns: 4
}
  }

@media print {

.Cards__Projects_GWbfz {
    display: block
}

    .Cards__Projects_GWbfz > div {
      display: inline-block;
      width: 30%;
      margin-right: 2%;
      margin-bottom: 20px;
      vertical-align: top;
    }
  }

.Cards__Investment_1fYY1:not(:last-child) {
    margin-bottom: var(--padding--5xl);
  }

.Cards__InvestmentName_2EfUI {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

@media print {

.Cards__InvestmentName_2EfUI {
    margin-top: 20px;
    font-weight: 700
}
  }

.Cards__ProjectsShort_2gKO3 {
}

@media screen and (min-width: 1366px) {

.Cards__ProjectsShort_2gKO3 {
    --noOfColumns: 2
}
  }

@media (--screen--lg-min) {

.Cards__ProjectsShort_2gKO3 {
    --noOfColumns: 2
}
  }

.Cards__Links_3N8Fc {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

.Cards__Link_178gp:not(:last-child) {
    margin-right: 16px;
  }

@media print {

.Cards__Link_178gp {
    display: none
}
  }

.Cards__FundTitle_3s9bk {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

@media print {

.Cards__FundTitle_3s9bk {
    display: inline-block;
    border-bottom: 1px solid #000;
    margin-bottom: 20px
}
  }

.Cards__Fund_1sYDi:not(:last-child) {
    margin-bottom: var(--padding--4xl);
  }

.Cards__ShortWrapper_2fjtY {
}

@media screen and (min-width: 1366px) {

.Cards__ShortWrapper_2fjtY {
    --noOfColumns: 2
}
  }

@media (--screen--lg-min) {

.Cards__ShortWrapper_2fjtY {
    --noOfColumns: 2
}
  }

.Cards__FundLong_2BciT {
}

@media screen and (min-width: 1366px) {

.Cards__FundLong_2BciT {
    --noOfColumns: 2
}
  }

@media (--screen--lg-min) {

.Cards__FundLong_2BciT {
    --noOfColumns: 2
}
  }
