@media screen and (max-width: 767px) {.SimilarLink__Wrapper_3aHl9 {
    margin-top: 40px;
    margin-top: var(--padding--3xl)
}
  }

@media (--screen--xs-max) {.SimilarLink__Wrapper_3aHl9 {
    margin-top: 40px;
    margin-top: var(--padding--3xl)
}
  }

.SimilarLink__Card_3N17G {
  position: relative;
  margin-top: 16px;
}

.SimilarLink__Anchor_18sLV {
  position: absolute;
  width: 100%;
  height: 100%;
}

.SimilarLink__Picture_2G23v {
  overflow: hidden;
  border-radius: 2px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.SimilarLink__Picture_2G23v {
    max-width: 220px;
    max-height: 135px
}
  }

@media (--screen--sm-only) {

.SimilarLink__Picture_2G23v {
    max-width: 220px;
    max-height: 135px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.SimilarLink__Picture_2G23v {
    max-width: 280px;
    max-height: 170px
}
  }

@media (--screen--md-only) {

.SimilarLink__Picture_2G23v {
    max-width: 280px;
    max-height: 170px
}
  }

@media screen and (min-width: 1366px) {

.SimilarLink__Picture_2G23v {
    max-width: 370px;
    max-height: 170px
}
  }

@media (--screen--lg-min) {

.SimilarLink__Picture_2G23v {
    max-width: 370px;
    max-height: 170px
}
  }

.SimilarLink__Title_2umE_ {
  margin-top: 16px;
}

.SimilarLink__Description_2y_Li {
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
}
