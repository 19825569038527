.Programs__Wrapper_6L5P_ {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--5xl);
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
}

.Programs__Title_3s-m0 {
  padding-top: var(--padding--4xl);
  margin-top: calc(var(--padding--4xl) * -1);
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Programs__DescriptionWrapper_1QBgW {
}

@media screen and (min-width: 768px) {

.Programs__DescriptionWrapper_1QBgW {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.Programs__DescriptionWrapper_1QBgW {
    --noOfColumns: 12
}
  }

@media screen and (max-width: 1365px) {

.Programs__DescriptionWrapper_1QBgW {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }

@media (--screen--md-max) {

.Programs__DescriptionWrapper_1QBgW {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }

@media screen and (min-width: 1366px) {

.Programs__DescriptionWrapper_1QBgW {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl)
}
  }

@media (--screen--lg-min) {

.Programs__DescriptionWrapper_1QBgW {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl)
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Programs__Description_1wDH- {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Programs__Description_1wDH- {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Programs__Description_1wDH- {
    grid-column: span 6
}
  }

@media (--screen--md-only) {

.Programs__Description_1wDH- {
    grid-column: span 6
}
  }

@media screen and (min-width: 1366px) {

.Programs__Description_1wDH- {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.Programs__Description_1wDH- {
    grid-column: span 5
}
  }

.Programs__List_2uDdD {
  grid-row-gap: 32px;
  grid-row-gap: var(--padding--2xl);
}

@media screen and (min-width: 768px) {

.Programs__List_2uDdD {
    --noOfColumns: 2
}
  }

@media (--screen--sm-min) {

.Programs__List_2uDdD {
    --noOfColumns: 2
}
  }
