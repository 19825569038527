@media screen and (min-width: 768px) {.Content__Wrapper_RdUGP {
    padding-bottom: var(--padding--5xl)
}
  }

@media (--screen--sm-min) {.Content__Wrapper_RdUGP {
    padding-bottom: var(--padding--5xl)
}
  }

.Content__Header_mWRbJ {
  margin-bottom: 40px;
  grid-row-gap: 24px;
  grid-row-gap: var(--padding--xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Heading_3dOLx {
    grid-column: span 9
}
  }

@media (--screen--sm-only) {

.Content__Heading_3dOLx {
    grid-column: span 9
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Content__Heading_3dOLx {
    grid-column: span 10
}
  }

@media (--screen--md-only) {

.Content__Heading_3dOLx {
    grid-column: span 10
}
  }

@media screen and (min-width: 1366px) {

.Content__Heading_3dOLx {
    grid-column: span 9
}
  }

@media (--screen--lg-min) {

.Content__Heading_3dOLx {
    grid-column: span 9
}
  }

.Content__Title_TXzPV {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

.Content__Info_HswKF {
  color: rgba(0, 0, 0, 0.5);
  color: rgba(var(--rgb--black), 0.5);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Description_35-ES {
    grid-column: span 6
}
  }

@media (--screen--sm-only) {

.Content__Description_35-ES {
    grid-column: span 6
}
  }

@media screen and (min-width: 1024px) {

.Content__Description_35-ES {
    grid-column: span 5
}
  }

@media (--screen--md-min) {

.Content__Description_35-ES {
    grid-column: span 5
}
  }

.Content__Links_6h9tj {
  margin-top: 32px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Statistic_31f2y {
    grid-column: 7 / span 6
}
  }

@media (--screen--sm-only) {

.Content__Statistic_31f2y {
    grid-column: 7 / span 6
}
  }

@media screen and (min-width: 1024px) {

.Content__Statistic_31f2y {
    grid-column: 7 / span 5
}
  }

@media (--screen--md-min) {

.Content__Statistic_31f2y {
    grid-column: 7 / span 5
}
  }

.Content__Statistic_31f2y b {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
    font-weight: 500;
  }

.Content__Inner_3Lj77 {
}

@media screen and (min-width: 1024px) {

.Content__Inner_3Lj77 {
    --noOfColumns: 2
}
  }

@media (--screen--md-min) {

.Content__Inner_3Lj77 {
    --noOfColumns: 2
}
  }

.Content__CardList_2W__y {
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
}

@media screen and (max-width: 767px) {

.Content__CardList_2W__y {
    grid-row-gap: 24px
}
  }

@media (--screen--xs-max) {

.Content__CardList_2W__y {
    grid-row-gap: 24px
}
  }

@media screen and (min-width: 768px) {

.Content__CardList_2W__y {
    grid-row-gap: 32px
}
  }

@media (--screen--sm-min) {

.Content__CardList_2W__y {
    grid-row-gap: 32px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__CardList_2W__y {
    --noOfColumns: 12
}
  }

@media (--screen--sm-only) {

.Content__CardList_2W__y {
    --noOfColumns: 12
}
  }

@media screen and (max-width: 1023px) {

.Content__CardList_2W__y {
    margin-bottom: 48px
}
  }

@media (--screen--sm-max) {

.Content__CardList_2W__y {
    margin-bottom: 48px
}
  }

@media screen and (min-width: 1024px) {

.Content__CardList_2W__y {
    --noOfColumns: 6
}
  }

@media (--screen--md-min) {

.Content__CardList_2W__y {
    --noOfColumns: 6
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__TeamCardMain_2gfpp {
    grid-column: span 12
}
  }

@media (--screen--sm-only) {

.Content__TeamCardMain_2gfpp {
    grid-column: span 12
}
  }

@media screen and (min-width: 1024px) {

.Content__TeamCardMain_2gfpp {
    grid-column: span 6
}
  }

@media (--screen--md-min) {

.Content__TeamCardMain_2gfpp {
    grid-column: span 6
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__TeamCard_1vkQ- {
    grid-column: span 4
}
  }

@media (--screen--sm-only) {

.Content__TeamCard_1vkQ- {
    grid-column: span 4
}
  }

@media screen and (min-width: 1024px) {

.Content__TeamCard_1vkQ- {
    grid-column: span 3
}
  }

@media (--screen--md-min) {

.Content__TeamCard_1vkQ- {
    grid-column: span 3
}
  }

.Content__Note_2rv3g {
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
}

@media screen and (max-width: 767px) {

.Content__Note_2rv3g:not(:last-child) {
      margin-bottom: 24px
  }
    }

@media (--screen--xs-max) {

.Content__Note_2rv3g:not(:last-child) {
      margin-bottom: 24px
  }
    }

@media screen and (min-width: 768px) {

.Content__Note_2rv3g:not(:last-child) {
      margin-bottom: 32px
  }
    }

@media (--screen--sm-min) {

.Content__Note_2rv3g:not(:last-child) {
      margin-bottom: 32px
  }
    }

@media screen and (max-width: 767px) {

.Content__Note_2rv3g {
    padding: 32px 24px 48px;
    margin-right: -24px;
    margin-left: -24px
}
  }

@media (--screen--xs-max) {

.Content__Note_2rv3g {
    padding: 32px 24px 48px;
    margin-right: -24px;
    margin-left: -24px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Note_2rv3g {
    padding: 32px 40px 40px
}
  }

@media (--screen--sm-only) {

.Content__Note_2rv3g {
    padding: 32px 40px 40px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Content__Note_2rv3g {
    padding: 24px 32px 32px
}
  }

@media (--screen--md-only) {

.Content__Note_2rv3g {
    padding: 24px 32px 32px
}
  }

@media screen and (min-width: 1366px) {

.Content__Note_2rv3g {
    padding: 32px 48px 48px
}
  }

@media (--screen--lg-min) {

.Content__Note_2rv3g {
    padding: 32px 48px 48px
}
  }

.Content__Note_2rv3g h2 {
    font-weight: 500;
  }

@media screen and (max-width: 1365px) {

.Content__Note_2rv3g h2 {
      font-size: 26px;
      line-height: 32px
  }
    }

@media (--screen--md-max) {

.Content__Note_2rv3g h2 {
      font-size: 26px;
      line-height: 32px
  }
    }

@media screen and (min-width: 1366px) {

.Content__Note_2rv3g h2 {
      font-size: 32px;
      line-height: 38px
  }
    }

@media (--screen--lg-min) {

.Content__Note_2rv3g h2 {
      font-size: 32px;
      line-height: 38px
  }
    }

.Content__Note_2rv3g * + p {
    margin-top: 24px;
  }

.Content__Note_2rv3g p:nth-last-of-type(1) {
      margin-top: 24px;
      font-size: 12px;
      line-height: 16px;
    }

.Content__NoteList_SYo5O {
  padding-top: 24px;
  grid-row-gap: 16px;
}

.Content__NoteList_SYo5O > div {
    position: relative;
    padding-left: 15px;
  }

.Content__NoteList_SYo5O > div::before {
      position: absolute;
      top: 10px;
      left: 0;
      width: 7px;
      height: 1px;
      background: rgb(18, 82, 245);
      background: var(--color--second-blue);
      content: "";
    }
