.IndexesAndRaitings__Wrapper_Em2aU {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--5xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.IndexesAndRaitings__ColLeft_PvrnS {
    grid-column: span 6
}
  }

@media (--screen--sm-only) {

.IndexesAndRaitings__ColLeft_PvrnS {
    grid-column: span 6
}
  }

@media screen and (max-width: 1023px) {

.IndexesAndRaitings__ColLeft_PvrnS {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }

@media (--screen--sm-max) {

.IndexesAndRaitings__ColLeft_PvrnS {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }

@media screen and (min-width: 1024px) {

.IndexesAndRaitings__ColLeft_PvrnS {
    grid-column: span 5
}
  }

@media (--screen--md-min) {

.IndexesAndRaitings__ColLeft_PvrnS {
    grid-column: span 5
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.IndexesAndRaitings__ColRight_3wrx1 {
    grid-column: span 10
}
  }

@media (--screen--sm-only) {

.IndexesAndRaitings__ColRight_3wrx1 {
    grid-column: span 10
}
  }

@media screen and (min-width: 1024px) {

.IndexesAndRaitings__ColRight_3wrx1 {
    grid-column: 7 / span 6
}
  }

@media (--screen--md-min) {

.IndexesAndRaitings__ColRight_3wrx1 {
    grid-column: 7 / span 6
}
  }

.IndexesAndRaitings__Title_165qy {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}
