.Category__Header_lUo2p,
.Category__Description_1MB81 {
}

  @media screen and (min-width: 768px) {.Category__Header_lUo2p,
.Category__Description_1MB81 {
    --noOfColumns: 12
}
  }

  @media (--screen--sm-min) {.Category__Header_lUo2p,
.Category__Description_1MB81 {
    --noOfColumns: 12
}
  }

.Category__Header_lUo2p {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Category__Title_2_jAc {
    grid-column: span 7
}
  }

@media (--screen--sm-only) {

.Category__Title_2_jAc {
    grid-column: span 7
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Category__Title_2_jAc {
    grid-column: span 5
}
  }

@media (--screen--md-only) {

.Category__Title_2_jAc {
    grid-column: span 5
}
  }

@media screen and (min-width: 1366px) {

.Category__Title_2_jAc {
    grid-column: span 4
}
  }

@media (--screen--lg-min) {

.Category__Title_2_jAc {
    grid-column: span 4
}
  }

.Category__Description_1MB81 {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

@media screen and (max-width: 767px) {

.Category__ColLeft_1xq58 {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }

@media (--screen--xs-max) {

.Category__ColLeft_1xq58 {
    margin-bottom: 32px;
    margin-bottom: var(--padding--2xl)
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Category__ColLeft_1xq58 {
    grid-column: span 7
}
  }

@media (--screen--sm-only) {

.Category__ColLeft_1xq58 {
    grid-column: span 7
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Category__ColLeft_1xq58 {
    grid-column: span 6
}
  }

@media (--screen--md-only) {

.Category__ColLeft_1xq58 {
    grid-column: span 6
}
  }

@media screen and (min-width: 1366px) {

.Category__ColLeft_1xq58 {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.Category__ColLeft_1xq58 {
    grid-column: span 5
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Category__ColRight_MsEYh {
    grid-column: 8 / span 5
}
  }

@media (--screen--sm-only) {

.Category__ColRight_MsEYh {
    grid-column: 8 / span 5
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Category__ColRight_MsEYh {
    grid-column: 7 / span 6
}
  }

@media (--screen--md-only) {

.Category__ColRight_MsEYh {
    grid-column: 7 / span 6
}
  }

@media screen and (min-width: 1366px) {

.Category__ColRight_MsEYh {
    grid-column: 7 / span 5
}
  }

@media (--screen--lg-min) {

.Category__ColRight_MsEYh {
    grid-column: 7 / span 5
}
  }

.Category__ColRight_MsEYh span {
    display: block;
    margin-bottom: 8px;
    margin-bottom: var(--padding--sm);
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
    font-weight: 500;
  }

@media screen and (max-width: 1365px) {

.Category__ColRight_MsEYh span {
      font-size: 26px;
      line-height: 32px
  }
    }

@media (--screen--md-max) {

.Category__ColRight_MsEYh span {
      font-size: 26px;
      line-height: 32px
  }
    }

@media screen and (min-width: 1366px) {

.Category__ColRight_MsEYh span {
      font-size: 32px;
      line-height: 38px
  }
    }

@media (--screen--lg-min) {

.Category__ColRight_MsEYh span {
      font-size: 32px;
      line-height: 38px
  }
    }

.Category__List_2Z51l {
}

@media screen and (min-width: 1024px) {

.Category__List_2Z51l {
    --noOfColumns: 2;

    grid-row-gap: 32px;

    grid-row-gap: var(--padding--2xl)
}
  }

@media (--screen--md-min) {

.Category__List_2Z51l {
    --noOfColumns: 2;

    grid-row-gap: 32px;

    grid-row-gap: var(--padding--2xl)
}
  }
