.Committee__Wrapper_1Wd9u {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--5xl);
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Committee__ColLeft_dUrXm,
.Committee__ColRight_O-i5Y {
    grid-column: span 6
}
  }

@media (--screen--md-only) {

.Committee__ColLeft_dUrXm,
.Committee__ColRight_O-i5Y {
    grid-column: span 6
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Committee__ColLeft_dUrXm {
    grid-column: span 9
}
  }

@media (--screen--sm-only) {

.Committee__ColLeft_dUrXm {
    grid-column: span 9
}
  }

@media screen and (max-width: 1023px) {

.Committee__ColLeft_dUrXm {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl)
}
  }

@media (--screen--sm-max) {

.Committee__ColLeft_dUrXm {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl)
}
  }

@media screen and (min-width: 1366px) {

.Committee__ColLeft_dUrXm {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.Committee__ColLeft_dUrXm {
    grid-column: span 5
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Committee__ColRight_O-i5Y {
    grid-column: span 12
}
  }

@media (--screen--sm-only) {

.Committee__ColRight_O-i5Y {
    grid-column: span 12
}
  }

@media screen and (min-width: 1024px) {

.Committee__ColRight_O-i5Y {
    display: flex;
    flex-direction: column;
    justify-content: flex-end
}
  }

@media (--screen--md-min) {

.Committee__ColRight_O-i5Y {
    display: flex;
    flex-direction: column;
    justify-content: flex-end
}
  }

@media screen and (min-width: 1366px) {

.Committee__ColRight_O-i5Y {
    grid-column: 7 / span 6
}
  }

@media (--screen--lg-min) {

.Committee__ColRight_O-i5Y {
    grid-column: 7 / span 6
}
  }

.Committee__Title_3I3Q8 {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media print {

.Committee__Title_3I3Q8 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700
}
  }

.Committee__Link_VSHY5 {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

@media print {

.Committee__Link_VSHY5 {
    display: none
}
  }

.Committee__Cards_1FLbN {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Committee__Cards_1FLbN {
    --noOfColumns: 2
}
  }

@media (--screen--sm-only) {

.Committee__Cards_1FLbN {
    --noOfColumns: 2
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Committee__Cards_1FLbN {
    margin-top: 10px
}
  }

@media (--screen--md-only) {

.Committee__Cards_1FLbN {
    margin-top: 10px
}
  }

@media screen and (min-width: 1366px) {

.Committee__Cards_1FLbN {
    margin-top: 14px
}
  }

@media (--screen--lg-min) {

.Committee__Cards_1FLbN {
    margin-top: 14px
}
  }

@media print {

.Committee__Cards_1FLbN {
    display: none
}
  }

.Committee__Card_3Fu59 {
  padding: 24px 32px 32px;
}

@media screen and (max-width: 767px) {

.Committee__Card_3Fu59:not(:last-child) {
      margin-bottom: 24px;
      margin-bottom: var(--padding--xl)
  }
    }

@media (--screen--xs-max) {

.Committee__Card_3Fu59:not(:last-child) {
      margin-bottom: 24px;
      margin-bottom: var(--padding--xl)
  }
    }

@media screen and (min-width: 1024px) {

.Committee__Card_3Fu59:not(:last-child) {
      margin-bottom: 24px;
      margin-bottom: var(--padding--xl)
  }
    }

@media (--screen--md-min) {

.Committee__Card_3Fu59:not(:last-child) {
      margin-bottom: 24px;
      margin-bottom: var(--padding--xl)
  }
    }

.Committee__CardName_322VA {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}
