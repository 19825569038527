@media screen and (min-width: 1px) and (max-width: 1023px) {.PopupSendTreatment__Title_1fz0z {
    margin-bottom: 8px
}
  }
  @media (--screen--xs-min) and (--screen--sm-max) {.PopupSendTreatment__Title_1fz0z {
    margin-bottom: 8px
}
  }
  @media screen and (min-width: 1024px) {.PopupSendTreatment__Title_1fz0z {
    margin-bottom: 16px
}
  }
  @media (--screen--md-min) {.PopupSendTreatment__Title_1fz0z {
    margin-bottom: 16px
}
  }

@media screen and (min-width: 1px) and (max-width: 1023px) {

.PopupSendTreatment__Description_2VD0p {
    margin-bottom: 24px
}
  }

@media (--screen--xs-min) and (--screen--sm-max) {

.PopupSendTreatment__Description_2VD0p {
    margin-bottom: 24px
}
  }

@media screen and (min-width: 1024px) {

.PopupSendTreatment__Description_2VD0p {
    margin-bottom: 32px
}
  }

@media (--screen--md-min) {

.PopupSendTreatment__Description_2VD0p {
    margin-bottom: 32px
}
  }

.PopupSendTreatment__Description_2VD0p > p a {
      color: rgb(18, 82, 245);
      color: var(--color--second-blue);
      font-weight: 500;
    }

@media screen and (min-width: 1px) and (max-width: 1023px) {

.PopupSendTreatment__Description_2VD0p > p + p {
      margin-top: 16px
  }
    }

@media (--screen--xs-min) and (--screen--sm-max) {

.PopupSendTreatment__Description_2VD0p > p + p {
      margin-top: 16px
  }
    }

@media screen and (min-width: 1024px) {

.PopupSendTreatment__Description_2VD0p > p + p {
      margin-top: 24px
  }
    }

@media (--screen--md-min) {

.PopupSendTreatment__Description_2VD0p > p + p {
      margin-top: 24px
  }
    }

.PopupSendTreatment__Form_3bdOo {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 767px) {

.PopupSendTreatment__Form_3bdOo {
    padding-bottom: 40px
}
  }

@media (--screen--xs-max) {

.PopupSendTreatment__Form_3bdOo {
    padding-bottom: 40px
}
  }

@media screen and (min-width: 768px) {

.PopupSendTreatment__Form_3bdOo {
    padding-bottom: 48px
}
  }

@media (--screen--sm-min) {

.PopupSendTreatment__Form_3bdOo {
    padding-bottom: 48px
}
  }

.PopupSendTreatment__Form_3bdOo > div {
    grid-column: 1 / -1;
  }

.PopupSendTreatment__Form_3bdOo > div:nth-child(1) {
      grid-column: 1;
    }

.PopupSendTreatment__Form_3bdOo > div:nth-child(2) {
      grid-column: 2;
    }

.PopupSendTreatment__SendWrapper_1v_qR {
  margin-top: 16px;
}

@media screen and (min-width: 768px) {

.PopupSendTreatment__SendWrapper_1v_qR {
    display: flex;
    align-items: center
}
  }

@media (--screen--sm-min) {

.PopupSendTreatment__SendWrapper_1v_qR {
    display: flex;
    align-items: center
}
  }

.PopupSendTreatment__SendPolicy_1z3lP {
  font-size: 12px;
  line-height: 16px;
}

@media screen and (max-width: 767px) {

.PopupSendTreatment__SendPolicy_1z3lP {
    margin-top: 16px
}
  }

@media (--screen--xs-max) {

.PopupSendTreatment__SendPolicy_1z3lP {
    margin-top: 16px
}
  }

@media screen and (min-width: 768px) {

.PopupSendTreatment__SendPolicy_1z3lP {
    width: 281px;
    margin-left: 24px
}
  }

@media (--screen--sm-min) {

.PopupSendTreatment__SendPolicy_1z3lP {
    width: 281px;
    margin-left: 24px
}
  }

.PopupSendTreatment__SendPolicy_1z3lP a {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
  }
