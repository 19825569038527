.Vacancy__Wrapper_1TIzW {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 32px 24px;
  padding: var(--padding--lg) var(--padding--2xl) var(--padding--xl);
}

  .Vacancy__Wrapper_1TIzW:hover .Vacancy__Link_t70U2 > span::after {
          opacity: 1;
          transform: translateX(6px);
        }

.Vacancy__Footer_2RdWG {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

.Vacancy__Category_1ky-t {
  margin-bottom: 12px;
  margin-bottom: var(--padding--md);
}

.Vacancy__Title_25EHE {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

.Vacancy__Link_t70U2 {
  /* empty */
}

.Vacancy__Logo_21dzi {
  height: 18px;
}

.Vacancy__Logo_21dzi img {
    max-height: 100%;
  }
