.TeamCardMain__Wrapper_39qRh {
  overflow: hidden;
}

  @media screen and (min-width: 768px) and (max-width: 1023px) {.TeamCardMain__Wrapper_39qRh {
    --noOfColumns: 12
}
  }

  @media (--screen--sm-only) {.TeamCardMain__Wrapper_39qRh {
    --noOfColumns: 12
}
  }

.TeamCardMain__Photo_1xM5D {
  overflow: hidden;
}

@media screen and (max-width: 767px) {

.TeamCardMain__Photo_1xM5D {
    flex-shrink: 0
}
  }

@media (--screen--xs-max) {

.TeamCardMain__Photo_1xM5D {
    flex-shrink: 0
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.TeamCardMain__Photo_1xM5D {
    grid-column: span 6
}
  }

@media (--screen--sm-only) {

.TeamCardMain__Photo_1xM5D {
    grid-column: span 6
}
  }

.TeamCardMain__Photo_1xM5D > img {
    -o-object-fit: cover;
       object-fit: cover;
  }

@media screen and (max-width: 767px) {

.TeamCardMain__Photo_1xM5D > img {
      width: 430px;
      height: 367px
  }
    }

@media (--screen--xs-max) {

.TeamCardMain__Photo_1xM5D > img {
      width: 430px;
      height: 367px
  }
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.TeamCardMain__Photo_1xM5D > img {
      width: 344px;
      height: 344px
  }
    }

@media (--screen--sm-only) {

.TeamCardMain__Photo_1xM5D > img {
      width: 344px;
      height: 344px
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.TeamCardMain__Photo_1xM5D > img {
      width: 432px;
      height: 408px
  }
    }

@media (--screen--md-only) {

.TeamCardMain__Photo_1xM5D > img {
      width: 432px;
      height: 408px
  }
    }

@media screen and (min-width: 1366px) {

.TeamCardMain__Photo_1xM5D > img {
      width: 571px;
      height: 571px
  }
    }

@media (--screen--lg-min) {

.TeamCardMain__Photo_1xM5D > img {
      width: 571px;
      height: 571px
  }
    }

@media screen and (max-width: 767px) {

.TeamCardMain__About_2Lgd0 {
    padding: 16px 24px 24px
}
  }

@media (--screen--xs-max) {

.TeamCardMain__About_2Lgd0 {
    padding: 16px 24px 24px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.TeamCardMain__About_2Lgd0 {
    padding: 32px 32px 32px 0;
    grid-column: span 6
}
  }

@media (--screen--sm-only) {

.TeamCardMain__About_2Lgd0 {
    padding: 32px 32px 32px 0;
    grid-column: span 6
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.TeamCardMain__About_2Lgd0 {
    padding: 24px 32px 32px
}
  }

@media (--screen--md-only) {

.TeamCardMain__About_2Lgd0 {
    padding: 24px 32px 32px
}
  }

@media screen and (min-width: 1366px) {

.TeamCardMain__About_2Lgd0 {
    padding: 24px 40px 40px
}
  }

@media (--screen--lg-min) {

.TeamCardMain__About_2Lgd0 {
    padding: 24px 40px 40px
}
  }

.TeamCardMain__Bio_2-bnR {
  margin-bottom: 24px;
}

.TeamCardMain__Name_3K5Hr {
  margin-bottom: 12px;
}

.TeamCardMain__Contacts_1GQpL a {
    font-weight: 500;
  }

.TeamCardMain__Contacts_1GQpL > div {
    position: relative;
    z-index: 1;
  }

.TeamCardMain__Contacts_1GQpL > div:not(:last-child) {
      margin-bottom: 12px;
    }
