.ContentBlueForm__Email_30s1a {
  display: inline-block;
  margin-top: 32px;
  margin-top: var(--padding--2xl);
  font-weight: 300;
}

  @media screen and (max-width: 1365px) {.ContentBlueForm__Email_30s1a {
    font-size: 26px;
    line-height: 32px
}
  }

  @media (--screen--md-max) {.ContentBlueForm__Email_30s1a {
    font-size: 26px;
    line-height: 32px
}
  }

  @media screen and (min-width: 1366px) {.ContentBlueForm__Email_30s1a {
    font-size: 32px;
    line-height: 38px
}
  }

  @media (--screen--lg-min) {.ContentBlueForm__Email_30s1a {
    font-size: 32px;
    line-height: 38px
}
  }
