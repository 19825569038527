.Quote__Default_3sM_i {
  position: relative;
  padding-left: 24px;
  font-style: italic;
  font-weight: 300;
}

  .Quote__Default_3sM_i::before {
    position: absolute;
    left: 0;
    width: 16px;
    height: 19px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTguMzcyIDNMMy44MTQgOS40OSA4LjM3MiAxNkg0LjU3N0wwIDkuNDkgNC41NzcgM2gzLjc5NXpNMTYgM2wtNC41NzcgNi40OUwxNiAxNmgtMy44MTRMNy42MjggOS40OSAxMi4xODYgM0gxNnoiIGZpbGw9IiMxMjUyRjUiLz48L3N2Zz4K);
    content: "";
  }

.Quote__Big_8IzaH {
}

.Quote__Big_8IzaH::before {
    top: 6px;
  }

@media screen and (max-width: 1365px) {

.Quote__Big_8IzaH {
    font-size: 26px;
    line-height: 32px
}
  }

@media (--screen--md-max) {

.Quote__Big_8IzaH {
    font-size: 26px;
    line-height: 32px
}
  }

@media screen and (min-width: 1366px) {

.Quote__Big_8IzaH {
    font-size: 32px;
    line-height: 38px
}
  }

@media (--screen--lg-min) {

.Quote__Big_8IzaH {
    font-size: 32px;
    line-height: 38px
}
  }

.Quote__Small_2fGxB {
}

.Quote__Small_2fGxB::before {
    top: 0;
  }
