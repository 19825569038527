@media screen and (max-width: 1023px) {.SliderTextCards__Container_1ofSc {
    overflow: visible
}
  }

@media (--screen--sm-max) {.SliderTextCards__Container_1ofSc {
    overflow: visible
}
  }

.SliderTextCards__Card_3Jz-P {
  padding: 16px 24px 24px;
  border: 1px solid hsl(225, 100%, 49%);
  background: linear-gradient(347.14deg, hsl(231, 81%, 43%) 0%, hsl(230, 95%, 31%) 100%);
  border-radius: 2px;
}

@media screen and (max-width: 767px) {

.SliderTextCards__Card_3Jz-P {
    width: 270px
}
  }

@media (--screen--xs-max) {

.SliderTextCards__Card_3Jz-P {
    width: 270px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.SliderTextCards__Card_3Jz-P {
    width: 277px
}
  }

@media (--screen--sm-only) {

.SliderTextCards__Card_3Jz-P {
    width: 277px
}
  }
