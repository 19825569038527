.MassMedia__Wrapper_2SxqC {
  padding-bottom: var(--padding--5xl);
}

.MassMedia__Cards_36Xnk {
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

.MassMedia__Cards_36Xnk {
    --noOfColumns: 3
}
      .MassMedia__Cards_36Xnk > div:last-child {
        display: none;
      }
  }

@media (--screen--sm-min) and (--screen--md-max) {

.MassMedia__Cards_36Xnk {
    --noOfColumns: 3
}
      .MassMedia__Cards_36Xnk > div:last-child {
        display: none;
      }
  }

@media screen and (min-width: 1366px) {

.MassMedia__Cards_36Xnk {
    --noOfColumns: 4
}
  }

@media (--screen--lg-min) {

.MassMedia__Cards_36Xnk {
    --noOfColumns: 4
}
  }
