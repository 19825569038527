.Content__Wrapper_2d_6n {
  padding-bottom: var(--padding--5xl);
}

  @media screen and (min-width: 768px) {.Content__Wrapper_2d_6n {
    --noOfColumns: 12
}
  }

  @media (--screen--sm-min) {.Content__Wrapper_2d_6n {
    --noOfColumns: 12
}
  }

.Content__Head_ey8b6 {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

@media screen and (min-width: 768px) {

.Content__Head_ey8b6 {
    grid-column: span 12
}
  }

@media (--screen--sm-min) {

.Content__Head_ey8b6 {
    grid-column: span 12
}
  }

@media screen and (min-width: 768px) {

.Content__Head_ey8b6 {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.Content__Head_ey8b6 {
    --noOfColumns: 12
}
  }

.Content__Heading_5dWTW {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media screen and (min-width: 768px) {

.Content__Heading_5dWTW {
    grid-column: span 12
}
  }

@media (--screen--sm-min) {

.Content__Heading_5dWTW {
    grid-column: span 12
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Description_1Niz8 {
    grid-column: span 7
}
  }

@media (--screen--sm-only) {

.Content__Description_1Niz8 {
    grid-column: span 7
}
  }

@media screen and (min-width: 1024px) {

.Content__Description_1Niz8 {
    grid-column: span 5
}
  }

@media (--screen--md-min) {

.Content__Description_1Niz8 {
    grid-column: span 5
}
  }
