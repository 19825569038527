@media screen and (min-width: 768px) and (max-width: 1365px) {.NewsList__News_NBhGh {
    grid-column: span 7
}
  }
  @media (--screen--sm-min) and (--screen--md-max) {.NewsList__News_NBhGh {
    grid-column: span 7
}
  }
  @media screen and (min-width: 1366px) {.NewsList__News_NBhGh {
    grid-column: span 8
}
  }
  @media (--screen--lg-min) {.NewsList__News_NBhGh {
    grid-column: span 8
}
  }

@media screen and (max-width: 767px) {

.NewsList__Twitter_1er19 {
    margin-top: 48px
}
  }

@media (--screen--xs-max) {

.NewsList__Twitter_1er19 {
    margin-top: 48px
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.NewsList__Twitter_1er19 {
    grid-column: 9 / span 4
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.NewsList__Twitter_1er19 {
    grid-column: 9 / span 4
}
  }

@media screen and (min-width: 1366px) {

.NewsList__Twitter_1er19 {
    grid-column: 10 / span 3
}
  }

@media (--screen--lg-min) {

.NewsList__Twitter_1er19 {
    grid-column: 10 / span 3
}
  }
