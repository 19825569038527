.Cards__Wrapper_2WhYM {
  padding: var(--padding--4xl) 0 var(--padding--5xl);
}

  /* Для печати */

  @media print {.Cards__Wrapper_2WhYM {
    margin-top: 20px
}
  }

.Cards__Title_23Z3v {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

/* Для печати */

@media print {

.Cards__Title_23Z3v {
    font-weight: 700
}
  }

.Cards__Lead_1iqWQ {
  margin-bottom: var(--padding--4xl);
}

@media print {

.Cards__Lead_1iqWQ {
    margin-bottom: 40px
}
  }

.Cards__Cards_mL8Tn {
}

@media screen and (max-width: 767px) {

.Cards__Cards_mL8Tn {
    grid-row-gap: 24px
}
  }

@media (--screen--xs-max) {

.Cards__Cards_mL8Tn {
    grid-row-gap: 24px
}
  }

@media screen and (min-width: 768px) {

.Cards__Cards_mL8Tn {
    --noOfColumns: 3;

    grid-row-gap: 32px
}
  }

@media (--screen--sm-min) {

.Cards__Cards_mL8Tn {
    --noOfColumns: 3;

    grid-row-gap: 32px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Cards__Cards_mL8Tn {
    --noOfColumns: 2
}
  }

@media (--screen--sm-only) {

.Cards__Cards_mL8Tn {
    --noOfColumns: 2
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Cards__Cards_mL8Tn {
    --noOfColumns: 3
}
  }

@media (--screen--md-only) {

.Cards__Cards_mL8Tn {
    --noOfColumns: 3
}
  }

@media screen and (min-width: 1366px) {

.Cards__Cards_mL8Tn {
    --noOfColumns: 4
}
  }

@media (--screen--lg-min) {

.Cards__Cards_mL8Tn {
    --noOfColumns: 4
}
  }

/* Для печати */

@media print {

.Cards__Cards_mL8Tn {
    display: block
}

    .Cards__Cards_mL8Tn > div {
      display: inline-block;
      width: 30%;
      margin-right: 2%;
      margin-bottom: 20px;
    }
  }

@media screen and (max-width: 767px) {

.Cards__Tabs_2NK9Q {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

@media (--screen--xs-max) {

.Cards__Tabs_2NK9Q {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

@media screen and (min-width: 768px) {

.Cards__Tabs_2NK9Q {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl)
}
  }

@media (--screen--sm-min) {

.Cards__Tabs_2NK9Q {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl)
}
  }

@media print {

.Cards__Tabs_2NK9Q {
    display: none
}
  }
