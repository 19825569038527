.EventsList__Events_3wYgf {
  position: relative;
  z-index: 1;
  grid-row-gap: 32px;
}

  @media screen and (max-width: 767px) {.EventsList__Events_3wYgf {
    padding-bottom: 64px
}
  }

  @media (--screen--xs-max) {.EventsList__Events_3wYgf {
    padding-bottom: 64px
}
  }

  @media screen and (min-width: 768px) {.EventsList__Events_3wYgf {
    --noOfColumns: 2
}
  }

  @media (--screen--sm-min) {.EventsList__Events_3wYgf {
    --noOfColumns: 2
}
  }

  @media screen and (min-width: 768px) and (max-width: 1365px) {.EventsList__Events_3wYgf {
    padding-bottom: 72px
}
  }

  @media (--screen--sm-min) and (--screen--md-max) {.EventsList__Events_3wYgf {
    padding-bottom: 72px
}
  }

  @media screen and (min-width: 1366px) {.EventsList__Events_3wYgf {
    padding-bottom: 90px
}
  }

  @media (--screen--lg-min) {.EventsList__Events_3wYgf {
    padding-bottom: 90px
}
  }

.EventsList__Event_1l5Q2 {
  background: #fff;
  border-radius: 2px;
  color: #000;
}

.EventsList__EventWrapper_Rdqae {
  padding: 32px 56px;
}

@media screen and (max-width: 767px) {

.EventsList__EventWrapper_Rdqae {
    padding: 24px 32px 32px
}
  }

@media (--screen--xs-max) {

.EventsList__EventWrapper_Rdqae {
    padding: 24px 32px 32px
}
  }

@media screen and (min-width: 768px) {

.EventsList__EventWrapper_Rdqae {
    padding: 24px 32px 40px
}
  }

@media (--screen--sm-min) {

.EventsList__EventWrapper_Rdqae {
    padding: 24px 32px 40px
}
  }

.EventsList__EventTitle_aqexW {
  margin-bottom: 16px;
}

.EventsList__EventImage_3fBQ5 {
  height: 300px;
}

.EventsList__EventImage_3fBQ5 img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

.EventsList__BigEvent_UQcLF {
  background: #fff;
  border-radius: 2px;
  color: #000;
}

@media screen and (min-width: 768px) {

.EventsList__BigEvent_UQcLF {
    --noOfColumns: 2;

    padding: 56px;
    grid-column: 2 span
}
  }

@media (--screen--sm-min) {

.EventsList__BigEvent_UQcLF {
    --noOfColumns: 2;

    padding: 56px;
    grid-column: 2 span
}
  }

@media screen and (max-width: 1023px) {

.EventsList__BigTitle_2eGNo {
    margin-bottom: 16px;
    word-break: break-word
}
  }

@media (--screen--sm-max) {

.EventsList__BigTitle_2eGNo {
    margin-bottom: 16px;
    word-break: break-word
}
  }

@media screen and (min-width: 1024px) {

.EventsList__BigTitle_2eGNo {
    margin-bottom: 24px
}
  }

@media (--screen--md-min) {

.EventsList__BigTitle_2eGNo {
    margin-bottom: 24px
}
  }

@media screen and (min-width: 768px) {

.EventsList__BigImage_3shh2 + .EventsList__BigMeta_2ExDm {
      grid-column: 1 span
  }
    }

@media (--screen--sm-min) {

.EventsList__BigImage_3shh2 + .EventsList__BigMeta_2ExDm {
      grid-column: 1 span
  }
    }

@media screen and (max-width: 767px) {

.EventsList__BigMeta_2ExDm {
    padding: 24px 32px 32px
}
  }

@media (--screen--xs-max) {

.EventsList__BigMeta_2ExDm {
    padding: 24px 32px 32px
}
  }

@media screen and (min-width: 768px) {

.EventsList__BigMeta_2ExDm {
    grid-column: 2 span
}
  }

@media (--screen--sm-min) {

.EventsList__BigMeta_2ExDm {
    grid-column: 2 span
}
  }
