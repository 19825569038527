.Content__Inner_E9Pln {
  margin-bottom: var(--padding--5xl);
}

.Content__Title_2bqQ0 {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

@media screen and (max-width: 1365px) {

.Content__SubTitle_BNYLi {
    margin-bottom: 32px
}
  }

@media (--screen--md-max) {

.Content__SubTitle_BNYLi {
    margin-bottom: 32px
}
  }

@media screen and (min-width: 1366px) {

.Content__SubTitle_BNYLi {
    margin-bottom: 40px
}
  }

@media (--screen--lg-min) {

.Content__SubTitle_BNYLi {
    margin-bottom: 40px
}
  }

.Content__List_7NR_q {
}

@media screen and (max-width: 767px) {

.Content__List_7NR_q {
    grid-row-gap: 24px
}
  }

@media (--screen--xs-max) {

.Content__List_7NR_q {
    grid-row-gap: 24px
}
  }

@media screen and (min-width: 768px) {

.Content__List_7NR_q {
    grid-row-gap: 32px
}
  }

@media (--screen--sm-min) {

.Content__List_7NR_q {
    grid-row-gap: 32px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__List_7NR_q {
    --noOfColumns: 3
}
  }

@media (--screen--sm-only) {

.Content__List_7NR_q {
    --noOfColumns: 3
}
  }

@media screen and (min-width: 1024px) {

.Content__List_7NR_q {
    --noOfColumns: 4
}
  }

@media (--screen--md-min) {

.Content__List_7NR_q {
    --noOfColumns: 4
}
  }

@media print {

.Content__List_7NR_q {
    display: block
}

    .Content__List_7NR_q > div {
      display: inline-block;
      width: 45%;
      vertical-align: top;
    }

      .Content__List_7NR_q > div:nth-child(odd) {
        margin-right: 2%;
      }
  }
