.Hero__Wrapper_28krj {
  position: relative;
  overflow: hidden;
}

  @media screen and (min-width: 1024px) and (max-width: 1365px) {.Hero__Wrapper_28krj {
    height: 629px
}
  }

  @media (--screen--md-only) {.Hero__Wrapper_28krj {
    height: 629px
}
  }

  @media screen and (min-width: 1366px) {.Hero__Wrapper_28krj {
    height: 755px
}
  }

  @media (--screen--lg-min) {.Hero__Wrapper_28krj {
    height: 755px
}
  }

  /* Для печати */

  @media print {.Hero__Wrapper_28krj {
    display: none
}
  }

.Hero__AnimationWrapper_3UjTc:not([data-state="visible"]) .Hero__Bg_3Vcz1 {
      opacity: 0;
    }

.Hero__AnimationWrapper_3UjTc:not([data-state="visible"]) .Hero__Title_1FuR3 {
      opacity: 0;
      transform: translateY(20px);
    }

.Hero__AnimationWrapper_3UjTc:not([data-state="visible"]) .Hero__Quotes_3LS2T {
      opacity: 0;
      transform: translateY(20px);
    }

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Title_1FuR3 {
      transition-property: opacity, transform;
      transition-timing-function: ease-out;
    }

@media screen and (max-width: 1023px) {

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Title_1FuR3 {
        transition-delay: 0.3s;
        transition-duration: 0.8s, 0.8s
    }
      }

@media (--screen--sm-max) {

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Title_1FuR3 {
        transition-delay: 0.3s;
        transition-duration: 0.8s, 0.8s
    }
      }

@media screen and (min-width: 1024px) {

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Title_1FuR3 {
        transition-delay: 2.8s;
        transition-duration: 0.8s, 1.5s
    }
      }

@media (--screen--md-min) {

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Title_1FuR3 {
        transition-delay: 2.8s;
        transition-duration: 0.8s, 1.5s
    }
      }

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Bg_3Vcz1 {
      transition-property: opacity, transform;
      transition-timing-function: ease-out;
    }

@media screen and (max-width: 1023px) {

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Bg_3Vcz1 {
        transition-duration: 0.8s, 0.8s
    }
      }

@media (--screen--sm-max) {

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Bg_3Vcz1 {
        transition-duration: 0.8s, 0.8s
    }
      }

@media screen and (min-width: 1024px) {

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Bg_3Vcz1 {
        transition-delay: 2.5s;
        transition-duration: 0.8s, 1.5s
    }
      }

@media (--screen--md-min) {

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Bg_3Vcz1 {
        transition-delay: 2.5s;
        transition-duration: 0.8s, 1.5s
    }
      }

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Quotes_3LS2T {
      transition-property: opacity, transform;
      transition-timing-function: ease-out;
    }

@media screen and (max-width: 1023px) {

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Quotes_3LS2T {
        transition-duration: 0.8s, 0.8s
    }
      }

@media (--screen--sm-max) {

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Quotes_3LS2T {
        transition-duration: 0.8s, 0.8s
    }
      }

@media screen and (min-width: 1024px) {

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Quotes_3LS2T {
        transition-delay: 2.5s;
        transition-duration: 0.8s, 1.5s
    }
      }

@media (--screen--md-min) {

.Hero__AnimationWrapper_3UjTc[data-state="visible"] .Hero__Quotes_3LS2T {
        transition-delay: 2.5s;
        transition-duration: 0.8s, 1.5s
    }
      }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Hero__Title_1FuR3 {
    height: 168px
}
  }

@media (--screen--md-only) {

.Hero__Title_1FuR3 {
    height: 168px
}
  }

@media screen and (min-width: 1366px) {

.Hero__Title_1FuR3 {
    height: 192px
}
  }

@media (--screen--lg-min) {

.Hero__Title_1FuR3 {
    height: 192px
}
  }

.Hero__Inner_10foE {
  padding-bottom: var(--padding--4xl);
}

@media screen and (max-width: 767px) {

.Hero__Inner_10foE {
    padding-top: 226px
}
  }

@media (--screen--xs-max) {

.Hero__Inner_10foE {
    padding-top: 226px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Hero__Inner_10foE {
    padding-top: 64px
}
  }

@media (--screen--sm-only) {

.Hero__Inner_10foE {
    padding-top: 64px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Hero__Inner_10foE {
    padding-top: 40px
}
  }

@media (--screen--md-only) {

.Hero__Inner_10foE {
    padding-top: 40px
}
  }

@media screen and (min-width: 1366px) {

.Hero__Inner_10foE {
    padding-top: 72px;
    padding-bottom: 56px
}
  }

@media (--screen--lg-min) {

.Hero__Inner_10foE {
    padding-top: 72px;
    padding-bottom: 56px
}
  }

.Hero__Quotes_3LS2T {
  display: flex;
}

@media screen and (max-width: 767px) {

.Hero__Quotes_3LS2T {
    margin-top: 83px
}
  }

@media (--screen--xs-max) {

.Hero__Quotes_3LS2T {
    margin-top: 83px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Hero__Quotes_3LS2T {
    margin-top: 135px
}
  }

@media (--screen--sm-only) {

.Hero__Quotes_3LS2T {
    margin-top: 135px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Hero__Quotes_3LS2T {
    margin-top: 96px
}
  }

@media (--screen--md-only) {

.Hero__Quotes_3LS2T {
    margin-top: 96px
}
  }

@media screen and (min-width: 1366px) {

.Hero__Quotes_3LS2T {
    margin-top: 140px
}
  }

@media (--screen--lg-min) {

.Hero__Quotes_3LS2T {
    margin-top: 140px
}
  }

.Hero__Quote_1nMNL {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
    .Hero__Quote_1nMNL:not(:last-child) {
      margin-right: 64px;
    }
  }

@media (--screen--xs-max) {
    .Hero__Quote_1nMNL:not(:last-child) {
      margin-right: 64px;
    }
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .Hero__Quote_1nMNL:not(:last-child) {
      margin-right: 40px;
    }
  }

@media (--screen--sm-only) {
    .Hero__Quote_1nMNL:not(:last-child) {
      margin-right: 40px;
    }
  }

@media screen and (min-width: 1024px) {
    .Hero__Quote_1nMNL:not(:last-child) {
      margin-right: 64px;
    }
  }

@media (--screen--md-min) {
    .Hero__Quote_1nMNL:not(:last-child) {
      margin-right: 64px;
    }
  }

.Hero__Name_2aHJ5 {
  color: rgb(0, 196, 255) !important;
  color: var(--color--accent-light-blue) !important;
}

@media screen and (max-width: 1023px) {

.Hero__Name_2aHJ5 {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg)
}
  }

@media (--screen--sm-max) {

.Hero__Name_2aHJ5 {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg)
}
  }

@media screen and (min-width: 1024px) {

.Hero__Name_2aHJ5 {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

@media (--screen--md-min) {

.Hero__Name_2aHJ5 {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

.Hero__Percent_2IyAh {
  margin-bottom: 4px;
  margin-bottom: var(--padding--xs);
}

.Hero__PercentUp_2_Dj1 {
  color: rgb(0, 198, 91);
  color: var(--color--green);
}

.Hero__PercentDown_1he1o {
  color: rgb(249, 57, 57);
  color: var(--color--red);
}

.Hero__Value_3L5Nm {
  margin-bottom: 4px;
  margin-bottom: var(--padding--xs);
}
