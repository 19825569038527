.Hero__Wrapper_1T872 {
  position: relative;
  overflow: hidden;
  padding-bottom: var(--padding--4xl);
}

.Hero__Title_1QAGM {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

.Hero__Level_33-5S {
  margin-bottom: var(--padding--4xl);
}

.Hero__LevelTitle_1ycIO {
  margin-bottom: 8px;
  margin-bottom: var(--padding--sm);
}

.Hero__LevelLink_35yx7 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(var(--rgb--white), 0.3);
  cursor: pointer;
}
