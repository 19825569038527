.LinksBundle__Wrapper_2k-wz {
  margin-bottom: -16px;
}

  @media print {.LinksBundle__Wrapper_2k-wz {
    display: none
}
  }

  .LinksBundle__Wrapper_2k-wz > a {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg);
  }

  @media screen and (max-width: 767px) {

  .LinksBundle__Wrapper_2k-wz > a:not(:last-child) {
        margin-right: 40px
    }
      }

  @media (--screen--xs-max) {

  .LinksBundle__Wrapper_2k-wz > a:not(:last-child) {
        margin-right: 40px
    }
      }

  @media screen and (min-width: 768px) and (max-width: 1023px) {

  .LinksBundle__Wrapper_2k-wz > a:not(:last-child) {
        margin-right: 24px
    }
      }

  @media (--screen--sm-only) {

  .LinksBundle__Wrapper_2k-wz > a:not(:last-child) {
        margin-right: 24px
    }
      }

  @media screen and (min-width: 1024px) and (max-width: 1365px) {

  .LinksBundle__Wrapper_2k-wz > a:not(:last-child) {
        margin-right: 32px
    }
      }

  @media (--screen--md-only) {

  .LinksBundle__Wrapper_2k-wz > a:not(:last-child) {
        margin-right: 32px
    }
      }

  @media screen and (min-width: 1366px) {

  .LinksBundle__Wrapper_2k-wz > a:not(:last-child) {
        margin-right: 40px
    }
      }

  @media (--screen--lg-min) {

  .LinksBundle__Wrapper_2k-wz > a:not(:last-child) {
        margin-right: 40px
    }
      }
