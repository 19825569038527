@-webkit-keyframes KeyVisual__rotate_1Ohod {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transform: rotate(0);
  }

  50% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes KeyVisual__rotate_1Ohod {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transform: rotate(0);
  }

  50% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes KeyVisual__pulse_1pVwS {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes KeyVisual__pulse_1pVwS {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.KeyVisual__PreloaderWrapper_1L0Yg {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity ease-in-out 0.3s;
}

.KeyVisual__PreloaderWrapper_1L0Yg[data-state="show"] {
    opacity: 1;
  }

.KeyVisual__PreloaderInner_3XaQz {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% + 289px);
  transform: translate(-50%, -50%);
}

.KeyVisual__Preloader_2vgGv {
  -webkit-animation: KeyVisual__rotate_1Ohod 3s infinite;
          animation: KeyVisual__rotate_1Ohod 3s infinite;
  will-change: transform;
}

@media screen and (max-width: 1023px) {

.KeyVisual__VideoWrapper_yyzl_ {
    width: 100%;
    height: 100%;
    background-image: url(/_next/static/images/bg-8d504ecf5ed1becd56f9512c6a978e43.jpg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover
}
  }

@media (--screen--sm-max) {

.KeyVisual__VideoWrapper_yyzl_ {
    width: 100%;
    height: 100%;
    background-image: url(/_next/static/images/bg-8d504ecf5ed1becd56f9512c6a978e43.jpg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover
}
  }

@media screen and (min-width: 1024px) {

.KeyVisual__VideoWrapper_yyzl_ {
    height: 100%
}
  }

@media screen and (--screen--md-min) {

.KeyVisual__VideoWrapper_yyzl_ {
    height: 100%
}
  }

@media screen and (min-width: 1024px) {

.KeyVisual__VideoWrapper_yyzl_ video {
      height: 100%
  }
    }

@media screen and (--screen--md-min) {

.KeyVisual__VideoWrapper_yyzl_ video {
      height: 100%
  }
    }

.KeyVisual__NavigationWrapper_3gDlj {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}

.KeyVisual__NavigationWrapper_3gDlj.active {
    visibility: visible;
  }

.KeyVisual__NavigationWrapper_3gDlj.anim .KeyVisual__NavigationLabel_3UtAX {
      opacity: 1;
      transform: translate(-50%, -50%);
      transition: opacity 0.8s, transform 0.8s;
      transition-timing-function: ease-out;
    }

.KeyVisual__NavigationWrapper_3gDlj.anim .KeyVisual__NavigationLabel_3UtAX[data-label-id="C1"] {
        transition-delay: 0.3s;
      }

.KeyVisual__NavigationWrapper_3gDlj.anim .KeyVisual__NavigationLabel_3UtAX[data-label-id="C2"] {
        transition-delay: 0.2s;
      }

.KeyVisual__NavigationWrapper_3gDlj.anim .KeyVisual__NavigationLabel_3UtAX[data-label-id="C4"] {
        transition-delay: 0.4s;
      }

.KeyVisual__NavigationWrapper_3gDlj.anim .KeyVisual__NavigationLabel_3UtAX[data-label-id="C5"] {
        transition-delay: 0.1s;
      }

.KeyVisual__NavigationWrapper_3gDlj.anim .KeyVisual__NavigationDot_3K2xa {
      opacity: 1;
      transition: opacity 0.8s;
      transition-timing-function: ease-out;
    }

.KeyVisual__NavigationWrapper_3gDlj.anim .KeyVisual__NavigationDot_3K2xa[data-dot-id="O1"] {
        transition-delay: 1.2s;
      }

.KeyVisual__NavigationWrapper_3gDlj.anim .KeyVisual__NavigationDot_3K2xa[data-dot-id="O2"] {
        transition-delay: 1.1s;
      }

.KeyVisual__NavigationWrapper_3gDlj.anim .KeyVisual__NavigationDot_3K2xa[data-dot-id="O3"] {
        transition-delay: 1s;
      }

.KeyVisual__NavigationWrapper_3gDlj.anim .KeyVisual__NavigationDot_3K2xa[data-dot-id="O4"] {
        transition-delay: 0.9s;
      }

.KeyVisual__NavigationWrapper_3gDlj.anim-end .KeyVisual__NavigationLabel_3UtAX {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

.KeyVisual__NavigationWrapper_3gDlj.anim-end .KeyVisual__NavigationDot_3K2xa {
      opacity: 1;
    }

.KeyVisual__NavigationWrapper_3gDlj a::after {
      content: none;
    }

.KeyVisual__NavigationMap_1gWFD {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  max-height: 100%;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
}

.anim-end .KeyVisual__NavigationMap_1gWFD {
    pointer-events: auto;
  }

.glow-start .KeyVisual__NavigationMap_1gWFD {
    pointer-events: none !important;
  }

.KeyVisual__NavigationFade_Oh54G {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  display: block;
  max-height: 100%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity ease-in-out 0.25s 0.1s;
}

.KeyVisual__NavigationFade_Oh54G.show {
    opacity: 1;
    transition: opacity ease-in-out 0.25s;
  }

.KeyVisual__NavigationLabel_3UtAX {
  position: absolute;
  line-height: 18px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, calc(-50% + 20px));
  transition: opacity ease-in-out 0.25s 0.1s;
  will-change: opacity;
}

.KeyVisual__NavigationLabel_3UtAX:hover {
    cursor: pointer;
  }

.KeyVisual__NavigationLabel_3UtAX.hide {
    opacity: 0.35 !important;
    transition: opacity ease-in-out 0.25s;
  }

.KeyVisual__NavigationLabel_3UtAX.hide .KeyVisual__NavigationDot_3K2xa::after {
        -webkit-animation-play-state: paused;
                animation-play-state: paused;
      }

.KeyVisual__NavigationLabel_3UtAX.active .KeyVisual__NavigationDot_3K2xa::after {
        -webkit-animation-play-state: paused;
                animation-play-state: paused;
      }

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C1"] {
    width: 58px;
    padding-top: 20px;
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C1"] {
      top: calc(50% + 185px);
      left: calc(50% + 225px)
  }
    }

@media (--screen--md-only) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C1"] {
      top: calc(50% + 185px);
      left: calc(50% + 225px)
  }
    }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C1"] {
      top: calc(50% + 223px);
      left: calc(50% + 264px)
  }
    }

@media (--screen--lg-min) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C1"] {
      top: calc(50% + 223px);
      left: calc(50% + 264px)
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C1"] .KeyVisual__NavigationDot_3K2xa {
        top: 8px;
        left: 3px
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C1"] .KeyVisual__NavigationDot_3K2xa {
        top: 8px;
        left: 3px
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C1"] .KeyVisual__NavigationDot_3K2xa {
        top: 4px;
        left: 3px
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C1"] .KeyVisual__NavigationDot_3K2xa {
        top: 4px;
        left: 3px
    }
      }

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C2"] {
    width: 85px;
    padding-left: 20px;
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C2"] {
      top: calc(50% + 137px);
      left: calc(50% + 330px)
  }
    }

@media (--screen--md-only) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C2"] {
      top: calc(50% + 137px);
      left: calc(50% + 330px)
  }
    }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C2"] {
      top: calc(50% + 164px);
      left: calc(50% + 393px)
  }
    }

@media (--screen--lg-min) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C2"] {
      top: calc(50% + 164px);
      left: calc(50% + 393px)
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C2"] .KeyVisual__NavigationDot_3K2xa {
        top: 8px;
        left: 8px
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C2"] .KeyVisual__NavigationDot_3K2xa {
        top: 8px;
        left: 8px
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C2"] .KeyVisual__NavigationDot_3K2xa {
        top: 8px;
        left: 5px
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C2"] .KeyVisual__NavigationDot_3K2xa {
        top: 8px;
        left: 5px
    }
      }

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C3"] {
    width: 87px;
    padding-bottom: 20px;
    text-align: right;
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C3"] {
      top: calc(50% - 30px);
      left: calc(50% + 132px)
  }
    }

@media (--screen--md-only) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C3"] {
      top: calc(50% - 30px);
      left: calc(50% + 132px)
  }
    }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C3"] {
      top: calc(50% - 35px);
      left: calc(50% + 168px)
  }
    }

@media (--screen--lg-min) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C3"] {
      top: calc(50% - 35px);
      left: calc(50% + 168px)
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C3"] .KeyVisual__NavigationDot_3K2xa {
        right: 6px;
        bottom: 0
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C3"] .KeyVisual__NavigationDot_3K2xa {
        right: 6px;
        bottom: 0
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C3"] .KeyVisual__NavigationDot_3K2xa {
        right: 7px;
        bottom: -4px
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C3"] .KeyVisual__NavigationDot_3K2xa {
        right: 7px;
        bottom: -4px
    }
      }

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C4"] {
    width: 97px;
    padding-right: 20px;
    text-align: right;
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C4"] {
      top: calc(50% + 69px);
      left: calc(50% + 63px)
  }
    }

@media (--screen--md-only) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C4"] {
      top: calc(50% + 69px);
      left: calc(50% + 63px)
  }
    }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C4"] {
      top: calc(50% + 86px);
      left: calc(50% + 82px)
  }
    }

@media (--screen--lg-min) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C4"] {
      top: calc(50% + 86px);
      left: calc(50% + 82px)
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C4"] .KeyVisual__NavigationDot_3K2xa {
        top: 10px;
        right: 6px
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C4"] .KeyVisual__NavigationDot_3K2xa {
        top: 10px;
        right: 6px
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C4"] .KeyVisual__NavigationDot_3K2xa {
        top: 9px;
        right: 5px
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C4"] .KeyVisual__NavigationDot_3K2xa {
        top: 9px;
        right: 5px
    }
      }

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C5"] {
    width: 96px;
    padding-bottom: 20px;
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C5"] {
      top: calc(50% - 82px);
      left: calc(50% + 298px)
  }
    }

@media (--screen--md-only) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C5"] {
      top: calc(50% - 82px);
      left: calc(50% + 298px)
  }
    }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C5"] {
      top: calc(50% - 99px);
      left: calc(50% + 348px)
  }
    }

@media (--screen--lg-min) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C5"] {
      top: calc(50% - 99px);
      left: calc(50% + 348px)
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C5"] .KeyVisual__NavigationDot_3K2xa {
        bottom: 8px;
        left: 3px
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C5"] .KeyVisual__NavigationDot_3K2xa {
        bottom: 8px;
        left: 3px
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C5"] .KeyVisual__NavigationDot_3K2xa {
        bottom: 2px;
        left: 5px
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationLabel_3UtAX[data-label-id="C5"] .KeyVisual__NavigationDot_3K2xa {
        bottom: 2px;
        left: 5px
    }
      }

.KeyVisual__NavigationLabel_3UtAX a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

.anim-end .KeyVisual__NavigationLabel_3UtAX {
    pointer-events: auto;
  }

.glow-start .KeyVisual__NavigationLabel_3UtAX {
    pointer-events: none !important;
  }

.KeyVisual__NavigationDot_3K2xa {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: opacity ease-in-out 0.25s 0.1s;
}

.KeyVisual__NavigationDot_3K2xa::before,
  .KeyVisual__NavigationDot_3K2xa::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    transform: translate(-50%, -50%);
    will-change: transform;
  }

.KeyVisual__NavigationDot_3K2xa::before {
    width: 6px;
    height: 6px;
    background: rgb(255, 255, 255);
    background: var(--color--white);
    border-radius: 50%;
  }

.KeyVisual__NavigationDot_3K2xa::after {
    width: 16px;
    height: 16px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border: 1px solid rgba(var(--rgb--white), 0.4);
    -webkit-animation: KeyVisual__pulse_1pVwS 3s ease-in-out infinite;
            animation: KeyVisual__pulse_1pVwS 3s ease-in-out infinite;
    border-radius: 50%;
  }

.KeyVisual__NavigationDot_3K2xa:hover {
    cursor: pointer;
  }

.KeyVisual__NavigationDot_3K2xa.hide {
    opacity: 0.35 !important;
    transition: opacity ease-in-out 0.25s;
  }

.KeyVisual__NavigationDot_3K2xa.hide::after {
      -webkit-animation-play-state: paused;
              animation-play-state: paused;
    }

.KeyVisual__NavigationDot_3K2xa.active::after {
      -webkit-animation-play-state: paused;
              animation-play-state: paused;
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O1"] {
      top: calc(50% - 110px);
      left: calc(50% - 23px)
  }
    }

@media (--screen--md-only) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O1"] {
      top: calc(50% - 110px);
      left: calc(50% - 23px)
  }
    }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O1"] {
      top: calc(50% - 138px);
      left: calc(50% - 24px)
  }
    }

@media (--screen--lg-min) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O1"] {
      top: calc(50% - 138px);
      left: calc(50% - 24px)
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O2"] {
      top: calc(50% + 2px);
      left: calc(50% - 66px)
  }
    }

@media (--screen--md-only) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O2"] {
      top: calc(50% + 2px);
      left: calc(50% - 66px)
  }
    }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O2"] {
      top: calc(50% + 9px);
      left: calc(50% - 81px)
  }
    }

@media (--screen--lg-min) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O2"] {
      top: calc(50% + 9px);
      left: calc(50% - 81px)
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O3"] {
      top: calc(50% + 130px);
      left: calc(50% - 61px)
  }
    }

@media (--screen--md-only) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O3"] {
      top: calc(50% + 130px);
      left: calc(50% - 61px)
  }
    }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O3"] {
      top: calc(50% + 161px);
      left: calc(50% - 72px)
  }
    }

@media (--screen--lg-min) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O3"] {
      top: calc(50% + 161px);
      left: calc(50% - 72px)
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O4"] {
      top: calc(50% + 235px);
      left: calc(50% - 9px)
  }
    }

@media (--screen--md-only) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O4"] {
      top: calc(50% + 235px);
      left: calc(50% - 9px)
  }
    }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O4"] {
      top: calc(50% + 296px);
      left: calc(50% - 0px)
  }
    }

@media (--screen--lg-min) {

.KeyVisual__NavigationDot_3K2xa[data-dot-id="O4"] {
      top: calc(50% + 296px);
      left: calc(50% - 0px)
  }
    }

.anim-end .KeyVisual__NavigationDot_3K2xa {
    pointer-events: auto;
  }

.glow-start .KeyVisual__NavigationDot_3K2xa {
    pointer-events: none !important;
  }

.KeyVisual__NavigationElement_rrnt1 {
  --glow-time: 0.2s;

  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  line-height: 18px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: opacity ease-in-out 0.25s 0.1s;
}

.KeyVisual__NavigationElement_rrnt1.show {
    opacity: 1;
    transition: opacity ease-in-out 0.25s;
  }

.KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="C2"] {
      transition-delay: calc(var(--glow-time) * 1.5);
    }

.KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="C3"] {
      transition-delay: calc(var(--glow-time) * 2);
    }

.KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="C4"] {
      transition-delay: calc(var(--glow-time) * 2.5);
    }

.KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="C5"] {
      transition-delay: calc(var(--glow-time) * 3);
    }

.KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="O4"] {
      transition-delay: calc(var(--glow-time) * 2);
      transition-duration: calc(var(--glow-time) * 2);
    }

.KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="O3"] {
      transition-delay: calc(var(--glow-time) * 2.5);
      transition-duration: calc(var(--glow-time) * 2);
    }

.KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="O2"] {
      transition-delay: calc(var(--glow-time) * 3);
      transition-duration: calc(var(--glow-time) * 2);
    }

.KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="O1"] {
      transition-delay: calc(var(--glow-time) * 3.5);
      transition-duration: calc(var(--glow-time) * 2);
    }

.KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="P1"],
    .KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="P5"] {
      transition-delay: calc(var(--glow-time) * 3.5);
    }

.KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="P2"],
    .KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="P6"] {
      transition-delay: calc(var(--glow-time) * 4);
    }

.KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="P3"],
    .KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="P7"] {
      transition-delay: calc(var(--glow-time) * 4.5);
    }

.KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="P4"],
    .KeyVisual__NavigationElement_rrnt1.glow[data-layer-id="P8"] {
      transition-delay: calc(var(--glow-time) * 5);
      transition-duration: var(--glow-time) !important;
      transition-timing-function: ease-in !important;
    }

.KeyVisual__NavigationElement_rrnt1.glow[data-layer-id] {
      opacity: 1;
    }

.glow-start .KeyVisual__NavigationElement_rrnt1[data-layer-id] .KeyVisual__NavigationLabel_3UtAX {
        display: none;
      }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P1"] {
    background-image: url(/_next/static/images/kv_p1-c1ab3a22a398aec0968e4792f21bcf16.png);
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P1"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 189px);
        left: calc(50% + 89px)
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P1"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 189px);
        left: calc(50% + 89px)
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P1"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 227px);
        left: calc(50% + 114px)
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P1"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 227px);
        left: calc(50% + 114px)
    }
      }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P2"] {
    background-image: url(/_next/static/images/kv_p2-fd4c5464c0ea7e71a6491281f2d7ffc6.png);
  }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P2"] .KeyVisual__NavigationLabel_3UtAX {
      text-align: right;
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P2"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 38px);
        left: calc(50% - 27px)
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P2"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 38px);
        left: calc(50% - 27px)
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P2"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 47px);
        left: calc(50% - 17px)
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P2"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 47px);
        left: calc(50% - 17px)
    }
      }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P3"] {
    background-image: url(/_next/static/images/kv_p3-ab30b21cd852b73a0d08e543f2cd5157.png);
  }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P3"] .KeyVisual__NavigationLabel_3UtAX {
      text-align: right;
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P3"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 129px);
        left: calc(50% + 29px)
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P3"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 129px);
        left: calc(50% + 29px)
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P3"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 153px);
        left: calc(50% + 45px)
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P3"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 153px);
        left: calc(50% + 45px)
    }
      }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P4"] {
    background-image: url(/_next/static/images/kv_p4-80a839ba6282ad7725fc6c14f0e5dce9.png);
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P4"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 160px);
        left: calc(50% + 238px)
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P4"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 160px);
        left: calc(50% + 238px)
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P4"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 188px);
        left: calc(50% + 273px)
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P4"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 188px);
        left: calc(50% + 273px)
    }
      }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P5"] {
    background-image: url(/_next/static/images/kv_p5-48e00957527ee6067408bcb1e61b9804.png);
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P5"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 94px);
        left: calc(50% + 444px)
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P5"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 94px);
        left: calc(50% + 444px)
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P5"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 94px);
        left: calc(50% + 555px)
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P5"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 94px);
        left: calc(50% + 555px)
    }
      }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P6"] {
    background-image: url(/_next/static/images/kv_p6-8ea15408733896da9e645a7ba9a94c09.png);
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P6"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 24px);
        left: calc(50% + 459px)
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P6"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 24px);
        left: calc(50% + 459px)
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P6"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 30px);
        left: calc(50% + 542px)
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P6"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 30px);
        left: calc(50% + 542px)
    }
      }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P7"] {
    background-image: url(/_next/static/images/kv_p7-372fb0167c223239bc43b01302279e4f.png);
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P7"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 195px);
        left: calc(50% + 423px)
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P7"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 195px);
        left: calc(50% + 423px)
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P7"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 230px);
        left: calc(50% + 501px)
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P7"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 230px);
        left: calc(50% + 501px)
    }
      }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P8"] {
    background-image: url(/_next/static/images/kv_p8-c0d7e66994e04e0582ab496484a5317f.png);
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P8"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 261px);
        left: calc(50% + 354px)
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P8"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 261px);
        left: calc(50% + 354px)
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P8"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 311px);
        left: calc(50% + 411px)
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="P8"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 311px);
        left: calc(50% + 411px)
    }
      }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="C1"] {
    background-image: url(/_next/static/images/kv_c1-0a4b83751eb54a854aa010d3e979a014.png);
  }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="C2"] {
    background-image: url(/_next/static/images/kv_c2-7efaad9913b4cbe8239666fe6f541e71.png);
  }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="C3"] {
    background-image: url(/_next/static/images/kv_c3-396c1697ebc7b362a24ffa5fa33d95b3.png);
  }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="C4"] {
    background-image: url(/_next/static/images/kv_c4-d14e5ac5957099494b7d20f2d88fa8ca.png);
  }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="C5"] {
    background-image: url(/_next/static/images/kv_c5-b89ff8b549e3d33f9f3ff8a0d078f725.png);
  }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O1"] {
    background-image: url(/_next/static/images/kv_o1-e2f16240fbb981971393b75c9064a2c1.png);
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O1"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 125px);
        left: calc(50% - 63px)
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O1"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 125px);
        left: calc(50% - 63px)
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O1"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 160px);
        left: calc(50% - 65px)
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O1"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 160px);
        left: calc(50% - 65px)
    }
      }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O2"] {
    background-image: url(/_next/static/images/kv_o2-0be0d42d82139fb7ab2ed5baf0c20d6e.png);
  }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O2"] .KeyVisual__NavigationLabel_3UtAX {
      text-align: right;
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O2"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 10px);
        left: calc(50% - 113px)
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O2"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 10px);
        left: calc(50% - 113px)
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O2"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 10px);
        left: calc(50% - 127px)
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O2"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% - 10px);
        left: calc(50% - 127px)
    }
      }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O3"] {
    background-image: url(/_next/static/images/kv_o3-dbca2ec2a1bfa48047365ddf7494b99e.png);
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O3"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 105px);
        left: calc(50% - 107px)
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O3"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 105px);
        left: calc(50% - 107px)
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O3"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 138px);
        left: calc(50% - 115px)
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O3"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 138px);
        left: calc(50% - 115px)
    }
      }

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O4"] {
    background-image: url(/_next/static/images/kv_o4-fd6e002c07339d3145ce57fc06f109a6.png);
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O4"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 197px);
        left: calc(50% + 19px)
    }
      }

@media (--screen--md-only) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O4"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 197px);
        left: calc(50% + 19px)
    }
      }

@media screen and (min-width: 1366px) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O4"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 255px);
        left: calc(50% + 40px)
    }
      }

@media (--screen--lg-min) {

.KeyVisual__NavigationElement_rrnt1[data-layer-id="O4"] .KeyVisual__NavigationLabel_3UtAX {
        top: calc(50% + 255px);
        left: calc(50% + 40px)
    }
      }

@media screen and (min-width: 1024px) and (max-width: 2561px) {

.KeyVisual__NavigationElement_rrnt1 {
    background-size: cover
}
  }

@media screen and (--screen--md-min) and (max-width: 2561px) {

.KeyVisual__NavigationElement_rrnt1 {
    background-size: cover
}
  }

@media screen and (min-width: 2561px) {

.KeyVisual__NavigationElement_rrnt1 {
    background-size: contain
}
  }

.anim-end .KeyVisual__NavigationElement_rrnt1 {
    pointer-events: auto;
  }

.glow-start .KeyVisual__NavigationElement_rrnt1 {
    pointer-events: none !important;
    transition-duration: var(--glow-time);
    transition-property: opacity;
    transition-timing-function: ease-in-out;
  }

.glow-start .KeyVisual__NavigationElement_rrnt1[data-layer-id="P4"],
    .glow-start .KeyVisual__NavigationElement_rrnt1[data-layer-id="P8"] {
      transition-duration: calc(var(--glow-time) * 2.5);
      transition-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
    }
