.Activity__Wrapper_1QG4D {
  padding: var(--padding--4xl) 0 var(--padding--5xl);
  background-color: rgb(240, 248, 255);
  background-color: var(--color--bg-light);
}

.Activity__Title_5dF_l {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}
