.CorpDocuments__Wrapper_2M4og {
}

  @media screen and (min-width: 768px) {.CorpDocuments__Wrapper_2M4og {
    --noOfColumns: 2
}
  }

  @media (--screen--sm-min) {.CorpDocuments__Wrapper_2M4og {
    --noOfColumns: 2
}
  }

.CorpDocuments__Title_2pHKt {
  position: relative;
  padding-left: 33px;
  margin-bottom: 24px;
}

.CorpDocuments__Title_2pHKt::before {
    position: absolute;
    top: 8px;
    left: 0;
    width: 21px;
    height: 20px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIwLjIgMTBIMU04LjU3OSAySDFNMTUuMTQ3IDE4SDEiIHN0cm9rZT0iIzEyNTJGNSIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48L3N2Zz4K);
    content: "";
  }

@media screen and (max-width: 767px) {

.CorpDocuments__Description_36tpv {
    margin-top: 24px
}
  }

@media (--screen--xs-max) {

.CorpDocuments__Description_36tpv {
    margin-top: 24px
}
  }
