.News__Wrapper_33T0q {
  padding-bottom: var(--padding--5xl);
}

.News__Title_14pmy {
  padding-top: var(--padding--4xl);
  margin-top: calc(var(--padding--4xl) * -1);
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

@media screen and (min-width: 768px) {

.News__Title_14pmy {
    grid-column: span 12
}
  }

@media (--screen--sm-min) {

.News__Title_14pmy {
    grid-column: span 12
}
  }

.News__Cards_1zf5Q {
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

.News__Cards_1zf5Q {
    --noOfColumns: 2
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.News__Cards_1zf5Q {
    --noOfColumns: 2
}
  }

@media screen and (min-width: 768px) {

.News__Cards_1zf5Q {
    grid-row-gap: 32px
}
  }

@media (--screen--sm-min) {

.News__Cards_1zf5Q {
    grid-row-gap: 32px
}
  }

@media screen and (min-width: 1366px) {

.News__Cards_1zf5Q {
    --noOfColumns: 3
}
  }

@media (--screen--lg-min) {

.News__Cards_1zf5Q {
    --noOfColumns: 3
}
  }

.News__Grid_1oEEe {
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

.News__ColLeft_3O3d8 {
    grid-column: 8 span
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.News__ColLeft_3O3d8 {
    grid-column: 8 span
}
  }

@media screen and (min-width: 1366px) {

.News__ColLeft_3O3d8 {
    grid-column: 9 span
}
  }

@media (--screen--lg-min) {

.News__ColLeft_3O3d8 {
    grid-column: 9 span
}
  }

@media screen and (max-width: 767px) {

.News__ColRight_2qqHr {
    margin-top: 64px
}
  }

@media (--screen--xs-max) {

.News__ColRight_2qqHr {
    margin-top: 64px
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.News__ColRight_2qqHr {
    grid-column: 9 / span 4
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.News__ColRight_2qqHr {
    grid-column: 9 / span 4
}
  }

@media screen and (min-width: 1366px) {

.News__ColRight_2qqHr {
    grid-column: 10 / span 3
}
  }

@media (--screen--lg-min) {

.News__ColRight_2qqHr {
    grid-column: 10 / span 3
}
  }
