.Filters__Grid_1Rzpt {
}

  @media screen and (max-width: 767px) {.Filters__Grid_1Rzpt {
    grid-row-gap: 24px
}
  }

  @media (--screen--xs-max) {.Filters__Grid_1Rzpt {
    grid-row-gap: 24px
}
  }

  @media screen and (min-width: 768px) {.Filters__Grid_1Rzpt {
    --noOfColumns: 12
}
  }

  @media (--screen--sm-min) {.Filters__Grid_1Rzpt {
    --noOfColumns: 12
}
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {.Filters__Grid_1Rzpt {
    grid-row-gap: 32px
}
  }

  @media (--screen--sm-only) {.Filters__Grid_1Rzpt {
    grid-row-gap: 32px
}
  }

  @media print {.Filters__Grid_1Rzpt {
    display: none
}
  }

  @media screen and (min-width: 768px) and (max-width: 1365px) {

  .Filters__Grid_1Rzpt > div:nth-child(1) {
        grid-column: 8 span
    }
      }

  @media (--screen--sm-min) and (--screen--md-max) {

  .Filters__Grid_1Rzpt > div:nth-child(1) {
        grid-column: 8 span
    }
      }

  @media screen and (min-width: 1366px) {

  .Filters__Grid_1Rzpt > div:nth-child(1) {
        grid-column: 9 span
    }
      }

  @media (--screen--lg-min) {

  .Filters__Grid_1Rzpt > div:nth-child(1) {
        grid-column: 9 span
    }
      }

  @media screen and (min-width: 768px) and (max-width: 1365px) {

  .Filters__Grid_1Rzpt > div:nth-child(2) {
        grid-column: 4 span
    }
      }

  @media (--screen--sm-min) and (--screen--md-max) {

  .Filters__Grid_1Rzpt > div:nth-child(2) {
        grid-column: 4 span
    }
      }

  @media screen and (min-width: 1366px) {

  .Filters__Grid_1Rzpt > div:nth-child(2) {
        grid-column: 3 span
    }
      }

  @media (--screen--lg-min) {

  .Filters__Grid_1Rzpt > div:nth-child(2) {
        grid-column: 3 span
    }
      }

.Filters__Sort_10l5_ {
  margin-top: 16px;
  margin-top: var(--padding--lg);
}

@media print {

.Filters__Sort_10l5_ {
    display: none
}
  }
