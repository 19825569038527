.ActivityCard__Title_75Liz {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media screen and (max-width: 767px) {

.ActivityCard__Wrapper_33IqV {
    padding: 24px 32px 32px
}
  }

@media (--screen--xs-max) {

.ActivityCard__Wrapper_33IqV {
    padding: 24px 32px 32px
}
  }

@media screen and (min-width: 768px) {

.ActivityCard__Wrapper_33IqV {
    padding: 32px 40px 40px;
    padding: var(--padding--2xl) 40px var(--padding--3xl)
}
  }

@media (--screen--sm-min) {

.ActivityCard__Wrapper_33IqV {
    padding: 32px 40px 40px;
    padding: var(--padding--2xl) 40px var(--padding--3xl)
}
  }

.ActivityCard__Inner_38-15 {
  padding-top: 40px;
  padding-top: var(--padding--3xl);
  padding-bottom: 24px;
  padding-bottom: var(--padding--xl);
}

.ActivityCard__Facts_2ufLs {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

@media screen and (min-width: 768px) {

.ActivityCard__Facts_2ufLs {
    display: flex;
    flex-wrap: wrap
}
  }

@media (--screen--sm-min) {

.ActivityCard__Facts_2ufLs {
    display: flex;
    flex-wrap: wrap
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.ActivityCard__Facts_2ufLs {
    margin-right: -20px;
    margin-left: -20px
}
  }

@media (--screen--sm-only) {

.ActivityCard__Facts_2ufLs {
    margin-right: -20px;
    margin-left: -20px
}
  }

@media screen and (min-width: 1024px) {

.ActivityCard__Facts_2ufLs {
    margin-right: -16px;
    margin-left: -16px
}
  }

@media (--screen--md-min) {

.ActivityCard__Facts_2ufLs {
    margin-right: -16px;
    margin-left: -16px
}
  }

@media screen and (max-width: 767px) {

.ActivityCard__FactsBody_2fxM5 {
    display: none
}
  }

@media (--screen--xs-max) {

.ActivityCard__FactsBody_2fxM5 {
    display: none
}
  }

@media screen and (max-width: 767px) {
    .ActivityCard__Fact_2UXMF:not(:last-child) {
      margin-bottom: 16px;
      margin-bottom: var(--padding--lg);
    }
  }

@media (--screen--xs-max) {
    .ActivityCard__Fact_2UXMF:not(:last-child) {
      margin-bottom: 16px;
      margin-bottom: var(--padding--lg);
    }
  }

@media screen and (min-width: 768px) {

.ActivityCard__Fact_2UXMF {
    flex: 1 0 50%
}

    .ActivityCard__Fact_2UXMF:not(:nth-last-child(-n + 2)) {
      margin-bottom: 32px;
      margin-bottom: var(--padding--2xl);
    }
  }

@media (--screen--sm-min) {

.ActivityCard__Fact_2UXMF {
    flex: 1 0 50%
}

    .ActivityCard__Fact_2UXMF:not(:nth-last-child(-n + 2)) {
      margin-bottom: 32px;
      margin-bottom: var(--padding--2xl);
    }
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.ActivityCard__Fact_2UXMF {
    padding: 0 20px
}
  }

@media (--screen--sm-only) {

.ActivityCard__Fact_2UXMF {
    padding: 0 20px
}
  }

@media screen and (min-width: 1024px) {

.ActivityCard__Fact_2UXMF {
    padding: 0 16px
}
  }

@media (--screen--md-min) {

.ActivityCard__Fact_2UXMF {
    padding: 0 16px
}
  }

@media screen and (max-width: 767px) {

.ActivityCard__Link_29d6C {
    margin-top: 24px
}
  }

@media (--screen--xs-max) {

.ActivityCard__Link_29d6C {
    margin-top: 24px
}
  }

@media screen and (min-width: 768px) {

.ActivityCard__Link_29d6C {
    display: none
}
  }

@media (--screen--sm-min) {

.ActivityCard__Link_29d6C {
    display: none
}
  }

.ActivityCard__FactTitle_-7v9d {
  margin-bottom: 4px;
  margin-bottom: var(--padding--xs);
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
}

.ActivityCard__Text_61uaS a {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
    font-weight: 500;
  }

.ActivityCard__Text_61uaS a::after {
      content: none;
    }

@media screen and (max-width: 767px) {

.ActivityCard__TextBody_3CnGP {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4
}

    .ActivityCard__TextBody_3CnGP p {
      display: inline;
    }
  }

@media (--screen--xs-max) {

.ActivityCard__TextBody_3CnGP {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4
}

    .ActivityCard__TextBody_3CnGP p {
      display: inline;
    }
  }

@media screen and (max-width: 767px) {

.ActivityCard__Image_1PAXO {
    height: 150px
}
  }

@media (--screen--xs-max) {

.ActivityCard__Image_1PAXO {
    height: 150px
}
  }

@media screen and (min-width: 1024px) {

.ActivityCard__Image_1PAXO {
    height: 230px
}
  }

@media (--screen--md-min) {

.ActivityCard__Image_1PAXO {
    height: 230px
}
  }

.ActivityCard__Image_1PAXO img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
