.GrowBusiness__Wrapper_3YUVX {
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(var(--rgb--white), 0.3);
}

.GrowBusiness__Inner_1tQhC {
}

@media screen and (min-width: 768px) {

.GrowBusiness__Inner_1tQhC {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.GrowBusiness__Inner_1tQhC {
    --noOfColumns: 12
}
  }

.GrowBusiness__Data_2IK9j {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.GrowBusiness__Data_2IK9j {
    --noOfColumns: 9;

    grid-column: span 9
}
  }

@media (--screen--sm-only) {

.GrowBusiness__Data_2IK9j {
    --noOfColumns: 9;

    grid-column: span 9
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.GrowBusiness__Data_2IK9j {
    --noOfColumns: 7;

    grid-column: span 7
}
  }

@media (--screen--md-only) {

.GrowBusiness__Data_2IK9j {
    --noOfColumns: 7;

    grid-column: span 7
}
  }

@media screen and (min-width: 1366px) {

.GrowBusiness__Data_2IK9j {
    --noOfColumns: 6;

    grid-column: span 6
}
  }

@media (--screen--lg-min) {

.GrowBusiness__Data_2IK9j {
    --noOfColumns: 6;

    grid-column: span 6
}
  }

.GrowBusiness__Title_3Yqfh {
  padding-top: var(--padding--4xl);
  margin-top: calc(var(--padding--4xl) * -1);
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.GrowBusiness__Title_3Yqfh {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.GrowBusiness__Title_3Yqfh {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.GrowBusiness__Title_3Yqfh {
    grid-column: span 7
}
  }

@media (--screen--md-only) {

.GrowBusiness__Title_3Yqfh {
    grid-column: span 7
}
  }

@media screen and (min-width: 1366px) {

.GrowBusiness__Title_3Yqfh {
    grid-column: span 4
}
  }

@media (--screen--lg-min) {

.GrowBusiness__Title_3Yqfh {
    grid-column: span 4
}
  }

@media print {

.GrowBusiness__Title_3Yqfh {
    font-weight: 700
}
  }

.GrowBusiness__Text_1FaVf {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.GrowBusiness__Text_1FaVf {
    grid-column: span 9
}
  }

@media (--screen--sm-only) {

.GrowBusiness__Text_1FaVf {
    grid-column: span 9
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.GrowBusiness__Text_1FaVf {
    grid-column: span 7
}
  }

@media (--screen--md-only) {

.GrowBusiness__Text_1FaVf {
    grid-column: span 7
}
  }

@media screen and (min-width: 1366px) {

.GrowBusiness__Text_1FaVf {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.GrowBusiness__Text_1FaVf {
    grid-column: span 5
}
  }

@media screen and (min-width: 768px) {

.GrowBusiness__Buttons_5AUh- {
    display: flex;
    align-items: flex-end
}
  }

@media (--screen--sm-min) {

.GrowBusiness__Buttons_5AUh- {
    display: flex;
    align-items: flex-end
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.GrowBusiness__Buttons_5AUh- {
    grid-column: span 9
}
  }

@media (--screen--sm-only) {

.GrowBusiness__Buttons_5AUh- {
    grid-column: span 9
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.GrowBusiness__Buttons_5AUh- {
    grid-column: span 7
}
  }

@media (--screen--md-only) {

.GrowBusiness__Buttons_5AUh- {
    grid-column: span 7
}
  }

@media screen and (min-width: 1366px) {

.GrowBusiness__Buttons_5AUh- {
    grid-column: span 6
}
  }

@media (--screen--lg-min) {

.GrowBusiness__Buttons_5AUh- {
    grid-column: span 6
}
  }

@media print {

.GrowBusiness__Buttons_5AUh- {
    display: none
}
  }

@media screen and (max-width: 767px) {
    .GrowBusiness__Button_241A-:not(:last-child) {
      margin-bottom: 24px;
    }
  }

@media (--screen--xs-max) {
    .GrowBusiness__Button_241A-:not(:last-child) {
      margin-bottom: 24px;
    }
  }

@media screen and (min-width: 768px) {
    .GrowBusiness__Button_241A-:not(:last-child) {
      margin-right: 24px;
    }
  }

@media (--screen--sm-min) {
    .GrowBusiness__Button_241A-:not(:last-child) {
      margin-right: 24px;
    }
  }

.GrowBusiness__AnimationWrapper_T8q6V:not([data-state="visible"]) .GrowBusiness__AnimationInner_3_ekS {
      opacity: 0;
    }

.GrowBusiness__AnimationWrapper_T8q6V[data-state="visible"] .GrowBusiness__AnimationInner_3_ekS {
      transition: opacity ease-out 0.8s 0.3s;
    }

@media print {

.GrowBusiness__AnimationWrapper_T8q6V {
    margin-top: 40px;
    color: #000
}
  }

.GrowBusiness__InHome_50R1q:not([data-state="visible"]) {
    opacity: 0;
    transform: translateY(20px);
  }

.GrowBusiness__InHome_50R1q[data-state="visible"] {
    transition: opacity 0.8s, transform 0.8s;
    transition-timing-function: ease-out;
  }

.GrowBusiness__AnimationContainer_2W-rx {
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.GrowBusiness__AnimationContainer_2W-rx {
    grid-column: 8 / span 5
}
  }

@media (--screen--md-only) {

.GrowBusiness__AnimationContainer_2W-rx {
    grid-column: 8 / span 5
}
  }

@media screen and (min-width: 1366px) {

.GrowBusiness__AnimationContainer_2W-rx {
    grid-column: 8 / span 4
}
  }

@media (--screen--lg-min) {

.GrowBusiness__AnimationContainer_2W-rx {
    grid-column: 8 / span 4
}
  }

@media print {

.GrowBusiness__AnimationContainer_2W-rx {
    display: none
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.GrowBusiness__AnimationInner_3_ekS {
    transform: translate3d(0, -26px, 0) scale(1.3)
}
  }

@media (--screen--md-only) {

.GrowBusiness__AnimationInner_3_ekS {
    transform: translate3d(0, -26px, 0) scale(1.3)
}
  }

@media screen and (min-width: 1366px) {

.GrowBusiness__AnimationInner_3_ekS {
    transform: translate3d(0, -4px, 0) scale(1.2)
}
  }

@media (--screen--lg-min) {

.GrowBusiness__AnimationInner_3_ekS {
    transform: translate3d(0, -4px, 0) scale(1.2)
}
  }

.GrowBusiness__AnimationSign_1fZZV {
  font-weight: 500;
  text-align: center;
}
