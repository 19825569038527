.NewsItem__NewsItem_LgDFg {
  padding-bottom: 40px;
  padding-bottom: var(--padding--3xl);
}

  .NewsItem__NewsItem_LgDFg:not(:first-child) {
    padding-top: 40px;
    padding-top: var(--padding--3xl);
  }

  .NewsItem__NewsItem_LgDFg:not(:last-child) {
    border-bottom: 1px solid rgba(18, 82, 245, 0.3);
    border-bottom: 1px solid rgba(var(--rgb--second-blue), 0.3);
  }

.NewsItem__Date_3Rg1Y {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
  opacity: 0.3;
  text-transform: uppercase;
}

.NewsItem__Tags_3pChI {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

.NewsItem__Tag_27t3q {
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
}

.NewsItem__Tag_27t3q:not(:last-child) {
    margin-right: 16px;
  }

.NewsItem__Link_30lpI {
  --alpha: 0;

  border-bottom: 1px solid rgba(0, 0, 0, var(--alpha));

  border-bottom: 1px solid rgba(var(--rgb--black), var(--alpha));
  transition: border-color 0.3s;
}

.NewsItem__Link_30lpI:hover {
    --alpha: 0.3;
  }
