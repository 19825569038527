.TeamCard__Wrapper_2FBuS {
  position: relative;
}

  .TeamCard__Wrapper_2FBuS:hover .TeamCard__Name_2LVCY {
      color: rgb(18, 82, 245);
      color: var(--color--second-blue);
    }

  @media screen and (max-width: 767px) {.TeamCard__Wrapper_2FBuS {
    display: flex
}
  }

  @media (--screen--xs-max) {.TeamCard__Wrapper_2FBuS {
    display: flex
}
  }

.TeamCard__Photo_2BdKH {
  overflow: hidden;
}

@media screen and (max-width: 767px) {

.TeamCard__Photo_2BdKH {
    flex-shrink: 0
}
  }

@media (--screen--xs-max) {

.TeamCard__Photo_2BdKH {
    flex-shrink: 0
}
  }

@media screen and (min-width: 768px) {

.TeamCard__Photo_2BdKH {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px
}
  }

@media (--screen--sm-min) {

.TeamCard__Photo_2BdKH {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px
}
  }

.TeamCard__Photo_2BdKH > img {
    -o-object-fit: cover;
       object-fit: cover;
  }

@media screen and (max-width: 767px) {

.TeamCard__Photo_2BdKH > img {
      width: 148px;
      height: 175px
  }
    }

@media (--screen--xs-max) {

.TeamCard__Photo_2BdKH > img {
      width: 148px;
      height: 175px
  }
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.TeamCard__Photo_2BdKH > img {
      width: 218px;
      height: 148px
  }
    }

@media (--screen--sm-only) {

.TeamCard__Photo_2BdKH > img {
      width: 218px;
      height: 148px
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.TeamCard__Photo_2BdKH > img {
      width: 200px;
      height: 148px
  }
    }

@media (--screen--md-only) {

.TeamCard__Photo_2BdKH > img {
      width: 200px;
      height: 148px
  }
    }

@media screen and (min-width: 1366px) {

.TeamCard__Photo_2BdKH > img {
      width: 270px;
      height: 200px
  }
    }

@media (--screen--lg-min) {

.TeamCard__Photo_2BdKH > img {
      width: 270px;
      height: 200px
  }
    }

@media print {

.TeamCard__Photo_2BdKH > img {
      width: 270px;
      height: 200px
  }
    }

@media screen and (max-width: 1365px) {

.TeamCard__Info_3iPSJ {
    padding: 16px 24px 24px
}
  }

@media (--screen--md-max) {

.TeamCard__Info_3iPSJ {
    padding: 16px 24px 24px
}
  }

@media screen and (min-width: 1366px) {

.TeamCard__Info_3iPSJ {
    padding: 24px 32px 32px
}
  }

@media (--screen--lg-min) {

.TeamCard__Info_3iPSJ {
    padding: 24px 32px 32px
}
  }

.TeamCard__Name_2LVCY {
  transition: color ease-in-out 0.3s;
}

@media screen and (max-width: 1365px) {

.TeamCard__Job_dgppf,
.TeamCard__Download_2Xbwk {
    margin-top: 8px
}
  }

@media (--screen--md-max) {

.TeamCard__Job_dgppf,
.TeamCard__Download_2Xbwk {
    margin-top: 8px
}
  }

@media screen and (min-width: 1366px) {

.TeamCard__Job_dgppf,
.TeamCard__Download_2Xbwk {
    margin-top: 16px
}
  }

@media (--screen--lg-min) {

.TeamCard__Job_dgppf,
.TeamCard__Download_2Xbwk {
    margin-top: 16px
}
  }

.TeamCard__Download_2Xbwk,
.TeamCard__Links_358M5 {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1365px) {

.TeamCard__Job_dgppf {
    font-size: 12px;
    line-height: 16px
}
  }

@media (--screen--md-max) {

.TeamCard__Job_dgppf {
    font-size: 12px;
    line-height: 16px
}
  }

.TeamCard__Download_2Xbwk {
  display: inline-block;
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  font-size: 12px;
  line-height: 16px;
}

.TeamCard__Links_358M5 {
  margin-top: 16px;
}

.TeamCard__LinksBundle_15xFO {
  margin-bottom: -8px;
}

.TeamCard__LinksBundle_15xFO > a {
    margin-bottom: 8px;
  }

.TeamCard__LinksBundle_15xFO > a:not(:last-child) {
      margin-right: 16px;
    }
