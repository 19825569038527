.Program__Wrapper_1oCCp {
  position: relative;
}

  .Program__Wrapper_1oCCp:hover {
    cursor: pointer;
  }

  .Program__Wrapper_1oCCp:hover .Program__Title_3hIt1 {
      color: rgb(18, 82, 245);
      color: var(--color--second-blue);
    }

.Program__Title_3hIt1 {
  transition: color ease-in-out 0.3s;
}

.Program__Image_270dH {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Program__Image_270dH > img {
    -o-object-fit: cover;
       object-fit: cover;
  }

@media screen and (max-width: 767px) {

.Program__Image_270dH > img {
      width: 366px;
      height: 237px
  }
    }

@media (--screen--xs-max) {

.Program__Image_270dH > img {
      width: 366px;
      height: 237px
  }
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Program__Image_270dH > img {
      width: 344px;
      height: 223px
  }
    }

@media (--screen--sm-only) {

.Program__Image_270dH > img {
      width: 344px;
      height: 223px
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Program__Image_270dH > img {
      width: 432px;
      height: 280px
  }
    }

@media (--screen--md-only) {

.Program__Image_270dH > img {
      width: 432px;
      height: 280px
  }
    }

@media screen and (min-width: 1366px) {

.Program__Image_270dH > img {
      width: 570px;
      height: 370px
  }
    }

@media (--screen--lg-min) {

.Program__Image_270dH > img {
      width: 570px;
      height: 370px
  }
    }

.Program__Link_2uFTa {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
