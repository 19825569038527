.List__Wrapper_FukUJ {
  margin-top: var(--padding--4xl);
}

@media screen and (max-width: 767px) {

.List__Title_26mmS {
    margin-bottom: 16px
}
  }

@media (--screen--xs-max) {

.List__Title_26mmS {
    margin-bottom: 16px
}
  }

@media screen and (min-width: 768px) {

.List__Title_26mmS {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

@media (--screen--sm-min) {

.List__Title_26mmS {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

.List__Cells_bR34G {
  margin-bottom: 32px;
}
