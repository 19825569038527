.Cooperation__Wrapper_1rmGf {
}

  @media screen and (min-width: 1024px) {.Cooperation__Wrapper_1rmGf {
    --noOfColumns: 2
}
  }

  @media (--screen--md-min) {.Cooperation__Wrapper_1rmGf {
    --noOfColumns: 2
}
  }

.Cooperation__Button_1XUli {
  margin-top: auto;
}

.Cooperation__Description_3VuI2 {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

@media screen and (min-width: 1024px) {

.Cooperation__TitleWrapper_1_4aI {
    grid-column: 1
}
  }

@media (--screen--md-min) {

.Cooperation__TitleWrapper_1_4aI {
    grid-column: 1
}
  }

@media screen and (max-width: 1023px) {

.Cooperation__Title_3VCz5 {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl)
}
  }

@media (--screen--sm-max) {

.Cooperation__Title_3VCz5 {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl)
}
  }

@media screen and (min-width: 1024px) {

.Cooperation__ButtonWrapper_27nfD {
    align-self: end;
    grid-column: 1
}
  }

@media (--screen--md-min) {

.Cooperation__ButtonWrapper_27nfD {
    align-self: end;
    grid-column: 1
}
  }

@media screen and (max-width: 1023px) {

.Cooperation__DescriptionWrapper_xf9VK {
    margin-bottom: 40px
}
  }

@media (--screen--sm-max) {

.Cooperation__DescriptionWrapper_xf9VK {
    margin-bottom: 40px
}
  }

@media screen and (min-width: 1024px) {

.Cooperation__DescriptionWrapper_xf9VK {
    margin-top: 18px;
    grid-column: 2;
    grid-row: span 2
}
  }

@media (--screen--md-min) {

.Cooperation__DescriptionWrapper_xf9VK {
    margin-top: 18px;
    grid-column: 2;
    grid-row: span 2
}
  }
