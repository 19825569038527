.Content__Title_3CBX6 {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

.Content__Description_2pUAn {
  margin-bottom: 32px;
}

.Content__Form_WINey {
  display: grid;
  padding-bottom: 48px;
  grid-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.Content__Field_2a_vl,
.Content__SendWrapper_1RiMS {
  grid-column: 1 / -1;
}

.Content__Phone_V1yZe {
  grid-column: 1;
}

.Content__Email_321bE {
  grid-column: 2;
}

@media screen and (min-width: 768px) {

.Content__SendWrapper_1RiMS {
    display: flex;
    align-items: center
}
  }

@media (--screen--sm-min) {

.Content__SendWrapper_1RiMS {
    display: flex;
    align-items: center
}
  }

@media screen and (max-width: 1023px) {

.Content__SendWrapper_1RiMS {
    margin-top: 8px
}
  }

@media (--screen--sm-max) {

.Content__SendWrapper_1RiMS {
    margin-top: 8px
}
  }

@media screen and (min-width: 1024px) {

.Content__SendWrapper_1RiMS {
    margin-top: 10px
}
  }

@media (--screen--md-min) {

.Content__SendWrapper_1RiMS {
    margin-top: 10px
}
  }

.Content__SendPolicy_3H_Xt {
  font-size: 12px;
  line-height: 16px;
}

@media screen and (max-width: 767px) {

.Content__SendPolicy_3H_Xt {
    margin-top: 16px
}
  }

@media (--screen--xs-max) {

.Content__SendPolicy_3H_Xt {
    margin-top: 16px
}
  }

@media screen and (min-width: 768px) {

.Content__SendPolicy_3H_Xt {
    width: 281px;
    margin-left: 24px
}
  }

@media (--screen--sm-min) {

.Content__SendPolicy_3H_Xt {
    width: 281px;
    margin-left: 24px
}
  }

.Content__SendPolicy_3H_Xt > a {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
  }
