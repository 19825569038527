.Sistema__Wrapper_1o7tY {
  padding-bottom: var(--padding--5xl);
}

.Sistema__Title_3iTWO {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

.Sistema__Inner_2aESL,
.Sistema__List_1nKEN {
}

@media screen and (min-width: 768px) {

.Sistema__Inner_2aESL {
    --noOfColumns: 2
}
  }

@media (--screen--sm-min) {

.Sistema__Inner_2aESL {
    --noOfColumns: 2
}
  }

@media screen and (max-width: 767px) {

.Sistema__List_1nKEN {
    --noOfColumns: 2;

    margin-bottom: 40px;

    margin-bottom: var(--padding--3xl)
}
  }

@media (--screen--xs-max) {

.Sistema__List_1nKEN {
    --noOfColumns: 2;

    margin-bottom: 40px;

    margin-bottom: var(--padding--3xl)
}
  }

@media screen and (min-width: 768px) {

.Sistema__List_1nKEN {
    --noOfColumns: 6
}
  }

@media (--screen--sm-min) {

.Sistema__List_1nKEN {
    --noOfColumns: 6
}
  }

.Sistema__Item_1G4Iz {
  /* &:last-child { */

  /*  @media (--screen--lg-min) { */
}

/* } */

@media screen and (max-width: 767px) {

.Sistema__Item_1G4Iz {
    grid-column: span 1
}
  }

@media (--screen--xs-max) {

.Sistema__Item_1G4Iz {
    grid-column: span 1
}
  }

/*  } */

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Sistema__Item_1G4Iz {
    grid-column: span 3
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Sistema__Item_1G4Iz {
    grid-column: span 3
}
  }

/*    grid-column: 4 / span 2; */

@media screen and (min-width: 1366px) {

.Sistema__Item_1G4Iz {
    grid-column: span 2
}
  }

@media (--screen--lg-min) {

.Sistema__Item_1G4Iz {
    grid-column: span 2
}
  }

@media screen and (max-width: 1023px) {

.Sistema__Note_1JW8u {
    padding: 16px 24px 24px
}
  }

@media (--screen--sm-max) {

.Sistema__Note_1JW8u {
    padding: 16px 24px 24px
}
  }

@media screen and (min-width: 1024px) {

.Sistema__Note_1JW8u {
    padding: 24px 32px 32px
}
  }

@media (--screen--md-min) {

.Sistema__Note_1JW8u {
    padding: 24px 32px 32px
}
  }
