.List__Wrapper_2p-sI {
  margin-bottom: var(--padding--4xl);
  grid-row-gap: 32px;
  grid-row-gap: var(--padding--2xl);
}

  @media screen and (min-width: 768px) {.List__Wrapper_2p-sI {
    --noOfColumns: 12
}
  }

  @media (--screen--sm-min) {.List__Wrapper_2p-sI {
    --noOfColumns: 12
}
  }

  @media screen and (min-width: 1366px) {
      .List__Wrapper_2p-sI > div:first-child {
        grid-column: span 6;
        grid-row: span 2;
      }
  }

  @media (--screen--lg-min) {
      .List__Wrapper_2p-sI > div:first-child {
        grid-column: span 6;
        grid-row: span 2;
      }
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.List__Item_3qypW {
    grid-column: span 4
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.List__Item_3qypW {
    grid-column: span 4
}
  }

@media screen and (min-width: 1366px) {

.List__Item_3qypW {
    grid-column: span 3
}
  }

@media (--screen--lg-min) {

.List__Item_3qypW {
    grid-column: span 3
}
  }

@media screen and (min-width: 1366px) {

.List__ItemTitle_1Udwx {
    font-size: 32px;
    line-height: 38px
}
  }

@media (--screen--lg-min) {

.List__ItemTitle_1Udwx {
    font-size: 32px;
    line-height: 38px
}
  }

@media screen and (min-width: 1366px) {

.List__ItemPicture_1Dxii {
    /* TODO: убрать !important */
    height: 375px !important
}
  }

@media (--screen--lg-min) {

.List__ItemPicture_1Dxii {
    /* TODO: убрать !important */
    height: 375px !important
}
  }
