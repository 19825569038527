.TagsFilter__Tags_rZALq {
  margin-right: -8px;
  margin-left: -8px;
}

  @media screen and (max-width: 767px) {.TagsFilter__Tags_rZALq {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 16px
}

    .TagsFilter__Tags_rZALq .TagsFilter__Tag_1Uja7 {
      width: auto;
    }
  }

  @media (--screen--xs-max) {.TagsFilter__Tags_rZALq {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 16px
}

    .TagsFilter__Tags_rZALq .TagsFilter__Tag_1Uja7 {
      width: auto;
    }
  }

  @media screen and (min-width: 768px) {.TagsFilter__Tags_rZALq {
    margin-top: 16px;
    margin-top: var(--padding--lg)
}
  }

  @media (--screen--sm-min) {.TagsFilter__Tags_rZALq {
    margin-top: 16px;
    margin-top: var(--padding--lg)
}
  }

  @media print {.TagsFilter__Tags_rZALq {
    display: none
}
  }

.TagsFilter__Tag_1Uja7 {
  margin: 8px;
}

.TagsFilter__Tag_1Uja7:hover span {
      color: rgba(255, 255, 255, 0.3);
      color: rgba(var(--rgb--white), 0.3);
    }

.TagsFilter__Tag_1Uja7:hover i::before {
        border-color: transparent transparent rgba(255, 255, 255, 0.3);
        border-color: transparent transparent rgba(var(--rgb--white), 0.3);
      }

.TagsFilter__Tag_1Uja7 span {
    color: rgba(18, 82, 245, 0.3);
    color: rgba(var(--rgb--second-blue), 0.3);
    transition: color ease-in-out 0.3s;
  }

.TagsFilter__Tag_1Uja7 span i {
      position: relative;
      display: inline-block;
      width: 6px;
      height: 9px;
      margin-left: 6px;
    }

.TagsFilter__Tag_1Uja7 span i::before {
        position: absolute;
        top: 50%;
        right: 0;
        width: 0;
        height: 0;
        border-width: 0 4px 4px;
        border-style: solid;
        border-color: transparent transparent rgba(18, 82, 245, 0.3);
        border-color: transparent transparent rgba(var(--rgb--second-blue), 0.3);
        content: "";
        transform: translateY(-50%);
        transition: border-color ease-in-out 0.3s;
      }

.TagsFilter__ResetCategories_1m040 {
  border-color: rgba(249, 57, 57, 0.3) !important;
  border-color: rgba(var(--rgb--red), 0.3) !important;
  margin: 8px;
  color: rgb(249, 57, 57) !important;
  color: var(--color--red) !important;
}

.TagsFilter__ResetCategories_1m040:hover {
    background-color: rgb(249, 57, 57) !important;
    background-color: var(--color--red) !important;
    color: rgb(255, 255, 255) !important;
    color: var(--color--white) !important;
  }

@media screen and (max-width: 767px) {

.TagsFilter__ResetCategories_1m040 {
    width: auto
}
  }

@media (--screen--xs-max) {

.TagsFilter__ResetCategories_1m040 {
    width: auto
}
  }
