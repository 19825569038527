.Diagram__Bars_3midW {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

  @media screen and (min-width: 768px) {.Diagram__Bars_3midW {
    display: flex
}
  }

  @media (--screen--sm-min) {.Diagram__Bars_3midW {
    display: flex
}
  }

  @media screen and (min-width: 768px) and (max-width: 1365px) {.Diagram__Bars_3midW {
    height: 52px
}
  }

  @media (--screen--sm-min) and (--screen--md-max) {.Diagram__Bars_3midW {
    height: 52px
}
  }

  @media screen and (min-width: 1366px) {.Diagram__Bars_3midW {
    height: 58px
}
  }

  @media (--screen--lg-min) {.Diagram__Bars_3midW {
    height: 58px
}
  }

.Diagram__Bar_91mq6 {
  position: relative;
}

@media screen and (max-width: 767px) {

.Diagram__Bar_91mq6:not(:last-child) {
      margin-bottom: 16px
  }
    }

@media (--screen--xs-max) {

.Diagram__Bar_91mq6:not(:last-child) {
      margin-bottom: 16px
  }
    }

@media screen and (min-width: 768px) {

.Diagram__Bar_91mq6:not(:last-child) {
      border-right: 2px solid rgb(255, 255, 255);
      border-right: 2px solid var(--color--white)
  }
    }

@media (--screen--sm-min) {

.Diagram__Bar_91mq6:not(:last-child) {
      border-right: 2px solid rgb(255, 255, 255);
      border-right: 2px solid var(--color--white)
  }
    }

@media screen and (min-width: 768px) {

.Diagram__Bar_91mq6:nth-child(1) {
      background: rgb(0, 18, 117);
      background: var(--color--main-blue);
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px
  }
    }

@media (--screen--sm-min) {

.Diagram__Bar_91mq6:nth-child(1) {
      background: rgb(0, 18, 117);
      background: var(--color--main-blue);
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px
  }
    }

.Diagram__Bar_91mq6:nth-child(1) .Diagram__BarTitle_a0sHV {
      color: rgb(0, 18, 117);
      color: var(--color--main-blue);
    }

.Diagram__Bar_91mq6:nth-child(1) .Diagram__BarColor_5hFka {
      background: rgb(0, 18, 117);
      background: var(--color--main-blue);
    }

@media screen and (min-width: 768px) {

.Diagram__Bar_91mq6:nth-child(2) {
      background: rgb(18, 82, 245);
      background: var(--color--second-blue)
  }
    }

@media (--screen--sm-min) {

.Diagram__Bar_91mq6:nth-child(2) {
      background: rgb(18, 82, 245);
      background: var(--color--second-blue)
  }
    }

.Diagram__Bar_91mq6:nth-child(2) .Diagram__BarTitle_a0sHV {
      color: rgb(18, 82, 245);
      color: var(--color--second-blue);
    }

.Diagram__Bar_91mq6:nth-child(2) .Diagram__BarColor_5hFka {
      background: rgb(18, 82, 245);
      background: var(--color--second-blue);
    }

@media screen and (min-width: 768px) {

.Diagram__Bar_91mq6:nth-child(3) {
      background: rgb(0, 196, 255);
      background: var(--color--accent-light-blue)
  }
    }

@media (--screen--sm-min) {

.Diagram__Bar_91mq6:nth-child(3) {
      background: rgb(0, 196, 255);
      background: var(--color--accent-light-blue)
  }
    }

.Diagram__Bar_91mq6:nth-child(3) .Diagram__BarTitle_a0sHV {
      color: rgb(0, 196, 255);
      color: var(--color--accent-light-blue);
    }

.Diagram__Bar_91mq6:nth-child(3) .Diagram__BarColor_5hFka {
      background: rgb(0, 196, 255);
      background: var(--color--accent-light-blue);
    }

@media screen and (min-width: 768px) {

.Diagram__Bar_91mq6:nth-child(4) {
      background: rgba(0, 0, 0, 0.2);
      background: rgba(var(--rgb--black), 0.2);
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px
  }
    }

@media (--screen--sm-min) {

.Diagram__Bar_91mq6:nth-child(4) {
      background: rgba(0, 0, 0, 0.2);
      background: rgba(var(--rgb--black), 0.2);
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px
  }
    }

.Diagram__Bar_91mq6:nth-child(4) .Diagram__BarTitle_a0sHV {
      color: rgba(0, 0, 0, 0.2);
      color: rgba(var(--rgb--black), 0.2);
    }

.Diagram__Bar_91mq6:nth-child(4) .Diagram__BarColor_5hFka {
      background: rgba(0, 0, 0, 0.2);
      background: rgba(var(--rgb--black), 0.2);
    }

@media screen and (max-width: 767px) {

.Diagram__Bar_91mq6 {
    width: 100% !important;
    height: 52px
}
  }

@media (--screen--xs-max) {

.Diagram__Bar_91mq6 {
    width: 100% !important;
    height: 52px
}
  }

@media screen and (min-width: 768px) {

.Diagram__Bar_91mq6 {
    height: 12px;
    margin-top: auto
}
  }

@media (--screen--sm-min) {

.Diagram__Bar_91mq6 {
    height: 12px;
    margin-top: auto
}
  }

.Diagram__Bar_91mq6 .Diagram__BarTitle_a0sHV {
    white-space: nowrap;
  }

.Diagram__BarTitle_a0sHV {
  position: absolute;
  left: 0;
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Diagram__BarTitle_a0sHV {
    top: -40px
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Diagram__BarTitle_a0sHV {
    top: -40px
}
  }

@media screen and (min-width: 1366px) {

.Diagram__BarTitle_a0sHV {
    top: -46px
}
  }

@media (--screen--lg-min) {

.Diagram__BarTitle_a0sHV {
    top: -46px
}
  }

.Diagram__BarTitle_a0sHV > span {
    display: inline-block;
    margin-left: 6px;
    font-size: 16px;
    line-height: 22px;
  }

.Diagram__BarColor_5hFka,
.Diagram__BarPale_3rM2q {
  position: absolute;
  bottom: 0;
  height: 12px;
}

.Diagram__BarColor_5hFka {
  z-index: 1;
  border-right: 2px solid rgb(255, 255, 255);
  border-right: 2px solid var(--color--white);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.Diagram__BarPale_3rM2q {
  width: 100%;
  background: rgba(196, 196, 196, 0.16);
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.Diagram__Legend_weEvA {
}

@media screen and (max-width: 767px) {

.Diagram__Legend_weEvA {
    grid-row-gap: 16px
}
  }

@media (--screen--xs-max) {

.Diagram__Legend_weEvA {
    grid-row-gap: 16px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Diagram__Legend_weEvA {
    grid-row-gap: 24px
}
  }

@media (--screen--sm-only) {

.Diagram__Legend_weEvA {
    grid-row-gap: 24px
}
  }

.Diagram__LegendItem_3Mv8f {
  position: relative;
  padding-left: 17px;
}

.Diagram__LegendItem_3Mv8f::before {
    position: absolute;
    top: 5px;
    left: 0;
    width: 11px;
    height: 11px;
    border-radius: 2px;
    content: "";
  }

.Diagram__LegendItem_3Mv8f:nth-child(1)::before {
      background: rgb(0, 18, 117);
      background: var(--color--main-blue);
    }

.Diagram__LegendItem_3Mv8f:nth-child(2)::before {
      background: rgb(18, 82, 245);
      background: var(--color--second-blue);
    }

.Diagram__LegendItem_3Mv8f:nth-child(3)::before {
      background: rgb(0, 196, 255);
      background: var(--color--accent-light-blue);
    }

.Diagram__LegendItem_3Mv8f:nth-child(4)::before {
      background: rgba(0, 0, 0, 0.2);
      background: rgba(var(--rgb--black), 0.2);
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Diagram__LegendItem_3Mv8f:nth-child(-n + 3) {
      grid-column: span 3
  }
    }

@media (--screen--sm-only) {

.Diagram__LegendItem_3Mv8f:nth-child(-n + 3) {
      grid-column: span 3
  }
    }

@media screen and (min-width: 1024px) {

.Diagram__LegendItem_3Mv8f:nth-child(-n + 3) {
      grid-column: span 2
  }
    }

@media (--screen--md-min) {

.Diagram__LegendItem_3Mv8f:nth-child(-n + 3) {
      grid-column: span 2
  }
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Diagram__LegendItem_3Mv8f:last-child {
      grid-column: span 10
  }
    }

@media (--screen--sm-only) {

.Diagram__LegendItem_3Mv8f:last-child {
      grid-column: span 10
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Diagram__LegendItem_3Mv8f:last-child {
      grid-column: span 6
  }
    }

@media (--screen--md-only) {

.Diagram__LegendItem_3Mv8f:last-child {
      grid-column: span 6
  }
    }

@media screen and (min-width: 1366px) {

.Diagram__LegendItem_3Mv8f:last-child {
      grid-column: span 5
  }
    }

@media (--screen--lg-min) {

.Diagram__LegendItem_3Mv8f:last-child {
      grid-column: span 5
  }
    }
