.Content__Wrapper_fAnjy {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--4xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__ColLeft_d6Dye {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Content__ColLeft_d6Dye {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) {

.Content__ColLeft_d6Dye {
    grid-column: span 7
}
  }

@media (--screen--md-min) {

.Content__ColLeft_d6Dye {
    grid-column: span 7
}
  }

.Content__Share_28_RB {
  margin-top: var(--padding--4xl);
}
