.Content__Wrapper_1ZiNb {
  padding-bottom: var(--padding--4xl);
}

.Content__Block_tVj-c:not(:first-child) {
    margin-top: var(--padding--4xl);
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__ColLeft_2ME89 {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Content__ColLeft_2ME89 {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) {

.Content__ColLeft_2ME89 {
    grid-column: span 7
}
  }

@media (--screen--md-min) {

.Content__ColLeft_2ME89 {
    grid-column: span 7
}
  }
