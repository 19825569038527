.Hero__Wrapper_ACFdx {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

  @media screen and (min-width: 768px) {.Hero__Wrapper_ACFdx {
    --noOfColumns: 12
}
  }

  @media (--screen--sm-min) {.Hero__Wrapper_ACFdx {
    --noOfColumns: 12
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Hero__Head_3tzyv {
    grid-column: span 9
}
  }

@media (--screen--sm-only) {

.Hero__Head_3tzyv {
    grid-column: span 9
}
  }

@media screen and (min-width: 1024px) {

.Hero__Head_3tzyv {
    grid-column: span 7
}
  }

@media (--screen--md-min) {

.Hero__Head_3tzyv {
    grid-column: span 7
}
  }

.Hero__Title_2X0Pe,
.Hero__Description_1h2B7 {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

/* .StoresTitle {
  margin-bottom: var(--padding--lg);
} */
