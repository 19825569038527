.PopupGallery__Wrapper_UUKCP {
  position: relative;
}

  @media screen and (max-width: 767px) {.PopupGallery__Wrapper_UUKCP {
    margin: -16px -24px 0
}
  }

  @media (--screen--xs-max) {.PopupGallery__Wrapper_UUKCP {
    margin: -16px -24px 0
}
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {.PopupGallery__Wrapper_UUKCP {
    margin: -24px -40px 0
}
  }

  @media (--screen--sm-only) {.PopupGallery__Wrapper_UUKCP {
    margin: -24px -40px 0
}
  }

  @media screen and (min-width: 1024px) {.PopupGallery__Wrapper_UUKCP {
    margin: -32px -48px 0
}
  }

  @media (--screen--md-min) {.PopupGallery__Wrapper_UUKCP {
    margin: -32px -48px 0
}
  }

.PopupGallery__Slide_3MTD_:not(.swiper-slide-active) {
    opacity: 0 !important;
    visibility: hidden;
  }

@media screen and (min-width: 1024px) {

.PopupGallery__SliderInner_gDHza {
    display: flex
}
  }

@media (--screen--md-min) {

.PopupGallery__SliderInner_gDHza {
    display: flex
}
  }

.PopupGallery__ImageWrapper_aHAaJ {
  position: relative;
  background-color: rgb(0, 0, 0);
  background-color: var(--color--black);
}

@media screen and (max-width: 767px) {

.PopupGallery__ImageWrapper_aHAaJ {
    min-width: 414px

    /* min-height: 279px; */
}
  }

@media (--screen--xs-max) {

.PopupGallery__ImageWrapper_aHAaJ {
    min-width: 414px

    /* min-height: 279px; */
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.PopupGallery__ImageWrapper_aHAaJ {
    width: 720px;
    min-height: 430px
}
  }

@media (--screen--sm-only) {

.PopupGallery__ImageWrapper_aHAaJ {
    width: 720px;
    min-height: 430px
}
  }

@media screen and (min-width: 1024px) {

.PopupGallery__ImageWrapper_aHAaJ {
    flex-shrink: 0
}
  }

@media (--screen--md-min) {

.PopupGallery__ImageWrapper_aHAaJ {
    flex-shrink: 0
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.PopupGallery__ImageWrapper_aHAaJ {
    width: 587px;
    min-height: 395px
}
  }

@media (--screen--md-only) {

.PopupGallery__ImageWrapper_aHAaJ {
    width: 587px;
    min-height: 395px
}
  }

@media screen and (min-width: 1366px) {

.PopupGallery__ImageWrapper_aHAaJ {
    width: 772px;
    min-height: 540px
}
  }

@media (--screen--lg-min) {

.PopupGallery__ImageWrapper_aHAaJ {
    width: 772px;
    min-height: 540px
}
  }

.PopupGallery__Image_2WEzR {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.PopupGallery__ColRight_1ekPa {
  padding-top: 24px;
  padding-bottom: 112px;
}

@media screen and (max-width: 767px) {

.PopupGallery__ColRight_1ekPa {
    padding-right: 24px;
    padding-left: 24px
}
  }

@media (--screen--xs-max) {

.PopupGallery__ColRight_1ekPa {
    padding-right: 24px;
    padding-left: 24px
}
  }

@media screen and (min-width: 768px) {

.PopupGallery__ColRight_1ekPa {
    padding-right: 32px;
    padding-left: 32px
}
  }

@media (--screen--sm-min) {

.PopupGallery__ColRight_1ekPa {
    padding-right: 32px;
    padding-left: 32px
}
  }

.PopupGallery__Description_NWjFH {
  margin-top: 24px;
  margin-top: var(--padding--xl);
  word-break: break-word;
}

.PopupGallery__Date_2dO_U {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

.PopupGallery__Arrow_3Qy1Y {
  --arrowMargin: -40px;

  position: absolute !important;
}

.PopupGallery__ArrowLeft_23dY8 {
  left: var(--arrowMargin);
  transform: translateY(-50%) rotate(180deg) !important;
}

.PopupGallery__ArrowRight_1FFOq {
  right: var(--arrowMargin);
}

.PopupGallery__Navigation_3A01r {
  position: absolute;
  z-index: 10;
  bottom: 32px;
  display: flex;
  margin: 0 auto;
  margin-top: 24px;
}

@media screen and (max-width: 1023px) {

.PopupGallery__Navigation_3A01r {
    left: 50%;
    transform: translateX(-50%)
}
  }

@media (--screen--sm-max) {

.PopupGallery__Navigation_3A01r {
    left: 50%;
    transform: translateX(-50%)
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.PopupGallery__Navigation_3A01r {
    right: 170px
}
  }

@media (--screen--md-only) {

.PopupGallery__Navigation_3A01r {
    right: 170px
}
  }

@media screen and (min-width: 1366px) {

.PopupGallery__Navigation_3A01r {
    right: 264px
}
  }

@media (--screen--lg-min) {

.PopupGallery__Navigation_3A01r {
    right: 264px
}
  }

.PopupGallery__Pagination_3E_Sd {
  margin: 0 16px;
}
