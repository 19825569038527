.Hero__Wrapper_r8T9j {
  position: relative;
  overflow: hidden;
  padding-bottom: var(--padding--4xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Hero__Head_24Ksk {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Hero__Head_24Ksk {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Hero__Head_24Ksk {
    grid-column: span 6
}
  }

@media (--screen--md-only) {

.Hero__Head_24Ksk {
    grid-column: span 6
}
  }

@media screen and (min-width: 1366px) {

.Hero__Head_24Ksk {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.Hero__Head_24Ksk {
    grid-column: span 5
}
  }

.Hero__Title_v8dP3 {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Hero__Factoids_3SC0V {
  margin-top: var(--padding--4xl);
}
