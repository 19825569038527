.List__Item_23RXQ {
}

  .List__Item_23RXQ:hover {
    cursor: pointer;
  }

  .List__Item_23RXQ:hover .List__Title_1cbGG {
      color: rgb(18, 82, 245);
      color: var(--color--second-blue);
    }

  .List__Item_23RXQ:hover .List__HeadingLink_7DKo8 > span::after {
          cursor: pointer;
          opacity: 1;
          transform: translateX(6px);
        }

  .List__Item_23RXQ:not(:last-child) {
    border-bottom: 1px solid rgba(18, 82, 245, 0.3);
    border-bottom: 1px solid rgba(var(--rgb--second-blue), 0.3);
  }

  @media screen and (max-width: 767px) {

  .List__Item_23RXQ:not(:last-child) {
      margin-bottom: 32px
  }
    }

  @media (--screen--xs-max) {

  .List__Item_23RXQ:not(:last-child) {
      margin-bottom: 32px
  }
    }

  @media screen and (min-width: 768px) {

  .List__Item_23RXQ:not(:last-child) {
      margin-bottom: 40px
  }
    }

  @media (--screen--sm-min) {

  .List__Item_23RXQ:not(:last-child) {
      margin-bottom: 40px
  }
    }

  @media screen and (max-width: 1023px) {

  .List__Item_23RXQ:not(:last-child) {
      padding-bottom: 40px
  }
    }

  @media (--screen--sm-max) {

  .List__Item_23RXQ:not(:last-child) {
      padding-bottom: 40px
  }
    }

  @media screen and (min-width: 1024px) {

  .List__Item_23RXQ:not(:last-child) {
      padding-bottom: 48px
  }
    }

  @media (--screen--md-min) {

  .List__Item_23RXQ:not(:last-child) {
      padding-bottom: 48px
  }
    }

@media screen and (min-width: 768px) {

.List__Heading_oqC6x {
    grid-row: 1
}
  }

@media (--screen--sm-min) {

.List__Heading_oqC6x {
    grid-row: 1
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.List__Heading_oqC6x {
    grid-column: 1 / span 5
}
  }

@media (--screen--sm-only) {

.List__Heading_oqC6x {
    grid-column: 1 / span 5
}
  }

@media screen and (min-width: 1024px) {

.List__Heading_oqC6x {
    grid-column: 1 / span 4
}
  }

@media (--screen--md-min) {

.List__Heading_oqC6x {
    grid-column: 1 / span 4
}
  }

.List__Title_1cbGG {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
  transition: color ease-in-out 0.3s;
}

.List__HeadingLink_7DKo8 {
}

.List__FooterLink_39x5a {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

.List__Logo_1lYsB {
}

@media screen and (min-width: 1024px) {

.List__Logo_1lYsB {
    grid-column: 5 / span 2
}
  }

@media (--screen--md-min) {

.List__Logo_1lYsB {
    grid-column: 5 / span 2
}
  }

.List__Logo_1lYsB > img {
    margin: 0 auto;
  }

@media screen and (min-width: 768px) {

.List__Description__NGKA {
    grid-column: 7 / span 6
}
  }

@media (--screen--sm-min) {

.List__Description__NGKA {
    grid-column: 7 / span 6
}
  }

.List__Description__NGKA * + p,
  .List__Description__NGKA p + * {
    margin-top: 24px;
  }

.List__Fact_3EEmg {
  display: block;
  margin-bottom: 8px;
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  font-weight: 500;
}
