.UsefullLinks__Wrapper_1Er_U {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--5xl);
}

  /* Для печати */

  @media print {.UsefullLinks__Wrapper_1Er_U {
    display: none
}
  }

.UsefullLinks__Title_3LvsI {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

.UsefullLinks__Links_weTHT {
  grid-row-gap: 32px;
  grid-row-gap: var(--padding--2xl);
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

.UsefullLinks__Links_weTHT {
    --noOfColumns: 2
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.UsefullLinks__Links_weTHT {
    --noOfColumns: 2
}
  }

@media screen and (min-width: 1366px) {

.UsefullLinks__Links_weTHT {
    display: flex;

    /* justify-content: space-between; */
    justify-content: flex-start;
    grid-gap: 64px;
    gap: 64px
}
  }

@media (--screen--lg-min) {

.UsefullLinks__Links_weTHT {
    display: flex;

    /* justify-content: space-between; */
    justify-content: flex-start;
    grid-gap: 64px;
    gap: 64px
}
  }

.UsefullLinks__LinkWrapper_daQJq {
  position: relative;
  display: flex;
  height: 45px;
  flex-shrink: 0;
  align-items: center;
  padding-left: 62px;
}

.UsefullLinks__LinkWrapper_daQJq::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 56px;
    height: 45px;
    background-repeat: no-repeat;
    content: "";
    transform: translateY(-50%);
  }

.UsefullLinks__LinkWrapper_daQJq:nth-child(1)::before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjMzNSAyMi40NTRWMGwyMC4wNDcgMTEuMjI3IDE5Ljk1MyAxMS4yMjctMTkuOTUzIDExLjMxOUwxNS4zMzUgNDVWMjIuNDU0eiIgZmlsbD0iIzVGMUVFOSIvPjxwYXRoIGQ9Ik0yNS4zMzUgMTRsLTEwIDExLjAxOUwxMS41IDIxIDEgMzIiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48cGF0aCBkPSJNMjAuODM1IDEzLjVoNXY1IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS41Ii8+PC9zdmc+Cg==);
    }

.UsefullLinks__LinkWrapper_daQJq:nth-child(2)::before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1IDIyLjQ1NFYwbDIwLjA0NyAxMS4yMjdMNTUgMjIuNDU0IDM1LjA0NyAzMy43NzMgMTUgNDVWMjIuNDU0eiIgZmlsbD0iIzEyNTJGNSIvPjxwYXRoIGQ9Ik0yNSAyM0g1TTExIDE1SDVNMjAgMzFINSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPjwvc3ZnPg==);
    }

.UsefullLinks__LinkWrapper_daQJq:nth-child(3)::before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1IDIyLjQ1NFYwbDIwLjA0NyAxMS4yMjdMNTUgMjIuNDU0IDM1LjA0NyAzMy43NzMgMTUgNDVWMjIuNDU0eiIgZmlsbD0iIzAwQTRGMyIvPjxwYXRoIGQ9Ik0xMSAyMHYxM00zIDI4djVNMTkgMTJ2MjEiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48L3N2Zz4=);
    }

.UsefullLinks__LinkWrapper_daQJq:nth-child(4)::before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjA0OCAyMi40NTRWMGwyMC4wNDcgMTEuMjI3IDE5Ljk1MyAxMS4yMjctMTkuOTUzIDExLjMxOUwxNS4wNDggNDVWMjIuNDU0eiIgZmlsbD0iIzA4OEI3QiIvPjxwYXRoIGQ9Ik0xMS4wNTggMjkuMDAxYTkuMDEyIDkuMDEyIDAgMTAtLjA0Ni0xOC4wMjQgOS4wMTIgOS4wMTIgMCAwMC4wNDYgMTguMDI0ek0xNy41NDggMjYuNWw1LjUgNS41IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz48L3N2Zz4K);
    }

.UsefullLinks__LinkWrapper_daQJq > a {
    border-bottom: 1px solid transparent;
    transition: border-color ease-in-out 0.3s;
  }

.UsefullLinks__LinkWrapper_daQJq > a::after {
      content: none;
    }

.UsefullLinks__LinkWrapper_daQJq > a:hover {
      border-color: rgba(255, 255, 255, 0.5);
      border-color: rgba(var(--rgb--white), 0.5);
    }
