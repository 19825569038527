.FundsProjectCard__Wrapper_2bE9t {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

  @media screen and (max-width: 767px) {.FundsProjectCard__Wrapper_2bE9t {
    padding: 16px 32px
}
  }

  @media (--screen--xs-max) {.FundsProjectCard__Wrapper_2bE9t {
    padding: 16px 32px
}
  }

  @media screen and (min-width: 768px) {.FundsProjectCard__Wrapper_2bE9t {
    padding: 24px 32px
}
  }

  @media (--screen--sm-min) {.FundsProjectCard__Wrapper_2bE9t {
    padding: 24px 32px
}
  }

  @media screen and (min-width: 1366px) {.FundsProjectCard__Wrapper_2bE9t {
    min-height: 270px
}
  }

  @media (--screen--lg-min) {.FundsProjectCard__Wrapper_2bE9t {
    min-height: 270px
}
  }

  @media print {.FundsProjectCard__Wrapper_2bE9t {
    padding: 20px
}
  }

@media screen and (max-width: 767px) {

.FundsProjectCard__ShowMore_1j9SG {
    --rotate: -45deg;

    position: relative
}

    .FundsProjectCard__ShowMore_1j9SG::before,
    .FundsProjectCard__ShowMore_1j9SG::after {
      position: absolute;
      top: 32px;
      right: 36px;
      width: 8px;
      height: 1px;
      background: rgb(18, 82, 245);
      background: var(--color--second-blue);
      content: "";
      transition: transform ease-in-out 0.3s;
      will-change: transform;
    }

    .FundsProjectCard__ShowMore_1j9SG::after {
      transform: translateX(-3px) rotate(calc(var(--rotate) * -1));
    }

    .FundsProjectCard__ShowMore_1j9SG::before {
      transform: translateX(2px) rotate(var(--rotate));
    }

    .FundsProjectCard__ShowMore_1j9SG.FundsProjectCard___open_C13AA {
      --rotate: 45deg;
    }
  }

@media (--screen--xs-max) {

.FundsProjectCard__ShowMore_1j9SG {
    --rotate: -45deg;

    position: relative
}

    .FundsProjectCard__ShowMore_1j9SG::before,
    .FundsProjectCard__ShowMore_1j9SG::after {
      position: absolute;
      top: 32px;
      right: 36px;
      width: 8px;
      height: 1px;
      background: rgb(18, 82, 245);
      background: var(--color--second-blue);
      content: "";
      transition: transform ease-in-out 0.3s;
      will-change: transform;
    }

    .FundsProjectCard__ShowMore_1j9SG::after {
      transform: translateX(-3px) rotate(calc(var(--rotate) * -1));
    }

    .FundsProjectCard__ShowMore_1j9SG::before {
      transform: translateX(2px) rotate(var(--rotate));
    }

    .FundsProjectCard__ShowMore_1j9SG.FundsProjectCard___open_C13AA {
      --rotate: 45deg;
    }
  }

.FundsProjectCard__Text_xWKEP {
  margin-bottom: 24px;
}

@media print {

.FundsProjectCard__Text_xWKEP {
    margin-bottom: 0
}
  }

.FundsProjectCard__Text_xWKEP p:not(:last-child) {
      margin-bottom: 24px;
    }

.FundsProjectCard__Bold_sc4Gl {
  font-weight: bold;
}

.FundsProjectCard__Logo_105B5 {
  height: 24px;
  margin-top: auto;
}

.FundsProjectCard__Logo_105B5 img {
    max-height: 100%;
  }

.FundsProjectCard__Heading_2yfE9 {
  margin-bottom: 12px;
  margin-bottom: var(--padding--md);
}

.FundsProjectCard__Title_2ejuV {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
  font-weight: 500;
  transition: color 0.3s;
}
