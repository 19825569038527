.Structure__Wrapper_3_aG_ {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--5xl);
}

  @media print {.Structure__Wrapper_3_aG_ {
    display: none
}
  }

.Structure__Title_36y6n {
  margin-bottom: 40px;
}
