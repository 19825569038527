@media screen and (max-width: 1365px) {.Diagram__Name_3pmby {
    margin-bottom: 16px
}
  }
  @media (--screen--md-max) {.Diagram__Name_3pmby {
    margin-bottom: 16px
}
  }
  @media screen and (min-width: 1366px) {.Diagram__Name_3pmby {
    margin-bottom: 24px
}
  }
  @media (--screen--lg-min) {.Diagram__Name_3pmby {
    margin-bottom: 24px
}
  }

.Diagram__ChartWrapper_3naTl {
  display: flex;
}

@media screen and (min-width: 1366px) {

.Diagram__ChartWrapper_3naTl {
    align-items: center
}
  }

@media (--screen--lg-min) {

.Diagram__ChartWrapper_3naTl {
    align-items: center
}
  }

.Diagram__PieChart_1-713 {
  flex-shrink: 0;
  margin-right: 24px;
}

@media screen and (max-width: 767px) {

.Diagram__PieChart_1-713 {
    width: 119px;
    height: 119px
}
  }

@media (--screen--xs-max) {

.Diagram__PieChart_1-713 {
    width: 119px;
    height: 119px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Diagram__PieChart_1-713 {
    width: 82px;
    height: 82px
}
  }

@media (--screen--sm-only) {

.Diagram__PieChart_1-713 {
    width: 82px;
    height: 82px
}
  }

@media screen and (min-width: 1024px) {

.Diagram__PieChart_1-713 {
    width: 112px;
    height: 112px
}
  }

@media (--screen--md-min) {

.Diagram__PieChart_1-713 {
    width: 112px;
    height: 112px
}
  }

.Diagram__Legend_3ILm9 {
  grid-row-gap: 16px;
}

.Diagram__LegendItem_2SSEN {
  position: relative;
  padding-left: 46px;
}

.Diagram__LegendItem_2SSEN::before {
    position: absolute;
    top: 0;
    left: 0;
    content: attr(data-content) "%";
  }

.Diagram__LegendItem_2SSEN:nth-child(1)::before {
      color: rgb(0, 18, 117);
      color: var(--color--main-blue);
    }

.Diagram__LegendItem_2SSEN:nth-child(2)::before {
      color: rgb(18, 82, 245);
      color: var(--color--second-blue);
    }

.Diagram__LegendItem_2SSEN:nth-child(3)::before {
      color: rgb(0, 196, 255);
      color: var(--color--accent-light-blue);
    }
