.Akkredit__Card_2f6bf {
  padding: 24px 40px 40px;
  padding: var(--padding--xl) 40px var(--padding--3xl);
    margin-bottom: 30px;
}

@media screen and (max-width: 767px) {

.Akkredit__Title_8LeWq {
    margin-bottom: 16px
}
  }

@media (--screen--xs-max) {

.Akkredit__Title_8LeWq {
    margin-bottom: 16px
}
  }

@media screen and (min-width: 768px) {

.Akkredit__Title_8LeWq {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

@media (--screen--sm-min) {

.Akkredit__Title_8LeWq {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

.Akkredit__Text_1rTpL:not(:last-child) {
      padding-bottom: 24px;
      padding-bottom: var(--padding--xl);
    }