.AddingEventCard__Wrapper_zjL7J {
  padding-top: 16px;
  padding-bottom: 24px;
}

  @media screen and (max-width: 1023px) {.AddingEventCard__Wrapper_zjL7J {
    padding-right: 24px;
    padding-left: 24px
}
  }

  @media (--screen--sm-max) {.AddingEventCard__Wrapper_zjL7J {
    padding-right: 24px;
    padding-left: 24px
}
  }

  @media screen and (min-width: 1024px) {.AddingEventCard__Wrapper_zjL7J {
    padding-right: 32px;
    padding-left: 32px
}
  }

  @media (--screen--md-min) {.AddingEventCard__Wrapper_zjL7J {
    padding-right: 32px;
    padding-left: 32px
}
  }

* + .AddingEventCard__Wrapper_zjL7J,
div.AddingEventCard__Wrapper_zjL7J + * {
  margin-top: 24px;
}

.AddingEventCard__City_15jKS {
  margin-bottom: 12px;
  margin-bottom: var(--padding--md);
}

.AddingEventCard__AddToCalendar_22ZMS {
  width: 100%;
  padding: 0;
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

.AddingEventCard__Link_rGqVQ {
  margin-top: 16px;
  margin-top: var(--padding--lg);
}
