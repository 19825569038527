.LeadHead__Wrapper_PBPhk {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

.LeadHead__Title_23S-x {
  padding-top: var(--padding--4xl);
  margin-top: calc(var(--padding--4xl) * -1);
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

@media screen and (min-width: 1024px) {

.LeadHead__Subtitle_7RlUJ {
    width: 65%
}
  }

@media (--screen--md-min) {

.LeadHead__Subtitle_7RlUJ {
    width: 65%
}
  }

@media screen and (min-width: 1366px) {

.LeadHead__Subtitle_7RlUJ {
    width: 50%
}
  }

@media (--screen--lg-min) {

.LeadHead__Subtitle_7RlUJ {
    width: 50%
}
  }

.LeadHead__Link_1gOcA {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}
