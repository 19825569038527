.List__List_2Ezk9 {
  padding-bottom: var(--padding--4xl);
  border-bottom: 1px solid rgba(18, 82, 245, 0.2);
  border-bottom: 1px solid rgba(var(--rgb--second-blue), 0.2);
}

  @media screen and (max-width: 767px) {.List__List_2Ezk9 {
    grid-row-gap: 24px
}
  }

  @media (--screen--xs-max) {.List__List_2Ezk9 {
    grid-row-gap: 24px
}
  }

  @media screen and (min-width: 768px) {.List__List_2Ezk9 {
    grid-row-gap: 32px
}
  }

  @media (--screen--sm-min) {.List__List_2Ezk9 {
    grid-row-gap: 32px
}
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {.List__List_2Ezk9 {
    --noOfColumns: 2
}
  }

  @media (--screen--sm-only) {.List__List_2Ezk9 {
    --noOfColumns: 2
}
  }

  @media screen and (min-width: 1024px) {.List__List_2Ezk9 {
    --noOfColumns: 3
}
  }

  @media (--screen--md-min) {.List__List_2Ezk9 {
    --noOfColumns: 3
}
  }

.List__Card_3Q9zm {
  padding: 24px 32px var(--padding--4xl);
  padding: var(--padding--xl) 32px var(--padding--4xl);
  font-weight: 500;
}

.List__Note_35b47 {
  padding-top: 32px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.List__Note_35b47 {
    grid-column: 10 span
}
  }

@media (--screen--sm-only) {

.List__Note_35b47 {
    grid-column: 10 span
}
  }

@media screen and (min-width: 1024px) {

.List__Note_35b47 {
    grid-column: 8 span
}
  }

@media (--screen--md-min) {

.List__Note_35b47 {
    grid-column: 8 span
}
  }

@media screen and (max-width: 1365px) {

.List__Note_35b47 p:not(:last-child) {
        margin-bottom: 8px
    }
      }

@media (--screen--md-max) {

.List__Note_35b47 p:not(:last-child) {
        margin-bottom: 8px
    }
      }

@media screen and (min-width: 1366px) {

.List__Note_35b47 p:not(:last-child) {
        margin-bottom: 24px
    }
      }

@media (--screen--lg-min) {

.List__Note_35b47 p:not(:last-child) {
        margin-bottom: 24px
    }
      }

.List__Note_35b47 a {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.List__Lead_31RLI {
    grid-column: 7 span
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.List__Lead_31RLI {
    grid-column: 7 span
}
  }

@media screen and (min-width: 1366px) {

.List__Lead_31RLI {
    grid-column: 5 span
}
  }

@media (--screen--lg-min) {

.List__Lead_31RLI {
    grid-column: 5 span
}
  }
