.Content__Wrapper_1rrry {
  position: relative;
}

.Content__Inner_1Prut {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--5xl);
}

@media screen and (min-width: 768px) {

.Content__Inner_1Prut {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.Content__Inner_1Prut {
    --noOfColumns: 12
}
  }

.Content__Content_1Fb-k {
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Content_1Fb-k {
    grid-column: 3 / span 8
}
  }

@media (--screen--sm-only) {

.Content__Content_1Fb-k {
    grid-column: 3 / span 8
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Content__Content_1Fb-k {
    grid-column: 3 / span 7
}
  }

@media (--screen--md-only) {

.Content__Content_1Fb-k {
    grid-column: 3 / span 7
}
  }

@media screen and (min-width: 1366px) {

.Content__Content_1Fb-k {
    grid-column: 4 / span 6
}
  }

@media (--screen--lg-min) {

.Content__Content_1Fb-k {
    grid-column: 4 / span 6
}
  }

.Content__Description_3bFFi {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

.Content__Button_1bE7T {
  margin-top: 32px;
  margin-top: var(--padding--2xl);
}

@media screen and (max-width: 767px) {

.Content__Policy_2g8hu {
    margin-top: 113px
}
  }

@media (--screen--xs-max) {

.Content__Policy_2g8hu {
    margin-top: 113px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Policy_2g8hu {
    margin-top: 194px
}
  }

@media (--screen--sm-only) {

.Content__Policy_2g8hu {
    margin-top: 194px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Content__Policy_2g8hu {
    margin-top: 104px
}
  }

@media (--screen--md-only) {

.Content__Policy_2g8hu {
    margin-top: 104px
}
  }

@media screen and (min-width: 1366px) {

.Content__Policy_2g8hu {
    margin-top: 96px
}
  }

@media (--screen--lg-min) {

.Content__Policy_2g8hu {
    margin-top: 96px
}
  }
