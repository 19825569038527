.Hero__Wrapper_dqiYS {
  margin-bottom: var(--padding--4xl);
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Hero__Head_2Ug55 {
    grid-column: span 7
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Hero__Head_2Ug55 {
    grid-column: span 7
}
  }

@media screen and (min-width: 1366px) {

.Hero__Head_2Ug55 {
    grid-column: span 6
}
  }

@media (--screen--lg-min) {

.Hero__Head_2Ug55 {
    grid-column: span 6
}
  }

/* Для печати */

@media print {

.Hero__Head_2Ug55 {
    margin-bottom: 40px
}
  }

.Hero__Title_2CrPg {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}
