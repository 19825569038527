.MapLegend__Legend_2uSjb {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: calc(100% + 20px);
  display: none;
  width: 280px;
  max-height: 190px;
  box-sizing: border-box;
  flex-direction: column;
  padding: 16px 24px;
  border: 1px solid #e9efff;
  background-color: rgb(255, 255, 255);
  background-color: var(--color--white);
  border-radius: 2px;
  overflow-y: auto;
}

  @media screen and (min-width: 768px) {.MapLegend__Legend_2uSjb {
    display: flex
}
  }

  @media (--screen--sm-min) {.MapLegend__Legend_2uSjb {
    display: flex
}
  }

  @media screen and (min-width: 1366px) {.MapLegend__Legend_2uSjb {
    top: -480px;
    right: 0;
    bottom: auto;
    left: auto;
    width: 270px;
    max-height: 400px
}
  }

  @media (--screen--lg-min) {.MapLegend__Legend_2uSjb {
    top: -480px;
    right: 0;
    bottom: auto;
    left: auto;
    width: 270px;
    max-height: 400px
}
  }

@media screen and (min-width: 1366px) {

.MapLegend__LegendFunds_2zI04 {
    top: -450px
}
  }

@media (--screen--lg-min) {

.MapLegend__LegendFunds_2zI04 {
    top: -450px
}
  }

@media screen and (min-width: 1366px) {

.MapLegend__LegendCareer_3rKSO {
    top: -630px
}
  }

@media (--screen--lg-min) {

.MapLegend__LegendCareer_3rKSO {
    top: -630px
}
  }

.MapLegend__LegendTitle_2z26L {
  color: rgb(0, 0, 0);
  color: var(--color--black);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.MapLegend__LegendInfo_33juW {
  color: rgb(0, 0, 0);
  color: var(--color--black);
  font-size: 16px;
  line-height: 22px;
  opacity: 0.3;
}

.MapLegend__LegendText_X720U {
  margin-top: 8px;
  color: rgb(0, 0, 0);
  color: var(--color--black);
  font-size: 12px;
  line-height: 16px;
}
