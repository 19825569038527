.Mission__Wrapper_3XYFc {
  padding: var(--padding--5xl) 0;
}

  /* Для печати */

  @media print {.Mission__Wrapper_3XYFc {
    margin-top: 20px;
    color: #000
}
  }

.Mission__Title_3c87f {
  padding-top: var(--padding--5xl);
  margin-top: calc(var(--padding--5xl) * -1);
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
  grid-column: span 12;
}

/* Для печати */

@media print {

.Mission__Title_3c87f {
    margin-bottom: 10px
}
  }

.Mission__Description_16f6J {
  font-weight: 300;
}

@media screen and (max-width: 767px) {

.Mission__Description_16f6J {
    font-size: 26px;
    grid-column: span 12;
    line-height: 32px
}
  }

@media (--screen--xs-max) {

.Mission__Description_16f6J {
    font-size: 26px;
    grid-column: span 12;
    line-height: 32px
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Mission__Description_16f6J {
    grid-column: span 9
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Mission__Description_16f6J {
    grid-column: span 9
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Mission__Description_16f6J {
    font-size: 40px;
    line-height: 48px
}
  }

@media (--screen--sm-only) {

.Mission__Description_16f6J {
    font-size: 40px;
    line-height: 48px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Mission__Description_16f6J {
    font-size: 48px;
    line-height: 56px
}
  }

@media (--screen--md-only) {

.Mission__Description_16f6J {
    font-size: 48px;
    line-height: 56px
}
  }

@media screen and (min-width: 1366px) {

.Mission__Description_16f6J {
    font-size: 56px;
    grid-column: span 9;
    line-height: 64px
}
  }

@media (--screen--lg-min) {

.Mission__Description_16f6J {
    font-size: 56px;
    grid-column: span 9;
    line-height: 64px
}
  }
