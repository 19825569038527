* + .LinksStack__Wrapper_3vtOl,
div.LinksStack__Wrapper_3vtOl + * {
  margin-top: 32px !important;
  margin-top: var(--padding--2xl) !important;
}

.LinksStack__Wrapper_3vtOl h4, div.LinksStack__Wrapper_3vtOl h4 {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg);
  }

.LinksStack__Wrapper_3vtOl p, div.LinksStack__Wrapper_3vtOl p {
    margin-top: 0;
  }

.LinksStack__Wrapper_3vtOl p:not(:last-child), div.LinksStack__Wrapper_3vtOl p:not(:last-child) {
      margin-bottom: 16px;
      margin-bottom: var(--padding--lg);
    }
