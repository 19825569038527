.history__Wrapper_1F5fY {
  overflow: hidden;
  padding-top: var(--padding--4xl);
  padding-right: 0;
  padding-bottom: var(--padding--4xl);
  padding-left: 0;
}

  @media print {.history__Wrapper_1F5fY {
    color: #000
}
  }

.history__Title_3TdAo {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}
