.Contacts__Wrapper_3j7Un {
  padding: var(--padding--4xl) 0 var(--padding--5xl);
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
}

  /* Для печати */

  @media print {.Contacts__Wrapper_3j7Un {
    margin-top: 40px
}
  }

.Contacts__Title_jN2oE {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

/* Для печати */

@media print {

.Contacts__Title_jN2oE {
    font-weight: 700
}
  }

.Contacts__Links_1jsx3 {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Contacts__Address_13MoN {
    grid-column: span 6
}
  }

@media (--screen--sm-only) {

.Contacts__Address_13MoN {
    grid-column: span 6
}
  }

@media screen and (min-width: 1024px) {

.Contacts__Address_13MoN {
    grid-column: span 4
}
  }

@media (--screen--md-min) {

.Contacts__Address_13MoN {
    grid-column: span 4
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Contacts__HelpDesk_nnIyH {
    margin-bottom: 40px;
    grid-column: span 12;
    grid-row: 1
}
  }

@media (--screen--sm-only) {

.Contacts__HelpDesk_nnIyH {
    margin-bottom: 40px;
    grid-column: span 12;
    grid-row: 1
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Contacts__HelpDesk_nnIyH {
    grid-column: 5 / span 4
}
  }

@media (--screen--md-only) {

.Contacts__HelpDesk_nnIyH {
    grid-column: 5 / span 4
}
  }

@media screen and (min-width: 1366px) {

.Contacts__HelpDesk_nnIyH {
    grid-column: 6 / span 3
}
  }

@media (--screen--lg-min) {

.Contacts__HelpDesk_nnIyH {
    grid-column: 6 / span 3
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Contacts__Fax_2vsKO {
    grid-column: 7 / span 3
}
  }

@media (--screen--sm-only) {

.Contacts__Fax_2vsKO {
    grid-column: 7 / span 3
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Contacts__Fax_2vsKO {
    grid-column: 9 / span 3
}
  }

@media (--screen--md-only) {

.Contacts__Fax_2vsKO {
    grid-column: 9 / span 3
}
  }

@media screen and (min-width: 1366px) {

.Contacts__Fax_2vsKO {
    grid-column: 10 / span 2
}
  }

@media (--screen--lg-min) {

.Contacts__Fax_2vsKO {
    grid-column: 10 / span 2
}
  }

.Contacts__Name_w0zve {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}
