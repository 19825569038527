.Content__Wrapper_1-1uH {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--5xl);
}

.Content__Block_1MG3V:not(:last-child) {
    margin-bottom: var(--padding--4xl);
  }

.Content__Title_25hBu {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

.Content__Masonry_IKTE3 { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  width: auto;
  grid-column-gap: 32px;
  -moz-column-gap: 32px;
       column-gap: 32px;
}

.Content__MasonryColumn_lyd5G > div {
  margin-bottom: 32px;
}
