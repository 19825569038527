.Cost__Cost_2v74k {
  padding-top: 24px;
}

  @media screen and (max-width: 767px) {.Cost__Cost_2v74k {
    padding-right: 32px;
    padding-bottom: 40px;
    padding-left: 32px
}
  }

  @media (--screen--xs-max) {.Cost__Cost_2v74k {
    padding-right: 32px;
    padding-bottom: 40px;
    padding-left: 32px
}
  }

  @media screen and (min-width: 768px) {.Cost__Cost_2v74k {
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 40px
}
  }

  @media (--screen--sm-min) {.Cost__Cost_2v74k {
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 40px
}
  }

.Cost__Wrapper_2lEyP:not(:last-child) {
    padding-bottom: 24px;
    padding-bottom: var(--padding--xl);
    border-bottom: 1px solid rgba(18, 82, 245, 0.2);
    border-bottom: 1px solid rgba(var(--rgb--second-blue), 0.2);
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl);
  }

.Cost__Title_53ltQ {
  display: flex;
  margin-bottom: 16px;
}

.Cost__Date_kTTFk {
  margin-left: auto;
}

.Cost__Footer_27gDW {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.Cost__Total_3cK1k {
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  font-weight: 300;
  text-align: right;
}

@media screen and (max-width: 1365px) {

.Cost__Total_3cK1k {
    font-size: 26px;
    line-height: 32px
}
  }

@media (--screen--md-max) {

.Cost__Total_3cK1k {
    font-size: 26px;
    line-height: 32px
}
  }

@media screen and (min-width: 1366px) {

.Cost__Total_3cK1k {
    font-size: 32px;
    line-height: 36px
}
  }

@media (--screen--lg-min) {

.Cost__Total_3cK1k {
    font-size: 32px;
    line-height: 36px
}
  }

.Cost__Position_BNvwK {
  margin-top: 4px;
  text-align: right;
}

.Cost__PositionUp_oeKrx {
  color: rgb(0, 198, 91);
  color: var(--color--green);
}

.Cost__PositionDown_20725 {
  color: rgb(249, 57, 57);
  color: var(--color--red);
}

.Cost__Ticker_1DlMG {
  margin-bottom: 4px;
}

@media screen and (max-width: 767px) {

.Cost__Ticker_1DlMG {
    width: 150px
}
  }

@media (--screen--xs-max) {

.Cost__Ticker_1DlMG {
    width: 150px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Cost__Ticker_1DlMG {
    width: 450px
}
  }

@media (--screen--sm-only) {

.Cost__Ticker_1DlMG {
    width: 450px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Cost__Ticker_1DlMG {
    width: 200px
}
  }

@media (--screen--md-only) {

.Cost__Ticker_1DlMG {
    width: 200px
}
  }

@media screen and (min-width: 1366px) {

.Cost__Ticker_1DlMG {
    width: 300px
}
  }

@media (--screen--lg-min) {

.Cost__Ticker_1DlMG {
    width: 300px
}
  }
