.Events__Wrapper_xY-gh {
  position: relative;
}

  .Events__Wrapper_xY-gh[data-theme="dark"] {
    --accent-rgb: var(--rgb--accent-light-blue);
  }

  .Events__Wrapper_xY-gh[data-theme="light"] {
    --accent-rgb: var(--rgb--second-blue);
  }

  /* Для печати */

  @media print {.Events__Wrapper_xY-gh {
    display: flex
}
  }

.Events__Events_xx9ER {
}

@media screen and (min-width: 768px) {

.Events__Events_xx9ER {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.Events__Events_xx9ER {
    --noOfColumns: 12
}
  }

.Events__Event_11nG_ {
  position: relative;
  z-index: 1;
  cursor: pointer;
}

@media screen and (max-width: 767px) {

.Events__Event_11nG_:hover .Events__Title_pYp5i > span::after {
            opacity: 1;
            transform: translateX(6px)
        }
          }

@media (--screen--xs-max) {

.Events__Event_11nG_:hover .Events__Title_pYp5i > span::after {
            opacity: 1;
            transform: translateX(6px)
        }
          }

@media screen and (min-width: 768px) {

.Events__Event_11nG_ {
    padding-left: 24px;
    grid-column: 4 span;
    pointer-events: none
}

    .Events__Event_11nG_::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3px;
      background: linear-gradient(180deg, #d6ebff 0%, #1252f5 22.59%, #d6ebff 60.42%, #d6ebff 100%);
      border-radius: 100px;
      content: "";
    }

    .Events__Event_11nG_::after {
      position: absolute;
      z-index: 1;
      left: -10px;
      width: 23px;
      height: 23px;
      background-color: rgb(18, 82, 245);
      background-color: var(--color--second-blue);
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0zLjUgMHY3TTcgMy41SDAiIHN0cm9rZT0iI2ZmZiIvPjwvc3ZnPg==);
      background-position: 50%;
      background-repeat: no-repeat;
      border-radius: 50%;
      content: "";
      pointer-events: all;
      transition: background-color 0.3s, box-shadow 0.3s;
    }
      .Events__Event_11nG_:hover::after {
        background-color: rgba(var(--accent-rgb), 1);
        box-shadow: 0 0 0 16px rgba(var(--accent-rgb), 0.5), 0 0 0 48px rgba(var(--accent-rgb), 0.2),
          0 0 0 96px rgba(var(--accent-rgb), 0.1);
      }

      .Events__Event_11nG_:hover .Events__Year_DDYHn {
        color: rgba(var(--accent-rgb), 1);
      }

    .Events__Event_11nG_:nth-child(odd) {
      align-self: flex-end;
      grid-row: 1;
    }

      .Events__Event_11nG_:nth-child(odd)::before {
        bottom: -140px;
      }

      .Events__Event_11nG_:nth-child(odd)::after {
        bottom: 0;
      }

    .Events__Event_11nG_:nth-child(even) {
      align-self: flex-start;
      grid-row: 3;
    }

      .Events__Event_11nG_:nth-child(even)::before {
        top: -130px;
      }

      .Events__Event_11nG_:nth-child(even)::after {
        top: 0;
      }

    .Events__Event_11nG_:nth-child(6n + 1) {
      padding-bottom: 164px;
    }

    .Events__Event_11nG_:nth-child(6n + 2) {
      padding-top: 121px;
    }

    .Events__Event_11nG_:nth-child(6n + 3) {
      padding-bottom: 224px;
    }

    .Events__Event_11nG_:nth-child(6n + 4) {
      padding-top: 176px;
    }

    .Events__Event_11nG_:nth-child(6n + 5) {
      padding-bottom: 133px;
    }

    .Events__Event_11nG_:nth-child(6n + 6) {
      padding-top: 133px;
    }

    .Events__Event_11nG_:nth-child(2) {
      grid-column: 3/4 span;
    }
  }

@media (--screen--sm-min) {

.Events__Event_11nG_ {
    padding-left: 24px;
    grid-column: 4 span;
    pointer-events: none
}

    .Events__Event_11nG_::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3px;
      background: linear-gradient(180deg, #d6ebff 0%, #1252f5 22.59%, #d6ebff 60.42%, #d6ebff 100%);
      border-radius: 100px;
      content: "";
    }

    .Events__Event_11nG_::after {
      position: absolute;
      z-index: 1;
      left: -10px;
      width: 23px;
      height: 23px;
      background-color: rgb(18, 82, 245);
      background-color: var(--color--second-blue);
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0zLjUgMHY3TTcgMy41SDAiIHN0cm9rZT0iI2ZmZiIvPjwvc3ZnPg==);
      background-position: 50%;
      background-repeat: no-repeat;
      border-radius: 50%;
      content: "";
      pointer-events: all;
      transition: background-color 0.3s, box-shadow 0.3s;
    }
      .Events__Event_11nG_:hover::after {
        background-color: rgba(var(--accent-rgb), 1);
        box-shadow: 0 0 0 16px rgba(var(--accent-rgb), 0.5), 0 0 0 48px rgba(var(--accent-rgb), 0.2),
          0 0 0 96px rgba(var(--accent-rgb), 0.1);
      }

      .Events__Event_11nG_:hover .Events__Year_DDYHn {
        color: rgba(var(--accent-rgb), 1);
      }

    .Events__Event_11nG_:nth-child(odd) {
      align-self: flex-end;
      grid-row: 1;
    }

      .Events__Event_11nG_:nth-child(odd)::before {
        bottom: -140px;
      }

      .Events__Event_11nG_:nth-child(odd)::after {
        bottom: 0;
      }

    .Events__Event_11nG_:nth-child(even) {
      align-self: flex-start;
      grid-row: 3;
    }

      .Events__Event_11nG_:nth-child(even)::before {
        top: -130px;
      }

      .Events__Event_11nG_:nth-child(even)::after {
        top: 0;
      }

    .Events__Event_11nG_:nth-child(6n + 1) {
      padding-bottom: 164px;
    }

    .Events__Event_11nG_:nth-child(6n + 2) {
      padding-top: 121px;
    }

    .Events__Event_11nG_:nth-child(6n + 3) {
      padding-bottom: 224px;
    }

    .Events__Event_11nG_:nth-child(6n + 4) {
      padding-top: 176px;
    }

    .Events__Event_11nG_:nth-child(6n + 5) {
      padding-bottom: 133px;
    }

    .Events__Event_11nG_:nth-child(6n + 6) {
      padding-top: 133px;
    }

    .Events__Event_11nG_:nth-child(2) {
      grid-column: 3/4 span;
    }
  }

@media screen and (max-width: 1023px) {
    .Events__Event_11nG_:not(:last-child) {
      margin-bottom: 32px;
    }
  }

@media (--screen--sm-max) {
    .Events__Event_11nG_:not(:last-child) {
      margin-bottom: 32px;
    }
  }

.Events__Year_DDYHn {
  margin-bottom: 8px;
  margin-bottom: var(--padding--sm);
  transition: color 0.3s;
}

@media screen and (min-width: 768px) {

.Events__Year_DDYHn {
    pointer-events: all
}
  }

@media (--screen--sm-min) {

.Events__Year_DDYHn {
    pointer-events: all
}
  }

/* Для печати */

@media print {

.Events__Year_DDYHn {
    margin-top: 10px
}
  }

.Events__Title_pYp5i {
  margin-bottom: 8px;
  margin-bottom: var(--padding--sm);
}

@media screen and (min-width: 768px) {

.Events__Title_pYp5i {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px
}
  }

@media (--screen--sm-min) {

.Events__Title_pYp5i {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px
}
  }

@media screen and (min-width: 768px) {

.Events__Title_pYp5i > span::before,
    .Events__Title_pYp5i > span::after {
        content: none
    }
      }

@media (--screen--sm-min) {

.Events__Title_pYp5i > span::before,
    .Events__Title_pYp5i > span::after {
        content: none
    }
      }

@media screen and (min-width: 768px) {

.Events__Title_pYp5i > span {
      padding: 0;
      color: currentColor
  }
    }

@media (--screen--sm-min) {

.Events__Title_pYp5i > span {
      padding: 0;
      color: currentColor
  }
    }

/* Для печати */

@media print {

.Events__Title_pYp5i > span {
      border-bottom: 1px solid #000
  }
    }

@media screen and (max-width: 767px) {

.Events__Timeline_3-JjO {
    display: none
}
  }

@media (--screen--xs-max) {

.Events__Timeline_3-JjO {
    display: none
}
  }

@media screen and (min-width: 768px) {

.Events__Timeline_3-JjO {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%)
}
  }

@media (--screen--sm-min) {

.Events__Timeline_3-JjO {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%)
}
  }

@media screen and (min-width: 768px) {

.Events__TimelineWrapper_tembV {
    position: relative;
    grid-column: 12 span;
    grid-row: 2
}
  }

@media (--screen--sm-min) {

.Events__TimelineWrapper_tembV {
    position: relative;
    grid-column: 12 span;
    grid-row: 2
}
  }
