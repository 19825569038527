.Content__Wrapper_2mbKU {
  padding-bottom: var(--padding--5xl);
}

.Content__Inner_1Ru9j {
}

@media screen and (min-width: 1024px) {

.Content__Inner_1Ru9j {
    --noOfColumns: 2;

    grid-template-rows: repeat(3, -webkit-min-content);

    grid-template-rows: repeat(3, min-content)
}
  }

@media (--screen--md-min) {

.Content__Inner_1Ru9j {
    --noOfColumns: 2;

    grid-template-rows: repeat(3, -webkit-min-content);

    grid-template-rows: repeat(3, min-content)
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Header_2Hm3t {
    grid-column: span 9
}
  }

@media (--screen--sm-only) {

.Content__Header_2Hm3t {
    grid-column: span 9
}
  }

@media screen and (max-width: 1023px) {

.Content__Header_2Hm3t {
    margin-bottom: 32px;
    grid-row: 1
}
  }

@media (--screen--sm-max) {

.Content__Header_2Hm3t {
    margin-bottom: 32px;
    grid-row: 1
}
  }

/* Для печати */

@media print {

.Content__Header_2Hm3t {
    margin-bottom: 20px
}
  }

@media screen and (max-width: 1023px) {

.Content__Name_2kacx {
    margin-bottom: 16px
}
  }

@media (--screen--sm-max) {

.Content__Name_2kacx {
    margin-bottom: 16px
}
  }

@media screen and (min-width: 1024px) {

.Content__Name_2kacx {
    margin-bottom: 24px
}
  }

@media (--screen--md-min) {

.Content__Name_2kacx {
    margin-bottom: 24px
}
  }

/* Для печати */

@media print {

.Content__Name_2kacx {
    margin-top: 20px;
    margin-bottom: 20px
}
  }

@media screen and (min-width: 768px) {

.Content__PhotoWrapper_eJvNX {
    grid-row: 1 / -1
}
  }

@media (--screen--sm-min) {

.Content__PhotoWrapper_eJvNX {
    grid-row: 1 / -1
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__PhotoWrapper_eJvNX {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Content__PhotoWrapper_eJvNX {
    grid-column: span 8
}
  }

@media screen and (max-width: 1023px) {

.Content__PhotoWrapper_eJvNX {
    grid-row: 2
}
  }

@media (--screen--sm-max) {

.Content__PhotoWrapper_eJvNX {
    grid-row: 2
}
  }

@media screen and (min-width: 1024px) {

.Content__PhotoWrapper_eJvNX {
    grid-column: 2
}
  }

@media (--screen--md-min) {

.Content__PhotoWrapper_eJvNX {
    grid-column: 2
}
  }

/* Для печати */

@media print {

.Content__PhotoWrapper_eJvNX {
    margin-bottom: 20px
}
  }

.Content__Photo_g3Exi {
  overflow: hidden;
  border-radius: 2px;
}

@media screen and (max-width: 1023px) {

.Content__Photo_g3Exi {
    margin-bottom: 16px
}
  }

@media (--screen--sm-max) {

.Content__Photo_g3Exi {
    margin-bottom: 16px
}
  }

@media screen and (min-width: 1024px) {

.Content__Photo_g3Exi {
    margin-bottom: 24px
}
  }

@media (--screen--md-min) {

.Content__Photo_g3Exi {
    margin-bottom: 24px
}
  }

.Content__Photo_g3Exi > img {
    -o-object-fit: cover;
       object-fit: cover;
  }

@media screen and (max-width: 767px) {

.Content__Photo_g3Exi > img {
      width: 366px;
      height: 366px
  }
    }

@media (--screen--xs-max) {

.Content__Photo_g3Exi > img {
      width: 366px;
      height: 366px
  }
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Photo_g3Exi > img {
      width: 468px;
      height: 468px
  }
    }

@media (--screen--sm-only) {

.Content__Photo_g3Exi > img {
      width: 468px;
      height: 468px
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Content__Photo_g3Exi > img {
      width: 430px;
      height: 430px
  }
    }

@media (--screen--md-only) {

.Content__Photo_g3Exi > img {
      width: 430px;
      height: 430px
  }
    }

@media screen and (min-width: 1366px) {

.Content__Photo_g3Exi > img {
      width: 571px;
      height: 571px
  }
    }

@media (--screen--lg-min) {

.Content__Photo_g3Exi > img {
      width: 571px;
      height: 571px
  }
    }

@media print {

.Content__Photo_g3Exi > img {
      width: 400px;
      height: 400px;
      margin-bottom: 20px
  }
    }

.Content__Quote_28gL1 {
  position: relative;
  padding-left: 24px;
  font-style: italic;
  font-weight: 300;
}

.Content__Quote_28gL1::before {
    position: absolute;
    left: 0;
    width: 16px;
    height: 19px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTguMzcyIDNMMy44MTQgOS40OSA4LjM3MiAxNkg0LjU3N0wwIDkuNDkgNC41NzcgM2gzLjc5NXpNMTYgM2wtNC41NzcgNi40OUwxNiAxNmgtMy44MTRMNy42MjggOS40OSAxMi4xODYgM0gxNnoiIGZpbGw9IiMxMjUyRjUiLz48L3N2Zz4K);
    content: "";
  }

@media screen and (max-width: 767px) {

.Content__Quote_28gL1::before {
      top: 0
  }
    }

@media (--screen--xs-max) {

.Content__Quote_28gL1::before {
      top: 0
  }
    }

@media screen and (min-width: 768px) {

.Content__Quote_28gL1::before {
      top: 6px
  }
    }

@media (--screen--sm-min) {

.Content__Quote_28gL1::before {
      top: 6px
  }
    }

@media screen and (max-width: 767px) {

.Content__Quote_28gL1 {
    font-size: 16px;
    line-height: 22px
}
  }

@media (--screen--xs-max) {

.Content__Quote_28gL1 {
    font-size: 16px;
    line-height: 22px
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Content__Quote_28gL1 {
    font-size: 26px;
    line-height: 32px
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Content__Quote_28gL1 {
    font-size: 26px;
    line-height: 32px
}
  }

@media screen and (min-width: 1366px) {

.Content__Quote_28gL1 {
    font-size: 32px;
    line-height: 38px
}
  }

@media (--screen--lg-min) {

.Content__Quote_28gL1 {
    font-size: 32px;
    line-height: 38px
}
  }

.Content__Bio_2nuO8 {
  grid-row-gap: var(--padding--4xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Bio_2nuO8 {
    grid-column: span 10
}
  }

@media (--screen--sm-only) {

.Content__Bio_2nuO8 {
    grid-column: span 10
}
  }

@media screen and (max-width: 1365px) {

.Content__Bio_2nuO8 {
    margin-top: 48px
}
  }

@media (--screen--md-max) {

.Content__Bio_2nuO8 {
    margin-top: 48px
}
  }

@media screen and (min-width: 1366px) {

.Content__Bio_2nuO8 {
    margin-top: 64px
}
  }

@media (--screen--lg-min) {

.Content__Bio_2nuO8 {
    margin-top: 64px
}
  }

/* Для печати */

@media print {

.Content__Bio_2nuO8 {
    display: flex;
    flex-direction: column
}

    .Content__Bio_2nuO8 > div {
      margin-bottom: 20px;
    }

    .Content__Bio_2nuO8 > div h2 span {
      border-bottom: 1px solid #000;
    }
  }
