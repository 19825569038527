.StatusFilter__Status_1HWOK {
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 0.3s;
}

  .StatusFilter__Status_1HWOK:not(:first-child) {
    margin-left: 40px;
  }

  @media screen and (max-width: 767px) {
    .StatusFilter__Status_1HWOK:last-child {
      display: none;
    }
  }

  @media (--screen--xs-max) {
    .StatusFilter__Status_1HWOK:last-child {
      display: none;
    }
  }

.StatusFilter__Active_22sEs {
  opacity: 1;
}

.StatusFilter__FilterByStatus_1QHip {
  display: flex;
}
