.Committee__Item_NTWR_ {
  display: block;
}

  .Committee__Item_NTWR_:not(:last-child) {
    margin-bottom: 24px;
  }

.Committee__Text_12yAB {
  font-weight: 500;
}
