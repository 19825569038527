.Attachment__Title_FTQ4K {
  position: relative;
  padding-left: 21px;
  margin-bottom: 8px;
}

  .Attachment__Title_FTQ4K::before {
    position: absolute;
    top: 2px;
    left: 0;
    width: 13px;
    height: 16px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyLjUgMTUuNUguNVYuNWg1LjlsNi4xIDUuNjgzVjE1LjV6IiBzdHJva2U9IiMxMjUyRjUiLz48L3N2Zz4K);
    content: "";
  }

.Attachment__Footer_2RqhV {
  padding-left: 20px;
}

.Attachment__Footer_2RqhV > span {
    position: relative;
    display: inline-block;
    padding-left: 12px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.3);
    color: rgba(var(--rgb--black), 0.3);
  }

.Attachment__Footer_2RqhV > span::before {
      position: absolute;
      top: 3px;
      bottom: 4px;
      left: 0;
      width: 1px;
      background: rgba(0, 0, 0, 0.3);
      background: rgba(var(--rgb--black), 0.3);
      content: "";
    }

.Attachment__Link_1Cz8i {
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  font-weight: 500;
}
