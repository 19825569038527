.AssetCard__Wrapper_H1LYH {
  padding: 16px 24px 32px;
  padding: var(--padding--lg) 24px var(--padding--2xl);
}

.AssetCard__Factoid_3HOny:not(:last-child) {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg);
  }

.AssetCard__FactoidTitle_38t_M {
  color: var(--card--accent-color);
}

.AssetCard__FactoidText_oGVkE {
  margin-top: 4px;
  margin-top: var(--padding--xs);
}
