.Footer__Wrapper_28Xd9 {
  padding: 32px 48px 40px;
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
}

  @media screen and (max-width: 767px) {.Footer__Wrapper_28Xd9 {
    margin-right: calc(24px * -1);
    margin-right: calc(var(--padding--xl) * -1);
    margin-left: calc(24px * -1);
    margin-left: calc(var(--padding--xl) * -1)
}
  }

  @media (--screen--xs-max) {.Footer__Wrapper_28Xd9 {
    margin-right: calc(24px * -1);
    margin-right: calc(var(--padding--xl) * -1);
    margin-left: calc(24px * -1);
    margin-left: calc(var(--padding--xl) * -1)
}
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {.Footer__Wrapper_28Xd9 {
    margin-right: calc(40px * -1);
    margin-right: calc(var(--padding--3xl) * -1);
    margin-left: calc(40px * -1);
    margin-left: calc(var(--padding--3xl) * -1)
}
  }

  @media (--screen--sm-only) {.Footer__Wrapper_28Xd9 {
    margin-right: calc(40px * -1);
    margin-right: calc(var(--padding--3xl) * -1);
    margin-left: calc(40px * -1);
    margin-left: calc(var(--padding--3xl) * -1)
}
  }

  @media screen and (min-width: 1024px) {.Footer__Wrapper_28Xd9 {
    margin-right: -48px;
    margin-left: -48px
}
  }

  @media (--screen--md-min) {.Footer__Wrapper_28Xd9 {
    margin-right: -48px;
    margin-left: -48px
}
  }

.Footer__Title_2Jpow {
  margin-bottom: 8px;
}

.Footer__Link_1d3Bg {
  margin-top: 16px;
}
