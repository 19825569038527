.Item__Wrapper_D_2NW {
  padding: 16px 24px 24px;
}

.Item__StateRegNumber_210I6 {
  margin-bottom: 4px;
}

.Item__Detail_3iVHC {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

.Item__DetailItem_1n9A-:nth-child(odd) {
    margin-right: 32px;
    margin-right: var(--padding--2xl);
  }

.Item__DetailItem_1n9A-:not(:nth-last-child(-n + 2)) {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg);
  }

@media screen and (min-width: 1px) and (max-width: 1023px) {

.Item__DetailItem_1n9A- {
    width: 44%
}
  }

@media (--screen--xs-min) and (--screen--sm-max) {

.Item__DetailItem_1n9A- {
    width: 44%
}
  }

@media screen and (min-width: 1024px) {

.Item__DetailItem_1n9A- {
    width: 45%
}
  }

@media (--screen--md-min) {

.Item__DetailItem_1n9A- {
    width: 45%
}
  }

.Item__DetailTitle_21A4v {
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
}
