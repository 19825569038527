.Corp__Wrapper_dBxA7 {
  padding: var(--padding--4xl) 0 var(--padding--5xl);
}

  @media screen and (min-width: 768px) {.Corp__Wrapper_dBxA7 {
    background-image: url(/_next/static/images/bg-afd26e1da3ecc1cbe6dbcc8e453d8329.jpg);
    background-position: center;
    background-repeat: no-repeat
}
  }

  @media (--screen--sm-min) {.Corp__Wrapper_dBxA7 {
    background-image: url(/_next/static/images/bg-afd26e1da3ecc1cbe6dbcc8e453d8329.jpg);
    background-position: center;
    background-repeat: no-repeat
}
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {.Corp__Wrapper_dBxA7 {
    background-position-x: 56%;
    background-size: 1074px
}
  }

  @media (--screen--sm-only) {.Corp__Wrapper_dBxA7 {
    background-position-x: 56%;
    background-size: 1074px
}
  }

  @media screen and (min-width: 1024px) and (max-width: 1365px) {.Corp__Wrapper_dBxA7 {
    background-position-x: center;
    background-size: 1080px
}
  }

  @media (--screen--md-only) {.Corp__Wrapper_dBxA7 {
    background-position-x: center;
    background-size: 1080px
}
  }

  @media screen and (min-width: 1366px) {.Corp__Wrapper_dBxA7 {
    background-position-x: center;
    background-size: contain
}
  }

  @media (--screen--lg-min) {.Corp__Wrapper_dBxA7 {
    background-position-x: center;
    background-size: contain
}
  }

  /* Для печати */

  @media print {.Corp__Wrapper_dBxA7 {
    margin-top: 20px;
    color: #000
}
  }

.Corp__Inner_2JCTZ {
}

@media screen and (min-width: 768px) {

.Corp__Inner_2JCTZ {
    --noOfColumns: 8;

    grid-column: span 8
}
  }

@media (--screen--sm-min) {

.Corp__Inner_2JCTZ {
    --noOfColumns: 8;

    grid-column: span 8
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Corp__Header_2avPe {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Corp__Header_2avPe {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Corp__Header_2avPe {
    grid-column: span 7
}
  }

@media (--screen--md-only) {

.Corp__Header_2avPe {
    grid-column: span 7
}
  }

@media screen and (min-width: 1366px) {

.Corp__Header_2avPe {
    grid-column: span 6
}
  }

@media (--screen--lg-min) {

.Corp__Header_2avPe {
    grid-column: span 6
}
  }

.Corp__Title_cwIYF {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

/* Для печати */

@media print {

.Corp__Title_cwIYF {
    font-weight: 700
}
  }

.Corp__Description_1ZCMC {
  margin-top: 32px;
  margin-top: var(--padding--2xl);
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Corp__Description_1ZCMC {
    grid-column: span 6
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Corp__Description_1ZCMC {
    grid-column: span 6
}
  }

@media screen and (min-width: 1366px) {

.Corp__Description_1ZCMC {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.Corp__Description_1ZCMC {
    grid-column: span 5
}
  }
