@media screen and (min-width: 768px) {.Content__Wrapper_3KY_J {
    padding-bottom: var(--padding--5xl)
}
  }

@media (--screen--sm-min) {.Content__Wrapper_3KY_J {
    padding-bottom: var(--padding--5xl)
}
  }

.Content__HeaderWithFlex_2LScB {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
}

.Content__HeaderWithFlexWithoutStatistics_3XElJ {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
}

.Content__Header_26oKS {
  margin-bottom: 40px;
  grid-row-gap: 24px;
  grid-row-gap: var(--padding--xl);
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Content__Header_26oKS {
    --noOfColumns: 2
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Content__Header_26oKS {
    --noOfColumns: 2
}
  }

@media screen and (min-width: 1366px) {

.Content__Header_26oKS {
    --noOfColumns: 12
}
  }

@media (--screen--lg-min) {

.Content__Header_26oKS {
    --noOfColumns: 12
}
  }

@media screen and (min-width: 1366px) {

.Content__Heading_2icXl {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.Content__Heading_2icXl {
    grid-column: span 5
}
  }

.Content__Title_1deUD {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

.Content__Info_xr27W {
  color: rgba(0, 0, 0, 0.5);
  color: rgba(var(--rgb--black), 0.5);
}

.Content__DescriptionBlock_T7erN {
  display: flex;
  grid-gap: 60px;
  gap: 60px;
  margin-bottom: 40px;
}

@media (max-width: 765px) {
  .Content__DescriptionBlock_T7erN {
    flex-direction: column;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {

.Content__Description_2vjD- {
    grid-row: 2
}
  }

@media (--screen--sm-min) {

.Content__Description_2vjD- {
    grid-row: 2
}
  }

@media screen and (min-width: 1366px) {

.Content__Description_2vjD- {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.Content__Description_2vjD- {
    grid-column: span 5
}
  }

.Content__Statistic_2ED10 {
  min-width: 300px;
}

@media screen and (min-width: 768px) {

.Content__Statistic_2ED10 {
    grid-row: 2
}
  }

@media (--screen--sm-min) {

.Content__Statistic_2ED10 {
    grid-row: 2
}
  }

@media screen and (min-width: 1366px) {

.Content__Statistic_2ED10 {
    grid-column: 7 / span 5
}
  }

@media (--screen--lg-min) {

.Content__Statistic_2ED10 {
    grid-column: 7 / span 5
}
  }

.Content__Statistic_2ED10 b {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
    font-weight: 500;
  }

.Content__InnerWithoutTeam_3YLem {
}

@media screen and (min-width: 1024px) {

.Content__InnerWithoutTeam_3YLem {
  --noOfColumns: 0
}
}

@media (--screen--md-min) {

.Content__InnerWithoutTeam_3YLem {
  --noOfColumns: 0
}
}

.Content__Inner_3nPu3 {
}

@media screen and (min-width: 1024px) {

.Content__Inner_3nPu3 {
    --noOfColumns: 2
}
  }

@media (--screen--md-min) {

.Content__Inner_3nPu3 {
    --noOfColumns: 2
}
  }

.Content__CardList_2P8lX {
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
}

@media screen and (max-width: 767px) {

.Content__CardList_2P8lX {
    grid-row-gap: 24px
}
  }

@media (--screen--xs-max) {

.Content__CardList_2P8lX {
    grid-row-gap: 24px
}
  }

@media screen and (min-width: 768px) {

.Content__CardList_2P8lX {
    grid-row-gap: 32px
}
  }

@media (--screen--sm-min) {

.Content__CardList_2P8lX {
    grid-row-gap: 32px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__CardList_2P8lX {
    --noOfColumns: 12
}
  }

@media (--screen--sm-only) {

.Content__CardList_2P8lX {
    --noOfColumns: 12
}
  }

@media screen and (max-width: 1023px) {

.Content__CardList_2P8lX {
    margin-bottom: 48px
}
  }

@media (--screen--sm-max) {

.Content__CardList_2P8lX {
    margin-bottom: 48px
}
  }

@media screen and (min-width: 1024px) {

.Content__CardList_2P8lX {
    --noOfColumns: 6
}
  }

@media (--screen--md-min) {

.Content__CardList_2P8lX {
    --noOfColumns: 6
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__CardList_2P8lX > div {
      grid-column: span 4
  }
    }

@media (--screen--sm-only) {

.Content__CardList_2P8lX > div {
      grid-column: span 4
  }
    }

@media screen and (min-width: 1024px) {

.Content__CardList_2P8lX > div {
      grid-column: span 3
  }
    }

@media (--screen--md-min) {

.Content__CardList_2P8lX > div {
      grid-column: span 3
  }
    }

.Content__NoteWithTeam_rlATy {
  margin: 30px 0 40px;
}

.Content__NoteWithoutTeam_1m-yW {
  margin-top: 30px;
}

@media (min-width: 1024px) {

.Content__NoteWithoutTeam_1m-yW {
    margin-bottom: 40px
}
  }

.Content__Note_1-Ux8 {
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
}

@media screen and (max-width: 767px) {

.Content__Note_1-Ux8 {
    padding: 48px 24px 64px;
    margin-right: -24px;
    margin-left: -24px
}
  }

@media (--screen--xs-max) {

.Content__Note_1-Ux8 {
    padding: 48px 24px 64px;
    margin-right: -24px;
    margin-left: -24px
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Content__Note_1-Ux8 {
    padding: 24px 40px 40px
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Content__Note_1-Ux8 {
    padding: 24px 40px 40px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Note_1-Ux8 {
    --noOfColumns: 2;

    grid-template-rows: repeat(2, -webkit-min-content);

    grid-template-rows: repeat(2, min-content)
}
  }

@media (--screen--sm-only) {

.Content__Note_1-Ux8 {
    --noOfColumns: 2;

    grid-template-rows: repeat(2, -webkit-min-content);

    grid-template-rows: repeat(2, min-content)
}
  }

@media screen and (max-width: 1365px) {

.Content__Note_1-Ux8 {
    grid-row-gap: 32px
}
  }

@media (--screen--md-max) {

.Content__Note_1-Ux8 {
    grid-row-gap: 32px
}
  }

@media screen and (min-width: 1366px) {

.Content__Note_1-Ux8 {
    padding: 32px 48px 48px;
    grid-row-gap: 40px
}
  }

@media (--screen--lg-min) {

.Content__Note_1-Ux8 {
    padding: 32px 48px 48px;
    grid-row-gap: 40px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Preliminarily_2Uixz {
    grid-column: 2;
    grid-row: 1 / -1
}
  }

@media (--screen--sm-only) {

.Content__Preliminarily_2Uixz {
    grid-column: 2;
    grid-row: 1 / -1
}
  }

@media screen and (max-width: 1365px) {

.Content__Name_2jyVm {
    margin-bottom: 16px
}
  }

@media (--screen--md-max) {

.Content__Name_2jyVm {
    margin-bottom: 16px
}
  }

@media screen and (min-width: 1366px) {

.Content__Name_2jyVm {
    margin-bottom: 24px
}
  }

@media (--screen--lg-min) {

.Content__Name_2jyVm {
    margin-bottom: 24px
}
  }

.Content__NoteList_3ub2h {
  grid-row-gap: 16px;
}

.Content__NoteList_3ub2h > div {
    position: relative;
    padding-left: 15px;
  }

.Content__NoteList_3ub2h > div::before {
      position: absolute;
      top: 10px;
      left: 0;
      width: 7px;
      height: 1px;
      background: rgb(18, 82, 245);
      background: var(--color--second-blue);
      content: "";
    }

.Content__DiagramWithoutTeam_nXwR- {
  margin-top: 30px;
}

@media (max-width: 765px) {

.Content__DiagramWithoutTeam_nXwR- {
    margin-bottom: 30px
}
  }
