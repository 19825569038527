@media screen and (max-width: 767px) {.Popup__Title_AA3lR {
    margin-bottom: 8px;
    margin-bottom: var(--padding--sm)
}
  }
  @media (--screen--xs-max) {.Popup__Title_AA3lR {
    margin-bottom: 8px;
    margin-bottom: var(--padding--sm)
}
  }
  @media screen and (min-width: 768px) {.Popup__Title_AA3lR {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg)
}
  }
  @media (--screen--sm-min) {.Popup__Title_AA3lR {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg)
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Popup__Contacts_pO6zs {
    width: 594px
}
  }

@media (--screen--sm-only) {

.Popup__Contacts_pO6zs {
    width: 594px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Popup__Contacts_pO6zs {
    width: 586px
}
  }

@media (--screen--md-only) {

.Popup__Contacts_pO6zs {
    width: 586px
}
  }

@media screen and (min-width: 1366px) {

.Popup__Contacts_pO6zs {
    width: 772px
}
  }

@media (--screen--lg-min) {

.Popup__Contacts_pO6zs {
    width: 772px
}
  }
