.EventCard__Wrapper_2dFLZ {
  position: relative;
  padding: 24px 32px 32px;
}

  @media screen and (min-width: 768px) {.EventCard__Wrapper_2dFLZ {
    display: flex;
    flex-direction: column;
    align-items: flex-start
}
  }

  @media (--screen--sm-min) {.EventCard__Wrapper_2dFLZ {
    display: flex;
    flex-direction: column;
    align-items: flex-start
}
  }

.EventCard__Closed_3YZBi {
  color: rgba(0, 0, 0, 0.3);
  color: rgba(var(--rgb--black), 0.3);
}

.EventCard__Closed_3YZBi:hover {
    color: rgba(0, 0, 0, 1);
    color: rgba(var(--rgb--black), 1);
  }

@media screen and (min-width: 768px) {

.EventCard__Data_3O75O {
    margin-bottom: auto
}
  }

@media (--screen--sm-min) {

.EventCard__Data_3O75O {
    margin-bottom: auto
}
  }

.EventCard__Date_3Xq9m {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  margin-bottom: var(--padding--sm);
}

.EventCard__Date_3Xq9m span {
    margin-left: 6px;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.3;
  }

.EventCard__Site_qWXYo {
  position: relative;
  z-index: 1;
  margin-top: 16px;
  margin-top: var(--padding--lg);
}

.EventCard__Buttons_3U7uo {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

@media screen and (min-width: 1024px) {

.EventCard__Buttons_3U7uo {
    display: flex
}
  }

@media (--screen--md-min) {

.EventCard__Buttons_3U7uo {
    display: flex
}
  }

.EventCard__Button_3IKHN {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
    .EventCard__Button_3IKHN:not(:last-child) {
      margin-right: 16px;
    }
  }

@media (--screen--sm-min) {
    .EventCard__Button_3IKHN:not(:last-child) {
      margin-right: 16px;
    }
  }

@media screen and (max-width: 1023px) {
    .EventCard__Button_3IKHN:not(:last-child) {
      margin-bottom: 16px;
    }
  }

@media (--screen--sm-max) {
    .EventCard__Button_3IKHN:not(:last-child) {
      margin-bottom: 16px;
    }
  }
