.Career__Wrapper_Wzr5P {
  padding: var(--padding--4xl) 0 var(--padding--5xl);
}

  /* Для печати */

  @media print {.Career__Wrapper_Wzr5P {
    margin-top: 40px
}
  }

.Career__Title_2BO83 {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

/* Для печати */

@media print {

.Career__Title_2BO83 {
    font-weight: 700
}
  }

.Career__Links_3Q7qN {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

@media screen and (min-width: 768px) {

.Career__Description_3XlKG,
.Career__Note_6ya7y {
    margin-bottom: var(--padding--4xl)
}
  }

@media (--screen--sm-min) {

.Career__Description_3XlKG,
.Career__Note_6ya7y {
    margin-bottom: var(--padding--4xl)
}
  }

@media screen and (max-width: 767px) {

.Career__Description_3XlKG {
    margin-bottom: 32px
}
  }

@media (--screen--xs-max) {

.Career__Description_3XlKG {
    margin-bottom: 32px
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Career__Description_3XlKG {
    grid-column: span 6
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Career__Description_3XlKG {
    grid-column: span 6
}
  }

@media screen and (min-width: 1366px) {

.Career__Description_3XlKG {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.Career__Description_3XlKG {
    grid-column: span 5
}
  }

@media screen and (max-width: 767px) {

.Career__Note_6ya7y {
    margin-bottom: 40px
}
  }

@media (--screen--xs-max) {

.Career__Note_6ya7y {
    margin-bottom: 40px
}
  }

@media screen and (min-width: 768px) {

.Career__Note_6ya7y {
    grid-column: 7 / span 6
}
  }

@media (--screen--sm-min) {

.Career__Note_6ya7y {
    grid-column: 7 / span 6
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Career__Note_6ya7y {
    margin-top: -8px
}
  }

@media (--screen--sm-only) {

.Career__Note_6ya7y {
    margin-top: -8px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Career__Note_6ya7y {
    margin-top: 2px
}
  }

@media (--screen--md-only) {

.Career__Note_6ya7y {
    margin-top: 2px
}
  }

@media screen and (min-width: 1366px) {

.Career__Note_6ya7y {
    margin-top: -11px
}
  }

@media (--screen--lg-min) {

.Career__Note_6ya7y {
    margin-top: -11px
}
  }

@media screen and (min-width: 768px) {

.Career__ImageText_3IFW_ {
    grid-column: span 8
}
  }

@media (--screen--sm-min) {

.Career__ImageText_3IFW_ {
    grid-column: span 8
}
  }

.Career__Image_2QJrk {
}

@media screen and (min-width: 768px) {

.Career__Image_2QJrk {
    grid-column: 9 / span 4
}
  }

@media (--screen--sm-min) {

.Career__Image_2QJrk {
    grid-column: 9 / span 4
}
  }
