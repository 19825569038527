.Case__Wrapper_lgKZ7 {
  overflow: hidden;
}

@media screen and (max-width: 767px) {

.Case__Image_QxKgV {
    overflow: hidden;
    height: 200px
}
  }

@media (--screen--xs-max) {

.Case__Image_QxKgV {
    overflow: hidden;
    height: 200px
}
  }

@media screen and (min-width: 768px) {

.Case__Image_QxKgV img {
      height: 200px;
      -o-object-fit: cover;
         object-fit: cover
  }
    }

@media (--screen--sm-min) {

.Case__Image_QxKgV img {
      height: 200px;
      -o-object-fit: cover;
         object-fit: cover
  }
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Case__Image_QxKgV img {
      width: 344px
  }
    }

@media (--screen--sm-only) {

.Case__Image_QxKgV img {
      width: 344px
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Case__Image_QxKgV img {
      width: 432px
  }
    }

@media (--screen--md-only) {

.Case__Image_QxKgV img {
      width: 432px
  }
    }

@media screen and (min-width: 1366px) {

.Case__Image_QxKgV img {
      width: 571px
  }
    }

@media (--screen--lg-min) {

.Case__Image_QxKgV img {
      width: 571px
  }
    }

.Case__Inner_14gS4 {
  padding: 16px 32px 32px;
  padding: var(--padding--lg) 32px var(--padding--2xl);
}

.Case__Top_2gYle {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Case__Tag_1fQut {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  font-weight: 500;
}

.Case__Description_2bJsf {
  margin-top: auto;
}

.Case__Description_2bJsf > div {
    margin-bottom: 4px;
    margin-bottom: var(--padding--xs);
  }

.Case__Description_2bJsf > div span {
      color: rgb(18, 82, 245);
      color: var(--color--second-blue);
    }

.Case__Description_2bJsf > div span:first-child {
        font-weight: 300;
      }

@media screen and (max-width: 1365px) {

.Case__Description_2bJsf > div span:first-child {
          font-size: 26px;
          line-height: 32px
      }
        }

@media (--screen--md-max) {

.Case__Description_2bJsf > div span:first-child {
          font-size: 26px;
          line-height: 32px
      }
        }

@media screen and (min-width: 1366px) {

.Case__Description_2bJsf > div span:first-child {
          font-size: 32px;
          line-height: 38px
      }
        }

@media (--screen--lg-min) {

.Case__Description_2bJsf > div span:first-child {
          font-size: 32px;
          line-height: 38px
      }
        }
