.Focus__Title_2EW7h {
  margin-bottom: 8px;
  margin-bottom: var(--padding--sm);
}

.Focus__Wrapper_16tNl {
  padding: 24px 40px 40px;
  padding: var(--padding--xl) 40px var(--padding--3xl);
  background-color: rgb(240, 248, 255);
  background-color: var(--color--bg-light);
}

.Focus__Wrapper_16tNl:not(:first-child) {
    margin-top: var(--padding--4xl);
  }
