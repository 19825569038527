.Quotation__Item_208lK {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.Quotation__Name_aEvtQ {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Quotation__Price_PqcAw {
  margin-bottom: 4px;
  margin-bottom: var(--padding--xs);
}

.Quotation__ChangeUp_22yLZ {
  color: rgb(0, 198, 91);
  color: var(--color--green);
}

.Quotation__ChangeDown_2EDwF {
  color: rgb(249, 57, 57);
  color: var(--color--red);
}

.Quotation__StockMarket_3q3QA {
  margin-top: 24px;
  margin-top: var(--padding--xl);
  margin-bottom: 8px;
  margin-bottom: var(--padding--sm);
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-transform: uppercase;
  white-space: nowrap;
}

.Quotation__StockMarketOnDark_11pmF {
  color: rgb(0, 196, 255);
  color: var(--color--accent-light-blue);
}

.Quotation__StockMarketOnLight_3cnxU {
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
}

.Quotation__Update_1M95K {
  font-size: 12px;
  line-height: 16px;
  opacity: 0.4;
}
