.Content__Wrapper_3p7Ep {
  padding-bottom: var(--padding--5xl);
}

.Content__Block_dh4vY:not(:first-child) {
    margin-top: var(--padding--4xl);
  }

@media screen and (min-width: 768px) {

.Content__ColLeft_2KW1E {
    grid-column: span 7
}
  }

@media (--screen--sm-min) {

.Content__ColLeft_2KW1E {
    grid-column: span 7
}
  }

.Content__ColRight_3f4SW {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__ColRight_3f4SW {
    grid-column: 8 / span 5
}
  }

@media (--screen--sm-only) {

.Content__ColRight_3f4SW {
    grid-column: 8 / span 5
}
  }

@media screen and (min-width: 1024px) {

.Content__ColRight_3f4SW {
    grid-column: 9 / span 4
}
  }

@media (--screen--md-min) {

.Content__ColRight_3f4SW {
    grid-column: 9 / span 4
}
  }
