.WebGL__Map_1YbGM::after {
    --pos: 0;
    --gradient-color: 0, 18, 117;
    --gradient-opacity: 1;
    --gradient-stop: 25%;

    position: absolute;
    top: var(--pos);
    right: var(--pos);
    bottom: var(--pos);
    left: var(--pos);
    display: block;
    background: linear-gradient(
        to bottom,
        rgba(var(--gradient-color), var(--gradient-opacity)) 0%,
        rgba(0, 0, 0, 0) var(--gradient-stop)
      ),
      linear-gradient(
        to top,
        rgba(var(--gradient-color), var(--gradient-opacity)) 0%,
        rgba(0, 0, 0, 0) var(--gradient-stop)
      ),
      linear-gradient(
        to right,
        rgba(var(--gradient-color), var(--gradient-opacity)) 0%,
        rgba(0, 0, 0, 0) var(--gradient-stop)
      ),
      linear-gradient(
        to left,
        rgba(var(--gradient-color), var(--gradient-opacity)) 0%,
        rgba(0, 0, 0, 0) var(--gradient-stop)
      );
    content: "";
  }
  .WebGL__Map_1YbGM::before {
    /* position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0) 40%
    );
    border-radius: 50%;
    content: "";
    mix-blend-mode: overlay; */
  }
  @media screen and (max-width: 767px) {.WebGL__Map_1YbGM {
    display: none
}
  }
  @media (--screen--xs-max) {.WebGL__Map_1YbGM {
    display: none
}
  }
  @media screen and (min-width: 768px) {.WebGL__Map_1YbGM {
    position: absolute;
    z-index: 0;
    overflow: hidden;
    margin-top: 0;
    mix-blend-mode: lighten
}
  }
  @media (--screen--sm-min) {.WebGL__Map_1YbGM {
    position: absolute;
    z-index: 0;
    overflow: hidden;
    margin-top: 0;
    mix-blend-mode: lighten
}
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {.WebGL__Map_1YbGM {
    top: calc(24px + 50px);
    top: calc(var(--padding--xl) + 50px)
}
  }
  @media (--screen--sm-only) {.WebGL__Map_1YbGM {
    top: calc(24px + 50px);
    top: calc(var(--padding--xl) + 50px)
}
  }
  @media screen and (min-width: 1024px) and (max-width: 1365px) {.WebGL__Map_1YbGM {
    top: calc(24px - 10px);
    top: calc(var(--padding--xl) - 10px)
}
  }
  @media (--screen--md-only) {.WebGL__Map_1YbGM {
    top: calc(24px - 10px);
    top: calc(var(--padding--xl) - 10px)
}
  }
  @media screen and (min-width: 1366px) {.WebGL__Map_1YbGM {
    top: calc(24px - 100px);
    top: calc(var(--padding--xl) - 100px);
    right: -200px;
    left: -200px
}
  }
  @media (--screen--lg-min) {.WebGL__Map_1YbGM {
    top: calc(24px - 100px);
    top: calc(var(--padding--xl) - 100px);
    right: -200px;
    left: -200px
}
  }
  /* Для печати */
  @media print {.WebGL__Map_1YbGM {
    display: none
}
  }
  .WebGL__Map_1YbGM canvas {
    opacity: 0;
    transition: opacity 500ms ease;
  }
  .WebGL__Map_1YbGM canvas[data-ready] {
      opacity: 1;
    }
