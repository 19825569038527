.Content__Wrapper_2rBGm {
  padding-bottom: var(--padding--4xl);
}

.Content__Block_SULYI:not(:last-child) {
    margin-bottom: var(--padding--4xl);
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__ColLeft_2tw7D {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Content__ColLeft_2tw7D {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) {

.Content__ColLeft_2tw7D {
    grid-column: span 8
}
  }

@media (--screen--md-min) {

.Content__ColLeft_2tw7D {
    grid-column: span 8
}
  }
