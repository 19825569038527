.ContentBlueForm__Item_2g4YA {
  font-weight: 300;
}

  @media screen and (max-width: 1023px) {

  .ContentBlueForm__Item_2g4YA:not(:last-child) {
      margin-bottom: 16px;
      margin-bottom: var(--padding--lg)
  }
    }

  @media (--screen--sm-max) {

  .ContentBlueForm__Item_2g4YA:not(:last-child) {
      margin-bottom: 16px;
      margin-bottom: var(--padding--lg)
  }
    }

  @media screen and (min-width: 1024px) {

  .ContentBlueForm__Item_2g4YA:not(:last-child) {
      margin-right: 56px
  }
    }

  @media (--screen--md-min) {

  .ContentBlueForm__Item_2g4YA:not(:last-child) {
      margin-right: 56px
  }
    }

  @media screen and (min-width: 1024px) {.ContentBlueForm__Item_2g4YA {
    display: inline-block
}
  }

  @media (--screen--md-min) {.ContentBlueForm__Item_2g4YA {
    display: inline-block
}
  }

  @media screen and (max-width: 1365px) {.ContentBlueForm__Item_2g4YA {
    font-size: 26px;
    line-height: 32px
}
  }

  @media (--screen--md-max) {.ContentBlueForm__Item_2g4YA {
    font-size: 26px;
    line-height: 32px
}
  }

  @media screen and (min-width: 1366px) {.ContentBlueForm__Item_2g4YA {
    font-size: 32px;
    line-height: 38px
}
  }

  @media (--screen--lg-min) {.ContentBlueForm__Item_2g4YA {
    font-size: 32px;
    line-height: 38px
}
  }

  .ContentBlueForm__Item_2g4YA > a::after {
      content: none;
    }
