.Content__Wrapper_2fE-O {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--5xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__ColLeft_3ySB1,
.Content__ColRight_39gn0 {
    grid-column: span 9
}
  }

@media (--screen--sm-only) {

.Content__ColLeft_3ySB1,
.Content__ColRight_39gn0 {
    grid-column: span 9
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Content__ColLeft_3ySB1 {
    grid-column: span 6
}
  }

@media (--screen--md-only) {

.Content__ColLeft_3ySB1 {
    grid-column: span 6
}
  }

@media screen and (min-width: 1366px) {

.Content__ColLeft_3ySB1 {
    grid-column: span 7
}
  }

@media (--screen--lg-min) {

.Content__ColLeft_3ySB1 {
    grid-column: span 7
}
  }

@media screen and (min-width: 1px) and (max-width: 1023px) {

.Content__ColRight_39gn0 {
    margin-bottom: 48px
}
  }

@media (--screen--xs-min) and (--screen--sm-max) {

.Content__ColRight_39gn0 {
    margin-bottom: 48px
}
  }

@media screen and (max-width: 1023px) {

.Content__ColRight_39gn0 {
    grid-row: 1
}
  }

@media (--screen--sm-max) {

.Content__ColRight_39gn0 {
    grid-row: 1
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Content__ColRight_39gn0 {
    grid-column: 8 / span 5
}
  }

@media (--screen--md-only) {

.Content__ColRight_39gn0 {
    grid-column: 8 / span 5
}
  }

@media screen and (min-width: 1366px) {

.Content__ColRight_39gn0 {
    grid-column: 9 / span 4
}
  }

@media (--screen--lg-min) {

.Content__ColRight_39gn0 {
    grid-column: 9 / span 4
}
  }

.Content__Block_19_sf:not(:first-child) {
    margin-top: var(--padding--4xl);
  }
