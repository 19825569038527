.VideoWrapper__Wrapper_3NBNF {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

  .VideoWrapper__Wrapper_3NBNF::after,
  .VideoWrapper__Wrapper_3NBNF::before {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 500px;
  }

  .VideoWrapper__Wrapper_3NBNF::after {
    right: -2px;
    background: linear-gradient(
      90deg,
      rgba(0, 18, 117, 0) 0%,
      rgb(0, 18, 117) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(var(--rgb--main-blue), 0) 0%,
      var(--color--main-blue) 100%
    );
  }

  .VideoWrapper__Wrapper_3NBNF::before {
    left: -2px;
    background: linear-gradient(
      -90deg,
      rgba(0, 18, 117, 0) 0%,
      rgb(0, 18, 117) 100%
    );
    background: linear-gradient(
      -90deg,
      rgba(var(--rgb--main-blue), 0) 0%,
      var(--color--main-blue) 100%
    );
  }

  @media screen and (min-width: 2561px) {
    .VideoWrapper__Wrapper_3NBNF::after,
    .VideoWrapper__Wrapper_3NBNF::before {
      content: "";
    }
  }

  @media screen and (max-width: 1023px) {

  .VideoWrapper__Wrapper_3NBNF video {
      display: none
  }
    }

  @media (--screen--sm-max) {

  .VideoWrapper__Wrapper_3NBNF video {
      display: none
  }
    }

.VideoWrapper__Background_RN6dv {
  /* (V)(;,,;)(V) */
}
