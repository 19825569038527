.Principles__Head_z8lfn {
  margin-bottom: 32px;
  margin-bottom: var(--padding--2xl);
}

  @media screen and (min-width: 768px) and (max-width: 1023px) {.Principles__Head_z8lfn {
    grid-column: span 10
}
  }

  @media (--screen--sm-only) {.Principles__Head_z8lfn {
    grid-column: span 10
}
  }

  @media screen and (min-width: 1024px) {.Principles__Head_z8lfn {
    grid-column: span 8
}
  }

  @media (--screen--md-min) {.Principles__Head_z8lfn {
    grid-column: span 8
}
  }

.Principles__Title_3BJJP {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Principles__SubTitle_2_csg {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

.Principles__Content_3m14n {
}

@media screen and (min-width: 768px) {

.Principles__Content_3m14n {
    --noOfColumns: 12;

    grid-column: span 12
}
  }

@media (--screen--sm-min) {

.Principles__Content_3m14n {
    --noOfColumns: 12;

    grid-column: span 12
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Principles__ColLeft_26RFl,
.Principles__ColRight_lhcm2 {
    grid-column: span 12
}
  }

@media (--screen--sm-only) {

.Principles__ColLeft_26RFl,
.Principles__ColRight_lhcm2 {
    grid-column: span 12
}
  }

@media screen and (min-width: 1366px) {

.Principles__ColLeft_26RFl,
.Principles__ColRight_lhcm2 {
    grid-column: span 6
}
  }

@media (--screen--lg-min) {

.Principles__ColLeft_26RFl,
.Principles__ColRight_lhcm2 {
    grid-column: span 6
}
  }

.Principles__ColLeft_26RFl {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Principles__ColLeft_26RFl {
    --noOfColumns: 12
}
  }

@media (--screen--sm-only) {

.Principles__ColLeft_26RFl {
    --noOfColumns: 12
}
  }

@media screen and (max-width: 1023px) {

.Principles__ColLeft_26RFl {
    margin-bottom: 48px
}
  }

@media (--screen--sm-max) {

.Principles__ColLeft_26RFl {
    margin-bottom: 48px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Principles__ColLeft_26RFl {
    grid-column: span 5
}
  }

@media (--screen--md-only) {

.Principles__ColLeft_26RFl {
    grid-column: span 5
}
  }

@media screen and (min-width: 1366px) {

.Principles__ColLeft_26RFl {
    --noOfColumns: 6
}
  }

@media (--screen--lg-min) {

.Principles__ColLeft_26RFl {
    --noOfColumns: 6
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Principles__ColRight_lhcm2 {
    grid-column: 7 / span 6
}
  }

@media (--screen--md-only) {

.Principles__ColRight_lhcm2 {
    grid-column: 7 / span 6
}
  }

.Principles__List_14Wd4 {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Principles__List_14Wd4 {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Principles__List_14Wd4 {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Principles__List_14Wd4 {
    grid-column: 1 / -1
}
  }

@media (--screen--md-only) {

.Principles__List_14Wd4 {
    grid-column: 1 / -1
}
  }

@media screen and (min-width: 1366px) {

.Principles__List_14Wd4 {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.Principles__List_14Wd4 {
    grid-column: span 5
}
  }

.Principles__List_14Wd4 ul {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;
  }

.Principles__List_14Wd4 ul > li {
      position: relative;
      padding-left: 20px;
    }

.Principles__List_14Wd4 ul > li::before {
        position: absolute;
        top: 9px;
        left: 0;
        width: 8px;
        height: 1px;
        background: rgb(18, 82, 245);
        background: var(--color--second-blue);
        content: "";
      }

.Principles__List_14Wd4 ul > li:not(:last-child) {
        margin-bottom: 24px;
        margin-bottom: var(--padding--xl);
      }

.Principles__Attachments_7OBx0 {
  grid-row-gap: 32px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Principles__Attachments_7OBx0 {
    --noOfColumns: 10;

    grid-column: span 10
}
  }

@media (--screen--sm-only) {

.Principles__Attachments_7OBx0 {
    --noOfColumns: 10;

    grid-column: span 10
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Principles__Attachments_7OBx0 {
    grid-column: 1 / -1
}
  }

@media (--screen--md-only) {

.Principles__Attachments_7OBx0 {
    grid-column: 1 / -1
}
  }

@media screen and (min-width: 1366px) {

.Principles__Attachments_7OBx0 {
    --noOfColumns: 6;

    grid-column: span 6
}
  }

@media (--screen--lg-min) {

.Principles__Attachments_7OBx0 {
    --noOfColumns: 6;

    grid-column: span 6
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Principles__Attachment_1VNkt {
    grid-column: span 5
}
  }

@media (--screen--sm-only) {

.Principles__Attachment_1VNkt {
    grid-column: span 5
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Principles__Attachment_1VNkt {
    grid-column: 1 / -1
}
  }

@media (--screen--md-only) {

.Principles__Attachment_1VNkt {
    grid-column: 1 / -1
}
  }

@media screen and (min-width: 1366px) {

.Principles__Attachment_1VNkt {
    grid-column: span 3
}
  }

@media (--screen--lg-min) {

.Principles__Attachment_1VNkt {
    grid-column: span 3
}
  }

.Principles__Block_PW9O5 {
  margin-top: 40px;
  margin-top: var(--padding--3xl);
}

@media screen and (min-width: 768px) {

.Principles__Block_PW9O5 {
    grid-column: 1 / -1
}
  }

@media (--screen--sm-min) {

.Principles__Block_PW9O5 {
    grid-column: 1 / -1
}
  }

.Principles__BlockAttachment_1oAlD {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

.Principles__CardWrapper_1_nYI {
  padding: 24px 32px 32px;
}

.Principles__CardWrapper_1_nYI:not(:last-child) {
    margin-bottom: 32px;
  }

.Principles__CardHead_Gg_CX {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}

.Principles__CardLogo_3KEJX {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

@media screen and (max-width: 767px) {

.Principles__CardLogo_3KEJX {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

@media (--screen--xs-max) {

.Principles__CardLogo_3KEJX {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

@media screen and (min-width: 768px) {

.Principles__CardLogo_3KEJX {
    flex-shrink: 0;
    margin-right: 16px
}
  }

@media (--screen--sm-min) {

.Principles__CardLogo_3KEJX {
    flex-shrink: 0;
    margin-right: 16px
}
  }

.Principles__CardLogo_3KEJX > img {
    max-height: 58px;
  }

.Principles__CardLink_2IcuC {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}
