.Content__Wrapper_3RJVI {
  padding-bottom: var(--padding--4xl);
}

.Content__Block_1RZGn:not(:last-child) {
    margin-bottom: var(--padding--4xl);
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__Block_1RZGn {
    width: calc(8 / 12 * 100%)
}
  }

@media (--screen--sm-only) {

.Content__Block_1RZGn {
    width: calc(8 / 12 * 100%)
}
  }

@media screen and (min-width: 1024px) {

.Content__Block_1RZGn {
    width: calc(8 / 12 * 100%)
}
  }

@media (--screen--md-min) {

.Content__Block_1RZGn {
    width: calc(8 / 12 * 100%)
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Content__ColLeft_QvDUN {
    grid-column: span 12
}
  }

@media (--screen--sm-only) {

.Content__ColLeft_QvDUN {
    grid-column: span 12
}
  }

@media screen and (min-width: 1024px) {

.Content__ColLeft_QvDUN {
    grid-column: span 12
}
  }

@media (--screen--md-min) {

.Content__ColLeft_QvDUN {
    grid-column: span 12
}
  }
