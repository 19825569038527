.Hero__Wrapper_1B1ZT {
  padding-bottom: var(--padding--4xl);
}

.Hero__Head_3B8L3 {
  margin-bottom: var(--padding--4xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Hero__Head_3B8L3 {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.Hero__Head_3B8L3 {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Hero__Head_3B8L3 {
    grid-column: span 7
}
  }

@media (--screen--md-only) {

.Hero__Head_3B8L3 {
    grid-column: span 7
}
  }

@media screen and (min-width: 1366px) {

.Hero__Head_3B8L3 {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.Hero__Head_3B8L3 {
    grid-column: span 5
}
  }

.Hero__Title_2mpNg {
  margin-bottom: 24px;
  margin-bottom: var(--padding--xl);
}
