.ButtonsWrapper__Wrapper_2dxkg {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

  .ButtonsWrapper__Wrapper_2dxkg > .ButtonsWrapper__Button_1JbUl:not(:first-child) {
      margin-top: 16px;
      margin-top: var(--padding--lg);
    }

.ButtonsWrapper__Button_1JbUl {
  width: 100%;
  padding: 0;
}
