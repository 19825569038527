.About__Wrapper_1KHxg {
  padding-top: var(--padding--4xl);
  padding-bottom: var(--padding--5xl);
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
}

.About__Inner_2mqN7 {
}

@media screen and (max-width: 1023px) {

.About__Inner_2mqN7 {
    grid-row-gap: 40px
}
  }

@media (--screen--sm-max) {

.About__Inner_2mqN7 {
    grid-row-gap: 40px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.About__Inner_2mqN7 {
    --noOfColumns: 2
}
  }

@media (--screen--md-only) {

.About__Inner_2mqN7 {
    --noOfColumns: 2
}
  }

@media screen and (min-width: 1366px) {

.About__Inner_2mqN7 {
    --noOfColumns: 12
}
  }

@media (--screen--lg-min) {

.About__Inner_2mqN7 {
    --noOfColumns: 12
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.About__Person_kjHwq {
    grid-column: span 1
}
  }

@media (--screen--md-only) {

.About__Person_kjHwq {
    grid-column: span 1
}
  }

@media screen and (min-width: 1366px) {

.About__Person_kjHwq {
    grid-column: span 5
}
  }

@media (--screen--lg-min) {

.About__Person_kjHwq {
    grid-column: span 5
}
  }

.About__ColRight_1AdAt {
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
}

@media screen and (min-width: 1024px) {

.About__ColRight_1AdAt {
    --noOfColumns: 6
}
  }

@media (--screen--md-min) {

.About__ColRight_1AdAt {
    --noOfColumns: 6
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.About__ColRight_1AdAt {
    grid-column: 2 / span 1
}
  }

@media (--screen--md-only) {

.About__ColRight_1AdAt {
    grid-column: 2 / span 1
}
  }

@media screen and (min-width: 1366px) {

.About__ColRight_1AdAt {
    grid-column: 7 / span 6
}
  }

@media (--screen--lg-min) {

.About__ColRight_1AdAt {
    grid-column: 7 / span 6
}
  }

.About__Logo_PpWV4 {
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.About__Logo_PpWV4 {
    margin-top: 12px;
    margin-bottom: 68px;
    grid-column: span 4
}
  }

@media (--screen--md-only) {

.About__Logo_PpWV4 {
    margin-top: 12px;
    margin-bottom: 68px;
    grid-column: span 4
}
  }

@media screen and (min-width: 1366px) {

.About__Logo_PpWV4 {
    margin-top: 32px;
    margin-bottom: 92px;
    grid-column: span 3
}
  }

@media (--screen--lg-min) {

.About__Logo_PpWV4 {
    margin-top: 32px;
    margin-bottom: 92px;
    grid-column: span 3
}
  }

@media screen and (min-width: 1024px) {

.About__CardWrapper_1xljD {
    grid-column: span 6
}
  }

@media (--screen--md-min) {

.About__CardWrapper_1xljD {
    grid-column: span 6
}
  }
