.Ideas__Ideas_25BAD {
}

  @media screen and (min-width: 1024px) {.Ideas__Ideas_25BAD {
    --noOfColumns: 3
}
  }

  @media (--screen--md-min) {.Ideas__Ideas_25BAD {
    --noOfColumns: 3
}
  }

.Ideas__Wrapper_9jr7n {
  padding: var(--padding--4xl) 0 var(--padding--5xl);
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
}
