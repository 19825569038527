.TeamCardMain__Wrapper_3G-tf {
  position: relative;
  margin-bottom: 40px;
}

  @media screen and (min-width: 768px) {.TeamCardMain__Wrapper_3G-tf {
    display: flex;
    border: 1px solid rgba(18, 82, 245, 0.1);
    border: 1px solid rgba(var(--rgb--second-blue), 0.1);
    background: rgb(255, 255, 255);
    background: var(--color--white);
    border-radius: 2px;
    box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1);
    transform: translateY(0);
    transition: transform ease-in-out 0.3s, box-shadow ease-in-out 0.3s
}

    .TeamCardMain__Wrapper_3G-tf:hover {
      box-shadow: 0 24px 32px rgba(18, 82, 245, 0.15);
      transform: translateY(-4px);
    }

      .TeamCardMain__Wrapper_3G-tf:hover .TeamCardMain__Name_9RUH4 {
        color: rgb(18, 82, 245);
        color: var(--color--second-blue);
      }
  }

  @media (--screen--sm-min) {.TeamCardMain__Wrapper_3G-tf {
    display: flex;
    border: 1px solid rgba(18, 82, 245, 0.1);
    border: 1px solid rgba(var(--rgb--second-blue), 0.1);
    background: rgb(255, 255, 255);
    background: var(--color--white);
    border-radius: 2px;
    box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1);
    transform: translateY(0);
    transition: transform ease-in-out 0.3s, box-shadow ease-in-out 0.3s
}

    .TeamCardMain__Wrapper_3G-tf:hover {
      box-shadow: 0 24px 32px rgba(18, 82, 245, 0.15);
      transform: translateY(-4px);
    }

      .TeamCardMain__Wrapper_3G-tf:hover .TeamCardMain__Name_9RUH4 {
        color: rgb(18, 82, 245);
        color: var(--color--second-blue);
      }
  }

  @media screen and (min-width: 768px) and (max-width: 1365px) {.TeamCardMain__Wrapper_3G-tf {
    padding: 40px
}
  }

  @media (--screen--sm-min) and (--screen--md-max) {.TeamCardMain__Wrapper_3G-tf {
    padding: 40px
}
  }

  @media screen and (min-width: 1366px) {.TeamCardMain__Wrapper_3G-tf {
    padding: 56px
}
  }

  @media (--screen--lg-min) {.TeamCardMain__Wrapper_3G-tf {
    padding: 56px
}
  }

.TeamCardMain__Link_28CRg {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.TeamCardMain__Photo_14-T0 {
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 2px;
}

@media screen and (max-width: 767px) {

.TeamCardMain__Photo_14-T0 {
    margin-bottom: 16px
}
  }

@media (--screen--xs-max) {

.TeamCardMain__Photo_14-T0 {
    margin-bottom: 16px
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.TeamCardMain__Photo_14-T0 {
    margin-right: 40px
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.TeamCardMain__Photo_14-T0 {
    margin-right: 40px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.TeamCardMain__Photo_14-T0 {
    width: 296px
}
  }

@media (--screen--sm-only) {

.TeamCardMain__Photo_14-T0 {
    width: 296px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.TeamCardMain__Photo_14-T0 {
    width: 384px
}
  }

@media (--screen--md-only) {

.TeamCardMain__Photo_14-T0 {
    width: 384px
}
  }

@media screen and (min-width: 1366px) {

.TeamCardMain__Photo_14-T0 {
    width: 490px;
    margin-right: 60px
}
  }

@media (--screen--lg-min) {

.TeamCardMain__Photo_14-T0 {
    width: 490px;
    margin-right: 60px
}
  }

.TeamCardMain__Photo_14-T0 > img {
    -o-object-fit: cover;
       object-fit: cover;
  }

@media screen and (max-width: 767px) {

.TeamCardMain__Photo_14-T0 > img {
      width: 366px;
      height: 366px
  }
    }

@media (--screen--xs-max) {

.TeamCardMain__Photo_14-T0 > img {
      width: 366px;
      height: 366px
  }
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.TeamCardMain__Photo_14-T0 > img {
      width: 296px;
      height: 296px
  }
    }

@media (--screen--sm-only) {

.TeamCardMain__Photo_14-T0 > img {
      width: 296px;
      height: 296px
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.TeamCardMain__Photo_14-T0 > img {
      width: 388px;
      height: 384px
  }
    }

@media (--screen--md-only) {

.TeamCardMain__Photo_14-T0 > img {
      width: 388px;
      height: 384px
  }
    }

@media screen and (min-width: 1366px) {

.TeamCardMain__Photo_14-T0 > img {
      width: 490px;
      height: 490px
  }
    }

@media (--screen--lg-min) {

.TeamCardMain__Photo_14-T0 > img {
      width: 490px;
      height: 490px
  }
    }

@media print {

.TeamCardMain__Photo_14-T0 > img {
      width: 400px;
      height: 400px
  }
    }

.TeamCardMain__Name_9RUH4 {
  transition: color ease-in-out 0.3s;
}

@media screen and (max-width: 767px) {

.TeamCardMain__Name_9RUH4 {
    font-size: 26px;
    font-weight: 500;
    line-height: 32px
}
  }

@media (--screen--xs-max) {

.TeamCardMain__Name_9RUH4 {
    font-size: 26px;
    font-weight: 500;
    line-height: 32px
}
  }

@media screen and (min-width: 768px) {

.TeamCardMain__Name_9RUH4 {
    margin-left: -3px;
    font-weight: 300
}
  }

@media (--screen--sm-min) {

.TeamCardMain__Name_9RUH4 {
    margin-left: -3px;
    font-weight: 300
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.TeamCardMain__Name_9RUH4 {
    font-size: 48px;
    line-height: 56px
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.TeamCardMain__Name_9RUH4 {
    font-size: 48px;
    line-height: 56px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.TeamCardMain__Name_9RUH4 {
    margin-top: -12px
}
  }

@media (--screen--sm-only) {

.TeamCardMain__Name_9RUH4 {
    margin-top: -12px
}
  }

@media screen and (max-width: 1023px) {

.TeamCardMain__Name_9RUH4 {
    margin-bottom: 16px
}
  }

@media (--screen--sm-max) {

.TeamCardMain__Name_9RUH4 {
    margin-bottom: 16px
}
  }

@media screen and (min-width: 1024px) {

.TeamCardMain__Name_9RUH4 {
    margin-bottom: 24px
}
  }

@media (--screen--md-min) {

.TeamCardMain__Name_9RUH4 {
    margin-bottom: 24px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.TeamCardMain__Name_9RUH4 {
    margin-top: -11px
}
  }

@media (--screen--md-only) {

.TeamCardMain__Name_9RUH4 {
    margin-top: -11px
}
  }

@media screen and (min-width: 1366px) {

.TeamCardMain__Name_9RUH4 {
    margin-top: -14px;
    font-size: 56px;
    line-height: 64px
}
  }

@media (--screen--lg-min) {

.TeamCardMain__Name_9RUH4 {
    margin-top: -14px;
    font-size: 56px;
    line-height: 64px
}
  }

@media screen and (max-width: 767px) {

.TeamCardMain__Job_33XaP {
    margin-bottom: 24px
}
  }

@media (--screen--xs-max) {

.TeamCardMain__Job_33XaP {
    margin-bottom: 24px
}
  }

@media screen and (min-width: 768px) {

.TeamCardMain__Job_33XaP {
    margin-bottom: 32px;
    font-weight: 300
}
  }

@media (--screen--sm-min) {

.TeamCardMain__Job_33XaP {
    margin-bottom: 32px;
    font-weight: 300
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.TeamCardMain__Job_33XaP {
    font-size: 26px;
    line-height: 32px
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.TeamCardMain__Job_33XaP {
    font-size: 26px;
    line-height: 32px
}
  }

@media screen and (min-width: 1366px) {

.TeamCardMain__Job_33XaP {
    font-size: 32px;
    line-height: 38px
}
  }

@media (--screen--lg-min) {

.TeamCardMain__Job_33XaP {
    font-size: 32px;
    line-height: 38px
}
  }

.TeamCardMain__Committees_2XcOz {
  margin-top: 32px;
  margin-top: var(--padding--2xl);
}

.TeamCardMain__Committees_2XcOz a {
    position: relative;
    z-index: 1;
  }

.TeamCardMain__CommitteeHeading_2rRMr {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
  font-weight: 500;
}
