.SocialInvestment__Wrapper_2lYKn {
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.SocialInvestment__Content_1Vz1u {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.SocialInvestment__Content_1Vz1u {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) {

.SocialInvestment__Content_1Vz1u {
    grid-column: span 5
}
  }

@media (--screen--md-min) {

.SocialInvestment__Content_1Vz1u {
    grid-column: span 5
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.SocialInvestment__Quote_3XbjV {
    grid-column: span 8
}
  }

@media (--screen--sm-only) {

.SocialInvestment__Quote_3XbjV {
    grid-column: span 8
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.SocialInvestment__Quote_3XbjV {
    margin-top: 31px;
    grid-column: 7 / span 6;
    grid-row: 1 / -1
}
  }

@media (--screen--md-only) {

.SocialInvestment__Quote_3XbjV {
    margin-top: 31px;
    grid-column: 7 / span 6;
    grid-row: 1 / -1
}
  }

@media screen and (min-width: 1366px) {

.SocialInvestment__Quote_3XbjV {
    margin-top: 36px;
    grid-column: 7 / span 5
}
  }

@media (--screen--lg-min) {

.SocialInvestment__Quote_3XbjV {
    margin-top: 36px;
    grid-column: 7 / span 5
}
  }

@media screen and (max-width: 1365px) {

.SocialInvestment__Title_3grz0 {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

@media (--screen--md-max) {

.SocialInvestment__Title_3grz0 {
    margin-bottom: 24px;
    margin-bottom: var(--padding--xl)
}
  }

@media screen and (min-width: 1366px) {

.SocialInvestment__Title_3grz0 {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl)
}
  }

@media (--screen--lg-min) {

.SocialInvestment__Title_3grz0 {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl)
}
  }

@media screen and (max-width: 1023px) {

.SocialInvestment__Description_3ciYf {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl)
}
  }

@media (--screen--sm-max) {

.SocialInvestment__Description_3ciYf {
    margin-bottom: 40px;
    margin-bottom: var(--padding--3xl)
}
  }

.SocialInvestment__FootNote_3NHsr {
  margin-top: 32px;
  margin-top: var(--padding--2xl);
}

.SocialInvestment__FootNote_3NHsr > span {
    display: block;
    margin-bottom: 8px;
    margin-bottom: var(--padding--sm);
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
  }

.SocialInvestment__FootNote_3NHsr > span > b {
      margin-right: 6px;
      font-weight: 300;
    }

@media screen and (max-width: 1365px) {

.SocialInvestment__FootNote_3NHsr > span > b {
        font-size: 26px;
        line-height: 32px
    }
      }

@media (--screen--md-max) {

.SocialInvestment__FootNote_3NHsr > span > b {
        font-size: 26px;
        line-height: 32px
    }
      }

@media screen and (min-width: 1366px) {

.SocialInvestment__FootNote_3NHsr > span > b {
        font-size: 32px;
        line-height: 38px
    }
      }

@media (--screen--lg-min) {

.SocialInvestment__FootNote_3NHsr > span > b {
        font-size: 32px;
        line-height: 38px
    }
      }
