.Portfolio__Head_3YXE9 {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Portfolio__Title_1f2jS {
    grid-column: span 6
}
  }

@media (--screen--sm-only) {

.Portfolio__Title_1f2jS {
    grid-column: span 6
}
  }

@media screen and (min-width: 1024px) {

.Portfolio__Title_1f2jS {
    grid-column: span 5
}
  }

@media (--screen--md-min) {

.Portfolio__Title_1f2jS {
    grid-column: span 5
}
  }

/* Для печати */

@media print {

.Portfolio__Title_1f2jS {
    margin-bottom: 20px
}
  }

.Portfolio__Icons_2RDPm {
}

@media screen and (min-width: 1024px) {

.Portfolio__Icons_2RDPm {
    align-self: flex-end
}
  }

@media (--screen--md-min) {

.Portfolio__Icons_2RDPm {
    align-self: flex-end
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Portfolio__Icons_2RDPm {
    grid-column: 7 / span 6
}
  }

@media (--screen--md-only) {

.Portfolio__Icons_2RDPm {
    grid-column: 7 / span 6
}
  }

@media screen and (min-width: 1366px) {

.Portfolio__Icons_2RDPm {
    grid-column: 8 / span 5
}
  }

@media (--screen--lg-min) {

.Portfolio__Icons_2RDPm {
    grid-column: 8 / span 5
}
  }

.Portfolio__List_2PVYY {
  grid-row-gap: 32px;
}

@media screen and (min-width: 1024px) {

.Portfolio__List_2PVYY {
    --noOfColumns: 2
}
  }

@media (--screen--md-min) {

.Portfolio__List_2PVYY {
    --noOfColumns: 2
}
  }
