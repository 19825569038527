.Hero__Head_8dzEs {
  margin-bottom: 32px;
}

  @media screen and (min-width: 1366px) {.Hero__Head_8dzEs {
    display: flex;
    align-items: flex-end;
    justify-content: space-between
}
  }

  @media (--screen--lg-min) {.Hero__Head_8dzEs {
    display: flex;
    align-items: flex-end;
    justify-content: space-between
}
  }

@media screen and (max-width: 1365px) {

.Hero__Title_1FYRa {
    margin-bottom: 24px
}
  }

@media (--screen--md-max) {

.Hero__Title_1FYRa {
    margin-bottom: 24px
}
  }

@media screen and (max-width: 767px) {

.Hero__Download_3fMRu {
    width: auto
}
  }

@media (--screen--xs-max) {

.Hero__Download_3fMRu {
    width: auto
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Hero__Download_3fMRu {
    align-items: center;
    margin-top: 3px
}
  }

@media (--screen--md-only) {

.Hero__Download_3fMRu {
    align-items: center;
    margin-top: 3px
}
  }

@media screen and (min-width: 1366px) {

.Hero__Download_3fMRu {
    align-items: flex-end;
    margin-bottom: 5px
}
  }

@media (--screen--lg-min) {

.Hero__Download_3fMRu {
    align-items: flex-end;
    margin-bottom: 5px
}
  }

.Hero__LegendLine_3d5JW {
  position: relative;
  padding-left: 32px;
}

.Hero__LegendLine_3d5JW::before {
    position: absolute;
    top: 4px;
    left: 0;
    width: 26px;
    height: 15px;
    content: "";
  }

@media screen and (max-width: 767px) {

.Hero__LegendLine_3d5JW:not(:last-child) {
      margin-bottom: 8px
  }
    }

@media (--screen--xs-max) {

.Hero__LegendLine_3d5JW:not(:last-child) {
      margin-bottom: 8px
  }
    }

@media screen and (min-width: 768px) {

.Hero__LegendLine_3d5JW:not(:last-child) {
      margin-right: 40px
  }
    }

@media (--screen--sm-min) {

.Hero__LegendLine_3d5JW:not(:last-child) {
      margin-right: 40px
  }
    }

.Hero__LegendSolid_1Bp1P {
}

.Hero__LegendSolid_1Bp1P::before {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE3Ljk1IDFsNi40NSA2LjQ1LTYuNDUgNi40NU0wIDcuNWgyNCIgc3Ryb2tlPSIjMTI1MkY1Ii8+PC9zdmc+Cg==);
  }

.Hero__LegendDashed_2rj2m {
}

.Hero__LegendDashed_2rj2m::before {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE3Ljk1IDFsNi40NSA2LjQ1LTYuNDUgNi40NSIgc3Ryb2tlPSIjMTI1MkY1Ii8+PHBhdGggZD0iTTAgNy41aDI0IiBzdHJva2U9IiMxMjUyRjUiIHN0cm9rZS1kYXNoYXJyYXk9IjggOCIvPjwvc3ZnPgo=);
  }
