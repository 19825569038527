.Filter__Filters_3cokL {
  margin-bottom: var(--padding--4xl);
}

.Filter__Grid_3Am2- {
}

@media screen and (max-width: 767px) {

.Filter__Grid_3Am2- {
    grid-row-gap: 24px
}
  }

@media (--screen--xs-max) {

.Filter__Grid_3Am2- {
    grid-row-gap: 24px
}
  }

@media screen and (min-width: 768px) {

.Filter__Grid_3Am2- {
    --noOfColumns: 12
}
  }

@media (--screen--sm-min) {

.Filter__Grid_3Am2- {
    --noOfColumns: 12
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Filter__Grid_3Am2- {
    grid-row-gap: 32px
}
  }

@media (--screen--sm-only) {

.Filter__Grid_3Am2- {
    grid-row-gap: 32px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Filter__Grid_3Am2- > div:nth-child(1) {
        grid-column: 12 span
    }
      }

@media (--screen--sm-only) {

.Filter__Grid_3Am2- > div:nth-child(1) {
        grid-column: 12 span
    }
      }

@media screen and (min-width: 1024px) {

.Filter__Grid_3Am2- > div:nth-child(1) {
        grid-column: 6 span
    }
      }

@media (--screen--md-min) {

.Filter__Grid_3Am2- > div:nth-child(1) {
        grid-column: 6 span
    }
      }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Filter__Grid_3Am2- > div:nth-child(2) {
        grid-column: 6 span
    }
      }

@media (--screen--sm-only) {

.Filter__Grid_3Am2- > div:nth-child(2) {
        grid-column: 6 span
    }
      }

@media screen and (min-width: 1024px) {

.Filter__Grid_3Am2- > div:nth-child(2) {
        grid-column: 3 span
    }
      }

@media (--screen--md-min) {

.Filter__Grid_3Am2- > div:nth-child(2) {
        grid-column: 3 span
    }
      }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Filter__Grid_3Am2- > div:nth-child(3) {
        grid-column: 6 span
    }
      }

@media (--screen--sm-only) {

.Filter__Grid_3Am2- > div:nth-child(3) {
        grid-column: 6 span
    }
      }

@media screen and (min-width: 1024px) {

.Filter__Grid_3Am2- > div:nth-child(3) {
        grid-column: 3 span
    }
      }

@media (--screen--md-min) {

.Filter__Grid_3Am2- > div:nth-child(3) {
        grid-column: 3 span
    }
      }

.Filter__Reset_U93mx {
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  color: rgb(249, 57, 57);
  color: var(--color--red);
  opacity: 0.3;
  transition: color 0.3s, opacity 0.3s;
}

.Filter__Reset_U93mx::before,
  .Filter__Reset_U93mx::after {
    position: absolute;
    top: 0;
    top: 50%;
    bottom: 0;
    left: 50%;
    width: 15px;
    height: 1px;
    background: currentColor;
    content: "";
    transform: translate(-50%, -50%) rotate(var(--rotate));
  }

.Filter__Reset_U93mx::before {
    --rotate: 45deg;
  }

.Filter__Reset_U93mx::after {
    --rotate: -45deg;
  }

.Filter__Category_3bJow {
  display: flex;
  width: auto;
  max-width: 100%;
  align-items: center;
  margin-bottom: 16px;
}

.Filter__Category_3bJow:hover .Filter__Reset_U93mx {
      color: rgb(255, 255, 255);
      color: var(--color--white);
      opacity: 1;
    }

.Filter__Category_3bJow:not(:last-child) {
    margin-right: 16px;
  }

@media screen and (max-width: 767px) {

.Filter__Category_3bJow {
    width: auto;
    max-width: 100%
}
  }

@media (--screen--xs-max) {

.Filter__Category_3bJow {
    width: auto;
    max-width: 100%
}
  }

.Filter__Categories_1uCMT {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 32px;
  margin-top: var(--padding--2xl);
  margin-bottom: -16px;
}

.Filter__CategoryName_TsoKn {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Filter__ResetCategories_1EiAK {
  border-color: rgba(249, 57, 57, 0.3) !important;
  border-color: rgba(var(--rgb--red), 0.3) !important;
  color: rgb(249, 57, 57) !important;
  color: var(--color--red) !important;
}

.Filter__ResetCategories_1EiAK:hover {
    background-color: rgb(249, 57, 57) !important;
    background-color: var(--color--red) !important;
    color: rgb(255, 255, 255) !important;
    color: var(--color--white) !important;
  }

.Filter__StatusFilter_2NNTu {
  margin-bottom: 40px;
  margin-bottom: var(--padding--3xl);
}
